import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

function CircularProgressWithLabel(props) {
  const { evalVal } = props;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
     {evalVal && (  <Stack spacing={20} direction="row">
       <CircularProgress
          style={{ width: "54px", height: "40px", marginLeft: "-17px" }}
          color="success"
          variant="determinate"
          value={evalVal}
        />
        <CircularProgress
          style={{ width: "54px", height: "40px", marginLeft: "20px" }}
          color="error"
          variant="determinate"
          value={100-evalVal}
        />
      </Stack>)} 
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          style={{ marginRight: "18px" }}
          color="text.secondary"
        >
          {`${evalVal.toFixed(2)}%`}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          style={{ marginLeft: "20px" }}
          color="text.secondary"
        >
          {`${(100-evalVal).toFixed(2)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
