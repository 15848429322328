import React, { useEffect } from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import Divider from "@mui/material/Divider";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function LifeScience() {
  const clientControls = useAnimation();
  const challengeControls = useAnimation();
  const solControls = useAnimation();
  const whatControls = useAnimation();
  const qusControls = useAnimation();
  const craftControls = useAnimation();

  const resControls = useAnimation();

  const [clientRef, inViewClient] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [challengeRef, inViewChallenge] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [whatRef, inViewWhat] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [qusRef, inViewQus] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [craftRef, inViewCraft] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });

  useEffect(() => {
    clientControls.start({
      opacity: inViewClient ? 1 : 0,
      y: inViewClient ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [clientControls, inViewClient]);

  useEffect(() => {
    challengeControls.start({
      opacity: inViewChallenge ? 1 : 0,
      y: inViewChallenge ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [challengeControls, inViewChallenge]);
  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);
  useEffect(() => {
    whatControls.start({
      opacity: inViewWhat ? 1 : 0,
      y: inViewWhat ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [whatControls, inViewWhat]);
  useEffect(() => {
    qusControls.start({
      opacity: inViewQus ? 1 : 0,
      y: inViewQus ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [qusControls, inViewQus]);
  useEffect(() => {
    craftControls.start({
      opacity: inViewCraft ? 1 : 0,
      y: inViewCraft ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [craftControls, inViewCraft]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  const solutionM = [
    {
      title: "Disease Understanding",
      desc: "AI revolutionizes disease understanding through genomics sequencing. By analyzing vast genomic datasets, AI identifies disease-related genetic variations like mutations and deletions. This accelerates the detection of genes associated with conditions such as cancer and rare genetic disorders. Swift data analysis enables early diagnoses, precise risk assessments, and the development of targeted therapies, transforming healthcare outcomes.",
    },
    {
      title: "Evolutionary Insights",
      desc: "The combination of genomics sequencing and AI revolutionizes evolution understanding. AI identifies shared genes, structural variations, and adaptations, unveiling insights into species diversification, migration, and environmental adaptations. Analyzing human, Neanderthal, and Denisovan genomes reveals interbreeding events and unique genetic variations, providing a holistic perspective on biodiversity's intricate processes.",
    },
    {
      title: "Precision Medicine",
      desc: "Accurate solar energy forecasting aids solar plant operators and traders in decision-making for production and sales. Precision medicine, integrating genomic sequencing and AI, tailors treatment plans based on individual genetic profiles, improving outcomes with minimized side effects. Reliable forecasts optimize energy trading strategies, preventing imbalances, and maximizing revenue during peak demand.",
    },
  ];
  const results = [
    {
      title: "Overcoming Complex",
      extra: "Computational Needs",

      imgUrl:
        "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/lifeScience1.jpg",
      desc: "To tackle the intense computational demands of genomic sequencing, Smarttrak AI introduces a GPU-based infrastructure designed to handle the immense data involved in training genomic models. Our approach leverages the parallel processing capabilities of GPUs, resulting in significantly accelerated model training speeds and enabling rapid insights.",
    },
    {
      title: "Enhanced Model Accuracy",
      extra: "through Optimized Algorithms",

      imgUrl:
        "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/lifescience2.jpg",

      desc: "We optimize the client’s machine learning algorithms specifically for the GPU infrastructure, increasing model accuracy and predictive capabilities. This fine-tuning allows for more precise analysis of genomic variants, improving the detection of mutations associated with diseases and enhancing the model's adaptability to complex datasets.",
    },
    {
      title: "Adaptive Data Processing",
      extra: "for Precision Medicine",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/rg3.jpg",

      desc: "By integrating adaptive data processing, our solution supports real-time model adjustments, making it ideal for precision medicine applications. This dynamic framework ensures accurate variant classification and identification of therapeutic targets, enabling clinicians to make well-informed, patient-specific treatment decisions.",
    },
  ];

  const resultData = [
    {
      title: "Reduced Training Time:",
      desc: "The model training time was reduced from days to just 35 minutes, an unprecedented improvement that significantly accelerated the pace of research.",
    },
    {
      title: "Increased Efficiency:",
      desc: "Faster model training meant the client could iterate more quickly, experiment with different models, and speed up their overall research process.",
    },
    {
      title: "Cost Optimization:",
      desc: "The efficient use of GPU infrastructure resulted in lower computational costs over time, leading to significant savings for the client.",
    },
  ];

  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main"
        id="our_sol"
      >
        <div className="expert_div flex_crct gap_1vw justify_space">
          <div className="expert_div sol_sec flex_col gap_1vw align_item ">
            <p className="Raleway_61px font_500 c_secBlack text_c">
              Rapid Genomic Sequencing Model Training through GPU Infrastructure
            </p>
            <p className="Raleway_31px_dark c_black opacity_8">
              Use AI to study genomics sequencing model training through GPU
            </p>
          </div>

          <div className="expert_div width_40 box_shade">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/lifescience.png"
              alt="solar deep learning"
            />
          </div>
        </div>

        <motion.div
          className="expert_div flex_reverse gap_3vw justify_space"
          id="our_sol"
          ref={clientRef}
          initial={{ opacity: 0, y: 50 }}
          animate={clientControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureOne.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Client</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              Our client is a leader in the field of genomics, engaged in
              extensive research using machine learning models for genomic
              sequencing. The immense complexity and size of genomic data
              resulted in models taking days to train, slowing down their
              research progress.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="expert_div flex_crct gap_3vw justify_space"
          id="our_sol"
          ref={challengeRef}
          initial={{ opacity: 0, y: 50 }}
          animate={challengeControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Challenge</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              Genomic sequencing data is incredibly large and complex, which
              leads to substantial computational requirements when training
              machine learning models. The client's existing infrastructure was
              insufficient for the task, causing long delays in model training.
              These delaysposed significant challenges to the pace of research
              and discovery.
            </p>
          </div>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureFour.png"
              alt="solar deep learning"
            />
          </div>
        </motion.div>
        <motion.div
          className="expert_div flex_reverse gap_3vw justify_space"
          id="our_sol"
          ref={solRef}
          initial={{ opacity: 0, y: 50 }}
          animate={solControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureThree.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">
              Solution Methodology
            </p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              At Smarttrak , we recognized the power of parallel processing
              using GPU infrastructure to drastically reduce model training
              time. We proposed and implemented a GPU-based solution designed to
              handle the massive computational requirements of genomic
              sequencing. This system leverages parallel processing capabilities
              of GPUs to perform multiple computations simultaneously,
              drastically improving the speed of model training.Furthermore, we
              optimized the client's machine learning algorithms to better
              leverage the new infrastructure. The combined approach of hardware
              and software optimization resulted in significantly reduced model
              training time.
            </p>
          </div>
        </motion.div>
        <div className="expert_div flex_col gap_1vw" id="our_sol">
          <p className="Raleway_48px_600 c_dark text_c" id="our_sol">
            What is Genomics Sequencing Model?
          </p>
          <p className="Raleway_31px_dark opacity_8 text_cen text_j">
            A genomic sequencing model is a method for analyzing an organism's
            DNA to decode genetic information and uncover insights about traits
            and diseases, using processes like DNA sequencing, alignment, and
            variant identification. It has revolutionized genetics and medicine
            by revealing in-depth genetic details.
          </p>
        </div>
        <motion.div
          className="expert_div flex_col text_cen"
          id="our_sol"
          ref={qusRef}
          initial={{ opacity: 0, y: 50 }}
          animate={qusControls}
          transition={{ duration: 0.5 }}
        >
          <Grid container className="home_grid_container gap_3vw">
            {solutionM.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.7}
                xs={12}
                sm={12}
                md={3.6}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p"
                  id="valData"
                  style={{ flex: 1 }}
                >
                  <div
                    className="expert_div flex_col gap_1vw padd_1vw"
                    id="text_just"
                  >
                    <p className="Raleway_31px_600 c_lightdrak ">{ele.title}</p>
                    <p className="Raleway_21px_500 c_dark opacity_8 height_20vw">
                      {ele.desc}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw margin_4vw"
          id="our_sol"
          ref={whatRef}
          initial={{ opacity: 0, y: 50 }}
          animate={whatControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark" id="our_sol">
            How does AI help in Genomics sequencing ?
          </p>

          <div className="div_casestudy life_gene" id="our_sol">
            <img
              className="expertise_image"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/yGene.png"
              alt="our solution"
            />
          </div>
          <div id="our_sol" className="expert_div flex_col gap_1vw">
            <p className="Raleway_26px_500 opacity_8" id="text_just">
              AI is a pivotal force in revolutionizing genomics sequencing,
              greatly enhancing the analysis and utilization of the vast amount
              of genetic information generated by sequencing technologies. AI
              algorithms and techniques are harnessed to process, interpret, and
              derive meaningful insights from the massive datasets produced
              during genomic sequencing processes. These algorithms excel at
              identifying patterns, variations, and potential disease-related
              mutations from raw sequencing data, outperforming traditional
              methods in terms of both speed and accuracy.
            </p>
            <p className="Raleway_26px_500 opacity_8 text_cen" id="text_just">
              AI's impact spans across various aspects of genomics, from variant
              calling and functional impact prediction to drug discovery and
              clinical decision support. By efficiently classifying genetic
              variants based on their potential effects on genes and proteins,
              AI aids in pinpointing disease-associated mutations and
              accelerating the search for therapeutic targets. Furthermore,
              AI-driven platforms assist clinicians in interpreting genomic
              data, offering personalized treatment options based on an
              individual's genetic profile. This not only improves patient
              outcomes but also advances the field of precision medicine.
              Moreover, AI's ability to analyze vast datasets assists
              researchers in understanding population genetics, evolutionary
              relationships, and cellular heterogeneity, unveiling insights into
              human history, biodiversity, and intricate cellular processes.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="expert_div flex_col text_cen"
          id="our_sol"
          ref={craftRef}
          initial={{ opacity: 0, y: 50 }}
          animate={craftControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_dark">Crafting Solutions</p>
            <p className="Raleway_31px_dark opacity_8 text_cen">
              Navigating Challenges with Strategic Ingenuity in Problems That
              Have Been Smartly Resolved.
            </p>
          </div>
          <Grid container className="home_grid_container" id="our_sol">
            {results.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.4}
                xs={12}
                sm={12}
                md={3.4}
                className="home_grid_product"
                id="border_none"
              >
                <Paper className="home_paper_product">
                  <div className="solar_divs result_down">
                    <img
                      src={ele.imgUrl}
                      alt="ourproduct"
                      className="height_w border_5"
                    />
                  </div>

                  <div className="expert_div result_up width_97">
                    <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                      <div>
                        <p className="Raleway_23px_dark">{ele.title}</p>
                        <p className="Raleway_23px_dark">{ele.extra}</p>
                      </div>
                      <p
                        className="Raleway_21px_500 c_dark opacity_8 height_18vw"
                        id="text_just"
                      >
                        {ele.desc}
                      </p>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw align_item margin_4vw"
          id="our_sol"
          ref={resRef}
          initial={{ opacity: 0, y: 20 }}
          animate={resControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark">Results</p>
          <p className="Raleway_26px c_black">
            Our GPU-based solution led to a dramatic improvement in model
            training time: Reduced Training Time:
          </p>
          <div className="expert_div gap_1vw flex_col">
            {resultData.map((ele, index) => (
              <div>
                <div className="expert_div gap_1vw align_item">
                  <p className="Raleway_26px c_lightgray">0{index + 1}</p>
                  <p className="Raleway_25px font_500 opacity_8" id="text_just">
                    <span className="c_blue">{ele.title} </span>
                    {ele.desc}
                  </p>
                </div>
                <Divider
                  className="yellow_line"
                  style={{
                    border: "0.1px solid #FDA737",
                  }}
                />
              </div>
            ))}
          </div>
        </motion.div>
        <div
          className="expert_div flex_col gap_1vw align_item margin_4vw margin_bottom"
          id="our_sol"
        >
          <p className="Raleway_48px_600 c_dark">Conclusion</p>
          <p className="Raleway_26px_500 opacity_8" id="text_just">
            This project highlights Smarttrak's capability to provide innovative
            solutions that address unique challenges faced by our clients. By
            leveraging state-of-the-art technology and a deep understanding of
            machine learning, we were able to deliver a solution that not only
            met the client's needs but also significantly enhanced their
            research capabilities.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LifeScience;
