import React from "react";
import CustomText from "../CustomText/CustomText";
import "./CustomButton.scss"

const CustomButton = (props) => {
    const {type, text, styles, textStyles, onClick, disabled} = props;
    if(type === "Simple"){
        return (
            <div className="button-container"
                onClick={onClick}
                style={{...styles}}
                disabled={disabled}>
                <CustomText type="Simple"
                 text={text}
                 overflow={true}
                 styles={{width : "90%", color: "#FFF", fontSize:"15px", fontFamily:"Poppins", textAlign: "center", ...textStyles}} />
            </div>
        )
    }
}

export default CustomButton;