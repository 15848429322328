import React from 'react';

const ArrowRight = ({  fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill={fill}>
  <path d="M6.25 14.2506H21.075L16.5375 8.80059C16.3253 8.54532 16.2232 8.21622 16.2537 7.88568C16.2842 7.55515 16.4447 7.25026 16.7 7.03809C16.9553 6.82592 17.2844 6.72384 17.6149 6.75431C17.9454 6.78479 18.2503 6.94532 18.4625 7.20059L24.7125 14.7006C24.7545 14.7602 24.7922 14.8229 24.825 14.8881C24.825 14.9506 24.825 14.9881 24.9125 15.0506C24.9692 15.1939 24.9988 15.3465 25 15.5006C24.9988 15.6547 24.9692 15.8073 24.9125 15.9506C24.9125 16.0131 24.9125 16.0506 24.825 16.1131C24.7922 16.1783 24.7545 16.2409 24.7125 16.3006L18.4625 23.8006C18.345 23.9417 18.1978 24.0552 18.0314 24.1329C17.8651 24.2107 17.6836 24.2509 17.5 24.2506C17.2079 24.2512 16.9249 24.1494 16.7 23.9631C16.5734 23.8582 16.4688 23.7293 16.3921 23.5838C16.3154 23.4384 16.2682 23.2793 16.2531 23.1156C16.238 22.9518 16.2553 22.7867 16.3041 22.6297C16.3529 22.4727 16.4322 22.3269 16.5375 22.2006L21.075 16.7506H6.25C5.91848 16.7506 5.60054 16.6189 5.36612 16.3845C5.13169 16.1501 5 15.8321 5 15.5006C5 15.1691 5.13169 14.8511 5.36612 14.6167C5.60054 14.3823 5.91848 14.2506 6.25 14.2506Z" fill={fill}/>
</svg>
  )
};

export default ArrowRight;
