import React from "react";

const InverterIcon = (props) => {
    const {
        strokeColorPath2 = 'black', 
        fillColor = "white",
        fillColorPath2 = "white",
        width = "24",
        height = "24",
        path1 = "M20 4H19.5V1H22C22.276 1 22.5 0.776 22.5 0.5C22.5 0.224 22.276 0 22 0H16C15.724 0 15.5 0.224 15.5 0.5C15.5 0.776 15.724 1 16 1H18.5V4H5C2.519 4 0.5 6.019 0.5 8.5V15.5C0.5 17.456 1.754 19.124 3.5 19.743V21.5C3.5 21.776 3.724 22 4 22C4.276 22 4.5 21.776 4.5 21.5V19.972C4.664 19.99 4.831 20 5 20H20C20.169 20 20.336 19.99 20.5 19.972V21.5C20.5 21.776 20.724 22 21 22C21.276 22 21.5 21.776 21.5 21.5V19.743C23.246 19.124 24.5 17.456 24.5 15.5V8.5C24.5 6.019 22.481 4 20 4ZM23.5 15.5C23.5 17.43 21.93 19 20 19H5C3.07 19 1.5 17.43 1.5 15.5V8.5C1.5 6.57 3.07 5 5 5H20C21.93 5 23.5 6.57 23.5 8.5V15.5ZM2.5 2.5C2.5 2.224 2.724 2 3 2H9C9.276 2 9.5 2.224 9.5 2.5C9.5 2.776 9.276 3 9 3H3C2.724 3 2.5 2.776 2.5 2.5ZM17.5 8C15.294 8 13.5 9.794 13.5 12C13.5 14.206 15.294 16 17.5 16C19.706 16 21.5 14.206 21.5 12C21.5 9.794 19.706 8 17.5 8ZM17.5 15C16.016 15 14.781 13.917 14.542 12.5H17C17.276 12.5 17.5 12.276 17.5 12C17.5 11.724 17.276 11.5 17 11.5H14.542C14.781 10.083 16.016 9 17.5 9C19.154 9 20.5 10.346 20.5 12C20.5 13.654 19.154 15 17.5 15ZM7.5 8C5.294 8 3.5 9.794 3.5 12C3.5 14.5 5.5 16 7.5 16C9.706 16 11.5 14.206 11.5 12C11.5 9.794 9.706 8 7.5 8ZM7.5 15C6.853 15 6.254 14.793 5.763 14.444L7.5 12.707C7.695 12.512 7.695 12.195 7.5 12C7.305 11.805 6.988 11.805 6.793 12L5.056 13.737C4.706 13.247 4.5 12.647 4.5 12C4.5 10.346 5.846 9 7.5 9C9.154 9 10.5 10.346 10.5 12C10.5 13.654 9.154 15 7.5 15Z",
        path2= "",
        ...rest                
      } = props;
  return (
    <svg
    width={width}
    height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path1} fill={fillColor}
      />
      <path d={path2} fill={fillColorPath2} stroke={strokeColorPath2} />
    </svg>
  );
};

export default InverterIcon;
