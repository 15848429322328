import React from "react";

// import ConfusionMatrix from "../../components/confusion-matrix/ConfusionMatrix";
import YourModalDrawer from "../YourModals/YourModalDrawer";
const Development = (props) => {
  return <YourModalDrawer />
}

export default Development;

// 3D Protein Structure
// import React, { useEffect, useRef } from "react";
// import * as NGL from "ngl";

// const ThreedPdb = () => {
//   const viewportRef = useRef(null);
//   const stageRef = useRef(null);
//   const componentRef = useRef(null);
//   const isMolecularBondRef = useRef(false);
//   const firstAtomColorRef = useRef(null);

//   useEffect(() => {
//     // Create NGL Stage object
//     const stage = new NGL.Stage(viewportRef.current);
//     stageRef.current = stage; // Store the stage object in the useRef

//     // Handle window resizing
//     window.addEventListener("resize", function (event) {
//       stage.handleResize();
//     }, false);

//     // Load PDB entry 2gb1
//     stage.loadFile("rcsb://2gb1", { defaultRepresentation: true }).then(function (component) {
//       componentRef.current = component; // Store the component object in the useRef
//       // Get the color of the first atom
//       component.structure.eachAtom(function(atom) {
//         firstAtomColorRef.current = atom.color;
//         return false; // Break the loop after getting the color of the first atom
//       });

//       // Make sure to clean up the NGL component when the component unmounts
//       return () => {
//         stage.dispose();
//       };
//     });
//   }, []);

//   // Toggle representation button handler
//   function toggleRepresentation() {
//     const stage = stageRef.current; // Access the stage object from the useRef
//     const isMolecularBond = isMolecularBondRef.current;
//     const component = componentRef.current;
//     if (isMolecularBond) {
//        // Switch back to the default representation
//        component.removeAllRepresentations();
//        component.addRepresentation("cartoon", { color: firstAtomColorRef.current });
//        isMolecularBondRef.current = false;
//     } else {
//       // Switch to the molecular bond representation
//       component.removeAllRepresentations();
//       component.addRepresentation("ball+stick", { color: firstAtomColorRef.current, multipleBond: "offset" });
//       isMolecularBondRef.current = true;
//     }
//   }

//   // Reset camera button handler
//   function resetCamera() {
//     const stage = stageRef.current; // Access the stage object from the useRef 
//     stage.autoView();
//   }

//   // Take screenshot button handler
//   function takeScreenshot() {
//     const stage = stageRef.current; // Access the stage object from the useRef
//     const screenshot = stage.makeImage().then(function(blob) {
//       const link = document.createElement("a");
//       link.href = URL.createObjectURL(blob);
//       link.download = "screenshot.png";
//       link.click();
//     });
//   }

//   return (
//     <div>
//       <div ref={viewportRef} style={{ width: "100%", height: "600px" }}></div>

//       {/* Toggle Representation Button */}
//       <button onClick={toggleRepresentation}>Toggle Representation</button>

//       {/* Reset Camera Button */}
//       <button onClick={resetCamera}>Reset Camera</button>

//       {/* Take Screenshot Button */}
//       <button onClick={takeScreenshot}>Take Screenshot</button>

//       {/* Rest of your React component */}
//     </div>
//   );
// };

// export default ThreedPdb;