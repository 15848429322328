import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CustomText from "../../components/CustomText/CustomText";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomTable from "./CustomTable";
import TrainModelDrawer from "./TrainModelDrawer";
import DataUploadDrawer from "./DataUploadDrawer";
import Barchart from "../../components/Charts/Barchart";
import "./CreateAI.scss";
import { useSnackbar } from "notistack";
import { barchartDataProcess } from "../../utils/chartProcessor";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Drawer } from "@mui/material";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { CreateAIService } from "../../services/CreateAIService";
import SimpleBackdrop from "../../components/common/SimpleBackdrop";

const createAIService = new CreateAIService();

let tempPlots = [
  {
    type: "bar",
    data: {
      0: {
        index: "Normal",
        class: 6,
      },
      1: {
        index: "Tumor",
        class: 6,
      },
    },
    name: "Classe's vs Count",
  },
];

const CreateAI = (props) => {
  const [modelDetails, setModelDetails] = useState({});
  const [openTrainAIModel, setOpenTrainAIModel] = useState(false);
  const [openUploadModel, setOpenUploadModal] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [searchVal, setSearchVal] = useState(null);
  const [isCreateAiActive, setIsCreateAiActive] = useState(false);
  const [openClassList, setOpenClassList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataId, setIsDataId] = useState("");
  const [isImagesClassDataList, setIsImagesClassDataList] = useState([]);
  const [isImageData, setIsImageData] = useState({});
  const [acceptedType, setAcceptedType] = useState([]);
  const [isImagePreviewUrlApi, setIsImagePreviewUrlApi] = useState(null);
  const [isClassMatched, setIsClassMatched] = useState(false);

  const location = useLocation();

  const [isDataUploaded, setIsDataUploaded] = useState(false);

  const [tableData, setTableData] = useState({
    headers: [],
    values: [],
    parsedData: [],
  });

  const [chartData, setChartData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (location.state) {
      const {
        modelConfigs,
        usecaseId,
        algorithm,
        architectureName,
      } = location.state;
      setModelDetails({
        modelConfigs,
        usecaseId,
        algorithm,
        architectureName,
      });
    }
  }, [location.state]);

  const handleAnalyze = (plots) => {
    if (!isDataUploaded) {
      enqueueSnackbar("Please upload the data", { variant: "warning" });
      return;
    }

    if (plots.length === 0) plots = [...tempPlots];
    setChartData(() =>
      plots.map((item) => {
        if (item.type === "bar") {
          const { headers, barValues } = barchartDataProcess(item.data);
          return {
            name: item?.name,
            type: "bar",
            headers,
            barValues,
          };
        } else {
          return null;
        }
      })
    );
    setIsCreateAiActive(true);
  };

  const handleCreateAI = () => {
    setOpenTrainAIModel(true);
  };

  const handleUploadClick = () => {
    setOpenUploadModal(true);
    acceptedType.length = 0;
    acceptedType.push(
      modelDetails.modelConfigs?.data_upload?.accepted_datatypes
    );
  };

  const handleUpdateImageDetails = (imagePreviewUrl, data_id) => {
    setImagePreviewUrl(imagePreviewUrl);
    setModelDetails((prevState) => ({ ...prevState, dataId: data_id }));
    setIsCreateAiActive(true);
    setIsDataId(data_id);
    setOpenUploadModal(false);
    setIsClassMatched(false);
  };

  const handleUpdateFileDetails = (headers, rows, data_id, plots) => {
    setTableData(() => ({
      headers: headers,
      values: rows,
      parsedData: rows,
    }));
    setModelDetails((prevState) => ({ ...prevState, dataId: data_id }));
    setOpenUploadModal(false);
    setIsDataUploaded(true);
  };

  const handleSearch = (value) => {
    const foundIndex = tableData.headers.findIndex(
      (data) => data.toLowerCase() === value.toLowerCase()
    );
    setSearchVal(foundIndex);
  };

  const getHandleClasses = async () => {
    setLoading(true);
    await createAIService
      .getImageData(isDataId)
      .then((res) => {
        if (res.status === 200 && res?.data) {
          setIsImagesClassDataList(res?.data.class_names);
          setIsImageData((prevState) => ({
            ...prevState,
            result: res?.data.data_dict,
          }));
        } else if (res?.data?.length <= 0) {
          enqueueSnackbar("No data available,", {
            variant: "warning",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error in fetching Image Classes", err);
        enqueueSnackbar(
          err.response?.data?.detail
            ? err.response?.data?.detail
            : "Error while fetching Image Classes",
          { variant: "error" }
        );
        setLoading(false);
      });

    setOpenClassList(true);
  };

  const handleClassLists = (event) => {
    const buttonText = event.target.innerText;
    const keys = Object.keys(isImageData);
    keys.forEach((key) => {
      let classArr = Object.keys(isImageData[key]);
      classArr.map((item, index) => {
        if (buttonText === item) {
          setIsImagePreviewUrlApi(isImageData[key][item]);
          setIsClassMatched(true);
        }
      });
    });
  };

  return (
    <div className="main">
      <CustomText
        type="Regular_15px"
        text={modelDetails.modelConfigs?.title}
        styles={{ color: "#414141" }}
      />
      <CustomText
        type="SemiBold_22px"
        text={
          modelDetails.architectureName ? modelDetails.architectureName : ""
        }
        styles={{ margin: "1px 0px 6px 0px" }}
      />
      <div className="create-ai-button-container">
        <div className="left-btn-group"></div>
        {isDataId ? (
          <CustomButton
            type="Simple"
            text="Classes"
            onClick={getHandleClasses}
            styles={{ width: "20rem", marginLeft: "24rem" }}
          />
        ) : (
          ""
        )}
        {loading && <SimpleBackdrop open={loading} />}
        <Drawer
          anchor="right"
          open={openClassList}
          onClose={() => setOpenClassList(false)}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "25%",
              alignItems: "center",
              backgroundColor: "#DAEEFF",
              paddingTop: "29px",
            },
          }}
        >
          {isImagesClassDataList.map((item, index) => {
            return (
              <div className="classes-list" key={index}>
                <CustomButton
                  type="Simple"
                  onClick={handleClassLists}
                  text={item}
                  styles={{ color: "#414141", width: "15rem" }}
                />
              </div>
            );
          })}
        </Drawer>
        <CustomButton
          type="Simple"
          text="+ Upload Data"
          styles={{ width: "20rem" }}
          onClick={handleUploadClick}
        />
      </div>
      <div className="preview-container">
        <CustomText
          type="SemiBold_20px"
          text="Preview"
          styles={{ color: "#414141" }}
        />
        {isDataUploaded ? (
          <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
      <div className="search-box">
        <input className="semibold-smalltext" id="search-input"
          onChange={handleSearch}
          type="text"
          placeholder="Search Case Studies"
        />
        <svg xmlns="http://www.w3.org/2000/svg" className="search-icon" viewBox="0 0 55 55" fill="none">
              <rect width="55" height="55" rx="27.5" fill="#212121" />
              <path d="M38.2307 37.3048L32.9222 31.9987C34.3449 30.3628 35.2092 28.2264 35.2092 25.8908C35.2092 20.7515 31.0291 16.5784 25.8968 16.5784C20.7574 16.5784 16.582 20.7584 16.582 25.8908C16.582 31.0232 20.7621 35.2032 25.8944 35.2032C28.2278 35.2032 30.3641 34.3412 32.0023 32.9162L37.3108 38.227C37.4359 38.3521 37.6051 38.4217 37.7719 38.4217C37.9364 38.4217 38.1056 38.3591 38.233 38.227C38.4809 37.9745 38.4809 37.5574 38.2307 37.3048ZM25.8898 33.901C21.4781 33.901 17.8842 30.3072 17.8842 25.8908C17.8842 21.4744 21.4781 17.8852 25.8898 17.8852C30.3062 17.8852 33.8954 21.479 33.8954 25.8908C33.8954 30.3025 30.3062 33.901 25.8898 33.901Z" fill="white" />
            </svg>
      </div>
      </div>
        ) : null}
        <div className="table-container">
          {!isDataUploaded ? (
            <ImageList
              style={{
                width: isDataUploaded ? "1200px" : "",
                height: isDataUploaded ? "450px" : "",
              }}
              cols={isDataUploaded ? 1 : 4}
            >
              {!isClassMatched
                ? imagePreviewUrl &&
                  imagePreviewUrl.map((item, index) => (
                    <ImageListItem key={index}>
                      <img
                        src={`${URL.createObjectURL(item)}`}
                        srcSet={`${URL.createObjectURL(item)}`}
                        alt={`Uploaded file ${index}`}
                        loading="lazy"
                      />
                      <ImageListItemBar title={item.name.split(".").shift()} />
                    </ImageListItem>
                  ))
                : isImagePreviewUrlApi &&
                  isImagePreviewUrlApi.map((item, index) => (
                    <ImageListItem key={index}>
                      <img
                        src={`data:image/jpeg;base64,${item.file}`}
                        srcSet={`data:image/jpeg;base64,${item.file}`}
                        alt={`Uploaded file ${index}`}
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title={item.file_name.split(".").shift()}
                      />
                    </ImageListItem>
                  ))}
            </ImageList>
          ) : (
            ""
          )}

          {isDataUploaded && (
            <CustomTable {...tableData} searchVal={searchVal} />
          )}
          {!isDataUploaded && !imagePreviewUrl ? (
            <CustomText
              type="SemiBold_35px"
              text="Please upload the data to proceed!"
              styles={{ alignSelf: "center" }}
            />
          ) : (
            ""
          )}
        </div>
        <div className="btn-container">
          {acceptedType.map(([item], index) => {
            return item === "csv" || item === "txt" || item === "zip" ? (
              <CustomButton
                type="Simple"
                text="Analyze"
                styles={{
                  backgroundColor: "#1C629E",
                  display: "flex",
                  alignSelf: "flex-end",
                  width: "20rem",
                }}
                textStyles={{ color: "#FFFFFF" }}
                onClick={() => handleAnalyze(tempPlots)}
              />
            ) : (
              ""
            );
          })}
          <CustomButton
            type="Simple"
            text="Create AI"
            disabled={isCreateAiActive}
            styles={{
              backgroundColor: isCreateAiActive ? "#1C629E" : "#D9D9D9",
              color: isCreateAiActive ? "#FFFFFF" : "#000000",
              pointerEvents: isCreateAiActive ? "auto" : "none",
              display: "flex",
              alignSelf: "flex-end",
              width: "20rem",
            }}
            textStyles={{ color: "#FFFFFF" }}
            onClick={handleCreateAI}
          />
        </div>
      </div>
      {chartData.length > 0 && (
        <div className="data-upload-chart-container">
          <Barchart
            title={chartData[0].name}
            labels={chartData[0].headers}
            barData={chartData[0].barValues}
          />
        </div>
      )}
      {openTrainAIModel && (
        <TrainModelDrawer
          open={openTrainAIModel}
          modelDetail={modelDetails}
          acceptedType={acceptedType}
          onClose={() => setOpenTrainAIModel(false)}
        />
      )}
      {openUploadModel && (
        <DataUploadDrawer
          open={openUploadModel}
          setImagePreviewUrl={setImagePreviewUrl}
          usecaseId={modelDetails.usecaseId}
          modelId={modelDetails?.modelConfigs?.model_id}
          updateFileDetails={handleUpdateFileDetails}
          updateImageDetails={handleUpdateImageDetails}
          onClose={() => setOpenUploadModal(false)}
          isDataId={isDataId}
          acceptedType={acceptedType}
        />
      )}
    </div>
  );
};

export default CreateAI;
