import React, { useState } from "react";
import { useDropzone } from 'react-dropzone';
import { styled, createTheme, ThemeProvider } from '@mui/system';
import CustomText from "../../components/CustomText/CustomText";
import "./ProteinAnalysis.scss";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import CommonText from "../../../IOT/Common/CommonText";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import SimpleBackdrop from "../../components/common/SimpleBackdrop";
import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar } from "notistack";
import { ProtienService } from "../../services/ProtienService";
const protienService = new ProtienService();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  padding: "8vh 30px 20px 30px",
};

const steps = ["Select the format of the file", "Select the file and upload"];


const customTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      contrastText: 'black',
    },
  },
});

const MyStyledDropzoneContainer = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  border: '2px dashed #ccc',
  borderRadius: theme.spacing(2),
  backgroundColor: '#f3f3f3',
  color: '#333',
  height: '25vh',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

const MyStyledFileInput = styled('input')({
  display: 'none',
});

const MyStyledImage = styled('img')({
  width: 180,
  height: 140,
});

const MyStyledText = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

function ProteinAnalysis() {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fileTypeSelected, setFileTypeSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const handleUploadData = (files) => {
    setUploadedFiles(files);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUploadData,
    accept: 'image/*, video/*',
    maxFileSize: 1000000000,
    maxFiles: 20,
  });

  const handleUploadDrawer = () => {
    setOpenDrawer(true);
    setCurrentStep(0);
  };
  const handleSelectFileType = (type) => {
    setFileTypeSelected(type);
    setCurrentStep(1);
  };
  const handlePostData = async () => {

    try {
      if (uploadedFiles.length === 0) {
        enqueueSnackbar("Please upload a file", { variant: "warning" });
      }
      if (uploadedFiles.length > 0 && fileTypeSelected === "CSV") {
        setLoading(true);
        const usecase_id = '64402226a39f87557d7e0294';
        const model_id = '813cf3a3-8b5e-4ce6-b6b3-15ee0eb9f958'
        const files = uploadedFiles;
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i], files[i].name);
        }
        formData.append("usecase_id", usecase_id);
        formData.append("model_id", model_id);


        let res = await protienService.predictProtienData(formData);
        if (res.status === 200) {
          enqueueSnackbar("File uploaded successfully", { variant: "success" });
          setUploadedFiles("");
          console.log("file fasta uploaded", res.data);
          setOpenModal(true);
        }

        setLoading(false);
      }
    } catch (err) {
      console.log("Error in handleUploadClick", err);
      enqueueSnackbar("Error while uploading file", { variant: "error" });
      setLoading(false);
    }

  };
  const handleAnalysis = async () => {
    history.push({
      pathname: "/home/protein-analysis/analysis-result",
    });
  };

  return (
    <div className="protein-analysis-container">
      <div className="protein-subcontainer">
        <div>
          <p
            style={{
              color: "#1C629E",
              fontSize: "20px",
              fontWeight: "700",
              fontFamily: "Poppins",
            }}
          >
            Protein Anaysis
          </p>
          <CustomText
            type="Bold_16px"
            text="Upload Files "
            styles={{ color: "#1c629e", paddingTop: "6px" }}
          />
          <p
            style={{
              color: "#1C629E",
              fontSize: "16px",
              padding: "12px 0px",
              fontWeight: "400",
              fontFamily: "Poppins",
            }}
          >
            Please upload the{" "}
            <span style={{ fontWeight: "600" }}>
              protein sequence in fasta format
            </span>{" "}
            to continue and predict the data
          </p>

          <div className="container-dropbox">
            <CustomText
              type="Regular_16px"
              text="Upload file here or Click here"
              styles={{ paddingTop: "30px" }}
            />
            <img
              src="https://s3.amazonaws.com/smarttrak.co/images/Vector.png"
              width={160}
              alt="images not found"
              className="imgvector"
              onClick={handleUploadDrawer}
            />
          </div>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openDrawer}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openDrawer}>
              <Box sx={style}>
                {loading && <SimpleBackdrop open={loading} />}
                <CloseIcon
                  onClick={() => setOpenDrawer(false)}
                  className="close-btn"
                />
                <Stepper activeStep={currentStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {currentStep === 0 && (
                  <div>
                    <CustomText
                      type="SemiBold_16px"
                      text="Select the Data Source"
                      styles={{ margin: "40px 0px 20px 30px" }}
                    />
                    <div className="file-format-container">
                      <div className="file-type-card">
                        <img src="https://s3.amazonaws.com/smarttrak.co/images/image 25.png" width={60} alt="fasta" />
                        <CustomText
                          type="Regular_14px"
                          text="Fasta Files"
                          styles={{ marginTop: 15 }}
                        />
                        <div
                          className="upload-file-button"
                          onClick={() => handleSelectFileType("CSV")}
                        >
                          <CustomText
                            type="Regular_12px"
                            text="Upload File +"
                            styles={{ color: "#FFF" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {currentStep === 1 && (
                  <div>
                    <CustomText
                      type="SemiBold_16px"
                      text="Upload Fasta Files"
                      styles={{ margin: "30px 20px 20px 0px" }}
                    />
                    <div className="dropzone-wrapper">

                      <section>
                        <div {...getRootProps()}>

                          <ThemeProvider theme={customTheme}>
                            <MyStyledDropzoneContainer>
                              <MyStyledFileInput {...getInputProps()} />
                              <MyStyledImage src="https://s3.amazonaws.com/smarttrak.co/images/cloud-computing.png" alt="fileupload" />
                              <MyStyledText>Click to browse</MyStyledText>
                            </MyStyledDropzoneContainer>
                          </ThemeProvider>
                        </div>
                      </section>
                    </div>
                    <div className="upload-data-button-wrapper">
                      <CustomButton
                        type="Simple"
                        text="Upload Data"
                        styles={{ width: "12rem" }}
                        onClick={handlePostData}
                      />
                    </div>
                  </div>
                )}
              </Box>
            </Fade>
          </Modal>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openModal}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openModal}>
            <Box sx={style} style={{ textAlign: "center" }}>
              {loading && <SimpleBackdrop open={loading} />}
              <CloseIcon
                onClick={() => setOpenModal(false)}
                className="close-btn"
              />
              <img src="https://s3.amazonaws.com/smarttrak.co/images/rightTick.png" width={120} alt="tick" />
              <CustomText
                type="SemiBold_16px"
                text="Data Uploaded Successfully"
                styles={{ margin: "20px" }}
              />
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#1C629E",
                  width: "41%",
                  marginTop: "40px",
                  marginRight: "10px",
                  textTransform: "none",
                }}
                onClick={handleAnalysis}
              >
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/images/codicon_run-above.png"
                  alt="run"
                  style={{ marginRight: "10px" }}
                />{" "}
                Run Analysis
              </Button>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}

export default ProteinAnalysis;
