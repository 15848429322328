export const barchartDataProcess = (data) => {
    let headers = [];
    let barValues = [];
    Object.values(data).forEach((item) => {
        headers.push(item.index);
        barValues.push(item.class);
    })
    return {
        headers,
        barValues
    }
}