import React, { useState } from "react";
import "./pdfchat.scss";
import TextField from "@mui/material/TextField";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

const API_KEY = "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";
const systemMessage = {
  role: "system",
  content:
    "Explain things like you're talking to a software professional with 2 years of experience.",
};

const styles = {
  chatWindow: {
    display: "flex",
    width: "90%",
    height: "70vh",
    minWidth: "40%",
    flexDirection: "column",
    overflowY: "auto",
    border: "5px solid #ccc",
    padding: "10px",
    margin: "20px",
    borderRadius: "5px",
  },
  userMessage: {
    alignSelf: "flex-end",
    background: "#DCF8C6",
    padding: "8px",
    borderRadius: "8px",
    marginBottom: "4px",
    textAlign: "left",
  },
  aiMessage: {
    alignSelf: "flex-start",
    background: "#F0F0F0",
    padding: "8px",
    borderRadius: "8px",
    marginBottom: "4px",
    textAlign: "left",
  },
};
function ChatGpt() {
  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm ChatGPT! Ask me anything!",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  const [captureValue, setCaptureValue] = useState(false);
 

  const handleCaptureValueSend = () => {
    if (captureValue) {
      const inputElement = document.getElementById("chatInput");
      const inputValue = inputElement.value.trim();

      if (inputValue !== "") {
        handleSend(inputValue);
        inputElement.value = "";
      }
    }
  };

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];
    console.log("message", message);
    setMessages(newMessages);

    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
    setIsTyping(false);
    setCaptureValue(false);
  };

  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [systemMessage, ...apiMessages],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT",
          },
        ]);
        setIsTyping(false);
      });
  }

  const handleCaptureValue = (event) => {
    if(event.target.value===''){
    setCaptureValue(false);

    }else{
        setCaptureValue(true);

    }
  };

  return (
    <div className="chat-gpt-container">
      <div style={styles.chatWindow}>
        {messages.map((message, i) => (
          <div
            style={
              message.sender === "ChatGPT"
                ? styles.aiMessage
                : styles.userMessage
            }
            key={i}
          >
            <p>{message.message}</p>
          </div>
        ))}
       
        {isTyping && <div className="typing-indicator">Typing...</div>}
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          width: "100%",
          marginBottom: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "70%" }}>
          <TextField
            sx={{
              borderRadius: "10px",
              backgroundColor: "#daeeff",
            }}
            id="chatInput" 
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSend(e.target.value);
                e.target.value = "";
              }
            }}
            onChange={handleCaptureValue}
            placeholder="How can I help you today?"
            fullWidth
          />
        </div>
        <div
          style={{
            backgroundColor: "#f5f0f0",
            borderRadius: "10px",
            padding: "6px 24px",
            position: "static",
          }}
        >
          <SendRoundedIcon
            onClick={handleCaptureValueSend} 
            style={{
              fontSize: "40px",
              color: captureValue ? "green" : "red", 
              cursor: captureValue ? "pointer" : "no-drop", 
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ChatGpt