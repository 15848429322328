import React from "react";
import Plot from "react-plotly.js";

const BoxPlot = (props) => {
  const { plotData } = props;
  const boxPlot = JSON.parse(plotData);

  const classes = Object.values(boxPlot.class);
  const variables = Object.values(boxPlot.variable);
  const values = Object.values(boxPlot.value);

  const boxPlotData = [];

  for (let i = 0; i < classes.length; i++) {
    const trace = {
      type: "box",
      y: values.filter((_, index) => classes[index] === classes[i]),
      x: variables.filter((_, index) => classes[index] === classes[i]),
      name: classes[i],
    };
    boxPlotData.push(trace);
  }

  const layout = {
    xaxis: {
      title: "Variable",
    },
    yaxis: {
      title: "Value",
    },
  };

  return (
    <div>
      <Plot data={boxPlotData} layout={layout} />
    </div>
  );
};

export default BoxPlot;
