import React, { useEffect, useState } from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./service.scss";

function MainServices() {
  const [autoSlide, setAutoSlide] = useState(true);
  const [activeSol, setActiveSol] = useState(0);

  const solControls = useAnimation();
  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });
  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);

  const ourSolution = [
    {
      title: "Digital Twin",
      subTitle:
        "Real-Time Control, Predictive Insights, and Immersive Training",
      description:
        "Our Digital Twin technology provides an interactive digital replica of your physical assets, offering real-time insights and control. With AI Agents and Generative AI, you can simulate What If scenarios and create immersive training experiences.",
      titleOne: "AI-Driven Scenarios",
      descOne:
        "Explore operational outcomes with AI-powered scenario simulations to make confident decisions.",
      imgOne: "https://s3.amazonaws.com/smarttrak.co/newHomePage/twin_one.png",
      titleTwo: "Immersive VR/AR Training",
      descTwo:
        "Upskill your workforce through interactive virtual environments simulating real-world scenarios.",
      imgTwo: "https://s3.amazonaws.com/smarttrak.co/newHomePage/twin_two.png",

      titleThree: "Real-Time Monitoring & Predictive Maintenance",
      descThree:
        "Access live data, track performance, and predict maintenance, minimizing downtime and maximizing operational efficiency.",
      imgThree:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/twin_three.png",
    },
    {
      title: "Custom AI Models",
      subTitle:
        "Tailored AI Solutions for Automation, Prediction, and Advanced Analytics",
      description:
        "We believe that every organization’s challenges are unique. Our Custom AI Models are designed to address specific business needs, providing tailored solutions that enhance productivity, automate processes, and drive predictive analytics. With Smarttrak AI, you gain access to powerful tools that support data-driven decision-making and streamline operations.",
      titleOne: "Predictive Analytics for activeSol Management",
      descOne:
        "Anticipate market changes, optimize supply chains, and forecast energy needs with advanced analytics that keep you a step ahead.",
      imgOne: "https://s3.amazonaws.com/smarttrak.co/newHomePage/ai_one.png",
      titleTwo: "Operational Automation",
      descTwo:
        "Increase efficiency by automating complex workflows, reducing manual tasks, and improving accuracy in critical business processes.",
      imgTwo: "https://s3.amazonaws.com/smarttrak.co/newHomePage/ai_two.png",
      titleThree: "Personalized Decision Support",
      descThree:
        "Utilize AI models customized to your data, delivering insights that enable you to make smarter, more informed business decisions.",
      imgThree:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/ai_three.png",
    },
    {
      title: "Generative AI",
      subTitle:
        "Empowering AI Agents and Transforming Training with AI-Enhanced Creativity",
      description:
        "We believe that every organization’s challenges are unique. Our Custom AI Models are designed to address specific business needs, providing tailored solutions that enhance productivity, automate processes, and drive predictive analytics. With Smarttrak AI, you gain access to powerful tools that support data-driven decision-making and streamline operations.",
      titleOne: "AI Agent Creation for Digital Twins",
      descOne:
        "Generate AI-driven agents that simulate real-world conditions and respond dynamically, allowing you to test various scenarios and optimize decision-making processes.",
      imgOne: "https://s3.amazonaws.com/smarttrak.co/newHomePage/gen_one.png",
      titleTwo: "Enhanced VR/AR Learning Experiences",
      descTwo:
        "Develop adaptive training modules that adjust to individual learner needs, helping employees acquire skills faster and more effectively.",
      imgTwo: "https://s3.amazonaws.com/smarttrak.co/newHomePage/gen_two.png",
      titleThree: "Creative Process Automation",
      descThree:
        "From product design to operational modeling, our Generative AI can rapidly create and refine digital assets, bringing ideas to life with unprecedented speed and precision.",
      imgThree:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/gen_three.png",
    },
  ];
 
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (autoSlide) {
        handleSolution(activeSol + 1);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [activeSol, autoSlide]);
  const handleSolution = (ind) => {
    setActiveSol(ind >= ourSolution.length ? 0 : ind);
  };

  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main"
        id="our_sol"
      >
        <p className="Raleway_64px" id="our_sol">
          Our Solutions
        </p>
        <p className="Raleway_31px_dark opacity_8 c_black text_cen">
          At Smarttrak AI, we combine the power of Artificial Intelligence with
          immersive technology to revolutionize industry operations and
          training. With our Digital Twin solutions, Generative AI, and Custom
          AI Models, we empower businesses to unlock new efficiencies, drive
          innovation, and foster a culture of continuous learning.
        </p>

        <div className="div_casestudy">
          <img
            className="expertise_image"
            src="https://s3.amazonaws.com/smarttrak.co/newHomePage/ourSol_main.png"
            alt="our solution"
          />
        </div>
        <motion.div
          className="expert_div flex_col gap_1vw margin_bottom"
          ref={solRef}
          initial={{ opacity: 0, y: 20 }}
          animate={solControls}
          transition={{ duration: 0.5 }}
        >
          {ourSolution.map(
            (ele, index) =>
              activeSol === index && (
                <React.Fragment key={index}>
                  <div className="expert_div flex_col gap_1vw text_cen our_solution_main">
                    <p className="Raleway_48px_600">{ele.title}</p>
                    <p className="Raleway_26px c_blue">{ele.subTitle}</p>

                    <p className="Raleway_31px_dark opacity_8">
                      {ele.description}
                    </p>
                  </div>

                  <Grid container className="home_grid_container" id="our_sol">
                    <Grid
                      item
                      lg={3.4}
                      xs={12}
                      sm={12}
                      md={3.4}
                      className="home_grid_product" id="pro_hover"
                    >
                      <Paper className="home_paper_product">
                        <div className="img_div_sol">
                          <img
                            src={ele.imgOne}
                            alt="ourproduct"
                            className="img_div_sol"
                          />
                        </div>

                        <div className="expert_div sol_desc">
                          <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                            <p className="Raleway_23px_dark">{ele.titleOne}</p>
                            <p className="Raleway_18px_500 opacity_8">
                              {ele.descOne}
                            </p>
                          </div>
                        </div>
                      </Paper>
                    </Grid>

                    <Grid
                      item
                      lg={3.4}
                      xs={12}
                      sm={12}
                      md={3.4}
                      className="home_grid_product" id="pro_hover"
                    >
                      <Paper className="home_paper_product">
                        <div className="img_div_sol">
                          <img
                            src={ele.imgTwo}
                            alt="ourproduct"
                            className="img_div_sol"
                          />
                        </div>

                        <div className="expert_div sol_desc">
                          <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                            <p className="Raleway_23px_dark">{ele.titleTwo}</p>
                            <p className="Raleway_18px_500 opacity_8">
                              {ele.descTwo}
                            </p>
                          </div>
                        </div>
                      </Paper>
                    </Grid>

                    <Grid
                      item
                      lg={3.4}
                      xs={12}
                      sm={12}
                      md={3.4}
                      className="home_grid_product" id="pro_hover"
                    >
                      <Paper className="home_paper_product">
                        <div className="img_div_sol">
                          <img
                            src={ele.imgThree}
                            alt="ourproduct"
                            className="img_div_sol"
                          />
                        </div>

                        <div className="expert_div sol_desc">
                          <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                            <p className="Raleway_23px_dark">
                              {ele.titleThree}
                            </p>
                            <p className="Raleway_18px_500 opacity_8">
                              {ele.descThree}
                            </p>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <div className="dots_container expert_div">
                    {ourSolution.map((folder, index) => (
                      <span
                        key={index}
                        className={`dot ${activeSol === index ? "active" : ""}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSolution(index);
                        }}
                      ></span>
                    ))}
                  </div>
                </React.Fragment>
              )
          )}
        </motion.div>
      </div>
      <Footer />
    </div>
  );
}

export default MainServices;
