import React from "react";

const types = {
    overflowStyle: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      height: "100%"
    },
  };

const CustomText = (props) => {
    let {type, text, overflow, styles, className} = props;
    type = type.split("_");
    const fontSize = type[1];
    const weight = type[0];

    const predefinedStyles= {
        fontFamily : `Poppins-${weight}`,
        fontSize : fontSize,
        color: "#000000",
    }
    return (
        <div className={className} title={overflow  ? text : ""}
            style={overflow ? {...predefinedStyles, ...types['overflowStyle'], ...styles}
                : {...predefinedStyles, ...styles}}>
            {text}
        </div>
    )
}

export default CustomText;