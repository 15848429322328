import React, { useRef, useEffect } from 'react';

const AngleDrawer = ({ sunAngle, trackerAngle }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svg = svgRef.current;

    // Clear previous drawings
    while (svg.firstChild) {
      svg.removeChild(svg.firstChild);
    }

    const isMobile = window.innerWidth < 768; // Define mobile view breakpoint
    const baseSize = isMobile ? 200 : 450; // Reduce size for mobile
    const centerX = baseSize / 2;
    const centerY = baseSize / 2;
    const radius = isMobile ? 70 : 140; // Reduce radius for mobile view
    const labelOffset = isMobile ? 15 : 30; // Adjust spacing for labels

    const drawLine = (x1, y1, x2, y2, color, dashArray = 'none', width = '5px') => {
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('x1', x1);
      line.setAttribute('y1', y1);
      line.setAttribute('x2', x2);
      line.setAttribute('y2', y2);
      line.setAttribute('stroke', color);
      line.setAttribute('stroke-width', width);
      if (dashArray !== 'none') {
        line.setAttribute('stroke-dasharray', dashArray);
      }
      svg.appendChild(line);
    };

    const drawText = (x, y, text, className) => {
      const label = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      label.setAttribute('x', x);
      label.setAttribute('y', y);
      label.setAttribute('text-anchor', 'middle');
      label.textContent = text;
      label.setAttribute('class', className);
      svg.appendChild(label);
    };

    const drawCardinalDirections = () => {
      const cardinalLines = [
        { angle: 0, text: 'N', xAdjust: 0, yAdjust: labelOffset - 7 },
        { angle: 90, text: 'E', xAdjust: -5, yAdjust: labelOffset },
        { angle: 180, text: 'S', xAdjust: 0, yAdjust: labelOffset },
        { angle: 270, text: 'W', xAdjust: 4, yAdjust: labelOffset },
      ];

      cardinalLines.forEach(({ angle, text, xAdjust, yAdjust }) => {
        const angleRadians = (angle - 90) * (Math.PI / 180); // Adjusting the angle to shift the directions correctly
        const labelX = centerX + (radius + labelOffset) * Math.cos(angleRadians) + xAdjust;
        const labelY = centerY + (radius + labelOffset) * Math.sin(angleRadians) + yAdjust;
        drawText(labelX, labelY, text, isMobile ? 'Poppins_16px_rem' : 'Poppins_24px_rem'); // Smaller font for mobile
      });
    };

    const drawQuadrantLines = () => {
      const quadrants = [0, 90, 180, 270];
      quadrants.forEach(angle => {
        const angleRadians = (angle - 90) * (Math.PI / 180); // Adjusting the angle to shift the directions correctly
        drawLine(centerX, centerY, centerX + radius * Math.cos(angleRadians), centerY + radius * Math.sin(angleRadians), '#646464', 'none', isMobile ? '5px' : '10px');
      });
    };

    const drawAngles = () => {
      if (sunAngle !== null) {
        const sunAngleInRadians = (sunAngle - 90) * (Math.PI / 180); // Adjusting the angle to shift the directions correctly
        drawLine(centerX, centerY, centerX + radius * Math.cos(sunAngleInRadians), centerY + radius * Math.sin(sunAngleInRadians), 'orange', '5,5');
        const sunImage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        sunImage.setAttribute('href', 'https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_sun.svg');
        sunImage.setAttribute('width', isMobile ? '30' : '50'); // Smaller sun image for mobile
        sunImage.setAttribute('height', isMobile ? '30' : '50');
        sunImage.setAttribute('x', centerX + radius * Math.cos(sunAngleInRadians) - (isMobile ? 15 : 25));
        sunImage.setAttribute('y', centerY + radius * Math.sin(sunAngleInRadians) - (isMobile ? 15 : 25));
        svg.appendChild(sunImage);
      }

      if (trackerAngle !== null) {
        let adjustedTrackerAngle;
        if (trackerAngle >= 0) {
          adjustedTrackerAngle = trackerAngle - 180; // Positive angle moves from W-N-E
        } else {
          adjustedTrackerAngle = trackerAngle - 180; // Negative angle moves from W-S-E
        }
        const trackerAngleInRadians = adjustedTrackerAngle * (Math.PI / 180);
        drawLine(centerX, centerY, centerX + radius * Math.cos(trackerAngleInRadians), centerY + radius * Math.sin(trackerAngleInRadians), 'blue', '5,5');

        const trackerLabelX = centerX + (radius + labelOffset) * Math.cos(trackerAngleInRadians);
        const trackerLabelY = centerY + (radius + labelOffset) * Math.sin(trackerAngleInRadians);
        drawText(trackerLabelX, trackerLabelY, `${trackerAngle}°`, isMobile ? 'Poppins_12px_rem' : 'Poppins_16px_rem'); // Smaller text for mobile
      }
    };

    const drawAngleLabels = () => {
      const angleLabels = [
        { angle: 0, text: '0°' },
        { angle: 90, text: '90°' },
        { angle: 180, text: '180°' },
        { angle: 270, text: '270°' },
        { angle: sunAngle, text: sunAngle ? `${sunAngle}°` : "" },
      ];
      angleLabels.forEach(({ angle, text }) => {
        const angleRadians = (angle - 90) * (Math.PI / 180); // Adjusting the angle to shift the directions correctly
        const labelX = centerX + (radius + labelOffset) * Math.cos(angleRadians);
        let labelY = centerY + (radius + labelOffset) * Math.sin(angleRadians);
        if (angle === 0) {
          labelY -= 5; // Move "0°" slightly up
        }
        drawText(labelX, labelY, text, isMobile ? 'Poppins_12px_rem' : 'Poppins_16px_rem'); // Smaller text for mobile
      });
    };

    drawCardinalDirections();
    drawQuadrantLines();
    drawAngles();
    drawAngleLabels();
  }, [sunAngle, trackerAngle]);

  return <svg ref={svgRef} width={window.innerWidth < 768 ? '200' : '450'} height={window.innerWidth < 768 ? '200' : '450'}></svg>;
};

export default AngleDrawer;

