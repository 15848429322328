import React, { useEffect } from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import Divider from "@mui/material/Divider";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function ProcessIndustry() {
  const clientControls = useAnimation();
  const challengeControls = useAnimation();
  const solControls = useAnimation();
  const whatControls = useAnimation();
  const qusControls = useAnimation();
  const craftControls = useAnimation();

  const resControls = useAnimation();

  const [clientRef, inViewClient] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [challengeRef, inViewChallenge] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [whatRef, inViewWhat] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [qusRef, inViewQus] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [craftRef, inViewCraft] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });

  useEffect(() => {
    clientControls.start({
      opacity: inViewClient ? 1 : 0,
      y: inViewClient ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [clientControls, inViewClient]);

  useEffect(() => {
    challengeControls.start({
      opacity: inViewChallenge ? 1 : 0,
      y: inViewChallenge ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [challengeControls, inViewChallenge]);
  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);
  useEffect(() => {
    whatControls.start({
      opacity: inViewWhat ? 1 : 0,
      y: inViewWhat ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [whatControls, inViewWhat]);
  useEffect(() => {
    qusControls.start({
      opacity: inViewQus ? 1 : 0,
      y: inViewQus ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [qusControls, inViewQus]);
  useEffect(() => {
    craftControls.start({
      opacity: inViewCraft ? 1 : 0,
      y: inViewCraft ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [craftControls, inViewCraft]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  const solutionM = [
    {
      title: "Image Classification",

      extra: "and Deep Learning",
      desc: "Computer Vision models excel in classifying objects within images using deep learning techniques. For instance, the ImageNet Large Scale Visual Recognition Challenge showcases the effectiveness of deep learning models in image classification. The winning models in recent years have achieved top-1 error rates as low as 2.25%, significantly surpassing traditional methods.",
    },
    {
      title: "Object Detection for Real-",
      extra: "world Applications",
      desc: "Object detection, a vital Computer Vision task, finds applications in diverse fields. In autonomous driving, models like Faster R-CNN and YOLO (You Only Look Once) enable vehicles to identify pedestrians, vehicles, and other obstacles. The YOLO model boasts impressive real-time performance, processing images at around 45 frames per second.",
    },
    {
      title: "Medical Image",
      extra: "Analysis Revolution",
      desc: "Computer Vision models have revolutionized medical imaging, aiding diagnostics and treatment. In detecting diabetic retinopathy, a leading cause of blindness, deep learning models have shown AUC (Area Under the ROC Curve) values exceeding 0.95, outperforming traditional methods. Such models provide quick, accurate assessments, enhancing patient care.",
    },
  ];
  const results = [
    {
      title: "Autonomous Robotics",
      extra: "and Navigation",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/dc1.jpg",
      desc: "By integrating NVIDIA Jetson with machine vision, robots and drones can navigate autonomously in complex environments. Real-time image processing and deep learning inference enable these devices to perceive their surroundings, detect obstacles, and make instant decisions. This is crucial for applications like warehouse automation, agriculture, and search and rescue missions, where precise navigation and obstacle avoidance are essential.",
    },
    {
      title: "Quality Control",
      extra: "and Inspection",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/dc2.jpg",

      desc: "Manufacturing industries benefit from Jetson-powered machine vision for quality control. Jetson's high-performance processing allows for rapid analysis of product surfaces, identifying defects or inconsistencies. From electronics to automotive manufacturing, this integration ensures high-quality production, reduces human error, and increases overall efficiency.",
    },
    {
      title: "Smart Surveillance",
      extra: "and Security",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/dc3.jpg",

      desc: "Leveraging Jetson's edge AI capabilities, machine vision enhances surveillance systems. Cameras equipped with Jetson modules can analyze video feeds in real-time, detecting unusual behavior or unauthorized access. This proactive approach to security enhances safety in public spaces, transportation hubs, and critical infrastructure, preventing potential threats before they escalate.",
    },
  ];

  const resultData = [
    {
      title: "Improved Quality Control:",
      desc: "Our solution enabled the client to automate quality control, reducing human error and increasing overall product quality.",
    },
    {
      title: "Enhanced Operational Efficiency:",
      desc: "Real-time feedback on the production process allowed the client to promptly address inefficiencies, improving the productivity of their operations.",
    },
    {
      title: "Reduced Latency:",
      desc: "Deploying the solution on NVIDIA Jetson at the edge drastically reduced latency, ensuring swift response times for critical operational decisions.",
    },
  ];

  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main"
        id="our_sol"
      >
        <div className="expert_div flex_crct gap_1vw justify_space">
          <div className="expert_div sol_sec flex_col gap_1vw align_item">
            <p className="Raleway_61px font_500 c_secBlack text_c">
              Deploying Computer Vision Models on NVIDIA Jetson for Industrial
              Machine Vision Applications
            </p>
            <p className="Raleway_31px_dark c_black opacity_8">
              Enhance your business using NIVIDIA Jetson for industrial machine
              vision applications
            </p>
          </div>

          <div className="expert_div width_40 box_shade">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/pro_industry.png"
              alt="solar deep learning"
            />
          </div>
        </div>

        <motion.div
          className="expert_div flex_reverse gap_3vw justify_space"
          id="our_sol"
          ref={clientRef}
          initial={{ opacity: 0, y: 50 }}
          animate={clientControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureOne.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Client</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              Our client is an industry leader, leveraging sophisticated
              machinery in their production processes. They sought a reliable
              machine vision solution to enhance automation and quality control
              in their operations.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="expert_div flex_crct gap_3vw justify_space"
          id="our_sol"
          ref={challengeRef}
          initial={{ opacity: 0, y: 50 }}
          animate={challengeControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Challenge</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              The client required a robust and efficient computer vision
              solution that could operate in real-time in an industrial
              environment, identifying potential defects and inefficiencies in
              their production process. The solution needed to be powerful,
              capable of high-speed processing, and suitable for edge deployment
              to minimize latency.
            </p>
          </div>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureFour.png"
              alt="solar deep learning"
            />
          </div>
        </motion.div>
        <motion.div
          className="expert_div flex_reverse gap_3vw justify_space"
          id="our_sol"
          ref={solRef}
          initial={{ opacity: 0, y: 50 }}
          animate={solControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureThree.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">
              Solution Methodology
            </p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              At Smarttrak, we developed custom computer vision models
              specifically tailored for the client industrial operations. The
              models were trained to recognize and analyze various aspects of
              the client's production process, identifying potential issues and
              providing real-time feedback. To ensure low latency and efficient
              processing, we leveraged the power of NVIDIA Jetson for edge
              deployment of these models. NVIDIA Jetson's high-performance GPU
              made it an ideal platform for running our complex computer vision
              models in real-time.
            </p>
          </div>
        </motion.div>
        <div className="expert_div flex_col gap_1vw" id="our_sol">
          <p className="Raleway_48px_600 c_dark text_c" id="our_sol">
            What are Computer Vision models?
          </p>
          <p className="Raleway_31px_dark opacity_8 text_cen text_j">
            Computer Vision models are a category of AI models specifically
            designed to interpret and understand visual information from the
            world, just as humans do. These models use algorithms and techniques
            to analyze images, videos, and other visual data to extract
            meaningful insights and information.
          </p>
        </div>
        <motion.div
          className="expert_div flex_col text_cen"
          id="our_sol"
          ref={whatRef}
          initial={{ opacity: 0, y: 50 }}
          animate={whatControls}
          transition={{ duration: 0.5 }}
        >
          <Grid container className="home_grid_container gap_3vw">
            {solutionM.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.7}
                xs={12}
                sm={12}
                md={3.6}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p"
                  id="valData"
                  style={{ flex: 1 }}
                >
                  <div
                    className="expert_div flex_col gap_1vw padd_1vw"
                    id="text_just"
                  >
                    <div className="expert_div flex_col" id="text_just">
                      <p className="Raleway_31px_600 c_lightdrak ">
                        {ele.title}
                      </p>
                      <p className="Raleway_31px_600 c_lightdrak ">
                        {ele.extra}
                      </p>
                    </div>
                    <p className="Raleway_21px_500 c_dark opacity_8">
                      {ele.desc}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw margin_4vw"
          id="our_sol"
          ref={qusRef}
          initial={{ opacity: 0, y: 50 }}
          animate={qusControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark" id="our_sol">
            Integrating NIVIDIA Jetson and Machine Vision Applications
          </p>

          <div className="div_casestudy life_gene" id="our_sol">
            <img
              className="expertise_image"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/yProcess.png"
              alt="our solution"
            />
          </div>
          <div id="our_sol" className="expert_div flex_col gap_1vw">
            <p className="Raleway_26px_500 opacity_8" id="text_just">
              Integrating NVIDIA Jetson with machine vision applications creates
              a potent synergy of hardware and software capabilities. Jetson's
              robust GPUs and hardware accelerators enable high-performance
              processing, ideal for real-time image analysis and deep learning
              tasks. The platform's edge AI capabilities reduce latency and
              enhance privacy by processing data locally.
            </p>
            <p className="Raleway_26px_500 opacity_8 text_cen" id="text_just">
              With compatibility for popular deep learning frameworks, Jetson
              facilitates efficient deployment of AI models. Its compact form
              factor and software ecosystem further streamline development,
              making it a compelling choice for diverse applications, from
              robotics to industrial automation, where immediate, accurate, and
              efficient visual processing is paramount.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="expert_div flex_col text_cen"
          id="our_sol"
          ref={craftRef}
          initial={{ opacity: 0, y: 50 }}
          animate={craftControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_dark">Crafting Solutions</p>
            <p className="Raleway_31px_dark opacity_8 text_cen">
              Navigating Challenges with Strategic Ingenuity in Problems That
              Have Been Smartly Resolved.
            </p>
          </div>
          <Grid container className="home_grid_container" id="our_sol">
            {results.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.4}
                xs={12}
                sm={12}
                md={3.4}
                className="home_grid_product"
                id="border_none"
              >
                <Paper className="home_paper_product">
                  <div className="solar_divs result_down">
                    <img
                      src={ele.imgUrl}
                      alt="ourproduct"
                      className="height_w border_5"
                    />
                  </div>

                  <div className="expert_div result_up width_97">
                    <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                      <div>
                        <p className="Raleway_23px_dark">{ele.title}</p>
                        <p className="Raleway_23px_dark">{ele.extra}</p>
                      </div>
                      <p
                        className="Raleway_21px_500 c_dark opacity_8 height_18vw"
                        id="text_just"
                      >
                        {ele.desc}
                      </p>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw align_item margin_4vw"
          id="our_sol"
          ref={resRef}
          initial={{ opacity: 0, y: 20 }}
          animate={resControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark">Results</p>
          <p className="Raleway_26px c_black">
            The deployment of our computer vision solution on NVIDIA Jetson led
            to significant enhancements in the client’s operations:
          </p>
          <div className="expert_div gap_1vw flex_col">
            {resultData.map((ele, index) => (
              <div>
                <div className="expert_div gap_1vw align_item">
                  <p className="Raleway_26px c_lightgray">0{index + 1}</p>
                  <p className="Raleway_25px font_500 opacity_8" id="text_just">
                    <span className="c_blue">{ele.title} </span>
                    {ele.desc}
                  </p>
                </div>
                <Divider
                  className="yellow_line"
                  style={{
                    border: "0.1px solid #FDA737",
                  }}
                />
              </div>
            ))}
          </div>
        </motion.div>
        <div
          className="expert_div flex_col gap_1vw align_item margin_4vw margin_bottom"
          id="our_sol"
        >
          <p className="Raleway_48px_600 c_dark">Conclusion</p>
          <p className="Raleway_26px_500 opacity_8" id="text_just">
            This project showcases Smarttrak's expertise in developing and
            deploying advanced computer vision solutions for industrial
            applications. Our use of cutting-edge technology like NVIDIA Jetson
            exemplifies our commitment to delivering practical, efficient, and
            high-performing solutions tailored to our clients needs.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProcessIndustry;
