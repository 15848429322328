import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = 'https://smarttrakportal.in/api';

const apiHelper = new ApiHelper();

export class CreateAIService {
    uploadCSV(data){
        const uri = `${BASE_URL}/data/upload/csv`;
        return apiHelper.post(uri, data)
    }
    uploadImage(data){
        const uri = `${BASE_URL}/data/upload/img`;
        return apiHelper.post(uri, data)
    }

    getImageData(data_id){
        const uri = `${BASE_URL}/data/user_data/img?data_id=${data_id}`;
        return apiHelper.get(uri);
    }

    startTrainingJob(data){
        const uri = `${BASE_URL}/training/start`;
        return apiHelper.post(uri, data)
    }

    deployModel(data){
        const uri =`${BASE_URL}/training/deploy`;
        return apiHelper.post(uri, data);
    }

    getStatusData(job_id){
        const uri = `${BASE_URL}/training/status?job_id=${job_id}`;
        return apiHelper.get(uri);
    }

    evaluateModel(jobId){
        const uri = `${BASE_URL}/training/evaluate?job_id=${jobId}`;
        return apiHelper.get(uri);
    }

}