import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import "./FontSizes.scss";
import "./WeatherBoard.scss";
import CommonText from "./CommonText";
function WeatherBoard(props) {
  const { weaHeight, weatherData, weatherParam, title, panelWeatherData, borderRadius } = props;
  const weatherIconUrl = `https://openweathermap.org/img/wn/${weatherParam.weatherUrl}@2x.png`;

  return (
    <Grid
      className="weatherdata-container"
      container
      sx={{
        height: weaHeight,
        borderRadius: borderRadius ? borderRadius : "1rem"
      }}
    >
      {title !== "Inverter Status" && (
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="inv-status-weather"
        >
          <Paper className="inv-status-weather-paper">
          
            <img
              src={weatherIconUrl}
              alt="weather"
              className="weather-cloud"
            />
          </Paper>
          <Paper className="inv-status-weather-paper">
            <div className="weather-day">
              <p className="raleway_16px_rem">
                {weatherParam.day ? weatherParam.day : "--"}
              </p>

              <Divider
                style={{ border: "1px solid white", height: "1rem" }}
                orientation="vertical"
              />
              <p
                className="Poppins_16px_rem"
                style={{ fontWeight: "400", color: "#ffff" }}
              >
                {weatherParam.month ? weatherParam.month : "--"}{" "}
                {weatherParam.date ? weatherParam.date : "--"}
              </p>
            </div>
            <div className="center_div-nogap">
              <p
                className="Poppins_32px_rem"
                style={{ fontWeight: "600", color: "white" }}
              >
                {weatherParam.temperature ? weatherParam.temperature : "--"}°C
              </p>
              <p className="raleway_16px_rem" style={{ fontWeight: "700" }}>
                {weatherParam.weatherConditions
                  ? weatherParam.weatherConditions
                  : "--"}
              </p>
            </div>
          </Paper>
        </Grid>
      )}
      {/* <Grid item lg={11.9} xs={12} sm={12} md={12} className="weather-grid">
        <Paper className="weather-paper">
          <img
            src="https://s3.amazonaws.com/smarttrak.co/v2Images/WeatherSun.svg"
            style={{ width: "4vw" }}
            alt="plant"
          />
        </Paper>
        <Paper className="weather-paper">
          <div className="flex-start-column-two">
            <div className="space-btn-gap">
              <p
                className="Poppins_14px_rem"
                style={{ color: "white", fontWeight: "400" }}
              >
                Min Temperature
              </p>

              <p className="Poppins_18px_rem" style={{ color: "white" }}>
                {weatherParam.minTemp ? weatherParam.minTemp : "--"}°C
              </p>
            </div>
            <div className="space-btn-gap">
              <p
                className="Poppins_14px_rem"
                style={{ color: "white", fontWeight: "400" }}
              >
                Max Temperature
              </p>
              <p className="Poppins_18px_rem" style={{ color: "white" }}>
                {weatherParam.maxTemp ? weatherParam.maxTemp : "--"}°C
              </p>
            </div>
          </div>
        </Paper>
      </Grid> */}

      {/* <Grid
          item
          lg={11.9}
          xs={12}
          sm={12}
          md={12}
          sx={{
            marginTop: "1.25rem",

            background: "transparent",
            border: "none !important",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "transparent",
              gap: "0.625rem",
            }}
          >
            {ForecastInfo.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={2.7}
                xs={12}
                sm={5.4}
                md={5.4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "transparent",
                  borderRadius: "0.125rem",
                }}
              >
                <Paper
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none !important",
                    borderRadius: "1rem",
                    width: "100% !important",
                  }}
                >
                  <div
                    style={{
                      padding: "0.625rem",
                      borderRadius: "1rem 1rem 0rem 0rem",
                      background: "#2F67F8",
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p className="Poppins_10px_rem">{ele.time}</p>
                  </div>
                  <Divider
                    sx={{ mt: 0, mb: 0 }}
                    style={{ border: "0.0625rem solid #D9D9D9" }}
                  />
                  <div
                    style={{
                      background: "#111C43",
                      padding: "0.625rem",
                      gap: "0.625rem",
                      borderRadius: "0rem 0rem 1rem 1rem",
                      flexDirection: "column",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={ele.wewatherIcon}
                      alt="plant"
                      style={{ width: "2.1875rem", height: "2.1875rem" }}
                    />
                    <p className="Poppins_24px_rem">{ele.temp}</p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid> */}
      <Grid item lg={11.9} xs={12} sm={12} md={12} className="weather-title">
        <Grid container className="weather-title-grid">
          {title && (
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="flex-start-column"
            >
              <Paper className="paper_borderless">
                <CommonText
                  type="Bold_700"
                  text={title}
                  styles={{ color: "#fff" }}
                />
              </Paper>
            </Grid>
          )}
          {weatherData.map((ele, index) => (
            <Grid
              key={index}
              item
              lg={5.7}
              xs={5}
              sm={5.7}
              md={5.6}
              className="weather-data-grid"
            >
              <Paper className="grid-paper-weather">
                <img
                  src={ele.wewatherIcon}
                  alt="plant"
                  className="weather_icon"
                />
                <p className="raleway_16px_rem" style={{ fontWeight: "600" }}>
                  {ele.title}
                </p>
                <p className="Poppins_16px_rem" style={{color:"white"}}>{ele.temp}</p>
              </Paper>
            </Grid>
          ))}
          {panelWeatherData ? panelWeatherData.map((ele, index) => (
            <Grid
              key={index}
              item
              lg={5.7}
              xs={5}
              sm={5.7}
              md={5.6}
              className="weather-data-grid"
            >
              <Paper className="grid-paper-weather">
                <img
                  src={ele.wewatherIcon}
                  alt="plant"
                  style={{width:"3rem", height:"auto"}}
                  className="weather_icon"
                />
                <p className="raleway_16px_rem" style={{ fontWeight: "600" }}>
                  {ele.title}
                </p>
                <p className="Poppins_16px_rem" style={{color:"white"}}>{ele.temp}</p>
              </Paper>
            </Grid>
          )) : ""}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WeatherBoard;
