import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = 'https://smarttrakportal.in/api';

const apiHelper = new ApiHelper();
export class ProtienService{
    predictProtienData(data){
        const uri = `${BASE_URL}/data/upload/fasta_file`;
        return apiHelper.post(uri, data)
    }
   
     
}