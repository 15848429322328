import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = 'https://smarttrakportal.in/api';

const apiHelper = new ApiHelper();

export class GetStartedService {
    getAllUsecases(){
        const uri = `${BASE_URL}/models/`;
        return apiHelper.get(uri);
    }

    getTemplateDetails(usecase_id){
        const uri = `${BASE_URL}/models/details?usecase_id=${usecase_id}`;
        return apiHelper.get(uri);
    }
}