import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = 'https://smarttrakportal.in/api';
const apiHelper = new ApiHelper();

export class SubscriptionPlanService {
   
    postPlanName(data){
        const uri = `${BASE_URL}/product/payment_link`;
        return apiHelper.post(uri,data);
    }
    getProductPlan(data){
        const uri = `${BASE_URL}/product/plans`;
        return apiHelper.get(uri,data);
    }
    getUserPlan(data){
        const uri = `${BASE_URL}/login/user/plans`;
        return apiHelper.get(uri,data);
    }
}