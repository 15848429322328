import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "./InviteUsers.scss";
import { UserService } from "../../Services/UserService";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Select from "react-select";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";

const userService = new UserService();

function InviteUsers() {
  const roleTypeValue = ["EXECUTIVE", "MANAGER", "TECSTAFF"];
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [emailError, setEmailError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [plantIdError, setPlantIdError] = useState("");
  const [loading, setLoading] = useState(false);
  const [plantLists, setPlantLists] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  const validateFields = () => {
    let valid = true;
    if (!email) {
      setEmailError("Please enter your email address");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!role) {
      setRoleError("Please select a role");
      valid = false;
    } else {
      setRoleError("");
    }
  
    if (!selectedPlants || selectedPlants.length === 0) {
      setPlantIdError("Please select at least one plant id");
      valid = false;
    } else {
      setPlantIdError("");
    }
    return valid;
  };

  useEffect(()=>{
    getPlants();
  },[])
  const getPlants = async () => {
    try {
      setLoading(true);
      const res = await userService.getPlantsList();
      if (res.status === 200) {
        const idsArray = res.data.map(item => item.id);
        setPlantLists(idsArray);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error in fetchAllPlants", err);
      enqueueSnackbar(
        err.response?.data?.detail
          ? err.response?.data?.detail
          : "Error while fetching plant lists",
        { variant: "error",anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
      }, }
      );
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateFields()) {
      const data = new FormData(event.currentTarget);
      let reqData = {
        email: data.get("email"),
        role:role,
        plants:selectedPlants.map((plant) => plant.value),
      };
      await userService
        .postInviteUser(reqData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);
            enqueueSnackbar(res.data.detail, {
              variant: "success",
            });
           
          } else if (res.status === 422) {
            enqueueSnackbar(res.data.detail, {
              variant: "warning",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error", err);
          enqueueSnackbar(err.response.data.detail, { variant: "error" ,anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },});
        });
    } else {
      setLoading(false);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleRoleChange = (role) => {
    setRole(role.value);
  };
  const handlePlantIdChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedPlants(selectedOptions);
  };


  return (
    <form className="exceutive-dashboard" onSubmit={handleSubmit} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
      {loading && <SimpleBackdrop open={loading} />}
      <div className="main-container">
        <div className="left-container">
          <img src="https://s3.amazonaws.com/smarttrak.co/images/invite-user.jpg" style={{width:"500px"}}  className="pass_logo" alt="password-logo" />
        </div>
        <div className="right-container">
          <div className="forgotPasswordContainer">
            <span className="forgotPasswordText">Invite User</span>
          </div>
          <p className="paragraph">
          Welcome to the user invitation portal. To invite a new user, please enter their email, role, and associated plant ID. We'll guide you through the process.
          </p>
          <div style={{width:"60%"}}>
          <input
            type="text"
            name="email"
            id="email"
            value={email}
            placeholder="Email"
            onChange={handleEmailChange}
            className={`input ${emailError ? "error" : ""}`}
            style={{
              border: "1px solid #ccc",
              width: "100%",
              marginLeft: "0px",
              borderRadius:"2px",
              padding:"10px"
            }}
          />
          {emailError && <div className="error-message">{emailError}</div>}
         
           <div style={{ width: "100%", minWidth: 160, marginTop:"10px" }}>
              <Dropdown
                className="recommendations-text"
                options={roleTypeValue}
                onChange={handleRoleChange}
                value={role}
                placeholder="Select Role"
              />
            </div>
          {roleError && <div className="error-message">{roleError}</div>}
          <div style={{ marginTop:"10px", minWidth: 160 }}>
              <Select
                className="recommendations-text"
                options={plantLists.map((plant) => ({ value: plant, label:plant }))}
                isMulti
                onChange={handlePlantIdChange}
                value={selectedPlants}
                placeholder="Select Plant ID"
              />
            </div>
          {plantIdError && <div className="error-message">{plantIdError}</div>}
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#1C629E",
              width: "100%",
              marginTop: "40px",
            }}
          >
            Invite
          </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default InviteUsers;