import React from "react";
import Plot from "react-plotly.js";

function BarchartGraph(props) {
  const { data, labels } = props;
  const matrixData = JSON.parse(data);
  const layoutData = {
    xaxis: {
      title: labels[0],
      automargin: true,
      showgrid: false,
    },
    yaxis: {
      title: labels[1],
      showgrid: false,
    },
    bargap: 0.5,
    showgrid: false,
  };
  return (
    <div>
      <Plot
        style={{ width: "450px" }}
        layout={layoutData}
        data={[
          {
            x: Object.values(matrixData.count),

            y: Object.values(matrixData.class),
            type: "bar",
            mode: "lines+markers",
            marker: ["red", "blue"],
            orientation: "h",
          },
        ]}
      />
    </div>
  );
}
export default BarchartGraph;
