const TimestampFormatter = ({ dateString }) => {
  const formatTimestamp = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "--";
    }

    const day = date.toLocaleString('en-GB', { day: '2-digit' });
    const month = date.toLocaleString('en-GB', { month: 'short' });
    const year = date.toLocaleString('en-GB', { year: 'numeric' });

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    hours = (hours % 12 || 12).toString().padStart(2, '0');

    return `${day} ${month}, ${year} ${hours}:${minutes} ${period}`;
  };

  return (
    <span>{formatTimestamp(dateString)}</span>
  );
};

export default TimestampFormatter;
