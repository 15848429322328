import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Paper, Grid, Box } from "@mui/material";
import "./ReportFailure.scss";
import { CosmicInverterService } from "../../Services/CosmicInverterService";
import useErrorHandler from "../../../utils/errorHandler";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import { useMobile } from "../../Context/MobileContext/MobileContext";
const invService = new CosmicInverterService();

const ReportRow = ({ report }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobile } = useMobile();
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Grid
        container
        className="failure-data-Container"
        sx={{
          borderRadius: isExpanded ? "0.625rem 0.625rem 0rem 0rem" : "0.625rem",
        }}
      > 
     {isMobile ? 
     <div className="mobile_flex_fullwidth">
     <div className="mobile_flex_center">
        <Grid item xs={4.9} sm={3.9} md={3.9}>
           <p className="Poppins_16px_rem">Report ID</p>
           <p className="report_400">{report.id}</p>
        </Grid>
        <Grid item xs={4.9} sm={3.9} md={3.9} className="media_overflow-y">
        <p className="Poppins_16px_rem">Fault Type</p>
          <p className="report_400" style={{ color: "#FF472E" }}>
            {report.faultType}
          </p>
        </Grid>
    
        </div> 
         <Grid item xs={1.9} sm={2.9} md={2.9}>
         <div onClick={handleToggleExpand} className="open-close-btn">
           {isExpanded ? (
             <>
               <ExpandLessIcon sx={{ color: "#2F67F8", cursor: "pointer" }} />
             </>
           ) : (
             <>
               <ExpandMoreIcon sx={{ color: "#2F67F8", cursor: "pointer" }} />
             </>
           )}
         </div>
       </Grid>
       </div> :  
        <><Grid item xs={0.5}>
            <img
              src={report.raisedBy.avatar}
              alt="avatar"
              className="report_img" />
          </Grid><Grid item xs={2}>
              <p className="report_400">{report.raisedBy.name}</p>
              <p className="Poppins_12px_rem" style={{ color: "#969696" }}>
                {report.raisedBy.email}
              </p>
            </Grid></>}
        <Grid item xs={2} className="mobile_hide">
          <p className="report_400">{report.id}</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="report_400">{report.resolvedBy}</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="report_400">{report.reportDate}</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="report_400" id="scrollingHistory" style={{ color: "#FF472E" }}>
            {report.faultType}
          </p>
        </Grid>
        <Grid item xs={1.5} className="mobile_hide">
          <div onClick={handleToggleExpand} className="open-close-btn">
            {isExpanded ? (
              <>
                <p className="report_400" style={{ color: "#2F67F8" }}>
                  Close Details
                </p>
                <ExpandLessIcon sx={{ color: "#2F67F8", cursor: "pointer" }} />
              </>
            ) : (
              <>
                <p className="report_400" style={{ color: "#2F67F8" }}>
                  View Details
                </p>
                <ExpandMoreIcon sx={{ color: "#2F67F8", cursor: "pointer" }} />
              </>
            )}
          </div>
        </Grid>
      </Grid>
      {isExpanded && (
        <Paper className="expanded_paper">
         
        {isMobile && <Grid container className="expanded_container" sx={{marginBottom:"1rem"}}>
        <Grid item xs={12} sm={12} md={3.9}>
        <Paper className="flex_col_paper">
        <p className="Poppins_16px_rem">Raised By</p>
        <div className="flex_div">
          <img
            src={report.raisedBy.avatar}
            alt="avatar"
            className="report_img"
          /> 
          <div>      
            <p className="report_400">{report.raisedBy.name}</p>
          <p className="Poppins_12px_rem" style={{ color: "#969696" }}>
            {report.raisedBy.email}
          </p> 
          </div> 
          </div>
          </Paper>
         
        </Grid>
       
         
        <Grid item xs={5.6} sm={5.6} md={2.8} >
        <p className="Poppins_16px_rem">Resolved By</p>
          <p className="report_400">{report.resolvedBy}</p>
        </Grid>
        <Grid item xs={5.6} sm={5.6} md={2.8} >
        <p className="Poppins_16px_rem">Report Date</p>
          <p className="report_400">{report.reportDate}</p>
        </Grid>
        </Grid>}
        <Grid container className="expanded_container">
            <Grid item lg={3.8} xs={12} sm={12} className="notes-container">
              <div className="notes_bg">
                <p className="Poppins_16px_rem">Notes</p>
              </div>
              <p className="report_400 imp_notification_block mobile_height_overflow">
                {report.notes}
              </p>
            </Grid>
            <Grid
              item
              lg={3.8} xs={12} sm={12}
              className="notes-container"
              sx={{ backgroundColor: "#E6EEF5" }}
            >
              <div className="notes_bg">
                <p className="Poppins_16px_rem">Cause of Failure</p>
              </div>
              <p className="report_400 imp_notification_block mobile_height_overflow">
                {report.causeOfFailure}
              </p>
            </Grid>
            <Grid
              item
              lg={3.8} xs={12} sm={12}
              className="notes-container"
              sx={{ backgroundColor: "#F4F4F4" }}
            >
              <div className="notes_bg">
                <p className="Poppins_16px_rem">Recommendations</p>
              </div>
              <p className="report_400 imp_notification_block mobile_height_overflow">
                {report.recommendations}
              </p>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

const ReportList = ({ reportsData }) => {
  return (
    <div className="imp_notification_block">
      <Grid container className="failure-Container">
        <Grid item xs={0.5}></Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Raised By</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Report ID</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Resolved By</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Report Date</p>
        </Grid>
        <Grid item xs={2} className="mobile_hide">
          <p className="Poppins_16px_rem">Fault Type</p>
        </Grid>
        <Grid item xs={1.5} className="mobile_hide">
          <p className="Poppins_16px_rem">Actions</p>
        </Grid>
      </Grid>
      {reportsData.map((report) => (
        <ReportRow key={report.id} report={report} />
      ))}
    </div>
  );
};

const ReportFailure = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingState, setLoadingState] = useState({
    reportData: false,
  });
  const [reportsData, setReportsData] = useState([]);
  const handleFetchError = useErrorHandler();

  useEffect(() => {
    getReportData();
  }, []);
 
  const getReportData = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, reportData: true }));
      const res = await invService.getReportData();
      if (res.status === 200) {
        setReportsData(res.data);
        setLoadingState((prevState) => ({ ...prevState, reportData: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, reportData: false }));
      handleFetchError(error, "getting report data");
    }
  };
 const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredReports = reportsData.filter((report) =>
    report.id.toLowerCase().includes(searchQuery.toLowerCase()) || report.faultType.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className="home-container">
      {loadingState.reportData && (
        <SimpleBackdrop open={loadingState.reportData} />
      )}
     
      
      <Grid container className="grid-container">
        <Grid
          item
          lg={11.9}
          xs={12}
          sm={12}
          md={11.9}
          className="paper_borderless"
        >
          <Paper
            className="powerplant-sec-paper mobile_nogap mobile_flex-col"
            sx={{ justifyContent: "space-between" }}
          >
            <div className="failure_title">
              <p className="Poppins_24px_black">Report Failures</p>
              <p className="report_400">Addressing Solar System Challenges</p>
            </div>
           {reportsData.length!==0 &&( <div className="search-box_smb media_992px_width_80">
              <input
                className="search_Text"
                id="search-input_report"
                value={searchQuery}
                onChange={handleSearchChange}
                type="text"
                placeholder="Search by Report ID or Fault Type"
              />
            </div>)}
          </Paper>
        </Grid>
      </Grid>
      {reportsData.length !==0?
      <ReportList reportsData={filteredReports} />
      :(<div className="no-data">
                  <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>
                    No data is available!
                  </p>
                </div>)}
    </div>
  );
};

export default ReportFailure;
