import React from "react";
import Navbar from "../LandingPage/MainLandingPage/Navbar";
import Footer from "../LandingPage/MainLandingPage/Footer";

function PrivacyPolicy() {
  return (
    <div className="landing-container">
      <Navbar />
      <div className="slide">
        <img
          style={{
            width: "100%",
            background:
              "linear-gradient(357.77deg, rgba(253, 167, 55, 0.6) 3.34%, rgba(0, 82, 154, 0.6) 98.44%)",
          }}
          src="https://s3.amazonaws.com/smarttrak.co/new_landing_images/privacypolicy.png"
          alt="privacypolicys"
        />
        <div className="products_overlay" id="privacybanner">
          <p className="raleway_64px_rem">Privacy Policy</p>
        </div>
      </div>
      <div className="privacydata">
        <div className="privacy_text">
          <p className="raleway_20px_reg">
            This Privacy Policy outlines the information we collect, how we use
            it, and the measures we take to protect it. By using our Service,
            you consent to the terms outlined in this policy. If you do not
            agree, please refrain from using our Service. We may update this
            policy periodically; continued use of our Service signifies your
            acceptance of any changes.
          </p>
        </div>

        <div className="privacy_text">
          <p className="raleway_pri_semi">
            1. Information We Collect
          </p>
          <div>
            <p className="raleway_20px_reg pri_para">
              <strong>
                1.1 Interaction Data
              </strong>
            </p>
            <p className="raleway_20px_reg ">
              We collect information regarding your interactions and movements
              within our Service's 3D environment,
              specifically regarding your engagement with the digital twin of
              your solar plant. Importantly, we do not collect any location data
              or Meta account information.
            </p>{" "}
          </div>

          <div className="list_italic">
            <p className="raleway_20px_reg pri_para">
              <strong>
                1.2 Personally Identifiable
                Information
              </strong>
            </p>
            <p className="raleway_20px_reg ">
              While using our Service, we may ask you to provide certain
              personally identifiable information that can be used to contact or
              identify you. This information may include, but is not limited to:
            </p>

            <li>First Name</li>
            <li>Last Name</li>
            <li>Email Address</li>
            <li>Phone Number</li>
          </div>
          <div className="list_italic">
            <p className="raleway_20px_reg pri_para">
              <strong>
                1.3 Usage Data
              </strong>
            </p>
            <p className="raleway_20px_reg ">
              Usage Data is collected automatically when you use our Service.
              This may include:
            </p>

            <li>Your device’s Internet Protocol address (e.g., IP address)</li>
            <li>Browser type and version</li>
            <li>Pages of our Service that you visit</li>
            <li>Time and date of your visit</li>
            <li>Time spent on those pages</li>
            <li>Unique device identifiers and other diagnostic data</li>
          </div>
          <div className="list_italic">
            <p className="raleway_20px_reg pri_para">
              When you access our Service through a mobile device, we may also
              collect:
            </p>

            <li>Type of mobile device you use</li>
            <li>Your mobile device's unique ID</li>
            <li>IP address of your mobile device</li>
            <li>Your mobile operating system</li>
            <li>Type of mobile Internet browser you use</li>
            <li>Additional unique device identifiers and diagnostic data</li>
          </div>
          <div>
            <p className="raleway_20px_reg pri_para">
              <strong>
                1.4 Authentication Data
              </strong>
            </p>
            <p className="raleway_20px_reg pri_para">
              During onboarding, we provide registered users with login
              credentials to access our Service. This data is solely used for
              authentication purposes.
            </p>
            <p className="raleway_20px_reg">
              <strong>Note:</strong> We do not collect, process, or store any
              personal information related to your Meta account or device
              identifiers.
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_pri_semi">
            2. Data Processing
          </p>
          <div>
            <p className="raleway_20px_reg">
              All data collected through our Service is processed exclusively on
              Smarttrak AI’s secure servers. No data processing occurs on Meta's
              platform. Interaction data is transmitted in real-time to our
              servers through secure APIs, allowing you to monitor and control
              your solar plant remotely. Importantly, no user interaction data
              is stored on Meta’s platform or your Meta account.
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_pri_semi">
            3. Purpose of Data Collection
          </p>
          <div className="list_italic">
            <p className="raleway_20px_reg pri_para">
              The data collected within our Service serves the following
              purposes:
            </p>
            <li className="pri_para">
              <strong>Remote Monitoring & Control:</strong> Interaction data
              enables real-time control and monitoring of your solar plant's
              status and performance.
            </li>
            <li className="pri_para">
              <strong>Technical Support: </strong> Assisting users with
              technical issues related to solar plant operations.
            </li>

            <li className="pri_para">
              <strong>Operational Optimization: </strong> Enhancing plant
              performance by responding to operational needs.
            </li>
          </div>
          <div>
            <p className="raleway_20px_reg">
              We do not utilize your data for advertising or share it with third
              parties.
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_pri_semi">
            4. Data Security
          </p>
          <div>
            <p className="raleway_20px_reg pri_para">
              <strong>
                4.1 Data Storage
              </strong>
            </p>
            <p className="raleway_20px_reg ">
              Interaction data is processed in real-time and not stored after
              processing is complete. Smarttrak AI does not retain data
              associated with Meta accounts or devices.
            </p>
          </div>
          <div>
            <p className="raleway_20px_reg pri_para">
              <strong>
                4.2 Security Measures
              </strong>
            </p>
            <p className="raleway_20px_reg ">
              We implement industry-standard encryption and security protocols
              to protect data transmitted between your device and our servers.
              While we strive to maintain high security standards, please
              understand that no data transmission over the internet is entirely
              secure.{" "}
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_pri_semi">
            5. User Rights and Data Management
          </p>
          <div>
            <p className="raleway_20px_reg">
              Given that interaction data is not retained post-processing,
              long-term data deletion requests are unnecessary. Data is
              automatically deleted after processing. For specific data removal
              concerns or privacy inquiries, please contact us at
              info@smarttrak.co.
            </p>
          </div>
        </div>

        <div className="privacy_text">
          <p className="raleway_pri_semi">
            {" "}
            6. Access Control
          </p>
          <div>
            <p className="raleway_20px_reg">
              Our Service is exclusively accessible to registered users
              possessing Smarttrak AI credentials. Unauthorized users, including
              general Meta users lacking proper login credentials, cannot access
              our Service. User interactions are governed by the privacy
              agreement between Smarttrak AI and your organization.{" "}
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_pri_semi">
            {" "}
            7. International Data Transfers
          </p>
          <div>
            <p className="raleway_20px_reg">
              Data collected via our Service may be transferred and processed on
              Smarttrak AI servers globally. By using our Service, you consent
              to this transfer and processing. Smarttrak AI ensures compliance
              with relevant privacy regulations and maintains a high level of
              data protection consistent with your country’s standards.
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_pri_semi">
            8. Children’s Privacy
          </p>
          <div>
            <p className="raleway_20px_reg">
              Our Service is not designed for users under the age of 18. We do
              not knowingly collect data from individuals under this age
            </p>
          </div>
        </div>
        <div className="privacy_text">
          <p className="raleway_pri_semi">
            9. Policy Updates
          </p>
          <div>
            <p className="raleway_20px_reg">
              Smarttrak AI may revise this Privacy Policy as necessary. Any
              changes will be posted here, with the last updated date indicated.
              Your continued use of our Service signifies acceptance of any
              revisions to this policy.
            </p>
          </div>
        </div>

        <div className="privacy_text">
          <p className="raleway_pri_semi">
            10. Contact Information
          </p>
          <div>
            <p className="raleway_20px_reg">
              For questions regarding this policy or assistance with your
              rights, please contact us at: 
              <a href="mailto:info@smarttrak.co" className="mailinfo">
                  {" "}
                  info@smarttrak.co.
                </a>{" "}
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
