import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Divider,
} from "@mui/material";

const TableNoCheckBox = ({
  data,
  columns,
  page,
  rowsPerPage,
  order,
  orderBy,
  handlePageChange,
  handleRowsPerPageChange,
  handleRowClick,
  searchQuery,
  handleSearchChange,
  serchText, titleText,plantTable
}) => {
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
      : (a, b) => ascendingComparator(a[orderBy], b[orderBy]);
  };
  const ascendingComparator = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const descendingComparator = (a, b) => {
    if (b < a) return -1;
    if (b > a) return 1;
    return 0;
  };
  const filterData = searchQuery
    ? data.filter(item => Object.keys(item)[0] && item[Object.keys(item)[0]] && item[Object.keys(item)[0]].toLowerCase().includes(searchQuery.toLowerCase()))
    : data;

  return (
    <>
      <div className="table-paper">
        <div className="green-tab">
          <p className='Poppins_24px_rem' style={{ color: "black" }}>{titleText}</p>

        </div>
       {!plantTable && (<div className="search-box_smb">
                    <input className="search_Text" id="search-input_smb"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      type="text"
                      placeholder={serchText}
                    />
            </div>)}
      </div>

      <TableContainer className="table-scrollbar" component={Paper}>
        {data.length === 0 ? (
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableRow>
              <TableCell
                sx={{ borderBottom: "0rem solid gray !important" }}
                colSpan={columns.length}
                align="center"
                className="div-borderText"
              >
                No data available
              </TableCell>
            </TableRow>
            <Divider style={{ border: "0.0625rem solid #D9D9D9" }} />
          </Table>
        ) : (
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead sx={{position:"relative",zIndex:"0"}}>
            {!plantTable ? ( <TableRow>
              {columns.map((column,colIndex) => (
                <TableCell
                  key={column.field}
                  align="left"
                  sx={{
                    borderRight: colIndex < columns.length - 1 ? "0.0625rem solid #D8D8D8" : "none" ,
                  }}
                >
                  <p className="abt-tableText">{column.title}</p>
                </TableCell>
              ))}
            </TableRow>) 
            :
              (<TableRow>
                {columns.length > 0 &&
                  Object.keys(columns[0]).map((key, index) => (
                    <TableCell
                      key={index}
                      align="left"
                      sx={{
                        textTransform: "capitalize",
                        borderRight: index < columns.length - 1 ? "0.0625rem solid #D8D8D8" : "none",
                      }}
                    >
                      <p className="abt-tableText">{key}</p>

                      {/* <TableSortLabel
                              active={orderBy === key}
                              direction={orderBy === key ? order : "asc"}
                              // onClick={() => {
                              //   // setOrderBy(key);
                              //   setOrder((prevOrder) =>
                              //     prevOrder === "asc" ? "desc" : "asc"
                              //   );
                              // }}
                            >
                              {key}
                            </TableSortLabel> */}
                    </TableCell>
                  ))}
              </TableRow>)}
            </TableHead>
           {!plantTable ? (<TableBody>
              {stableSort(filterData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      backgroundColor:index %2 ===0?"#E6EEF5":"transparent"
                    }}
                    onClick={() => handleRowClick(row)}
                  >
                    {columns.map((column,index,) => (
                      <TableCell
                        key={column.field}
                        component="th"
                        scope="row"
                        align="left"
                        sx={{ 
                        cursor:"pointer"}}
                        onClick={() => column.onClick && column.onClick(row)}
                      >
                        <p className="abt-tableText-val"  style={index === 0 ? { color: "#2F67F8" } : {}}>
                          {row[column.field] || '--'}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody> ) : 
            (<TableBody>
              {stableSort(columns, getComparator(order, orderBy))
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map((row, rowIndex) => (
                  <TableRow key={rowIndex} sx={{
                    textTransform: "capitalize",
                    backgroundColor: rowIndex % 2 === 0 ? "#E6EEF5" : "transparent"
                  }}>
                    {Object.values(row).map((value, valueIndex) => (
                      <TableCell key={valueIndex} align="left" sx={{padding:"6px 16px !important" }}>
                        <p className="abt-tableText-val">
                          {valueIndex !== orderBy
                            ? typeof value === "number"
                              ? value.toFixed(2)
                              : value
                            : value}</p>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>)}
          </Table>
        )}
         <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  zIndex: 5,
                  backgroundColor: "white",
                }}
              >
        <TablePagination
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          rowsPerPageOptions={[5, 15, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        </div>
      </TableContainer>
    </>
  );
};

export default TableNoCheckBox;
