import React,{useEffect} from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import Divider from "@mui/material/Divider";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function Robotics() {
  const clientControls = useAnimation();
  const challengeControls = useAnimation();
  const solControls = useAnimation();
  const whatControls = useAnimation();
  const qusControls = useAnimation();
  const craftControls = useAnimation();

  const resControls = useAnimation();

  const [clientRef, inViewClient] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [challengeRef, inViewChallenge] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [whatRef, inViewWhat] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [qusRef, inViewQus] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [craftRef, inViewCraft] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });

  useEffect(() => {
    clientControls.start({
      opacity: inViewClient ? 1 : 0,
      y: inViewClient ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [clientControls, inViewClient]);

  useEffect(() => {
    challengeControls.start({
      opacity: inViewChallenge ? 1 : 0,
      y: inViewChallenge ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [challengeControls, inViewChallenge]);
  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);
  useEffect(() => {
    whatControls.start({
      opacity: inViewWhat ? 1 : 0,
      y: inViewWhat ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [whatControls, inViewWhat]);
  useEffect(() => {
    qusControls.start({
      opacity: inViewQus ? 1 : 0,
      y: inViewQus ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [qusControls, inViewQus]);
  useEffect(() => {
    craftControls.start({
      opacity: inViewCraft ? 1 : 0,
      y: inViewCraft ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [craftControls, inViewCraft]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  const solutionM = [
    {
      title: "High-Quality Data",

      extra: "Collection and Annotation",
      desc: "Effective deep learning for actionable robot insights begins with gathering diverse and high-quality data that spans various scenarios. Precise annotations are essential to ground the model's understanding. Both elements establish the foundation for robust learning and decision-making.",
    },
    {
      title: "Appropriate Model",
      extra: "Selection and Training",
      desc: "Selecting the right deep learning model tailored to the task and employing a thoughtful training strategy significantly impact robot system performance. Leveraging pre-trained models expedites learning, while iterative training refines the model's comprehension and prepares it for integration.",
    },
    {
      title: "Real-time Integration",
      extra: "and Adaptation",
      desc: "Seamless integration of well-trained models into robot control systems enables real-time decision-making. Incorporating adaptive learning methods empowers robots to learn from interactions, refining their responses based on environmental feedback. Regular monitoring sustains model effectiveness in dynamic scenarios.",
    },
  ];
  const results = [
    {
      title: "Enhanced Robotic",
      extra: "Performance",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/ai1.jpg",
      desc: "Deep learning facilitates the creation of robots that autonomously adapt to dynamic environments. This capability is crucial for tasks such as search and rescue missions, where these robots can analyze surroundings, identify hazards, and make informed decisions. By learning from experiences, they navigate and operate effectively in diverse settings. This adaptability enhances their performance in complex terrains.",
    },
    {
      title: "Autonomous Adaptation to",
      extra: "Dynamic Environments",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/ai2.jpg",

      desc: "Deep learning empowers robots to autonomously adapt to dynamic environments, enhancing their navigation and operational efficiency. In applications like search and rescue missions, robots with deep learning models analyze surroundings, identify hazards, and make informed decisions for navigating complex terrains. This adaptability proves invaluable in diverse and challenging scenarios",
    },
    {
      title: "Data-Driven Decision",
      extra: "Insights",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/ai3.jpg",

      desc: "Deep learning applied to robot data offers decision-makers valuable insights by revealing hidden patterns and trends. In industrial contexts, this could translate into predictive maintenance, where robots with such models detect early signs of equipment failure, enabling timely alerts to maintenance teams. This proactive approach has the potential to minimize downtime and enhance overall operational efficiency.",
    },
  ];

  const resultData = [
    {
      title: "Enhanced Operational Efficiency:",
      desc: "With deep learning-based insights, the client could optimize their robotic operations, leading to improved productivity.",
    },
    {
      title: "Reduced Downtime:",
      desc: "Early detection of potential issues allowed for preemptive maintenance, reducing machine downtime and associated costs.",
    },
    {
      title: "Data-Driven Decision Making:",
      desc: "Our solution transformed the client's vast robot data into a valuable asset for decision-making, enabling a more proactive and informed approach to operations management.",
    },
  ];

  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main"
        id="our_sol"
      >
        <div className="expert_div flex_crct gap_1vw justify_space">
          <div className="expert_div sol_sec flex_col gap_1vw align_item">
            <p className="Raleway_61px font_500 c_secBlack text_c">
              Actionable Insights from Robot Data Leveraging Deep Learning
            </p>
            <p className="Raleway_31px_dark c_black opacity_8">
              Use AI to teach robots with data
            </p>
          </div>

          <div className="expert_div width_40 box_shade">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/robotics.png"
              alt="solar deep learning"
            />
          </div>
        </div>

        <motion.div className="expert_div flex_reverse gap_3vw justify_space" id="our_sol"  ref={clientRef}
          initial={{ opacity: 0, y: 50 }}
          animate={clientControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureOne.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Client</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              Our client uses industrial robots in their operations and required
              an efficient way to analyze the vast amounts of data generated by
              these machines to enhance productivity and reduce downtime.
            </p>
          </div>
        </motion.div>
        <motion.div className="expert_div flex_crct gap_3vw justify_space" id="our_sol"  ref={challengeRef}
          initial={{ opacity: 0, y: 50 }}
          animate={challengeControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Challenge</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              The client faced a two-fold challenge: First, the efficient and
              reliable ingestion and transformation of massive amounts of data
              from different robots. Second, the development of a system capable
              of analyzing the transformed data to provide actionable insights
              that could drive improvements in operational efficiency and
              machine uptime.
            </p>
          </div>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureFour.png"
              alt="solar deep learning"
            />
          </div>
        </motion.div>
        <motion.div className="expert_div flex_reverse gap_3vw justify_space" id="our_sol"  ref={solRef}
          initial={{ opacity: 0, y: 50 }}
          animate={solControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureThree.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">
              Solution Methodology
            </p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              At Smarttrak, we developed a comprehensive solution that not only
              effectively managed the ingestion and transformation of robot data
              but also applied deep learning techniques to analyze it. We
              created a robust data ingestion and transformation system capable
              of handling high-volume, real-time data from the client's diverse
              set of robots. This system cleaned, transformed, and structured
              the data, making it ready for analysis. To provide actionable
              insights, we developed custom deep learning models tailored to the
              client's specific needs. These models analyzed the transformed
              data, identifying patterns and anomalies that could indicate
              potential issues or opportunities for improvement.
            </p>
          </div>
        </motion.div>
        <div className="expert_div flex_col gap_1vw" id="our_sol">
          <p className="Raleway_48px_600 c_dark text_c" id="our_sol">
            What is Robot Data leveraging?
          </p>
          <p className="Raleway_31px_dark opacity_8 text_cen text_j">
            Utilizing robot data, including inputs from sensors like cameras and
            LiDAR, enhances robots' intelligence and efficiency through deep
            learning techniques. This adaptive process significantly improves
            performance and decision-making, bolstering robots' autonomy across
            diverse applications.
          </p>
        </div>
        <motion.div className="expert_div flex_col text_cen" id="our_sol"  ref={whatRef}
          initial={{ opacity: 0, y: 50 }}
          animate={whatControls}
          transition={{ duration: 0.5 }}>
          <Grid container className="home_grid_container gap_3vw">
            {solutionM.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.7}
                xs={12}
                sm={12}
                md={3.6}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p"
                  id="valData"
                  style={{ flex: 1 }}
                >
                  <div
                    className="expert_div flex_col gap_1vw padd_1vw"
                    id="text_just"
                  >
                    <div className="expert_div flex_col" id="text_just">
                      <p className="Raleway_31px_600 c_lightdrak ">
                        {ele.title}
                      </p>
                      <p className="Raleway_31px_600 c_lightdrak ">
                        {ele.extra}
                      </p>
                    </div>
                    <p className="Raleway_21px_500 c_dark opacity_8">
                      {ele.desc}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div className="expert_div flex_col gap_1vw margin_4vw" id="our_sol"  ref={qusRef}
          initial={{ opacity: 0, y: 50 }}
          animate={qusControls}
          transition={{ duration: 0.5 }}>
          <p className="Raleway_48px_600 c_dark" id="our_sol">
            How does Deep Learning help in Robot data leveraging ?
          </p>
         
          <div className="div_casestudy life_gene" id="our_sol">
            <img
              className="expertise_image"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/yrobo.png"
              alt="our solution"
            />
          </div>
          <div id="our_sol" className="expert_div flex_col gap_1vw">
            <p className="Raleway_26px_500 opacity_8" id="text_just">
              Deep Learning empowers robot data leveraging by enabling the
              extraction of intricate patterns and insights from complex sensor
              data. Through neural network architectures, deep learning models
              can learn representations that capture relationships within the
              data, aiding tasks like object recognition, path planning, and
              anomaly detection.
            </p>
            <p className="Raleway_26px_500 opacity_8 text_cen" id="text_just">
              These models adapt and improve over time, enhancing robot
              decision-making and autonomy. Ultimately, deep learning transforms
              raw robot data into actionable information, enhancing the
              efficiency and effectiveness of robotic systems.
            </p>
          </div>
        </motion.div>
        <motion.div className="expert_div flex_col text_cen" id="our_sol"  ref={craftRef}
          initial={{ opacity: 0, y: 50 }}
          animate={craftControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_dark">Crafting Solutions</p>
            <p className="Raleway_31px_dark opacity_8 text_cen">
              Navigating Challenges with Strategic Ingenuity in Problems That
              Have Been Smartly Resolved.
            </p>
          </div>
          <Grid container className="home_grid_container" id="our_sol">
            {results.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.4}
                xs={12}
                sm={12}
                md={10}
                className="home_grid_product"
                id="border_none"
              >
                <Paper className="home_paper_product">
                  <div className="solar_divs result_down">
                    <img
                      src={ele.imgUrl}
                      alt="ourproduct"
                      className="height_w border_5"
                    />
                  </div>

                  <div className="expert_div result_up width_97">
                    <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                      <div>
                        <p className="Raleway_23px_dark">{ele.title}</p>
                        <p className="Raleway_23px_dark">{ele.extra}</p>
                      </div>
                      <p
                        className="Raleway_21px_500 c_dark opacity_8 height_18vw"
                        id="text_just"
                      >
                        {ele.desc}
                      </p>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw align_item margin_4vw"
          id="our_sol"
          ref={resRef}
          initial={{ opacity: 0, y: 20 }}
          animate={resControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark">Results</p>
          <p className="Raleway_26px c_black">
            The implementation of our solution led to significant improvements
            in the client's operations:
          </p>
          <div className="expert_div gap_1vw flex_col">
            {resultData.map((ele, index) => (
              <div>
                <div className="expert_div gap_1vw align_item">
                  <p className="Raleway_26px c_lightgray">0{index + 1}</p>
                  <p className="Raleway_25px font_500 opacity_8" id="text_just">
                    <span className="c_blue">{ele.title} </span>
                    {ele.desc}
                  </p>
                </div>
                <Divider
                  className="yellow_line"
                  style={{
                    border: "0.1px solid #FDA737",
                  }}
                />
              </div>
            ))}
          </div>
        </motion.div>
        <div
          className="expert_div flex_col gap_1vw align_item margin_4vw margin_bottom"
          id="our_sol"
        >
          <p className="Raleway_48px_600 c_dark">Conclusion</p>
          <p className="Raleway_26px_500 opacity_8" id="text_just">
            This project showcases Smarttrak's expertise in developing
            end-to-end solutions that harness the power of machine data and
            advanced analytics. Our ability to leverage deep learning and data
            engineering techniques allows us to provide our clients with the
            insights they need to optimize their operations and drive business
            growth.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Robotics;
