import React, { useState, useEffect } from "react";
import "./Subscription.scss";
import CustomText from "../../../LifeScience/components/CustomText/CustomText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { SubscriptionPlanService } from "../../Services/SubscriptionPlanService";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";

const subscriptionPlanService = new SubscriptionPlanService();

function Subscription() {
  const { enqueueSnackbar } = useSnackbar();
  const [hoveredGrid, setHoveredGrid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  const [planName, setPlanName] = useState("");
  const [payLink, setPayLink] = useState("");
  const history = useHistory();

  useEffect(() => {
    fetchAllPlans();
  }, []);

  const handleGridHover = (index) => {
    setHoveredGrid(index);
  };

  const handleGridLeave = () => {
    setHoveredGrid(null);
  };

  const fetchAllPlans = async () => {
    try {
      setLoading(true);
      const res = await subscriptionPlanService.getProductPlan();
      if (res.status === 200) {
        setPlanDetails(res.data.products);
        setPlanName(res.data.products);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error in fetchAllPlans", err);
      enqueueSnackbar(
        err.response?.data?.detail
          ? err.response?.data?.detail
          : "Error while fetching plan details",
        { variant: "error" ,anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
      },}
      );
      setLoading(false);
    }
  };

  const paymentLink = async (planName) => {
    try {
      setLoading(true);
      const reqData = { plan: planName };
      const res = await subscriptionPlanService.postPlanName(reqData);
      if (res.status === 200) {
        const payLink = res.data.payment_link;
        setPayLink(payLink);
        return payLink;
      }
      setLoading(false);
    } catch (err) {
      console.log("Error in handleGetStarted", err);
      enqueueSnackbar("Error while get started plan", { variant: "error",anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    }, });
      setLoading(false);
    }
  };

  const handleGetStarted = async (planName) => {
    const payLink = await paymentLink(planName);
    if (payLink) {
      window.location.href = payLink;
    }
  };

  const handleSkipStep = () => {
    history.push({
      pathname: "/iot-home",
    });
  };

  return (
    <div className="subscribe-container">
      {loading && <SimpleBackdrop open={loading} />}
      <img
        src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg"
        alt="logo"
        style={{ width: "10rem" }}
      />
      <div className="plans-container">
        <div className="main-text">
          We offer great{" "}
          <span style={{ color: "rgb(134 147 238)" }}>price</span> plan for your
          Business
        </div>
        <div>
          <Grid sx={{ flexGrow: 1 }} container spacing={10}>
            <Grid item lg={12}>
              <Grid container justifyContent="center" spacing={5}>
                {planDetails.map((item, index) => (
                  <Grid key={index} item>
                    <Paper
                      sx={{
                        width: 290,
                        border: "1px solid #FFFFFF",
                        borderRadius: "16px",
                        paddingBottom: "32px",
                        textAlign: "center",
                        marginBottom: "20px",
                        marginTop: "20px",
                        padding: "20px",
                        boxShadow: "none",
                        backgroundColor:
                          hoveredGrid === index ? "#BAC1F4" : "transparent",
                      }}
                      onMouseEnter={() => handleGridHover(index)}
                      onMouseLeave={handleGridLeave}
                    >
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          padding: "15px",
                          fontSize: "20px",
                          paddingBottom: "0px",
                        }}
                      >
                        {item.name}
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          padding: "15px",
                          fontSize: "14px",
                          textAlign: "initial",
                        }}
                      >
                        <ul>
                          <li>Access to the 3D dashboard of power plant</li>
                          <li>
                            View complete information about the power plant
                          </li>
                          <li>Advanced customization options</li>
                          <li>Email support</li>
                          <li>
                            Price: ${item.unit_amount}/{item.interval}
                          </li>
                        </ul>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          padding: "15px",
                          fontSize: "14px",
                        }}
                      >
                        <span>$</span>
                        <span style={{ fontWeight: "600", fontSize: "20px" }}>
                          {item.unit_amount}
                        </span>
                        <span>/</span>
                        {item.interval}
                      </p>
                      <Button
                        type="submit"
                        variant="contained"
                        style={{
                          backgroundColor:
                            hoveredGrid === index ? "#2A3568" : "transparent",
                          width: "59%",
                          marginTop: "18px",
                          color: hoveredGrid === index ? "#FFFFFF" : "#000000",
                          borderRadius: "8px",
                          textTransform: "none",
                          border: "0.917px solid #000",
                        }}
                        onClick={() => handleGetStarted(item.name)}
                      >
                        Get Started
                      </Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div onClick={handleSkipStep}>
          <CustomText
            type="Regular_15px"
            text="Skip this step for now >"
            styles={{ color: "#000000", padding: "15px", cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Subscription;
