import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import CustomText from "../../components/CustomText/CustomText";
import CustomButton from "../../components/CustomButton/CustomButton";
import SimpleBackdrop from "../../components/common/SimpleBackdrop";
import { GetStartedService } from "../../services/GetStartedService";
import "./GetStarted.scss";

const getStartedService = new GetStartedService();

const GetStarted = (props) => {
  const [loading, setLoading] = useState(false);
  const [useCases, setUseCases] = useState([
    { id: 1, title: "Drug Discovery and development ", isActive: true },
    { id: 2, title: "Genomics and genome editing", isActive: false },
    { id: 3, title: "preteomics and protein engineering", isActive: false },
  ]);
  const [templates, setTemplates] = useState([
    { id: 1, title: "Drug Discovery and development" },
    { id: 2, title: "Genomics and genome editing" },
    { id: 3, title: "preteomics and protein engineering" },
  ]);
  const [templateDetails, setTemplateDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const useCasesPerPage = 3;
  const totalPages = Math.ceil(useCases.length / useCasesPerPage);
  const handleArrowClick = (direction) => {
    if (direction === "prev") {
      setCurrentPage(currentPage === 0 ? totalPages - 1 : currentPage - 1);
    } else if (direction === "next") {
      setCurrentPage(currentPage === totalPages - 1 ? 0 : currentPage + 1);
    }
  };
  const displayedUseCases = useCases.slice(
    currentPage * useCasesPerPage,
    (currentPage + 1) * useCasesPerPage
  );

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    fetchAllUseCases();
    localStorage.getItem("firstName");
    localStorage.getItem("lastName");
  }, []);

  const fetchAllUseCases = async () => {
    try {
      setLoading(true);
      const res = await getStartedService.getAllUsecases();
      if (res.status === 200 && res.data?.data && res.data.data.length > 0) {
        setUseCases(() =>
          res.data.data.map((item, index) =>
            index === 0 ? { ...item, isActive: true } : { ...item }
          )
        );
        const firstUseCaseId = res.data.data[0].id;
        if (firstUseCaseId) await fetchTemplateWithUseCaseId(firstUseCaseId);
        setLoading(false);
      }
    } catch (err) {
      console.log("Error in fetchAllUseCases", err);
      enqueueSnackbar(
        err.response?.data?.detail
          ? err.response?.data?.detail
          : "Error while fetching usecases",
        { variant: "error" }
      );
      setLoading(false);
    }
  };

  const fetchTemplateWithUseCaseId = async (usecaseId) => {
    try {
      const res = await getStartedService.getTemplateDetails(usecaseId);
      if (res.status === 200) {
        const { architecture_name, algorithm, usecase_id, model_details } =
          res.data;
        setTemplateDetails({
          architectureName: architecture_name,
          algorithm: algorithm,
          usecaseId: usecase_id,
        });
        setTemplates(model_details);
      }
    } catch (err) {
      console.log("Error in fetchTemplateWithUseCaseId", err);
      enqueueSnackbar(err.response?.data?.detail);
      setLoading(false);
    }
  };

  const handleUsecaseSelect = async (usecaseId) => {
    setLoading(true);
    await fetchTemplateWithUseCaseId(usecaseId);
    setUseCases((prevState) =>
      prevState.map((item) =>
        item.id === usecaseId
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      )
    );
    setLoading(false);
  };

  const handleUseTemplateClick = (item) => {
    const dataObj = {
      modelConfigs: item,
      usecaseId: templateDetails.usecaseId,
      algorithm: templateDetails.algorithm,
      architectureName: templateDetails.architectureName,
    };
    history.push({
      pathname: `/home/create-ai`,
      state: dataObj,
    });
  };

  return (
    <div className="main">
      {loading && <SimpleBackdrop open={loading} />}
      <div className="welcome-container">
        <div>
          <CustomText
            type="Bold_31px"
            text={`Welcome  ${localStorage.getItem(
              "firstName"
            )} to the No Code AI Platform`}
          />
          <CustomText
            type="SemiBold_15px"
            text="An intutive interface for molecular biologists and life sciences researchers, Convert  
          your raw data directly in to the AI Model"
            styles={{ marginTop: 10, width: 600 }}
          />
        </div>
        <div>
          <img src="https://s3.amazonaws.com/smarttrak.co/images/userImage.png" width={120} alt="userImage" />
        </div>
      </div>
      <CustomText type="Bold_18px" text="Try Defined Usecases" />

      <div className="use-cases-container">
        <div
          className="arrow-usecase-btn left"
          onClick={() => handleArrowClick("prev")}
        >
          <ArrowBackIosIcon />
        </div>
        {displayedUseCases.map((item) => (
          <div
            className={`use-case ${item.isActive ? "selected" : ""}`}
            onClick={() => handleUsecaseSelect(item.id)}
            key={item.id}
          >
            <CustomText
              type="Medium_18px"
              text={item.name}
              styles={{
                textAlign: "center",
                color: item.isActive ? "white" : "black",
              }}
              overflow={false}
            />
          </div>
        ))}
        <div
          className="arrow-usecase-btn right"
          onClick={() => handleArrowClick("next")}
        >
          <ArrowForwardIosIcon />
        </div>
      </div>

      <div className="template-list">
        {templates.map((item) => (
          <div className="template">
            <img
              src={item.image_url}
              height={128}
              width={"100%"}
              alt="template"
            />
            <CustomText
              type="Medium_15px"
              text={item.title}
              overflow={true}
              styles={{ margin: "15px 0px 20px 0px", whiteSpace: "pre-wrap" }}
            />
            <CustomButton
              type="Simple"
              text="Use Template"
              onClick={() => handleUseTemplateClick(item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GetStarted;
