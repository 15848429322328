import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Chart from 'react-apexcharts';

const ScrollXaxisChart = () => {
  const data1 = [
    [new Date('2023-01-01').getTime(), 30],
    [new Date('2023-01-02').getTime(), 40],
    [new Date('2023-01-03').getTime(), 35],
    [new Date('2023-01-04').getTime(), 25],
    [new Date('2023-01-05').getTime(), 20],
    [new Date('2023-01-06').getTime(), 14],
    [new Date('2023-01-07').getTime(), 37],
    [new Date('2023-01-08').getTime(), 32],
    [new Date('2023-01-09').getTime(), 35],
  ];

  const data2 = [
    [new Date('2023-01-01').getTime(), 20],
    [new Date('2023-01-02').getTime(), 25],
    [new Date('2023-01-03').getTime(), 20],
    [new Date('2023-01-04').getTime(), 40],
    [new Date('2023-01-05').getTime(), 33],
    [new Date('2023-01-06').getTime(), 25],
    [new Date('2023-01-07').getTime(), 36],
    [new Date('2023-01-08').getTime(), 28],
    [new Date('2023-01-09').getTime(), 32],
  ];

  const [series] = useState([
    { name: 'Series 1', data: data1 },
    { name: 'Series 2', data: data2 }
  ]);

  const [options] = useState({
    chart: {
      id: 'chart2',
      type: 'line',
      height: 230,
      dropShadow: {
        enabled: true,
        enabledOnSeries: [1]
      },
      toolbar: {
        autoSelected: 'pan',
        show: false
      }
    },
    colors: ['#008FFB', '#00E396'],
    stroke: {
      width: [2, 3],
      curve: ['straight', 'monotoneCubic']
    },
    fill: {
      opacity: [1, 0.75]
    },
    markers: {
      size: 0
    },
    yaxis: [
      {
        seriesName: 'Series 1',
        axisTicks: {
          show: true,
          color: '#008FFB'
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#008FFB'
          }
        },
        title: {
          text: "Series 1",
          style: {
            color: '#008FFB'
          }
        }
      },
      {
        seriesName: 'Series 2',
        opposite: true,
        axisTicks: {
          show: true,
          color: '#00E396'
        },
        axisBorder: {
          show: true,
          color: '#00E396'
        },
        labels: {
          style: {
            colors: '#00E396'
          }
        },
        title: {
          text: "Series 2",
          style: {
            color: '#00E396'
          }
        }
      }
    ],
    xaxis: {
      type: 'datetime'
    }
  });

  const [seriesLine] = useState([
    { name: 'Series 1', data: data1 },
    { name: 'Series 2', data: data2 }
  ]);

  const [optionsLine] = useState({
    chart: {
      id: 'chart1',
      height: 130,
      type: 'area',
      brush: {
        target: 'chart2',
        enabled: true
      },
      selection: {
        enabled: true,
        xaxis: {
          min: new Date('2023-01-01').getTime(),
          max: new Date('2023-01-07').getTime()
        }
      }
    },
    colors: ['#008FFB', '#00E396'],
    stroke: {
      width: [1, 3],
      curve: ['straight', 'monotoneCubic']
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1
      }
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      max: 100,
      tickAmount: 2
    }
  });

  return (
    <div style={{width:"100%"}}>
      <div style={{width:"100%"}}>
        <div style={{width:"100%"}}>
          <Chart options={options} series={series} type="line" height={230} />
        </div>
        <div style={{width:"100%"}}>
          <Chart options={optionsLine} series={seriesLine} type="area" height={130} />
        </div>
      </div>
    </div>
  );
};

export default ScrollXaxisChart;

// Ensure the DOM is fully loaded before rendering the component
document.addEventListener('DOMContentLoaded', () => {
  const domContainer = document.querySelector('#app');
  if (domContainer) {
    ReactDOM.render(<ScrollXaxisChart />, domContainer);
  }
});
