import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = 'https://smarttrakportal.in/api';

const apiHelper = new ApiHelper();
export class PredictAIService{
    getAllPredictModels(){
        const uri = `${BASE_URL}/models/predict`;
        return apiHelper.get(uri);

    }
    predictModelData(data){
        const uri = `${BASE_URL}/training/predict/lamda`;
        return apiHelper.post(uri, data)
    }

    
}