import React, { useState, useEffect } from "react";
import "./ExceutiveDashboard.scss";
import WeatherBoard from "../../Common/WeatherBoard";
import "../../Common/FontSizes.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { useSnackbar } from "notistack";
import EnergyData from "../../Common/EnergyData";
import BarPlotForIOT from "../../Graphs/BarPlotForIOT";
import MarkerBarPlot from "../../Graphs/MarkerBarPlot";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import { CosmicInverterService } from "../../Services/CosmicInverterService";
import { UserService } from "../../Services/UserService";
import { useHistory } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Chatbot from "../../Common/Chatbot";
import NewBarLineChart from "../../Graphs/NewBarLineChart";
import TableNoCheckBox from "../../Common/TableNoCheckBox";
import Tooltip from "@mui/material/Tooltip";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import ModalCalender from "../../Common/ModalCalender";
import "../../Graphs/graphCSS.css";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import "react-circular-progressbar/dist/styles.css";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import CustomCircularBar from "../../Common/CustomCircularBar";
import useErrorHandler from "../../../utils/errorHandler";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import UnixDateChart from "../../Graphs/UnixDateChart";
import VoiceChat from "../../Common/VoiceChat";
import TimestampFormatter from "../../Common/TimestampFormatter";
import { useMobile } from "../../Context/MobileContext/MobileContext";
import OperationalEfficiency from "../../Common/OperationalEfficiency";
import DynamicArrow from "../../SVG/DynamicArrow";
import EnergyGenerated from "../../Common/EnergyGenerated";
import LossesDonutChart from "../../Common/LossesDonutChart";
const invService = new CosmicInverterService();
const userService = new UserService();
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "green" : "#308fe8",
  },
}));
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#F4F4F4 !important",
    border: "1px solid #2F67F8 !important",
    color: "black", // Change text color if needed
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#2F67F8 !important",
  },
});
function ExecutiveDashboard() {
  const [perfRatioStartTime, setPerfRatioStartTime] = useState("");
  const [perfRatioStopTime, setPerfRatioStopTime] = useState("");
  const [pastGenStartTime, setPastGenStartTime] = useState("");
  const [pastGenStopTime, setPastGenStopTime] = useState("");
  const [isStartTimeCUF_AC, setIsStartTimeCUF_AC] = useState("");
  const [isStopTimeCUF_AC, setIsStopTimeCUF_AC] = useState("");
  const [isStartTimeCUF_DC, setIsStartTimeCUF_DC] = useState("");
  const [isStopTimeCUF_DC, setIsStopTimeCUF_DC] = useState("");
  const [isWeatherData, setIsWeatherData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [averagePerformaceRatio, setAveragePerformaceRatio] = useState("");
  const [averageCUF_DC, setAverageCUF_DC] = useState("");
  const [averageCUF_AC, setAverageCUF_AC] = useState("");

  const [loadingState, setLoadingState] = useState({
    perfRatio: false,
    cufDC: false,
    cufAC: false,
    irrPOA: false,
    plantTable: false,
    fieldList: false,
    mfmFieldList: false,
    weatherPara: false,
    plantData: false,
    weatherData: false,
    powerinfo: false,
    invList: false,
    ghiOverview: false,
    pastData: false,
    deviceStatus: false,
    reportCSV: false,
    forecast: false,
    plantEnergyOne: false,
  });

  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
    graph3: false,
    graph4: false,
    graph5: false,
    graph6: false,
  });
  const [isStartTimeActiveIrr, setIsStartTimeActiveIrr] = useState("");
  const [isStopTimeActiveIrr, setIsStopTimeActiveIrr] = useState("");
  const [isStartTimePrediction, setIsStartTimePrediction] = useState("");
  const [isStopTimePrediction, setIsStopTimePrediction] = useState("");
  const [predictedData, setPredictedData] = useState({});
  const [performanceValue, setPerformanceValue] = useState([]);
  const [pastGenData, setPastGenData] = useState({});
  const [CUF_DC_Value, setCUF_DC_Value] = useState([]);
  const [CUF_AC_Value, setCUF_AC_Value] = useState([]);
  const [stringDurationTwo, setStringDurationTwo] = useState("h");
  const [stringDurationThree, setStringDurationThree] = useState("h");
  const [activePowerIrr, setActivePowerIrr] = useState([]);
  const [plantListsArray, setPlantListsArray] = useState([]);
  const [powerarray, setPowerArray] = useState({});
  const [totalPowerGen, setTotalPowerGen] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [weatherInfoData, setWeatherInfoData] = useState({});
  const [deviceStatus, setDeviceStatus] = useState([]);
  const [isStartTime, setIsStartTime] = useState("");
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [isDeviceFields, setIsDeviceFields] = useState("output_power_kW");
  const [isDeviceDurations, setIsDeviceDurations] = useState("hour");
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [openDrawerCal, setOpenDrawerCal] = useState(false);
  const [isStopTime, setIsStopTime] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openVoiceChat, setOpenVoiceChat] = useState(false);
  const [isGenType, setIsGenType] = useState("");

  const [plantEnergy, setPlantEnergy] = useState({});

  const [ghiOverview, setGHIOverview] = useState({});
  const [openCalender, setOpenCalender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newInvArray, setNewInvArray] = useState([]);
  const [isAbtData, setIsAbtData] = useState([]);
  const [presentPower, setPresentPower] = useState(0);
  const [isPanelData, setIsPanelData] = useState([]);
  const [isStartTimeCalender, setIsStartTimeCalender] = useState("");
  const [isStopTimeCalender, setIsStopTimeCalender] = useState("");

  const [showPopup, setShowPopup] = useState(true);
  const [showChatIcons, setShowChatIcons] = useState(false);
  const { isOnlyMobile, isMobile, isMobileAndTab, isDesktopView } = useMobile();
  const { enqueueSnackbar } = useSnackbar();
  // const isFullScreen = true;
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];
  let warningDisplayed = false;
  const history = useHistory();
  const deviceDurationValue = ["hour", "minute"];

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day1 = String(currentDate.getDate()).padStart(2, "0");
  const defaultEndValue = `${year1}-${month1}-${day1}T00:00:00Z`;
  const handleFetchError = useErrorHandler();
  const totalPowerGenPercent = (totalPowerGen / totalCapacity) * 100

  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };
  const inverterNames = newInvArray
    .filter(
      (item) =>
        item.device_details !== null && item.device_details.name !== null
    )
    .map((item) => item.device_details.name);
  const filteredInverterArray = newInvArray.filter(
    (item) =>
      item.device_data !== null &&
      item.device_data["output_power_kW"] !== null &&
      item.device_details !== null
  );

  useEffect(() => {
      handleFieldsList()
  }, [])
  // const demoPlant = plantListsArray[0]?.name === "Demo Plant "
  // useEffect(() => {
  //   if(demoPlant) {
  //   if (totalPowerGen === 0 || presentPower < 20) {
  //     // const randomPower = Math.floor(Math.random() * (700 - 600 + 1)) + 600;
  //     setTotalPowerGen(2016);
  //     setPresentPower(1150);
  //   }}
  // }, [totalPowerGen, presentPower]);
  useEffect(() => {
    handlePlantEnergy(id)
  }, [id])

  useEffect(() => {
    const fetchInitialData = async () => {
      await getPlantsData();
      if (id) {
        await Promise.all([
          handleInvertersList(id),
          handleWeatherParameters(id),
          handleInverterPowerInfo(id),
          handleDeviceStatus(id),
          handleWeatherInfo(id),
          handleGHIOverview(id),
        ]);
      }
    };
    const fetchDataPeriodically = () => {
      const intervalId = setInterval(async () => {
        if (id) {
          await Promise.all([handleInvertersList(id)]);
        }
      }, 5 * 60 * 1000); // 10 minutes interval

      // Call initially and clear the interval on component unmount
      fetchInitialData();

      return () => clearInterval(intervalId);
    };

    fetchDataPeriodically();
  }, [id]);

  useEffect(() => {
    const fetchActivePowerIrrPOA = async () => {
      if (
        id &&
        ((isStartTimeActiveIrr && isStopTimeActiveIrr) || stringDurationTwo)
      ) {
        await handleActivePowerIrrPOA(
          id,
          stringDurationTwo || "h",
          isStartTimeActiveIrr,
          isStopTimeActiveIrr
        );
      }
    };
    fetchActivePowerIrrPOA();
  }, [id, isStopTimeActiveIrr, stringDurationTwo]);
  useEffect(() => {
    const fetchForecatedGenData = async () => {
      if (
        id &&
        ((isStartTimePrediction && isStopTimePrediction) || stringDurationThree)
      ) {
        await handleForecastedGen(
          id,
          stringDurationThree || "h",
          isStartTimePrediction,
          isStopTimePrediction
        );
      }
    };
    fetchForecatedGenData();
  }, [id, isStopTimePrediction, stringDurationThree]);

  const fetchIntervalData = (
    fetchDataFn,
    id,
    startTime,
    endTime,
    intervalSetter
  ) => {
    const fetchData = async () => {
      await fetchDataFn(id, startTime, endTime);
    };
    fetchData();
    const intervalId = setInterval(fetchData, 900000); // 15 minutes

    intervalSetter(intervalId);
  };

  useEffect(() => {
    let intervalId;
    if (id) {
      fetchIntervalData(
        handleCUF_AC_Graph,
        id,
        isStartTimeCUF_AC,
        isStopTimeCUF_AC,
        (id) => (intervalId = id)
      );
    }
    return () => clearInterval(intervalId);
  }, [id, isStopTimeCUF_AC, isStartTimeCUF_AC]);

  useEffect(() => {
    let intervalId;
    if (id) {
      fetchIntervalData(
        handleCUF_DC_Graph,
        id,
        isStartTimeCUF_DC,
        isStopTimeCUF_DC,
        (id) => (intervalId = id)
      );
    }
    return () => clearInterval(intervalId);
  }, [id, isStopTimeCUF_DC, isStartTimeCUF_DC]);

  useEffect(() => {
    let intervalId;
    if (id) {
      fetchIntervalData(
        handlePlantPerfRatio,
        id,
        perfRatioStartTime,
        perfRatioStopTime,
        (id) => (intervalId = id)
      );
    }
    return () => clearInterval(intervalId);
  }, [id, perfRatioStartTime, perfRatioStopTime]);
  useEffect(() => {
    if (isStartTimeCalender && isStopTimeCalender) {
      handleDailyCSVReport(id, isStartTimeCalender, isStopTimeCalender);
    }
  }, [isStopTimeCalender, id]);
  useEffect(() => {
    let intervalId;
    if (id) {
      fetchIntervalData(
        handlePastGenData,
        id,
        pastGenStartTime,
        pastGenStopTime,
        (id) => (intervalId = id)
      );
    }
    return () => clearInterval(intervalId);
  }, [id, pastGenStartTime, pastGenStopTime]);

  useEffect(() => {
    if (id) {
      if (isStartTime && isStopTime) {
        handlePlantTable(
          id,
          isDeviceFields,
          isDeviceDurations,
          isStartTime,
          isStopTime
        );
      } else {
        const fetchData = () => {
          handlePlantTable(id, isDeviceFields, isDeviceDurations);
        };
        fetchData();
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isDeviceDurations, isDeviceFields, isStopTime, id]);
  const handleCalenderTimings = () => {
    setOpenCalender(true);
  };

  const weatherInfo = [
    {
      title: "Humidity",
      temp: `${weatherInfoData?.humidity ?? "--"} %`,

      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/Vector.svg",
    },
    {
      title: "Wind Speed",
      temp: `${weatherInfoData?.windSpeed ?? "--"} km/h`,
      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/Wind.svg",
    },
    {
      title: "Air Temp",
      temp: `${weatherInfoData?.airTemperature ?? "--"} °C`,

      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/Cloud+Wind.svg",
    },
    {
      title: "Irradiance",
      temp: `${isWeatherData[0]?.device_data?.irradiance
          ? isWeatherData[0]?.device_data?.irradiance
          : "--"
        } W/m²`,

      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/Solarirradiance.svg",
    },
  ];
  const panelWeatherInfo = [
    {
      title: "Ambient Temp",
      temp: `${isPanelData[0]?.device_data?.ambient_temperature_degree_C ?? "--"
        } °C`,

      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/ambient_temp.png",
    },
    {
      title: "Module Temp",
      temp: `${isPanelData[0]?.device_data?.module_temperature_degree_C ?? "--"
        } °C`,
      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/module_temp.png",
    },
  ];
  const historyList = [
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
    {
      imageIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/message-icon.svg",
      title: "Insights",
    },
  ];
  const PowerPlant = [
    {
      title: "AC Power Analysis",
      energy: "25999",
      subtitle1: "Plant AC Capacity",
      value1:
        Array.isArray(plantListsArray) && plantListsArray.length > 0
          ? plantListsArray[0]?.plant_specific_details?.["Plant AC Capacity kW"]
            ? `${plantListsArray[0].plant_specific_details["Plant AC Capacity kW"]} kW`
            : "0/0 kW"
          : "0/0 kW",

      subtitle2: "AC Power (kW)",
      value2: presentPower !== null ? presentPower.toFixed(0)
          : 0,

      PlantIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/plant_dc_icon.svg",
      colorCode: "#00529A",
    },
    {
      title: "DC Power Analysis",
      subtitle1: "Plant DC Capacity",
      value1:
        Array.isArray(plantListsArray) && plantListsArray.length > 0
          ? plantListsArray[0]?.plant_specific_details?.[
            "Plant DC Capacity kWp"
          ]
            ? `${plantListsArray[0].plant_specific_details["Plant DC Capacity kWp"]} kW`
            : "0/0 kW"
          : "0/0 kW",

      subtitle2: "DC Power (kW)",
      value2:
        totalPowerGen === 0
          ? "0.00 kW"
          : totalPowerGen
            ? `${totalPowerGen.toFixed(0)} kW`
            : "--",
      PlantIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/plant_ac_icon.svg",
      colorCode: "#04724D",
    },

  ];

  const jsonData = {
    title: "Operational Efficiency Metrics",
    energy: "25999",
    subtitle1: "CUF-AC Capacity",
    value1: averageCUF_AC
      ? `${Math.floor(averageCUF_AC * 100) / 100} %`
      : "--",
    subtitle2: "CUF-DC Capacity",
    value2: averageCUF_DC
      ? `${Math.floor(averageCUF_DC * 100) / 100} %`
      : "--",
    subtitle3: "Performance Ratio",
    value3: averagePerformaceRatio
      ? `${Math.floor(averagePerformaceRatio * 100) / 100} %`
      : "--",
    PlantIcon:
      "https://s3.amazonaws.com/smarttrak.co/v2Images/plant_other_icon.svg",
    colorCode: "#04724D",
  }
  const [anchorEl, setAnchorEl] = useState(
    Array.from({ length: PowerPlant.length }, () => null)
  );

  const NotificationData = [
    {
      title: "Security alert:",
      disc: "Unauthorized access attempt detected",
    },
    {
      title: "Performance issue detected:",
      disc: "Equipment malfunction reported",
    },
    {
      title: "Weather alert:",
      disc: "Cloudy conditions impacting energy production",
    },
    {
      title: "Scheduled maintenance reminder:",
      disc: "Maintenance required",
    },
    {
      title: "System health alert:",
      disc: "Battery status critical",
    },
    {
      title: "Energy production trend:",
      disc: "Decreasing output observed",
    },
  ];
  const invStatus = [
    {
      title: "Total Running Inverters",
      value:
        deviceStatus.filter(
          (device) => device.type === "inverter" && device.status === true
        ).length > 0
          ? deviceStatus.filter(
            (device) => device.type === "inverter" && device.status === true
          ).length
          : "0",
      total:
        deviceStatus.filter((device) => device.type === "inverter").length > 0
          ? deviceStatus.filter((device) => device.type === "inverter").length
          : "0",
      iconInv: "https://s3.amazonaws.com/smarttrak.co/v2Images/invStatus.svg",
    },
    {
      title: "Total Running MFMs",
      value:
        deviceStatus.filter(
          (device) => device.type === "mfm" && device.status === true
        ).length > 0
          ? deviceStatus.filter(
            (device) => device.type === "mfm" && device.status === true
          ).length
          : "0",
      total:
        deviceStatus.filter((device) => device.type === "mfm").length > 0
          ? deviceStatus.filter((device) => device.type === "mfm").length
          : "0",
      iconInv: "https://s3.amazonaws.com/smarttrak.co/v2Images/mfmStatus.svg",
    },
    {
      title: "Total Running ABT" + "\u00A0" + "\u00A0" + "\u00A0" + "\u00A0",

      value:
        deviceStatus.filter(
          (device) => device.type === "abt" && device.status === true
        ).length > 0
          ? deviceStatus.filter(
            (device) => device.type === "abt" && device.status === true
          ).length
          : "0",
      total:
        deviceStatus.filter((device) => device.type === "abt").length > 0
          ? deviceStatus.filter((device) => device.type === "abt").length
          : "0",

      iconInv: "https://s3.amazonaws.com/smarttrak.co/v2Images/vcbStatus.svg",
    },
  ];

  const upwardPath =
    "M5.20759 0.529365C5.6079 0.00935769 6.3921 0.00935763 6.79241 0.529365L11.6888 6.89001C12.195 7.54757 11.7263 8.5 10.8964 8.5H1.10358C0.273737 8.5 -0.195026 7.54757 0.311171 6.89001L5.20759 0.529365Z";
  const downwardPath =
    "M6.79241 8.47063C6.3921 8.99064 5.6079 8.99064 5.20759 8.47063L0.311171 2.10999C-0.195026 1.45243 0.273737 0.5 1.10358 0.5H10.8964C11.7263 0.5 12.195 1.45243 11.6888 2.10999L6.79241 8.47063Z";
  const greenFill = "#30B900";
  const redFill = "#FF0000";

  const handleChatBot = () => {
    setOpenDrawer(true);
    setOpenVoiceChat(false);
  };
  const handleVoiceOver = () => {
    setOpenVoiceChat(true);
    setOpenDrawer(false);
  };
  const handleDeviceStatus = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, deviceStatus: true }));
      const res = await invService.getDeviceSttaus(id);
      if (res.status === 200) {
        setDeviceStatus(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, deviceStatus: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, deviceStatus: false }));
      handleFetchError(error, "Device Status");
    }
  };
  const handleDailyCSVReport = async (
    id,
    isStartTimeCalender,
    isStopTimeCalender
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, reportCSV: true }));

      const res = await invService.getDailyCSVReport(
        id,
        isStartTimeCalender,
        isStopTimeCalender,
        true
      );
      if (res.status === 200) {
        const csvData = res.data;
        downloadCSV(csvData, isStartTimeCalender, isStopTimeCalender);
      }
      setLoadingState((prevState) => ({ ...prevState, reportCSV: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, reportCSV: false }));
      handleFetchError(error, "report dowload");
    }
  };
  const downloadCSV = (csvData, start, end) => {
    const csvContent = "data:text/csv;charset=utf-8," + csvData;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      start && end
        ? `${isStartTimeCalender.split("T")[0] !==
          isStopTimeCalender.split("T")[0]
          ? `${isStartTimeCalender.split("T")[0]}_${isStopTimeCalender.split("T")[0]
          }`
          : isStartTimeCalender.split("T")[0]
        }.csv`
        : `${defaultEndValue.split("T")[0]}.csv`
    );

    document.body.appendChild(link);
    link.click();
  };


  const handlePlantEnergy = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, plantEnergyOne: true }));
      const res = await invService.getPlantEnergyGen(id);
      if (res.status === 200) {
        setPlantEnergy(res.data);
        setLoadingState((prevState) => ({ ...prevState, plantEnergyOne: false }));

      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, plantEnergyOne: false }));
      handleFetchError(error, "energy");

    }
  };
  const handlePlantPerfRatio = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, perfRatio: true }));
      const res = await invService.getPlantPerfRatio(id, start_time, end_time);
      if (res.status === 200) {
        const data = res.data.series[0].data;
        const sum = data.reduce((acc, currentValue) => acc + currentValue, 0);
        const averagePerformanceRatio = sum / data.length;
        setAveragePerformaceRatio(averagePerformanceRatio);
        setPerformanceValue(res.data);
        setLoadingState((prevState) => ({ ...prevState, perfRatio: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, perfRatio: false }));
      handleFetchError(error, "Performance Ratio");
    }
  };
  const handlePastGenData = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, pastData: true }));
      const res = await invService.getPastGenExportedData(
        id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        setPastGenData(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, pastData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, pastData: false }));
      handleFetchError(error, "past generated data");
    }
  };
  const handleForecastedGen = async (
    id,
    stringDurationThree,
    start_time,
    end_time
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, forecast: true }));
      const res = await invService.getPredictedData(
        id,
        stringDurationThree === undefined ? "h" : stringDurationThree,
        start_time,
        end_time
      );
      if (res.status === 200) {
        setPredictedData(res.data);
        setLoadingState((prevState) => ({ ...prevState, forecast: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, forecast: false }));
      handleFetchError(error, "Actual Vs Forecasted Generation");
    }
  };

  const handleGHIOverview = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, ghiOverview: true }));
      const res = await invService.getGHIOverview(id);
      if (res.status === 200) {
        setGHIOverview(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, ghiOverview: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, ghiOverview: false }));
      handleFetchError(error, "GHI Overview");
    }
  };

  const handleActivePowerIrrPOA = async (
    id,
    stringDurationTwo,
    isStartTimeActiveIrr,
    isStopTimeActiveIrr
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, irrPOA: true }));
      const res = await invService.getActivePowerIrrPOA(
        id,
        stringDurationTwo,
        isStartTimeActiveIrr,
        isStopTimeActiveIrr
      );
      if (res.status === 200) {
        setActivePowerIrr(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, irrPOA: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, irrPOA: false }));
      handleFetchError(error, "Power Vs Inverter Vs Irradiance POA");
    }
  };
  const handleCUF_DC_Graph = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, cufDC: true }));
      const res = await invService.getCufDc(id, start_time, end_time);
      if (res.status === 200) {
        setCUF_DC_Value(res.data.filter((item) => item.value !== 0));

        const sum = res.data.reduce(
          (acc, currentValue) => acc + currentValue.value,
          0
        );

        const performanacevalue = sum / res.data.length;
        setAverageCUF_DC(performanacevalue);
      }
      setLoadingState((prevState) => ({ ...prevState, cufDC: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, cufDC: false }));
      handleFetchError(error, "CUF DC Data");
    }
  };
  const handleCUF_AC_Graph = async (id, start_time, end_time) => {
    if (id) {
      try {
        setLoadingState((prevState) => ({ ...prevState, cufAC: true }));

        const res = await invService.getCufAc(id, start_time, end_time);
        if (res.status === 200) {
          setCUF_AC_Value(res.data.filter((item) => item.value !== 0));

          const sum = res.data.reduce(
            (acc, currentValue) => acc + currentValue.value,
            0
          );

          const performanacevalue = sum / res.data.length;
          setAverageCUF_AC(performanacevalue);
        }
        setLoadingState((prevState) => ({ ...prevState, cufAC: false }));
      } catch (error) {
        setLoadingState((prevState) => ({ ...prevState, cufAC: false }));
        handleFetchError(error, "CUF AC Data");
      }
    } else {
      if (!warningDisplayed) {
        enqueueSnackbar("Please have the plant _id", {
          variant: "warning",
        });
        warningDisplayed = true;
      }
    }
  };

  const handleInvertersList = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, invList: true }));
      const res = await invService.getDeviceLevelData(id, "inverter");
      if (res.status === 200) {
        setNewInvArray(res.data);
        const totalOutputPower = res.data.reduce((total, device) => {
          if (
            device.device_data &&
            device.device_data.output_power_kW !== null
          ) {
            return total + device.device_data.output_power_kW;
          } else {
            return total;
          }
        }, 0);

        const sumCapacity = res.data.reduce((total, inverter) => {
          const capacityKW =
            inverter.device_details?.device_specific_details?.capacity_kW || 0;
          return total + capacityKW;
        }, 0);

        setTotalCapacity(sumCapacity);
        setTotalPowerGen(totalOutputPower);
      }
      setLoadingState((prevState) => ({ ...prevState, invList: false }));
    } catch (error) {
      console.log(error);

      setLoadingState((prevState) => ({ ...prevState, invList: false }));
      handleFetchError(error, "Inverter List");
    }
  };
  const handleInverterPowerInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, powerinfo: true }));
      const res = await invService.getPlantOverview(id);
      if (res.status === 200) {
        setPowerArray(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, powerinfo: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, powerinfo: false }));
      handleFetchError(error, "Power Info");
    }
  };
  const handleWeatherInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherData: true }));
      const res = await invService.getWeatherInfo(id);
      if (res.status === 200) {
        const timestamp = res.data.dt * 1000;
        const date = new Date(timestamp);
        const month = date.toLocaleString("default", { month: "long" });
        const dayAbbreviation = date.toLocaleString("en-US", {
          weekday: "long",
        });
        setWeatherInfoData({
          weatherConditions: res.data.weather[0].main,
          weatherUrl: res.data.weather[0].icon,

          temperature: res.data.main.feels_like,
          airTemperature: res.data.main.temp,

          minTemp: res.data.main.temp_min,
          maxTemp: res.data.main.temp_max,
          humidity: res.data.main.humidity,
          windSpeed: res.data.wind.speed,
          day: dayAbbreviation,
          date: date.getDate(),
          month: month,
        });
      }
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
      handleFetchError(error, "Weather Data");
    }
  };

  const getPlantsData = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, plantData: true }));
      const res = await userService.getPlantsList();
      if (res.status === 200) {
        setPlantListsArray(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, plantData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, plantData: false }));
      handleFetchError(error, "Plants Data");
    }
  };
  const handleWeatherParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherPara: true }));
      const res = await invService.getDeviceLevelData(id, "wms");
      if (res.status === 200) {
        setIsWeatherData(res.data);
      }
      const resAbt = await invService.getDeviceLevelData(id, "abt");
      if (resAbt.status === 200) {
        setIsAbtData(resAbt.data);
        setPresentPower(resAbt.data[0]?.device_data?.three_phase_apparent_power_KVA);
      }
      const resPanel = await invService.getDeviceLevelData(id, "panel");
      if (resPanel.status === 200) {
        setIsPanelData(resPanel.data);
      }
      setLoadingState((prevState) => ({ ...prevState, weatherPara: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherPara: false }));
      handleFetchError(error, "Weather Parameters");
    }
  };
  const handleComponents = async(ele) => {
    if (ele === 0) {
      history.push("/iot-home/dev-monitoring/inv-dashboard");
    } else if (ele === 1) {
      const mfmData = await handleMfmParameters(id);
      const fieldsList = await handleMfmFieldsList();
      history.push({
        pathname: "/iot-home/dev-monitoring/mfm",
        state: {
          mfmDeviceData: mfmData[0],
          mfmList: mfmData,
          firstField: fieldsList[0],
        },
      });
    } else if (ele === 2) {
      history.push("/iot-home/dev-monitoring/abt");
    }
  };
  const handlePopoverOpen = (index, event) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };
  const handlePopoverClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeDeviceFields = (device) => {
    setIsDeviceFields(device.value);
    handlePlantTable(id, device.value, isDeviceDurations);
  };
  const handleChangeDeviceDuration = (device) => {
    setIsDeviceDurations(device.value.toLowerCase());
    handlePlantTable(id, isDeviceFields, device.value.toLowerCase());
  };
  const handleTimings = () => {
    setOpenDrawerCal(true);
  };
  const handleFieldsList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldList: true }));
      const res = await invService.getListDeviceFields("inverter");
      if (res.status === 200) {
        let modifiedData = [...res.data];
        const fieldMappings = {
          output_power_kW: "Output Power",
          output_current_A: "Output Current",
          energy_counter_MWh: "Energy Counter",
          net_frequency_Hz: "Net Frequency",
          net_AC_voltage_V: "Net AC Voltage",
          DC_voltage_DCV: "DC Voltage",
          average_cosphii_percent: "Average Cosphi",
        };
        const dropdownOptions = modifiedData.map((field) => {
          return {
            label: fieldMappings[field],
            value: field,
          };
        });
        setDeviceFieldValue(dropdownOptions);
        setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      }

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, fieldList: false }));

      handleFetchError(error, "device fields");
    }
  };
  const handleMfmFieldsList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, mfmFieldList: true }));
      
      const res = await invService.getListDeviceFields("mfm");
      if (res.status === 200) {
      setLoadingState((prevState) => ({ ...prevState, mfmFieldList: false }));
        return res.data
      }
    
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, mfmFieldList: false }));

      handleFetchError(error, "device fields");
    }
  };
  const handleMfmParameters = async (id) => {
    try {
      setLoading(true);
      const res = await invService.getDeviceLevelData(id, "mfm");
      if (res.status === 200) {
        setLoading(false);
        return res.data.map((item) => ({
          name: item?.device_details?.name,
          current: item?.device_data?.phase_A_RMS_current_A.toFixed(2),
          voltage: item?.device_data?.phase_A_RMS_voltage_V.toFixed(2),
          Energy: item?.device_data?.positive_active_energy_kWh.toFixed(2),
          status: item?.device_details?.status === true ? "ON" : "OFF",
          raised_at: (
            item?.device_details?.last_active.match(
              /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/
            ) || []
          )
            .slice(1)
            .join(" "),
          device_data: item?.device_data?.device_id,
          id: item?.device_details?.id,
        }));
      }
    } catch (err) {
      setLoading(false);
      handleFetchError(err, "mfm field List");
    }
  };

  const handlePlantTable = async (
    id,

    fieldVal,
    duration,
    isStartTime,
    isStopTime
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, plantTable: true }));
      const res = await invService.getPlantTable(
        id,
        fieldVal,
        duration,
        isStartTime,
        isStopTime
      );
      if (res.status === 200) {
        const modifiedData = res.data.map((item) => ({
          ...item,
          timestamp_edge: item.timestamp_edge
            .replace(/T|\.|Z/g, " ")
            .slice(0, 16),
        }));
        setColumns(modifiedData);
      }
      setLoadingState((prevState) => ({ ...prevState, plantTable: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, plantTable: false }));
      handleFetchError(error, "plant table");
    }
  };
  const deviceWithData = filteredInverterArray.find(
    (device) =>
      device.device_data["output_power_kW"] !== null &&
      device.device_data["output_power_kW"] !== 0
  );

  const handleChatbotClick = () => {
    setShowPopup(false);
    setShowChatIcons(prev => (!prev));
  }

  return (
    <div className="home-container">
      <Grid
        container
        className="weatherDash-container"
        sx={{ zIndex: "0", pointerEvents: openDrawer ? "none" : "auto" }}
      >
        <Grid
          item
          lg={9.6}
          xs={12}
          sm={12}
          md={isMobile ? 11.9 : 9.1}
          className="scrolling-grid-exc"
        >
          <Grid container className="grid-container">
            <Grid
              item
              lg={4}
              xs={12}
              sm={12}
              md={12}
              sx={{
                position: "relative",
              }}
              className="total_power_container"
            >
              <Paper className="inv_total_paper" id="timeGap">
                <div className="Poppins_24px_rem" style={{ color: "#2F67F8" }}>
                  {plantListsArray[0]?.name ? plantListsArray[0]?.name : "--"}
                </div>
                <div className="flex-start-column-two" id="timeGap">
                  {/* <div className="space-btn-center">
                    <div className="flex-start-column">
                      <p className="raleway_14px_rem">
                      AC Power (kW)
                      </p>
                      <p className="Poppins_18px_rem">
                     { isAbtData.length > 0 &&
                      isAbtData[0]?.device_data
                        ?.three_phase_apparent_power_KVA != null
                        ? `${isAbtData[0]?.device_data?.three_phase_apparent_power_KVA.toFixed(
                            2
                          )} kW`
                        : 0
                    }

                      </p>
                    </div>
                    <div className="flex-start-column">
                      <p className="raleway_14px_rem">
                      DC Power (kW)
                      </p>
                      <p className="Poppins_18px_rem">
                       {
                      totalPowerGen === 0
                        ? "0.00 kW"
                        : totalPowerGen
                        ? `${totalPowerGen.toFixed(2)} kW`
                        : "--"
                    }
                      </p>
                    </div>
                  </div> */}
                  <div className="space-btn-center">

                    <div className="flex-start-column">
                      <p className="raleway_14px_rem">Last Updated on</p>

                      <p className="Poppins_18px_rem">
                        {plantListsArray?.[0]?.last_active ? (
                          <TimestampFormatter
                            dateString={plantListsArray[0].last_active}
                          />
                        ) : "--"}
                      </p>
                    </div>

                    <div></div>
                  </div>
                  <div className="space-btn-center">
                    <div className="flex-start-column">
                      <p className="raleway_14px_rem">
                        Power Generated At Present
                      </p>
                      <p className="raleway_32px_rem">
                        {presentPower !== null ? presentPower.toFixed(2) : 0
                        } {" "}
                        <span className="Poppins_18px_rem">kW</span>
                      </p>
                    </div>
                    <div></div>
                  </div>

                  <div className="space-btn-center">
                    <div className="flex-start-column">
                      <p className="raleway_14px_rem">
                        Total Yield Energy Till Now
                      </p>
                      <p className="raleway_32px_rem">
                        {powerarray.total_yield_energy_kWh !== null &&
                          powerarray.total_yield_energy_kWh !== undefined
                          ? `${powerarray.total_yield_energy_kWh.toFixed(2)} `
                          : "--"}
                        <span className="Poppins_18px_rem">
                          {powerarray.unit && powerarray.unit}
                        </span>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
              </Paper>
              {loadingState.powerinfo && (
                <SimpleBackdrop open={loadingState.invList} />
              )}
              {loadingState.plantData && (
                <SimpleBackdrop open={loadingState.plantData} />
              )}
              {loadingState.powerinfo && (
                <SimpleBackdrop open={loadingState.powerinfo} />
              )}

            </Grid>

            {!isMobile && <Grid
              item
              order={{ lg: 2 }}
              lg={3.3}
              xs={12}
              sm={12}
              md={12}
              className="power-plant-grid-exc imp_notification_block"
            >
              <Paper className="power-plant-paper-exc">
                <div className="center_div">
                  <div className="icon-exc-div">
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/plant_other_icon.svg"
                      alt="plant"
                      style={{ width: "2rem" }}
                    />
                  </div>
                  <div className="flex-start-column">
                    <p className="Poppins_18px_rem">Start & Stop Time</p>
                  </div>
                </div>

                <div onClick={(event) => handlePopoverOpen(5, event)}>
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/ep_info-filled.svg"
                    alt="plant"
                    style={{ width: "1rem", cursor: "pointer" }}
                  />
                </div>
              </Paper>
              <Paper
                className="powerplant-sec-paper"
                sx={{ flexDirection: { lg: "column !important" } }}
              >
                <div
                  className="powerplant-div-exc"
                  style={{
                    borderLeft: `0.2rem solid #FDA737`,
                  }}
                >
                  <p className="raleway_14px_rem">Start Time</p>
                  <p className="Poppins_18px_rem">
                    {plantListsArray[0]?.sunrise_time
                      ? plantListsArray[0]?.sunrise_time.substring(11, 16)
                      : "--"}
                  </p>
                </div>
                <div
                  className="powerplant-div-exc"
                  style={{
                    borderLeft: `0.2rem solid #FDA737`,
                  }}
                >
                  <p className="raleway_14px_rem">Stop Time</p>
                  <p className="Poppins_18px_rem">
                    {plantListsArray[0]?.sunset_time
                      ? plantListsArray[0]?.sunset_time.substring(11, 16)
                      : "--"}
                  </p>
                </div>
              </Paper>
              <Popover
                open={Boolean(anchorEl[5])}
                anchorEl={anchorEl[5]}
                onClose={() => handlePopoverClose(5)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{ className: "customPopoverPaper-exc" }}
              >
                <div className="center_div">
                  <p className="raleway_14px_rem">Shows daily power generation start and stop times, aligned with sunrise and sunset.</p>
                </div>
              </Popover>
              {loadingState.plantData && (
                <SimpleBackdrop open={loadingState.plantData} />
              )}
            </Grid>}

            <Grid
              item
              order={{ xs: 4 }}
              lg={4.5}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >

              <OperationalEfficiency data={jsonData} />


              {loadingState.invList && (
                <SimpleBackdrop open={loadingState.invList} />
              )}
            </Grid>
            {/* 
            <Grid
              sx={{
                position: "relative",
              }}
              order={{ xs: 2, lg: 4 }}
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="center_center_column"
            >
              <Paper className="energy-paper">
              <Grid container className="center_center_flex_gap">
                <Grid
                  item
                  lg={11.9}
                  xs={12}
                  sm={12}
                  md={11.9}
                  className="flex-center_transparent"
                >
                  <Paper className="gen-power_paper">
                    <p className="Poppins_24px_black">Energy Generated</p>
                  </Paper>
                </Grid>
                <Grid
                  item
                  lg={2.8}
                  xs={5.8}
                  sm={5.8}
                  md={5.5}
                  className="center_center_flex"
                >
                  <Paper className="column_gap_transparent">
                    <EnergyData
                      ACPercent={
                        powerarray?.today_forcasted_increase_percent
                          ?.percentage !== null &&
                        powerarray?.today_forcasted_increase_percent
                          ?.percentage !== undefined
                          ? powerarray.today_forcasted_increase_percent.percentage.toFixed(
                              2
                            )
                          : "--"
                      }
                      ACValue={
                        powerarray?.today_forcasted_increase_percent?.value !==
                          null &&
                        powerarray?.today_forcasted_increase_percent?.value !==
                          undefined
                          ? powerarray?.today_forcasted_increase_percent?.value.toFixed(
                              2
                            )
                          : "--"
                      }
                      ACEnergy={
                        powerarray.today_forcasted_energy_kWh !== null &&
                        powerarray.today_forcasted_energy_kWh !== undefined
                          ? powerarray.today_forcasted_energy_kWh.toFixed(2)
                          : "--"
                      }
                      DCEnergy="true"
                      ghiValue={
                        ghiOverview.today_forcasted_ghi_irradiance_kWh_m2 !==
                          null &&
                        ghiOverview.today_forcasted_ghi_irradiance_kWh_m2 !==
                          undefined
                          ? ghiOverview.today_forcasted_ghi_irradiance_kWh_m2.toFixed(
                              2
                            )
                          : "--"
                      }
                      ghiPercent={
                        ghiOverview?.today_forcasted_increase_percent
                          ?.percentage !== null &&
                        ghiOverview?.today_forcasted_increase_percent
                          ?.percentage !== undefined
                          ? ghiOverview.today_forcasted_increase_percent.percentage.toFixed(
                              2
                            )
                          : "--"
                      }
                      ghiVal={
                        ghiOverview?.today_forcasted_increase_percent?.value !==
                          null &&
                        ghiOverview?.today_forcasted_increase_percent?.value !==
                          undefined
                          ? ghiOverview?.today_forcasted_increase_percent?.value.toFixed(
                              2
                            )
                          : "--"
                      }
                      unit="kWh"
                      title="Today’s Forecast"
                      colorCode="#00529A"
                    />
                  </Paper>
                </Grid>
               {!isMobile && <Divider className="divider_14rem" orientation= {"vertical"} />
               }
                <Grid
                  item
                  lg={2.8}
                  xs={5.8}
                  sm={5.8}
                  md={5.5}
                  className="center_center_flex"
                >
                  <Paper className="column_gap_transparent">
                    <EnergyData
                      ACPercent={
                        powerarray?.today_increase_percent?.percentage !==
                          null &&
                        powerarray?.today_increase_percent?.percentage !==
                          undefined
                          ? powerarray.today_increase_percent.percentage.toFixed(
                              2
                            )
                          : "--"
                      }
                      ACValue={
                        powerarray?.today_increase_percent?.value !== null &&
                        powerarray?.today_increase_percent?.value !== undefined
                          ? powerarray?.today_increase_percent?.value.toFixed(2)
                          : "--"
                      }
                      ACEnergy={
                        powerarray.today_generated_energy_kWh !== null &&
                        powerarray.today_generated_energy_kWh !== undefined
                          ? powerarray.today_generated_energy_kWh.toFixed(2)
                          : "--"
                      }
                      DCEnergy="true"
                      ghiValue={
                        ghiOverview.today_ghi_irradiance_kWh_m2 !== null &&
                        ghiOverview.today_ghi_irradiance_kWh_m2 !== undefined
                          ? ghiOverview.today_ghi_irradiance_kWh_m2.toFixed(2)
                          : "--"
                      }
                      ghiPercent={
                        ghiOverview?.today_increase_percent?.percentage !==
                          null &&
                        ghiOverview?.today_increase_percent?.percentage !==
                          undefined
                          ? ghiOverview.today_increase_percent.percentage.toFixed(
                              2
                            )
                          : "--"
                      }
                      ghiVal={
                        ghiOverview?.today_increase_percent?.value !== null &&
                        ghiOverview?.today_increase_percent?.value !== undefined
                          ? ghiOverview?.today_increase_percent?.value.toFixed(
                              2
                            )
                          : "--"
                      }
                      unit="kWh"
                      title="Today’s Generation"
                      colorCode="#FDA737"

                      // colorCode="#00529A"
                    />
                  </Paper>
                </Grid>
                {!isMobile && <Divider className="divider_14rem" orientation= {"vertical"} />
               }
                <Grid
                  item
                  lg={2.8}
                  xs={5.8}
                  sm={5.8}
                  md={5.5}
                  className="center_center_flex"
                >
                  <Paper className="column_gap_transparent">
                    <EnergyData
                      ACPercent={
                        powerarray?.yesterday_increase_percent?.percentage !==
                          null &&
                        powerarray?.yesterday_increase_percent?.percentage !==
                          undefined
                          ? powerarray?.yesterday_increase_percent?.percentage.toFixed(
                              2
                            )
                          : "--"
                      }
                      ACValue={
                        powerarray?.yesterday_increase_percent?.value !==
                          null &&
                        powerarray?.yesterday_increase_percent?.value !==
                          undefined
                          ? powerarray?.yesterday_increase_percent?.value.toFixed(
                              2
                            )
                          : "--"
                      }
                      ACEnergy={
                        powerarray.yesterdays_generated_energy_kWh !== null &&
                        powerarray.yesterdays_generated_energy_kWh !== undefined
                          ? powerarray.yesterdays_generated_energy_kWh.toFixed(
                              2
                            )
                          : "--"
                      }
                      DCEnergy="true"
                      ghiValue={
                        ghiOverview.yesterday_ghi_irradiance_kWh_m2 !== null &&
                        ghiOverview.yesterday_ghi_irradiance_kWh_m2 !==
                          undefined
                          ? ghiOverview.yesterday_ghi_irradiance_kWh_m2.toFixed(
                              2
                            )
                          : "--"
                      }
                      ghiPercent={
                        ghiOverview?.yesterday_increase_percent?.percentage !==
                          null &&
                        ghiOverview?.yesterday_increase_percent?.percentage !==
                          undefined
                          ? ghiOverview.yesterday_increase_percent.percentage.toFixed(
                              2
                            )
                          : "--"
                      }
                      ghiVal={
                        ghiOverview?.yesterday_increase_percent?.value !==
                          null &&
                        ghiOverview?.yesterday_increase_percent?.value !==
                          undefined
                          ? ghiOverview?.yesterday_increase_percent?.value.toFixed(
                              2
                            )
                          : "--"
                      }
                      unit="kWh"
                      title="Yesterday’s Generation"
                      colorCode="#04724D"

                      // colorCode="#FDA737"
                    />
                  </Paper>
                </Grid>
                {!isMobile && <Divider className="divider_14rem" orientation= {"vertical"} />
               }
                <Grid
                  item
                  lg={2.8}
                  xs={5.8}
                  sm={5.8}
                  md={5.5}
                  className="center_center_flex"
                >
                  <Paper className="column_gap_transparent">
                    <EnergyData
                      ACPercent={
                        powerarray?.monthly_increase_percent?.percentage !==
                          null &&
                        powerarray?.monthly_increase_percent?.percentage !==
                          undefined
                          ? powerarray?.monthly_increase_percent?.percentage.toFixed(
                              2
                            )
                          : "--"
                      }
                      ACValue={
                        powerarray?.monthly_increase_percent?.value !== null &&
                        powerarray?.monthly_increase_percent?.value !==
                          undefined
                          ? powerarray?.monthly_increase_percent?.value.toFixed(
                              2
                            )
                          : "--"
                      }
                      ACEnergy={
                        powerarray.past_30days_generated_energy_kWh
                          ? powerarray.past_30days_generated_energy_kWh.toFixed(
                              2
                            )
                          : "--"
                      }
                      ghiValue={
                        ghiOverview.past_30days_ghi_irradiance_kWh_m2 !==
                          null &&
                        ghiOverview.past_30days_ghi_irradiance_kWh_m2 !==
                          undefined
                          ? ghiOverview.past_30days_ghi_irradiance_kWh_m2.toFixed(
                              2
                            )
                          : "--"
                      }
                      ghiPercent={
                        ghiOverview?.monthly_increase_percent?.percentage !==
                          null &&
                        ghiOverview?.monthly_increase_percent?.percentage !==
                          undefined
                          ? ghiOverview.monthly_increase_percent.percentage.toFixed(
                              2
                            )
                          : "--"
                      }
                      ghiVal={
                        ghiOverview?.monthly_increase_percent?.value !== null &&
                        ghiOverview?.monthly_increase_percent?.value !==
                          undefined
                          ? ghiOverview?.monthly_increase_percent?.value.toFixed(
                              2
                            )
                          : "--"
                      }
                      DCEnergy="true"
                      unit="kWh"
                      title="Monthly Generation"
                      colorCode="yellowgreen"
                    />
                  </Paper>
                </Grid>
                {loadingState.powerinfo && (
                  <SimpleBackdrop open={loadingState.powerinfo} />
                )}
              </Grid>
              </Paper>
            </Grid> */}

            {/* <Grid
              item
              order={{ xs: 4 }}
              lg={4.9}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <Paper className="energy-paper" id="donut_paper">
                <div className="space-betwn_div">
                <p className="Poppins_18px_rem">Energy Generated</p>
                <div style={{ width: "auto", minWidth: 160 }}>
              <Dropdown
                className="recommendations-text"
                id="navy_blue_text"
                options={generationType}
                onChange={handleChangeGen}
                value={isGenType}
                placeholder={generationType[0]}
                placeholderClassName="custom-placeholder"
              />
            </div>

                </div>
                <div className="space-betwn_div">
                  <p className="Poppins_16px_rem" style={{fontWeight:"500"}}>AC Energy</p>
                  <div className="val_arrow">
                    <p className="Poppins_18px_rem">19693.85 kWh</p>
                   <div className="green-tab"> 
                    <DynamicArrow pathData={downwardPath} fillColor={redFill} width={12} height={12} />
                     <p className="Poppins_18px_rem" style={{color:redFill}}>34.19 %</p>
                     </div>
                  </div>
                </div>
                <div className="space-betwn_div">
                  <p className="Poppins_16px_rem" style={{fontWeight:"500"}}>GHI</p>
                  <div className="val_arrow">
                    <p className="Poppins_18px_rem">4.13 kWh/m²</p>
                   <div className="green-tab"> 
                    <DynamicArrow pathData={downwardPath} fillColor={redFill} width={12} height={12} />
                     <p className="Poppins_18px_rem" style={{color:redFill}}>34.19 %</p>
                     </div>
                  </div>
                </div>
<p className="Poppins_14px_rem" style={{color:"#5E6064", fontWeight:"500",fontStyle:"italic", textAlign:"center" }}>Vs <span style={{fontWeight:"700", color:"black"}}>forecasted values</span> as of last updated timestamp</p>
              </Paper>

            </Grid> */}
            <Grid
              item
              order={{ xs: 4 }}
              lg={4.9}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <EnergyGenerated isValueData={plantEnergy} />

              {loadingState.plantEnergyOne && (
                <SimpleBackdrop open={loadingState.plantEnergyOne} />
              )}
            </Grid>

            <Grid
              item
              order={{ xs: 4 }}
              lg={7}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <Paper className="energy-paper" id="donut_paper">
                <div className="space-betwn_div">
                  <p className="Poppins_18px_rem">Daily Energy Loss Analysis</p>
                
                </div>
              </Paper>
              <Paper className="energy-paper center_div_nogap">
                <LossesDonutChart
                  isValueData={plantEnergy?.energy_loss}
                />
              </Paper>

              {loadingState.plantEnergyOne && (
                <SimpleBackdrop open={loadingState.plantEnergyOne} />
              )}
            </Grid>

            <Grid
              item
              order={{ xs: 4 }}
              lg={4}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <Paper className="energy-paper" id="donut_paper">
                <p className="Poppins_18px_rem">Generated Power</p>
              </Paper>
              <Paper className="energy-paper center_div_nogap">
                <CustomCircularBar
                  centerText={totalPowerGen ? totalPowerGen : 0}
                  values={newInvArray
                    .filter((item) => item.device_details !== null)
                    .map((device, index) => {
                      const outputPower = device.device_data !== null ? device.device_data?.output_power_kW : 0;
                
                      // if (demoPlant && outputPower === 0) {
                      //   // Assign specific values based on inverter index
                      //   switch (index) {
                      //     case 0:
                      //       return 650;
                      //     case 1:
                      //       return 677;
                      //     case 2:
                      //       return 689;
                      //     case 3:
                      //       return null;
                      //     default:
                      //       return 0;
                      //   }
                      // }
                
                      // Return the actual `output_power_kW` if it's not `0`
                      return outputPower || 0;
                    })}
                    
                  nameData={inverterNames}
                  unit="kW"
                />
              </Paper>

              {loadingState.invList && (
                <SimpleBackdrop open={loadingState.invList} />
              )}
            </Grid>

            {PowerPlant.map((ele, index) => (
              <Grid
                key={index}
                item
                order={{
                  xs: index === 0 ? 10 : index === 1 ? 11 : index === 2 ? 5 : 7,
                  lg: index === 0 ? 5 : index === 1 ? 6 : index === 2 ? 7 : 7
                }}
                lg={3.9}
                xs={12}
                sm={12}
                md={5.9}
                sx={{
                  justifyContent: "space-between",
                }}
                className="power-plant-grid-exc"
              >
                <Paper className="power-plant-paper-exc">
                  <div className="center_div">
                    <div className="icon-exc-div">
                      <img
                        src={ele.PlantIcon}
                        alt="plant"
                        style={{ width: "2rem" }}
                      />
                    </div>
                    <div className="flex-start-column">
                      <p className="Poppins_18px_rem">{ele.title}</p>
                    </div>
                  </div>
                  {/* <div onClick={(event) => handlePopoverOpen(index, event)}>
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/ep_info-filled.svg"
                      alt="plant"
                      style={{ width: "1rem", cursor: "pointer" }}
                    />
                  </div> */}
                </Paper>
                <Paper
                  className="powerplant-sec-paper"
                  sx={{
                    flexDirection:
                      index === 1 || index === 0 ? "column" : "row",
                  }}
                >
                  {index === 0 && (
                    <>
                      <CustomTooltip
                        title={
                          <React.Fragment>
                            <div className="center_div">
                              <p
                                className="raleway_14px_rem"
                                style={{ color: "#2F67F8" }}
                              >
                                {ele.subtitle1}:
                              </p>
                              <p className="Poppins_18px_rem">
                                {ele.value1 ? ele.value1 : "--"}
                              </p>
                            </div>
                            <div className="center_div">
                              <p
                                className="raleway_14px_rem"
                                style={{ color: "#2F67F8" }}
                              >
                                {ele.subtitle2}:
                              </p>

                              <p className="Poppins_18px_rem">
                                {ele.value2 ? ele.value2 : "--"}
                              </p>
                            </div>
                          </React.Fragment>
                        }
                        arrow
                      >
                        <div style={{ cursor: "pointer" }} id="circularBar">
                          <CircularProgressbarWithChildren
                            strokeWidth={15}
                            value={
                              isAbtData.length > 0 &&
                              presentPower != null
                                ? (presentPower /
                                  plantListsArray[0].plant_specific_details[
                                  "Plant AC Capacity kW"
                                  ]) *
                                100
                                : 0
                            }
                          >
                            <div className="smb_value_container center_div_nogap">
                              <p className="Poppins_12px_rem">Efficiency</p>
                              <p className="Poppins_16px_rem">
                                {isAbtData.length > 0 &&
                                  presentPower != null
                                  ? `${(
                                    (presentPower.toFixed(0) /
                                      plantListsArray[0]
                                        .plant_specific_details[
                                      "Plant AC Capacity kW"
                                      ]) *
                                    100
                                  ).toFixed(2)} %`
                                  : "0 %"}
                              </p>
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                      </CustomTooltip>
                      <div className="center_div">
                        <p className="Poppins_16px_400">
                          <span style={{ fontWeight: "600" }}>AC Power: </span>{" "}
                          {parseFloat(ele.value2)}/{parseFloat(ele.value1)}kW
                        </p>
                      </div>
                    </>
                  )}
                  {index === 1 && (
                    <>
                      <CustomTooltip
                        title={
                          <React.Fragment>
                            <div className="center_div">
                              <p
                                className="raleway_14px_rem"
                                style={{ color: "#2F67F8" }}
                              >
                                {ele.subtitle1}:
                              </p>
                              <p className="Poppins_18px_rem">
                                {ele.value1 ? ele.value1 : "--"}
                              </p>
                            </div>
                            <div className="center_div">
                              <p
                                className="raleway_14px_rem"
                                style={{ color: "#2F67F8" }}
                              >
                                {ele.subtitle2}:
                              </p>

                              <p className="Poppins_18px_rem">
                                {ele.value2 ? ele.value2 : "--"}
                              </p>
                            </div>
                          </React.Fragment>
                        }
                        arrow
                      >
                        <div style={{ cursor: "pointer" }} id="circularBar">
                          <CircularProgressbarWithChildren
                            strokeWidth={15}
                            value={
                              totalPowerGen !== 0 &&
                                Array.isArray(plantListsArray) &&
                                plantListsArray.length > 0 &&
                                plantListsArray[0]?.plant_specific_details?.[
                                "Plant DC Capacity kWp"
                                ] != null
                                ? (totalPowerGen /
                                  plantListsArray[0].plant_specific_details[
                                  "Plant DC Capacity kWp"
                                  ]) *
                                100
                                : 0
                            }
                          >
                            <div className="smb_value_container center_div_nogap">
                              <p className="Poppins_12px_rem">Efficiency</p>
                              <p className="Poppins_16px_rem">
                                {totalPowerGen !== 0 &&
                                  Array.isArray(plantListsArray) &&
                                  plantListsArray.length > 0 &&
                                  plantListsArray[0]?.plant_specific_details?.[
                                  "Plant DC Capacity kWp"
                                  ] != null
                                  ? `${(
                                    (totalPowerGen /
                                      plantListsArray[0]
                                        .plant_specific_details[
                                      "Plant DC Capacity kWp"
                                      ]) *
                                    100
                                  ).toFixed(2)} %`
                                  : "0 %"}
                              </p>
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                      </CustomTooltip>
                      <div className="center_div">
                        <p className="Poppins_16px_400">
                          <span style={{ fontWeight: "600" }}>DC Power: </span>
                          {parseFloat(ele.value2)}/{parseFloat(ele.value1)}kW
                        </p>
                      </div>
                    </>
                  )}

                </Paper>
                <Popover
                  key={index}
                  open={Boolean(anchorEl[index])}
                  anchorEl={anchorEl[index]}
                  onClose={() => handlePopoverClose(index)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{ className: "customPopoverPaper-exc" }}
                >
                  <div className="center_div">
                    <p className="raleway_14px_rem">{ele.subtitle1}</p>:
                    <p className="Poppins_18px_rem">
                      {ele.value1 ? ele.value1 : "--"}
                    </p>
                  </div>
                  <div className="center_div">
                    <p className="raleway_14px_rem">{ele.subtitle2}</p>:
                    <p className="Poppins_18px_rem">
                      {ele.value2 ? ele.value2 : "--"}
                    </p>
                  </div>
                </Popover>
                {loadingState.plantData && (
                  <SimpleBackdrop open={loadingState.plantData} />
                )}
              </Grid>
            ))}

            <Grid
              item
              order={{ xs: 14, lg: 9 }}
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph1 ? "fixed" : "relative",
                top: fullScreenStates.graph1 ? "9vh" : "auto",
                width: fullScreenStates.graph1 ? "-webkit-fill-available !important" : "auto",
                height: fullScreenStates.graph1 ? "92vh" : "auto",
                margin: fullScreenStates.graph1 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph1 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}>
                <BarPlotForIOT
                  isValueData={pastGenData}
                  StartTimer={setPastGenStartTime}
                  StopTimer={setPastGenStopTime}
                  openDrawerValue={setDrawerOpen}
                  titleText="Exported Energy"
                  tooltipName="Exported Energy"
                  indicateUnit="kWh"
                  selectedDateRangeProp={[
                    {
                      startDate: pastGenStartTime
                        ? pastGenStartTime
                        : new Date(
                          new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                        ),
                      endDate: pastGenStopTime ? pastGenStopTime : new Date(),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isFullScreen={fullScreenStates.graph1}
                  toggleFullScreen={() => toggleFullScreen('graph1')}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                />
              </Paper>
              {loadingState.pastData && (
                <SimpleBackdrop open={loadingState.pastData} />
              )}
            </Grid>
            <Grid
              className="total_power_container"
              item
              order={{ xs: 12, lg: 10 }}
              lg={12}
              xs={12}
              md={12}
              sx={{
                position: fullScreenStates.graph3 ? "fixed" : "relative",
                top: fullScreenStates.graph3 ? "9vh" : "auto",
                width: fullScreenStates.graph3 ? "-webkit-fill-available !important" : "auto",
                height: fullScreenStates.graph3 ? "92vh" : "auto",
                margin: fullScreenStates.graph3 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph3 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper className="forecasted_paper" sx={{ height: fullScreenStates.graph3 ? "100%" : "auto" }}>
                <UnixDateChart
                  calenderClick="on"
                  isValueData={predictedData}
                  openDrawerValue={setDrawerOpen}
                  StartTimer={setIsStartTimePrediction}
                  StopTimer={setIsStopTimePrediction}
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimePrediction
                        ? isStartTimePrediction
                        : new Date(),
                      endDate: isStopTimePrediction
                        ? isStopTimePrediction
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isMobileAndTab={isMobileAndTab}
                  isFullScreen={fullScreenStates.graph3}
                  toggleFullScreen={() => toggleFullScreen('graph3')}
                  isDesktopView={isDesktopView}
                />
              </Paper>
              {loadingState.forecast && (
                <SimpleBackdrop open={loadingState.forecast} />
              )}
            </Grid>

            {/* <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="notification-grid"
            >
              <Paper className="notification-paper-one">
                <div style={{ padding: "1rem" }}>
                  <p className="Poppins_24px_black" >
                    Important Notifications
                  </p>
                </div>
                <Divider
                  sx={{ mt: 1 }}
                  style={{ border: "0.0625rem solid #D9D9D9" }}
                />
              </Paper>
              {NotificationData.map((ele, index) => (
              <Paper
                className="notification-paper"
                // key={index}
                sx={
                  {
                    // borderBottom:
                    //   index === NotificationData.length - 1
                    //     ? "none"
                    //     : "0.125rem solid #D9D9D9",
                  }
                }
              >
                <p className="Poppins_18px_rem">No Notification yet</p>
                <div
                    style={{
                      padding: "1rem 1.5rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <WarningRoundedIcon style={{ color: "#FFA287" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <p className="Poppins_18px_rem">
                        {ele.title}
                        <span style={{ fontWeight: "400" }}>
                          &nbsp;{ele.disc}
                        </span>
                      </p>
                    </div>
                  </div>
              </Paper>
               ))} 
            </Grid> */}
            <Grid item order={{ xs: 3, lg: 11 }} lg={12} xs={12} sm={12} md={12} className="energy-grid">
              <Grid container className="inv-count-exc">
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  className="notification-paper-one"
                >
                  <Paper className="notification-paper-one ">
                    <p className="Poppins_24px_black">Components Stats</p>
                  </Paper>
                </Grid>

                {invStatus.map((ele, index) => (
                  <Grid
                    key={index}
                    item
                    lg={3.8}
                    xs={12}
                    sm={3.5}
                    md={3.8}
                    className="component-grid"
                  >
                    <Paper
                      onClick={() => handleComponents(index)}
                      className="component-paper"
                    >
                      <div>
                        <img
                          src={ele.iconInv}
                          alt="invStatus"
                          style={{ width: "3.125rem", height: "3.125rem" }}
                        />
                      </div>
                      <div className="flex-start-column-two">
                        <p className="raleway_14px_blue">{ele.title}</p>
                        <p
                          className="raleway_32px_rem"
                          style={{ fontFamily: "Poppins" }}
                        >
                          {ele.value}/
                          <span className="Poppins_24px_black">
                            {ele.total}
                          </span>
                        </p>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              order={{ xs: 15, lg: 12 }}
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="exc-graph-grid"
              sx={{ position: "relative" }}
            >
              <Paper className="plant-table_paper">
                <div className="space-btn-center flex_colmn">
                  <p className="Poppins_24px_black">Plant Table</p>
                  <div className="flex-start_gap">
                    <div
                      style={{
                        width: "auto",
                        minWidth: "1.6vw",
                        cursor: "pointer",
                      }}
                      className="min_width_11rem"
                      id="exc-linegraph"
                    >
                      <Dropdown
                        className="recommendations-text"
                        options={deviceFieldValue}
                        onChange={handleChangeDeviceFields}
                        value={isDeviceFields}
                      />
                    </div>
                    <div
                      style={{
                        width: "auto",
                        minWidth: "1.6vw",
                        cursor: "pointer",
                      }}
                      className="min_width_4rem"
                      id="exc-linegraph"
                    >
                      <Dropdown
                        className="recommendations-text"
                        options={deviceDurationValue.map((field) =>
                          field
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase())
                        )}
                        onChange={handleChangeDeviceDuration}
                        value={isDeviceDurations
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                        placeholder="Select an option"
                      />
                    </div>
                    <Tooltip title="Calendar" arrow>
                      <CalendarMonthRoundedIcon
                        onClick={handleTimings}
                        className="icon-css"
                      />
                    </Tooltip>
                  </div>
                </div>
                <TableNoCheckBox
                  columns={columns}
                  data={columns}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  order={order}
                  orderBy={orderBy}
                  handlePageChange={handleChangePage}
                  handleRowsPerPageChange={handleChangeRowsPerPage}
                  handleRowClick=""
                  searchQuery={searchQuery}
                  handleSearchChange={handleSearchChange}
                  serchText="Search Date"
                  titleText=""
                  plantTable={true}
                />
                {openDrawerCal && (
                  <ModalCalender
                    openDrawer={openDrawerCal}
                    setOpenDrawer={setOpenDrawerCal}
                    handleStopDateChange={setIsStopTime}
                    handleStartDateChange={setIsStartTime}
                    singledaySelect={false}
                    selectedDateRangeVal={[
                      {
                        startDate: isStartTime ? isStartTime : new Date(),
                        endDate: isStopTime ? isStopTime : new Date(),
                        key: "selection",
                      },
                    ]}
                  />
                )}
              </Paper>
              {loadingState.plantTable && (
                <SimpleBackdrop open={loadingState.plantTable} />
              )}
            </Grid>
            <Grid
              item
              order={{ xs: 13, lg: 13 }}
              lg={12}
              xs={12}
              md={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph4 ? "fixed" : "relative",
                top: fullScreenStates.graph4 ? "9vh" : "auto",
                width: fullScreenStates.graph4 ? "-webkit-fill-available !important" : "auto",
                height: fullScreenStates.graph4 ? "92vh" : "auto",
                margin: fullScreenStates.graph4 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph4 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph4 ? "100%" : "auto" }}>
                <NewBarLineChart
                  isValueData={activePowerIrr}
                  StartTimer={setIsStartTimeActiveIrr}
                  StopTimer={setIsStopTimeActiveIrr}
                  openDrawerValue={setDrawerOpen}
                  indicateUnit=""
                  titleText="Inverters Vs Export Energy Vs Irradiance"
                  tooltipName=""
                  stringDurationFun={setStringDurationTwo}
                  isFilter={true}
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeActiveIrr
                        ? isStartTimeActiveIrr
                        : new Date(),
                      endDate: isStopTimeActiveIrr
                        ? isStopTimeActiveIrr
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                  separateYaxis={true}
                  isFullScreen={fullScreenStates.graph4}
                  toggleFullScreen={() => toggleFullScreen('graph4')}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                />
              </Paper>
              {loadingState.irrPOA && (
                <SimpleBackdrop open={loadingState.irrPOA} />
              )}
            </Grid>
            <Grid
              item
              order={{ xs: 6, lg: 14 }}
              lg={12}
              xs={12}
              sm={12}
              md={12}

              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph2 ? "fixed" : "relative",
                top: fullScreenStates.graph2 ? "9vh" : "auto",
                width: fullScreenStates.graph2 ? "-webkit-fill-available !important" : "auto",
                height: fullScreenStates.graph2 ? "92vh" : "auto",
                margin: fullScreenStates.graph2 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph2 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph2 ? "100%" : "auto" }}>
                <BarPlotForIOT
                  isValueData={performanceValue}
                  StartTimer={setPerfRatioStartTime}
                  StopTimer={setPerfRatioStopTime}
                  openDrawerValue={setDrawerOpen}
                  titleText="Performance Ratio (%)"
                  tooltipName="Performance"
                  indicateUnit="%"
                  selectedDateRangeProp={[
                    {
                      startDate: perfRatioStartTime
                        ? perfRatioStartTime
                        : new Date(
                          new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                        ),
                      endDate: perfRatioStopTime
                        ? perfRatioStopTime
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                  isFullScreen={fullScreenStates.graph2}
                  toggleFullScreen={() => toggleFullScreen('graph2')}
                />
              </Paper>
              {loadingState.perfRatio && (
                <SimpleBackdrop open={loadingState.perfRatio} />
              )}
            </Grid>

            <Grid
              item
              order={{ xs: 8, lg: 15 }}
              lg={fullScreenStates.graph5 ? 12 : 6}
              xs={12}
              sm={12}
              md={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph5 ? "fixed" : "relative",
                top: fullScreenStates.graph5 ? "9vh" : "auto",
                width: fullScreenStates.graph5 ? "-webkit-fill-available !important" : "auto",
                height: fullScreenStates.graph5 ? "92vh" : "auto",
                margin: fullScreenStates.graph5 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph5 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph5 ? "100%" : "auto" }}>
                <MarkerBarPlot
                  isValueData={CUF_DC_Value}
                  StartTimer={setIsStartTimeCUF_DC}
                  StopTimer={setIsStopTimeCUF_DC}
                  openDrawerValue={setDrawerOpen}
                  averageRatio={averageCUF_DC}
                  titleText="CUF-DC Capacity (%)"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeCUF_DC
                        ? isStartTimeCUF_DC
                        : new Date(
                          new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                        ),
                      endDate: isStopTimeCUF_DC ? isStopTimeCUF_DC : new Date(),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                  isFullScreen={fullScreenStates.graph5}
                  toggleFullScreen={() => toggleFullScreen('graph5')}
                />
              </Paper>
              {loadingState.cufDC && (
                <SimpleBackdrop open={loadingState.cufDC} />
              )}
            </Grid>
            <Grid
              item
              order={{ xs: 7, lg: 16 }}
              lg={fullScreenStates.graph6 ? 12 : 5.9}
              xs={12}
              sm={12}
              md={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph6 ? "fixed" : "relative",
                top: fullScreenStates.graph6 ? "9vh" : "auto",
                width: fullScreenStates.graph6 ? "-webkit-fill-available !important" : "auto",
                height: fullScreenStates.graph6 ? "92vh" : "auto",
                margin: fullScreenStates.graph6 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph6 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph6 ? "100%" : "auto" }}>
                <MarkerBarPlot
                  isValueData={CUF_AC_Value}
                  StartTimer={setIsStartTimeCUF_AC}
                  StopTimer={setIsStopTimeCUF_AC}
                  openDrawerValue={setDrawerOpen}
                  averageRatio={averageCUF_AC}
                  titleText="CUF-AC Capacity (%)"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeCUF_AC
                        ? isStartTimeCUF_AC
                        : new Date(
                          new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                        ),
                      endDate: isStopTimeCUF_AC ? isStopTimeCUF_AC : new Date(),
                      key: "selection",
                    },
                  ]}
                  isOnlyMobile={isOnlyMobile}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                  isFullScreen={fullScreenStates.graph6}
                  toggleFullScreen={() => toggleFullScreen('graph6')}
                />
              </Paper>
              {loadingState.cufAC && (
                <SimpleBackdrop open={loadingState.cufAC} />
              )}
            </Grid>
          </Grid>
        </Grid>

        {!isMobile && <Grid
          item
          lg={2.3}
          xs={12}
          sm={12}
          md={2.8}
          // className="exc-chatbot-grid"
          className="weather_grid_weather"
        >
          <Paper className="notification-paper-one gap-grid">

            {!isMobile && <div className="exc-chatbot-div">
              <div className="exc-chatbot-div-sub" onClick={handleChatBot}>
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/chatbot-icon.svg"
                  alt="chatbot"
                  className="chatbot-icon"
                />{" "}
                <p
                  className="Poppins_12px_rem"
                  style={{ color: "#2F67F8", fontWeight: "600" }}
                >
                  CosmicGPT
                </p>
              </div>
              <div className="exc-chatbot-div-sub" onClick={handleVoiceOver}>
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/images/microphone-svgrepo-com.svg"
                  alt="chatbot"
                  id="chat-mic"
                  className="chatbot-icon"
                />{" "}
                <p
                  className="Poppins_12px_rem"
                  style={{ color: "#2F67F8", fontWeight: "600" }}
                >
                  VoiceOver
                </p>
              </div>
            </div>}
            <div>
              {!isMobile && <Button
                className="reportDownload"
                type="submit"
                variant="contained"
              >
                <div onClick={() => handleDailyCSVReport(id)}>
                  <p className="Poppins_16px_400" style={{ color: "white" }}>
                    Download Daily Report
                  </p>
                </div>
                <IconButton
                  edge="end"
                  style={{
                    backgroundColor: "transparent !important",
                  }}
                >
                  <CalendarMonthRoundedIcon
                    onClick={handleCalenderTimings}
                    sx={{ cursor: "pointer", color: "white" }}
                  />
                </IconButton>
              </Button>}
              {openCalender && (
                <ModalCalender
                  openDrawer={openCalender}
                  setOpenDrawer={setOpenCalender}
                  handleStopDateChange={setIsStopTimeCalender}
                  handleStartDateChange={setIsStartTimeCalender}
                  singledaySelect={false}
                  selectedDateRangeVal={[
                    {
                      startDate: isStartTimeCalender
                        ? isStartTimeCalender
                        : new Date(),
                      endDate: isStopTimeCalender
                        ? isStopTimeCalender
                        : new Date(),
                      key: "selection",
                    },
                  ]}
                />
              )}
              {loadingState.reportCSV && (
                <SimpleBackdrop open={loadingState.reportCSV} />
              )}
            </div>
            {!isMobile && <WeatherBoard
              weatherData={weatherInfo}
              panelWeatherData={panelWeatherInfo}
              weatherParam={weatherInfoData}
              weaHeight="72vh"
            />}
          </Paper>
        </Grid>}
      </Grid>
      {isMobile && (<>
        {showPopup && <div className="popup_text">
          <p className="Poppins_14px_rem" style={{ fontWeight: "400" }}>We’re Online !</p>
          <p className="Poppins_12px_rem">Hello, how may I assist you today?</p>
        </div>}
        <div className="mobile_chatbot" onClick={handleChatbotClick}>
          <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/chatbot_icon.svg" style={{ width: "4rem" }} />
        </div>
      </>)}

      {isMobile && showChatIcons && (
        <div className="icons_wrapper">
          <div className="show_chaticon" style={{ bottom: "1rem", right: "5rem" }} onClick={handleChatBot}>
            <img
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/chatbot-icon.svg"
              alt="chatbot"
              className="chatbot-icon"
            />{" "}
            <p
              className="Poppins_12px_rem"
              style={{ color: "#2F67F8", fontWeight: "600" }}
            >
              CosmicGPT
            </p>
          </div>
          <div className="show_chaticon" style={{ bottom: "5rem", right: "2rem" }} onClick={handleVoiceOver}>
            <img
              src="https://s3.amazonaws.com/smarttrak.co/images/microphone-svgrepo-com.svg"
              alt="chatbot"
              id="chat-mic"
              className="chatbot-icon"
            />{" "}
            <p
              className="Poppins_12px_rem"
              style={{ color: "#2F67F8", fontWeight: "600" }}
            >
              VoiceOver
            </p>
          </div>
        </div>)}
      {openDrawer && (
        <Grid container>
          <Grid item lg={10} xs={12} sm={12} md={11}>
            <Paper className="chatbot_Paper">
              <Chatbot handleClose={setOpenDrawer} historyList={historyList} />
            </Paper>
          </Grid>
        </Grid>
      )}
      {openVoiceChat && (
        <Grid container>
          <Grid item lg={10} xs={12} sm={12} md={11}>
            <Paper className="chatbot_Paper" id="chatbot_Paper_voice">
              <VoiceChat
                handleClose={setOpenVoiceChat}
                historyList={historyList}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default ExecutiveDashboard;
