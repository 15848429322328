import React from "react";
import { Container } from "@mui/material";

import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect } from "react";
import CustomText from "../../components/CustomText/CustomText";

  function LinearProgressBar(props) {
    const {progress,setProgress}=props;

  useEffect(() =>{
    const timer = setInterval(() => {
        setProgress((oldProgress) => {
            if (oldProgress === 100) {
                return 100;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff,
                100);
        });
    }, 500);

    return () => {
        clearInterval(timer);
    };
 
      
    }, []);
  
  return (
    <Container component="main" maxWidth="lg" style={{ paddingTop: "50px" }}>
      <div style={{textAlign:"center"}}>
        <img src="https://s3.amazonaws.com/smarttrak.co/images/predictData.png" alt="img" width={300} style={{padding:"40px"}}/>
      
      <CustomText 
          type="Regular_16px"
         text="Please Wait.... We are predicting the data"
         /></div>
      <Box sx={{ display: 'flex', alignItems: 'center',marginTop:"20px" }}>
       
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={progress}  sx={{height:"30px",borderRadius:"15px"}} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(progress
        )}%`}</Typography>
      </Box>
      </Box>
    </Container>
  );
}

export default LinearProgressBar;
