import React from "react";
import CustomText from "../../components/CustomText/CustomText";
import "./ProteinAnalysis.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CachedIcon from '@mui/icons-material/Cached';
import Divider from "@mui/material/Divider";
import Slider from '@mui/material/Slider';
import ScaterPlots from "../../../IOT/Screens/TimeCharts/ScaterPlots";
import HistogramChart from "./HistogramChart";

function AnalysisResult() {

  const marks = [
    { value: 0, label: "0" },
    { value: 0.2, label: "0.2" },
    { value: 0.4, label: "0.4" },
    { value: 0.6, label: "0.6" },
    { value: 0.8, label: "0.8" },
    { value: 1.0, label: "1.0" },
  ];

  const handleChange = (event, value) => {
    console.log(value); // Perform any necessary action with the selected value
  };

  return (
    <div className="analyse-container">
      <div className="analyse-subcontainer">
        <CustomText
          type="Bold_20px"
          text="Protein Anaysis"
          styles={{ color: "#1C629E" }}
        />
        <CustomText
          type="Bold_16px"
          text="Prediction Results"
          styles={{ color: "#1C629E" }}
        />
        <p
          style={{ color: "#1C629E", paddingBottom: "10px", fontWeight: "600" }}
        >
          File Name:- <span style={{ fontWeight: "400" }}>Ferritin.fasta</span>
        </p>
        <div className="fasta-container">
          <CustomText
            type="Bold_17px"
            text="Adjust the slider of the prediction probablity or add an ID or list of IDs to plot the results of interest"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "40px 20px",
            }}
          >
            <div>
              <CustomText
                type="Regular_15px"
                text="Adjust the prediction probability to adjust stringency"
              />
              <Box sx={{ width: 250 }}>
                <Slider
                  sx={{width:"300px"}}
                  defaultValue={0.3}
                  min={0}
                  max={1}
                  step={0.1}
                  marks={marks}
                  onChange={handleChange}
                  valueLabelDisplay="on"
                />
              </Box>
            </div>
            <CustomText
              type="Regular_25px"
              text="OR"
              styles={{ color: "#1C629E", margin: "0px 40px" }}
            />
            <div>
              <CustomText
                type="Regular_15px"
                text="Enter a list of IDs separated by a space for a targeted search"
              />
              <input
                type="text"
                className="input-field"
                placeholder="Example: ID1 ID2 ID3"
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#1C629E",
                width: "22%",
                height: "11%",
                textTransform: "none",
              }}
            >
              <CachedIcon style={{ color: "white", marginRight: "10px" }} />
              Predict Again
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#1C629E",
                width: "22%",
                marginLeft: "10px",
                textTransform: "none",
              }}
            >
              <img
                src="https://s3.amazonaws.com/smarttrak.co/images/system-uicons_download.png"
                alt="download"
                style={{ marginRight: "10px" }}
              />
              Download Predictions
            </Button>
          </div>
        </div>
        <div className="prediction-results">
          <CustomText
            type="Bold_18px"
            text="Prediction Results Tab"
            styles={{
              color: "#1C629E",
              padding: "20px",
              paddingBottom: "11px",
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ padding: "2px 20px" }}>
              <CustomText
                type="Bold_14px"
                text="Highest probable prediction for each input sequence"
              />
              <CustomText
                type="Regular_14px"
                text="Sequences longer than 1234 amino acids will be split into 1234 subsequences prior to predictions. 
            The downloaded results and the plots can thus show multiple predictions for the same protein."
                styles={{ marginTop: "5px" }}
              />
              <div style={{ backgroundColor: "white", margin: "20px" }}>
                <HistogramChart />
              </div>
            </div>
            <Divider
              orientation="vertical"
              sx={{ backgroundColor: "black", height: "auto" }}
            />

            <div style={{ padding: "2px 20px" }}>
              <CustomText
                type="Bold_14px"
                text="Number of predictions for each protein family"
              />
              <CustomText
                type="Regular_14px"
                text="Sequences longer than 1234 amino acids will be split into 1234 subsequences prior to predictions. 
            The downloaded results and the plots can thus show multiple predictions for the same protein."
                styles={{ marginTop: "5px" }}
              />
              <div style={{ backgroundColor: "white", margin: "20px" }}>
                <ScaterPlots />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalysisResult;
