import React, { useState, useEffect, useRef } from "react";
import "./PowerOptix.scss";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import "react-slideshow-image/dist/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useHistory } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function PowerOptix() {
  const features = [
    {
      featureImg:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/productOne.jpg",
      title: "Accurate Generation Forecasting",
      description:
        "Leverage the power of deep learning with Fourcastnet and Graphcast for precise energy generation forecasts. Optimize energy distribution and maximize efficiency.",
    },
    {
      featureImg:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/productTwo.jpg",
      title: "Fault Prediction",
      description:
        "Stay ahead of potential issues with our advanced machine learning algorithms. Detect anomalies early and schedule predictive maintenance effortlessly.",
    },
    {
      featureImg:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/slideThree.jpg",
      title: "Real-Time 3D Digital Twin",
      description:
        "Experience detailed, high fidelity 3D models of your entire solar farm. Interact with every component, from panels to inverters, in a visually stunning and immersive way.",
    },
    {
      featureImg:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/productFour.jpg",
      title: "Remote Management",
      description:
        "Utilise our virtual control room to remotely manage your solar farm. As demand and the weather change, instantly adjust panel angles and settings for best results.",
    },
    {
      featureImg:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/productFive.jpg",
      title: "Drone Integration",
      description:
        "Utilize autonomous drones for real time image capture and AI-powered analysis. Detect panel damage, soiling, and vegetation encroachment seamlessly.",
    },
  ];
  const reasons = [
    {
      title: "Proactive Fault",
      extra: "Detection",
      description:
        "Minimize downtime and maintenance costs with early fault identification.",
    },
    {
      title: "Real-Time Performance",
      extra: "Monitoring",

      description:
        "Ensure peak operation through continuous performance tracking.",
    },
    {
      title: "Data-Driven",
      extra: "Decisions",

      description:
        "Utilize advanced analytics for strategic operational and financial choices.",
    },
    {
      title: "Remote",
      extra: "Management",

      description:
        "Effortlessly control and manage your plant from anywhere with an intuitive interface.",
    },
    {
      title: "Precise Energy",
      extra: "Forecasting",

      description:
        "Achieve optimal efficiency and revenue with accurate energy generation predictions.",
    },
    {
      title: "In-Depth",
      extra: "Reporting",

      description:
        "Trust in accurate predictions and automated maintenance to keep your solar farm running smoothly.",
    },
  ];

  const history = useHistory();
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderRef = useRef(null);

  const isMobile = window.innerWidth <= 800;

  const solControls = useAnimation();
  const whatControls = useAnimation();
  const craftControls = useAnimation();
  const resControls = useAnimation();

  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [whatRef, inViewWhat] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [craftRef, inViewCraft] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });
  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);
  useEffect(() => {
    whatControls.start({
      opacity: inViewWhat ? 1 : 0,
      y: inViewWhat ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [whatControls, inViewWhat]);
  useEffect(() => {
    craftControls.start({
      opacity: inViewCraft ? 1 : 0,
      y: inViewCraft ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [craftControls, inViewCraft]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    centerMode: isMobile ? false : true,
    centerPadding: "0px",
    autoplaySpeed: 5000,
    autoplay: true,
    beforeChange: (_, next) => setCurrentSlide(next),
    afterChange: (current) => setCurrentSlide(current),
  };

  const handleSolution = (ind) => {
    sliderRef.current.slickGoTo(ind);

    setCurrentSlide(ind >= features.length ? 0 : ind);
  };

  const handleProductClick = (name) => {
    if (name === "Accurate Generation Forecasting") {
      history.push("/products/energy_forecasting");
    } else if (name === "Fault Prediction") {
      history.push("/products/fault_prediction");
    } else if (name === "Real-Time 3D Digital Twin") {
      history.push("/products/three_d_twin");
    } else if (name === "Remote Management") {
      history.push("/products/remote_management");
    } else if (name === "Drone Integration") {
      history.push("/products/drone_integration");
    }
  };

  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main text_cen"
        id="our_sol"
      >
        <p className="Raleway_64px" id="our_sol">
          Transform Your Solar Farm with PowerOptix
        </p>
        <p className="Raleway_31px_dark opacity_8 c_black ">
          Unlock the Future of Renewable Energy with AI-Driven Insights,
          Predictive Analytics, and Digital Twin Precision
        </p>

        <div className="product_slide">
          <img
            className="powerOptix_img"
            src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/powerOptix.png"
            alt="banner"
          />
        </div>
        <motion.div
          className="expert_div flex_col gap_1vw "
          ref={solRef}
          initial={{ opacity: 0, y: 50 }}
          animate={solControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark">
            Moving Forward with Managing Solar Farms
          </p>
          <p
            id="expertise-description"
            className="Raleway_26px_500 opacity_7 text_cen"
          >
            Transform the management of your solar farms with cutting-edge
            technology and AI-driven solutions. PowerOptix offers a
            comprehensive range of tools designed to enhance productivity,
            maximize performance, and ensure reliability. Discover our
            innovative products and see how we can revolutionize your solar
            energy business.
          </p>
        </motion.div>
        <motion.div
          className="expert_div"
          id="margin_tb"
          ref={craftRef}
          initial={{ opacity: 0, y: 50 }}
          animate={craftControls}
          transition={{ duration: 0.5 }}
        >
          <motion.img
            className="div_width"
            src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/powerOptixImg.svg"
            alt="banner"
            initial={{ opacity: 0, y: 50 }}
            animate={craftControls}
            transition={{ duration: 0.5 }}
          />
        </motion.div>
      </div>
      <div className="our_solution_main">
        <motion.div
          className="key_fea_po expert_div flex_col"
          ref={whatRef}
          initial={{ opacity: 0, y: 50 }}
          animate={whatControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark">Key Features</p>
          <Grid
            container
            className="home_grid_container gap_3vw power_slick"
            id="margin_tb"
          >
            <Slider {...settings} ref={sliderRef}>
              {features.map((ele, index) => (
                <Grid
                  key={index}
                  className={`power_slide ${
                    currentSlide === index ? "active" : "inactive"
                  }`}
                  onClick={() => {
                    handleSolution(index);
                  }}
                >
                  <Paper
                    className="power_paper_slide cursor_p expert_div flex_col"
                    onClick={
                      currentSlide === index
                        ? () => handleProductClick(ele.title)
                        : null
                    }
                  >
                    <div className="img_div_sol">
                      <img
                        src={ele.featureImg}
                        alt="ourproduct"
                        className="img_div_sol"
                      />
                    </div>
                    <div className="expert_div svg_powers">
                      <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                        <p
                          className={`${
                            currentSlide === index
                              ? "Raleway_31px_600 c_thirdBlack"
                              : "Raleway_28px c_thirdBlack"
                          }`}
                        >
                          {ele.title}
                        </p>
                        <p
                          className={`${
                            currentSlide === index
                              ? "Raleway_26px_500"
                              : "Raleway_23px_dark font_500"
                          } opacity_8`}
                        >
                          {ele.description}
                        </p>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              ))}
            </Slider>
          </Grid>
          <div className="dots_container expert_div">
            {features.map((folder, index) => (
              <span
                key={index}
                className={`dot ${currentSlide === index ? "active" : ""}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSolution(index);
                }}
              ></span>
            ))}
          </div>
        </motion.div>
      </div>

      <motion.div
        className="expert_div flex_col gap_1vw padding_s"
        id="margin_tb"
        ref={resRef}
        initial={{ opacity: 0, y: 20 }}
        animate={resControls}
        transition={{ duration: 0.5 }}
      >
        <p className="Raleway_48px_600 c_dark">Reasons To Choose PowerOptix</p>
        <Grid
          container
          className="home_grid_container  power_grid gap_2vw"
          id="margin_tb"
        >
          {reasons.map((ele, index) => (
            <Grid
              key={index}
              item
              lg={2.3}
              xs={12}
              sm={5.5}
              md={2.3}
              className="abour_mission_grid"
            >
              <Paper
                className="about_mission cursor_p power_subG"
                id="valData"
                style={{ flex: 1 }}
              >
                <div className="expert_div flex_col gap_1vw text_cen padd_1vw">
                  <div className="expert_div flex_col">
                    <p className="Raleway_23px_dark">{ele.title}</p>
                    <p className="Raleway_23px_dark">{ele.extra}</p>
                  </div>
                  <p className="Raleway_18px_500 opacity_8">
                    {ele.description}
                  </p>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </motion.div>

      <Footer />
    </div>
  );
}

export default PowerOptix;
