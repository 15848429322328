import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = 'https://smarttrakportal.in/api';

const apiHelper = new ApiHelper();

export class ChatPdfService {
    uploadPdf(data){
        const uri = `${BASE_URL}/chat_pdf/upload_pdf`;
        return apiHelper.post(uri, data)
    }
    getPdfResponse(data_id, query){
        const uri = `${BASE_URL}/chat_pdf/chat_with_pdf/test?data_id=${data_id}&query=${query}`;
        return apiHelper.get(uri);
    }
}