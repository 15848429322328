import React, { useRef, useEffect, useState, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useAudioContext } from './AudioContext';


const WaveFormOne = ({ audioUrl, remoteurl }) => {
    const waveformRef = useRef(null);
    const waveSurfer = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [localAudioUrl, setLocalAudioUrl] = useState(null);
    const [error, setError] = useState(null);
    const { currentlyPlayingWaveSurfer, setCurrentlyPlaying } = useAudioContext();

    useEffect(() => {
        const fetchAudio = async () => {
            if (remoteurl) {
                try {
                    const response = await fetch(audioUrl);
                    if (!response.ok) {
                        throw new Error('Failed to fetch audio');
                    }
                    const blob = await response.blob();
                    const localUrl = URL.createObjectURL(blob);
                    setLocalAudioUrl(localUrl);
                } catch (error) {
                    console.error('Error fetching audio:', error);
                    setError('Failed to load audio.');
                }
            } else {
                setLocalAudioUrl(audioUrl);
            }
        };

        fetchAudio();
    }, [audioUrl, remoteurl]);

    useEffect(() => {
        if (!localAudioUrl) return;

        try {
            waveSurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: '#ddd',
                progressColor: '#00529A',
                cursorColor: '#00529A',
                barWidth: 3,
                barRadius: 3,
                cursorWidth: 1,
                height: 50, 

                normalize: true,
                responsive: true,
            });

            waveSurfer.current.load(localAudioUrl);

            waveSurfer.current.on('play', () => setIsPlaying(true));
            waveSurfer.current.on('pause', () => setIsPlaying(false));
            waveSurfer.current.on('finish', () => setIsPlaying(false));
            waveSurfer.current.on('error', (err) => {
                console.error('WaveSurfer error:', err);
                setError('An error occurred while processing the audio.');
            });

            return () => {
                if (waveSurfer.current) {
                    waveSurfer.current.un('play');
                    waveSurfer.current.un('pause');
                    waveSurfer.current.un('finish');
                    waveSurfer.current.destroy();
                }
            };
        } catch (error) {
            console.error('Error initializing WaveSurfer:', error);
            setError('An error occurred while initializing the waveform.');
        }
    }, [localAudioUrl]);

    const handlePlayPause = useCallback(() => {
        if (currentlyPlayingWaveSurfer && currentlyPlayingWaveSurfer !== waveSurfer.current) {
            currentlyPlayingWaveSurfer.pause();
        }

        if (isPlaying) {
            waveSurfer.current.pause();
        } else {
            waveSurfer.current.play();
            setCurrentlyPlaying(waveSurfer.current);
        }
    }, [isPlaying, currentlyPlayingWaveSurfer, setCurrentlyPlaying]);

    if (error) {
        return "";
    }

    return (
        <div className="space-btn-center" style={{gap:"0.625rem"}}>
            <div style={{ marginRight: '10px' }}>
                <button onClick={handlePlayPause} className="audioNew center_div_nogap">
                    {isPlaying ? <PauseIcon fontSize="medium" style={{color:"#00529A"}} /> : <PlayArrowIcon fontSize="medium"  style={{color:"#00529A"}} />}
                </button>
            </div>
            <div ref={waveformRef} style={{ flex: 1, height: '50px' }}></div>
        </div>
    );
};

export default WaveFormOne;
