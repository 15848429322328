import React from "react";
import "./NoPage.scss";
import CustomText from "../../components/CustomText/CustomText";
import BoldCommonText from "../../../IOT/Common/BoldCommonText";

const NoPage = (props) => {
  return (
    <div className="nopage-container">
      {" "}
      <CustomText
        type="Bold_48px"
        text="System Maintenance Underway"
        styles={{
          color: "#1C629E",
          fontFamily: "poppins",
          fontWeight: "600",
        }}
      />{" "}
      <img
        src="https://s3.amazonaws.com/smarttrak.co/images/questionMark.png"
        style={{ width: "32%" }}
        alt="404ErrorPage"
      />{" "}
      <BoldCommonText
        type="SemiBold_400"
        text="Page Not Found"
        styles={{ padding: "8px 123px 0px 123px", color: "gray" }}
      />
      <CustomText
        type="Regular_19px"
        text="We're working hard to improve your experience with our system. 
 Please be with us as we are making necessary updates"
        styles={{ padding: "8px 123px 0px 123px" }}
      />{" "}
    </div>
  );
};

export default NoPage;
