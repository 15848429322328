import React, { useEffect, useState } from "react";
import { Drawer, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import CircularProgressWithLabel from "../Common/CircularProgressWthLabel";
import CustomText from "../../components/CustomText/CustomText";
import CustomButton from "../../components/CustomButton/CustomButton";
import SimpleBackdrop from "../../components/common/SimpleBackdrop";
import { getCamelCaseLabel } from "../../utils/stringProcessor";
import { CreateAIService } from "../../services/CreateAIService";
import "./CreateAI.scss";
import { Stepper, Step, StepLabel } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ConfusionMatrix from "../../components/confusion-matrix/ConfusionMatrix";
import BarchartGraph from "../../components/Charts/BarchartGraph";
import BoxPlot from "../../components/Charts/BoxPlot";
import Linechart from "../../components/Charts/Linechart";
const createAIService = new CreateAIService();

const progress = [
  "Starting",
  "Downloading",
  "Training",
  "Uploading",
  "Completed",
];
const TrainModelDrawer = (props) => {
  const { open, onClose, modelDetail } = props;
  const hyperparameters =
    modelDetail?.modelConfigs?.train_details?.hyperparameters;

  const [parameters, setParameter] = useState([]);
  const [currentProgress, setCurrentProgress] = useState([]);
  const [trainingDetails, setTrainingDetails] = useState({
    jobId: "6440c7265a224eeb3ebaf703",
    trainingSuccess: true,
  });
  const [loading, setLoading] = useState(false);
  const [isDeployActive, setIsDeployActive] = useState(false);
  const [isTrainActive, setIsTrainActive] = useState(false);
  const [isEvaluateActive, setIsEvaluateActive] = useState(false);
  const [activeSteps, setActiveSteps] = useState(-1);
  const [isActive, setIsActive] = useState(false);
  const [lineGraphData, setLineGraphData] = useState({
    lineLossData: [],
    lineValData: [],
    showLineData: false,
  });
  const [ImageGraph, setImageGraph] = useState({
    geneData: "",
    showGene: false,
  });
  const [barGraphData, setBarGraphData] = useState({
    barDataValue: [],
    barLabels: [],
    showBarData: false,
  });
  const [confusionData, setConfusionData] = useState({
    matrix: [],
    matrixLabels: [],
    showConfusion: false,
  });
  const [evaluateData, setEvaluateData] = useState({
    accuracy: "0",
    loss: "0",
    showEvaluate: false,
  });
  const [boxPlotData, setBoxPlotData] = useState({
    boxPlotDataVal: [],
    showBoxPlot: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const paramArr = Object.entries(hyperparameters).map(
      ([key, val], index) => ({
        id: index,
        key: key,
        label: getCamelCaseLabel(key),
        val: val,
        isNum: typeof val === "number" ? true : false,
      })
    );
    setParameter(paramArr);
  }, [hyperparameters]);

  const handleParamChange = (id, val) => {
    setParameter((prevState) =>
      prevState.map((item) => (item.id === id ? { ...item, val: val } : item))
    );
  };

  const renderParameters = () => {
    let paramList = parameters.map((item) => {
      return (
        <Grid item md={3} className="parameter-container">
          <CustomText
            type="Regular_16px"
            text={item.label}
            styles={{ marginTop: 30 }}
          />
          <input
            className="parameter-input"
            value={item.val}
            type={item.isNum ? "number" : "text"}
            onChange={(e) => handleParamChange(item.id, e.target.value)}
          />
        </Grid>
      );
    });
    return paramList;
  };
  const handleStep = () => {
    const n = currentProgress[currentProgress.length - 1];
    switch (n) {
      case "Starting":
        setActiveSteps(0);
        setIsTrainActive(true);
        break;
      case "Downloading":
        setActiveSteps(1);
        break;
      case "Training":
        setActiveSteps(2);
        break;
      case "Uploading":
        setActiveSteps(3);
        break;
      case "Completed":
        setActiveSteps(4);
        setIsActive(true);
        setIsEvaluateActive(true);
        enqueueSnackbar("Model trained succesfully!", {
          variant: "success",
        });
        break;
      case "Failed":
        setIsTrainActive(false);
        enqueueSnackbar("Model training Failed!", { variant: "error" });
        break;
      default:
        console.log("training");
    }
  };
  const fetchStatus = async (job_id) => {
    await createAIService
      .getStatusData(job_id)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const status = res?.data?.status?.sec_status;
          const time = setTimeout(() => {
            fetchStatus(job_id);
          }, 10000);
          if (status === "Completed" || status === "Failed") {
            clearTimeout(time);
          }
          currentProgress.push(status);
          setCurrentProgress(currentProgress);

          handleStep();
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const handleTrainModel = async (event) => {
    event.preventDefault();

    const hyperparameterObj = {};
    parameters.forEach((item) => {
      // if (item.key !== "img_size") {
      hyperparameterObj[item.key] = item.val.toString();
      //  }
    });
    const reqData = {
      data_id: modelDetail?.dataId,
      model_id: modelDetail?.modelConfigs?.model_id,
      usecase_id: modelDetail?.usecaseId,
      train_details: {
        hyperparameters: hyperparameterObj,
      },
    };

    setLoading(true);
    try {
      const res = await createAIService.startTrainingJob(reqData);
      if (res.status === 200) {
        const jobId = res.data.job_id;
        if (jobId) {
          setTrainingDetails(() => ({
            jobId: jobId,
            trainingSuccess: true,
          }));
          enqueueSnackbar(`Model training started with job-id - ${jobId}`, {
            variant: "success",
          });
        }
        fetchStatus(jobId);
      }
      setLoading(false);
      setIsActive(false);
    } catch (err) {
      console.log("Error in handleTrainModel", err);
      enqueueSnackbar(
        err.response?.data?.detail
          ? err.response?.data?.detail
          : "Error while create model training job",
        { variant: "error" }
      );
      setLoading(false);
    }
  };

  const handleEvaluateModel = async () => {
    try {
      setLoading(true);
      const res = await createAIService.evaluateModel(trainingDetails.jobId);
      setLoading(false);
      if (res.status === 200) {
     setEvaluateData({
          accuracy: res.data.accuracy ? res.data.accuracy : 0,
          loss: res.data.loss ? res.data.loss : 0,
          showEvaluate: true,
        });
        setConfusionData({
          matrix: res.data.cm ? res.data.cm.data : [],
          matrixLabels: res.data.cm ? res.data.cm.labels : [],
          showConfusion: res.data.cm ? true : false,
        });
        setBarGraphData({
          barDataValue: res.data.bar_plot ? res.data.bar_plot.data : [],
          barLabels: res.data.bar_plot ? res.data.bar_plot.labels : [],
          showBarData: res.data.bar_plot ? true : false,
        });
        setImageGraph({
          geneData: res.data.genes_graph ? res.data.genes_graph : " ",
          showGene: res.data.genes_graph ? true : false,
        });
        setBoxPlotData({
          boxPlotDataVal: res.data.box_plot ? res.data.box_plot?.data : [],
          showBoxPlot: res.data.box_plot ? true : false,
        });
        setLineGraphData({
          lineLossData: res.data.loss_data ? res.data.loss_data : [],
          lineValData: res.data.val_loss ? res.data.val_loss : [],
          showLineData: res.data.loss_data ? true : false,
        });
      }
    } catch (err) {
      console.log("Error in handleEvaluateModel", err);
      enqueueSnackbar(
        err.response?.data?.detail
          ? err.response?.data?.detail
          : "Error while evalating model",
        { variant: "error" }
      );
      setLoading(false);
    }
    setIsDeployActive(true);
  };

  const handleDeployModel = async () => {
    setIsEvaluateActive(true);
    if (trainingDetails.trainingSuccess) {
      try {
        setLoading(true);
        setIsActive(false);
        const reqData = { job_id: trainingDetails.jobId };

        const res = await createAIService.deployModel(reqData);
        if (res.status === 200) {
          enqueueSnackbar(
            `Model deployed successfully with job-id - ${trainingDetails.jobId}`,
            { variant: "success" }
          );
        }
        setIsActive(false);
        setLoading(false);
      } catch (err) {
        console.log("Error in handleDeployModel", err);
        enqueueSnackbar("Error while deploying model", { variant: "error" });
        setLoading(false);
      }
    } else {
      enqueueSnackbar("Please train the model before deployment", {
        variant: "warning",
      });
    }
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ "& .MuiDrawer-paper": { boxSizing: "border-box", width: "75%" } }}
    >
      <div className="train-model-drawer-container">
        {loading && <SimpleBackdrop open={loading} />}
        <CustomText
          type="Regular_15px"
          text={modelDetail?.modelConfigs?.title}
          styles={{}}
        />

        <CloseIcon onClick={onClose} className="close-btn2" />
        <CustomText
          type="SemiBold_23px"
          text={modelDetail?.architectureName}
          styles={{ color: "#414141", margin: "10px 0px 20px 0px" }}
        />
        <Stepper
          activeStep={activeSteps}
          alternativeLabel
          orientation="horizontal"
        >
          {progress.map((label, index) => (
            <Step
              key={index}
              sx={
                currentProgress[currentProgress.length - 1] !== "Failed"
                  ? {
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: "green",
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: "grey.400",
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: "green",
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: "green",
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "black",
                      },
                    }
                  : {
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: "red", // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: "red", // Just text label (COMPLETED)
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: "red", // circle color (ACTIVE)
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: "red", // Just text label (ACTIVE)
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "black", // circle's number (ACTIVE)
                      },
                    }
              }
            >
              <StepLabel> {label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className="config-parameter-container">
          <Grid container spacing={2}>
            {renderParameters()}
          </Grid>
        </div>
        <div className="train-ai-button-container">
          <CustomButton
            type="Simple"
            disabled={isTrainActive}
            text="Train AI"
            styles={{
              width: "10rem",
              backgroundColor: isTrainActive ? "#D9D9D9" : "#1C629E",
              color: isTrainActive ? "#000000" : "#FFFFFF",
              pointerEvents: isTrainActive ? "none" : "auto",
            }}
            onClick={handleTrainModel}
          />

          <CustomButton
            type="Simple"
            disabled={activeSteps !== 4}
            text="Evaluate"
            styles={{
              width: "10rem",
              backgroundColor: isEvaluateActive ? "#1C629E" : "#D9D9D9",
              color: isEvaluateActive ? "#000000" : "#FFFFFF",
              pointerEvents: isEvaluateActive ? "auto" : "none",
              marginLeft: 30,
            }}
            onClick={handleEvaluateModel}
          />
        </div>

        {evaluateData.showEvaluate && (
          <div className="evaluate-model-accuracy-container">
            <div className="left">
              <CustomText
                type="SemiBold_14px"
                text="Performance"
                styles={{ color: "#1C629E", margin: "0px 20px 0px 0px" }}
              />
              <CircularProgressWithLabel
                evalVal={evaluateData.accuracy * 100}
                lossVal={evaluateData.loss * 100}
              />
            </div>
            <div className="right">
              <CustomText
                type="Regular_13px"
                text="Your AI model performance"
                styles={{ color: "#1C629E" }}
              />
            </div>
          </div>
        )}

        <>
        <div className="graphs-container">
            {confusionData.showConfusion && (
              <div className="confusionGraph" style={{ width: "500px" }}>
                <CustomText
                  type="Regular_15px"
                  text={"Confusion Matrix"}
                  styles={{ textAlign: "center" }}
                />

                <ConfusionMatrix
                  data={confusionData.matrix}
                  labels={confusionData.matrixLabels}
                />
              </div>
            )}

            {barGraphData.showBarData && (
              <div className="confusionGraph" style={{ width: "500px" }}>
                <CustomText
                  type="Regular_15px"
                  text={"Bar Plot Graph"}
                  styles={{ textAlign: "center" }}
                />
                <BarchartGraph
                  data={barGraphData.barDataValue}
                  labels={barGraphData.barLabels}
                />
              </div>
            )}
          </div>
          {ImageGraph.showGene && (
            <div className="confusionGraph" style={{ width: "900px" }}>
              <CustomText
                type="Regular_15px"
                text={"Gene Graph"}
                styles={{ textAlign: "center" }}
              />
              <img
                width={850}
                height={350}
                src={`data:image/jpeg;base64,${ImageGraph.geneData}`}
                alt="base64 graph"
              />
            </div>
          )}
          {lineGraphData.showLineData && (
            <div className="confusionGraph" style={{ width: "750px" }}>
              <CustomText
                type="Regular_15px"
                text={"Line Graph"}
                styles={{ textAlign: "center" }}
              />
              <Linechart
                lossData={lineGraphData.lineLossData}
                valData={lineGraphData.lineValData}
              />
            </div>
          )}
          {boxPlotData.showBoxPlot && (
            <div className="confusionGraph" style={{ width: "750px" }}>
              <CustomText
                type="Regular_15px"
                text={"Box Plot Graph"}
                styles={{ textAlign: "center" }}
              />
              <BoxPlot plotData={boxPlotData.boxPlotDataVal} />
            </div>
          )}
        </>

        {isActive && (
          <div className="train-ai-button-container">
            <CustomButton
              type="Simple"
              disabled={isDeployActive}
              text="Deploy"
              styles={{
                width: "10rem",
                backgroundColor: isDeployActive ? "#1C629E" : "#D9D9D9",
                color: isDeployActive ? "#FFFFFF" : "#000000",
                marginLeft: 30,
                pointerEvents: isDeployActive ? "auto" : "none",
              }}
              onClick={handleDeployModel}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default TrainModelDrawer;
