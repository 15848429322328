import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import "./MFM.scss";
import "../../../Common/FontSizes.scss";
import { useHistory } from "react-router-dom";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import TableNoCheckBox from "../../../Common/TableNoCheckBox";
import useErrorHandler from "../../../../utils/errorHandler";
import ButtonSlider from "../../../Common/ButtonSlider";
const invService = new CosmicInverterService();
function MFM() {
  const [tableData, setTableData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0); 
  const itemsPerPage = 7; 
  const [mfmDataArray, setMfmDataArray] = useState([]);
  const [loadingState, setLoadingState] = useState({
    mfmList: false,
  });

  const handleFetchError = useErrorHandler();
  const columns = [
    { field: "name", title: "MFM" },
    { field: "export", title: "Export Energy (kWh)" },
    { field: "import", title: "Import Energy (kWh)" },
    { field: "status", title: "Status" },
    { field: "raised_at", title: "Last Updated Timestamp" },
  ];


  useEffect(() => {
    handleFieldsList();
  }, []);

  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];
  useEffect(() => {
    if (id) {
      handleMfmParameters(id);
      // handleMFMDeviceID(id);
    }
  }, []);
  const gridRef = useRef(null);

  const [deviceTableData, setDeviceTableData] = useState([]);
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gridHeight, setGridHeight] = useState(0);

  useEffect(() => {
    if (gridRef.current) {
      const height = gridRef.current.offsetHeight;
      setGridHeight(height);
    }
  }, [gridRef]);

  const history = useHistory();
  
  const startIndex = currentPage * itemsPerPage;
  let visibleMfms = mfmDataArray.slice(startIndex, startIndex + itemsPerPage);
   if (currentPage === 0) {
    visibleMfms = [
      {
        device_details:{name: "MFMs Dashboard"},
      },
      ...visibleMfms,
    ];
  }
  const handleMfmClick = (mfmDevice, index) => {
    if (index !== 0) {
    history.push({
      pathname: `/iot-home/dev-monitoring/mfm-data`,
      state: {
        mfmDeviceData: mfmDevice,
        mfmList: tableData,
        firstField: deviceFieldValue[0],
      },
    });
  }
  };
  const handleFieldsList = async () => {
    try {
      setLoading(true);

      const res = await invService.getListDeviceFields("mfm");
      if (res.status === 200) {
        setDeviceFieldValue(res.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      handleFetchError(err, "Inverter field List");
    }
  };

  const handleMfmParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, mfmList: true }));
      const res = await invService.getDeviceLevelData(id, "mfm");
      if (res.status === 200) {
        setMfmDataArray(res.data);
        const tableDatas = res.data.map((item) => ({
          name: item?.device_details?.name,
          export: item?.device_data?.negative_active_energy_kWh.toFixed(2),
          import: item?.device_data?.positive_active_energy_kWh.toFixed(2),
          status: item?.device_details?.edge_status === true ? "ON" : "OFF",
          raised_at: (
            item?.device_details?.last_active.match(
              /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/
            ) || []
          )
            .slice(1)
            .join(" "),
          device_data: item?.device_data?.device_id,
          id: item?.device_details?.id,
        }));
        setTableData(tableDatas);
        setLoadingState((prevState) => ({ ...prevState, mfmList: false }));
      }
    } catch (err) {
      setLoadingState((prevState) => ({ ...prevState, mfmList: false }));
      handleFetchError(err, "mfm List");
    }
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };
  // const mfmData = tableData.filter(smb =>
  //     smb.device_details.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  return (
    <div className="home-container">
      {loading && <SimpleBackdrop open={loading} />}
      <Grid container className="grid-container">
      <Grid
          container
          className="inv_names_container"
          sx={{
            padding: "1rem !important",
            backgroundColor: "white !important",
            border: "0.0625rem solid #D8D8D8 !important",
            borderRadius: "0.75rem !important",
            position:"relative"
          }}
        >
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            className="only_flex-start"
           
          >
            <Grid container className="inv-names_grid">
              {visibleMfms.length > 0 ? (
                <>
          <ButtonSlider isValueData={visibleMfms} handleInvDeatil={handleMfmClick} itemsPerPage={itemsPerPage} />
        </>
              ) 
              : (
                <div className="no-data">
                   <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>
                    No data is available!
                  </p>
             
                </div>
              )}
            </Grid>
            { loadingState.mfmList && (
                  <SimpleBackdrop open={loadingState.mfmList} />
                )}
          </Grid>
        </Grid>
        
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={11.9}
          className="center_center_column"
        >
          <TableNoCheckBox
            data={tableData}
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            handlePageChange={handleChangePage}
            handleRowsPerPageChange={handleChangeRowsPerPage}
            handleRowClick={handleMfmClick}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            serchText="Search MFM"
            titleText="MFM List"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default MFM;
