import React from 'react';

const DashboardIcon = (props) => {
  const {
    strokeColor = '#111111', 
    strokeWidth = 1.5,     
    width = "24",
    height = "24",
    ...rest                
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest} 
    >
      <path
        d="M15.25 2.75H8.75C5.43629 2.75 2.75 5.43629 2.75 8.75V15.25C2.75 18.5637 5.43629 21.25 8.75 21.25H15.25C18.5637 21.25 21.25 18.5637 21.25 15.25V8.75C21.25 5.43629 18.5637 2.75 15.25 2.75Z"
        stroke={strokeColor}
        stroke-width={strokeWidth}
      />
      <path
        d="M7 14.8442L9.322 11.7542C9.4658 11.5646 9.67573 11.4361 9.91 11.3942C10.1418 11.3501 10.3818 11.3939 10.583 11.5173L12.848 12.9472C13.058 13.0792 13.308 13.1232 13.55 13.0712C13.7935 13.0219 14.0078 12.879 14.147 12.6733L16.479 9.15625"
        stroke={strokeColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.6836 9.67825L16.4796 9.15625L17.0006 11.9532"
        stroke={strokeColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DashboardIcon;
