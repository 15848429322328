import React,{useEffect} from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import ArrowRight from "../Common/ArrowRight";
import { useHistory } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function SolarDigitalTwin() {
  const clientControls = useAnimation();
  const challengeControls = useAnimation();
  const solControls = useAnimation();
  const resControls = useAnimation();
  const conControls = useAnimation();

  const [clientRef, inViewClient] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [challengeRef, inViewChallenge] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [conRef, inViewCon] = useInView({ triggerOnce: false, threshold: 0.1 });

  useEffect(() => {
    clientControls.start({
      opacity: inViewClient ? 1 : 0,
      y: inViewClient ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [clientControls, inViewClient]);

  useEffect(() => {
    challengeControls.start({
      opacity: inViewChallenge ? 1 : 0,
      y: inViewChallenge ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [challengeControls, inViewChallenge]);
  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  useEffect(() => {
    conControls.start({
      opacity: inViewCon ? 1 : 0,
      y: inViewCon ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [conControls, inViewCon]);

  const solutionM = [
    {
      title: "Real-Time Data Integration",
      desc: "Integrated sensor data from inverters, panels, and trackers into the Digital Twin, providing a comprehensive, real-time view of plant operations.",
    },
    {
      title: "Immersive Interaction",
      desc: "Utilized VR/AR technology to allow operators to navigate the plant virtually, offering intuitive control and the ability to visualize performance metrics in a 3D space.",
    },
    {
      title: "Grid Synchronization",
      desc: "Leveraged PINNs to detect potential faults in advance, predicting maintenance needs and allowing for timely interventions.",
    },
  ];
  const results = [
    {
      title: "Reduced",
      extraT: "Downtime",
      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/ResultOne.png",
      desc: "Achieved a 30% reduction in downtime by proactively identifying issues before they caused failures.",
    },
    {
      title: "Increased",
      extraT: "Efficiency",

      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/ResultTwo.png",

      desc: "Enhanced operational efficiency by 25% through optimized maintenance scheduling and reduced need for on-site inspections.",
    },
    {
      title: "Improved",
      extraT: "Decision-Making",

      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/ResultThree.png",

      desc: "Provided operators with intuitive tools for real-time decision-making, enabling more accurate and efficient management of plant resources.",
    },
  ];
  const caseStudies = [
    {
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseOne3.png",

      title:
        "Real-Time Solar Plant Monitoring with Drone Imagery and Edge Processing",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseOne1.png",

      title: "Enhancing Solar Forecast Accuracy with Advanced Deep Learning",
    },
  ];
  const history = useHistory();

  const handleCaseStudy = (index) => {
    if (index === 0) {
      history.push(`./solar-drone`);
    } else if (index === 1) {
      history.push(`./solar-forecast`);
    }
  };
  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main"
        id="our_sol"
      >
        <div className="expert_div flex_crct gap_1vw justify_space">
          <div className="expert_div sol_sec ">
            <p className="Raleway_61px font_500 c_secBlack text_c">
              Real-Time Solar Plant Management with 3D Digital Twin Technology
            </p>
          </div>

          <div className="expert_div width_casestudy">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solar3d.png"
              alt="solar deep learning"
            />
          </div>
        </div>

        <motion.div className="expert_div flex_reverse gap_3vw justify_space" id="our_sol"  ref={clientRef}
          initial={{ opacity: 0, y: 50 }}
          animate={clientControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureOne.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw align_item">
            <p className="Raleway_48px_600 c_thirdBlack text_c">
              Client: Solar Plant Owner
            </p>
            <p className="Raleway_40px opacity_8">Project Focus</p>
            <p className="Raleway_31px_dark c_black opacity_8">
              Real-Time Monitoring and Fault Detection
            </p>
            <p className="Raleway_40px opacity_8">Technologies Used </p>
            <p className="Raleway_31px_dark c_black opacity_8">
              3D Digital Twin, IoT Integration, VR/AR, Physics-Informed Neural
              Networks (PINNs)
            </p>
          </div>
        </motion.div>
        <motion.div className="expert_div flex_crct gap_3vw justify_space" id="our_sol"  ref={challengeRef}
          initial={{ opacity: 0, y: 50 }}
          animate={challengeControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Challenge</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              The client, managing a large-scale solar plant, needed a solution
              that would provide real-time insights into plant performance,
              including fault detection, remote monitoring, and predictive
              maintenance. The goal was to reduce downtime, optimize maintenance
              schedules, and ensure smooth operation with minimal manual
              intervention.
            </p>
          </div>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureTwo.png"
              alt="solar deep learning"
            />
          </div>
        </motion.div>
        <div className="expert_div flex_col gap_1vw" id="our_sol">
          <p className="Raleway_48px_600 c_dark" id="our_sol">
            Solution Methodology
          </p>
          <p className="Raleway_31px_dark opacity_8 text_cen text_j">
            Smarttrak AI developed a customized 3D Digital Twin solution,
            integrating real-time data from IoT sensors deployed across the
            plant. The solution combined VR/AR capabilities with our
            Physics-Informed Neural Networks (PINNs) to create an immersive,
            interactive experience.
          </p>
        </div>
        <motion.div className="expert_div flex_col text_cen" id="our_sol"  ref={solRef}
          initial={{ opacity: 0, y: 50 }}
          animate={solControls}
          transition={{ duration: 0.5 }}>
          <Grid container className="home_grid_container gap_3vw">
            {solutionM.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.7}
                xs={12}
                sm={12}
                md={3.6}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p"
                  id="valData"
                  style={{ flex: 1 }}
                >
                  <div
                    className="expert_div flex_col gap_1vw padd_1vw"
                    id="text_just"
                  >
                    <p className="Raleway_45px align_self">0{index + 1}</p>
                    <p className="Raleway_31px_600 c_lightdrak ">{ele.title}</p>
                    <p className="Raleway_26px_500 opacity_8 height_10vw">{ele.desc}</p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div className="expert_div flex_col text_cen" id="our_sol"  ref={resRef}
          initial={{ opacity: 0, y: 50 }}
          animate={resControls}
          transition={{ duration: 0.5 }}>
          <p className="Raleway_48px_600 c_dark" id="our_sol">
            Results
          </p>
          <Grid container className="home_grid_container" id="our_sol">
            {results.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.4}
                xs={12}
                sm={12}
                md={3.4}
                className="home_grid_product"
                id="border_none"

              >
                <Paper className="home_paper_product">
                  <div className="solar_divs result_down">
                    <img
                      src={ele.imgUrl}
                      alt="ourproduct"
                      className="height_w"
                    />
                  </div>

                  <div className="expert_div result_up">
                    <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                      <div>
                        <p className="Raleway_31px_600 c_black">{ele.title}</p>
                        <p className="Raleway_31px_600 c_black">{ele.extraT}</p>
                      </div>
                      <p
                        className="Raleway_26px_500 opacity_8 height_10vw"
                        id="text_just"
                      >
                        {ele.desc}
                      </p>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div className="expert_div flex_col gap_1vw" id="our_sol"  ref={conRef}
          initial={{ opacity: 0, y: 20 }}
          animate={conControls}
          transition={{ duration: 0.5 }}>
          <p className="Raleway_48px_600 c_dark">Conclusion</p>
          <p className="Raleway_31px_dark opacity_8 text_cen text_j">
            This project highlights Smarttrak AI’s capabilities in Digital Twin
            technology, showcasing how our solutions can transform solar plant
            operations through real-time monitoring, immersive interaction, and
            predictive maintenance. The client now enjoys streamlined operations
            with lower costs and improved performance.
          </p>
        </motion.div>
        <div className="expert_div flex_col gap_1vw" id="our_sol">
          <p className="Raleway_48px_600 c_dark text_c">
            Browse more Solar Industry case studies from Smarttrak AI
          </p>
          <Grid
            container
            className="home_grid_container gap_1vw casestudy-grid"
            id="our_sol"
          >
            {caseStudies.map((item, index) => (
              <Grid item key={index} lg={4} xs={11.9} sm={5} md={4}>
                <Paper className="home_paper_casestudy expert_div flex_col">
                  
                  <div className="slide expert_div" key={index}>
                    <img
                      src={item.image}
                      alt={`Slide ${index + 1}`}
                      className="div_casestudy"
                      style={{
                        display: "block",
                      }}
                    />

                    <div className="overlay">
                      <p className="Raleway_31px_600">{item.title}</p>
                    </div>

                    <div
                      className="case_arrows expert_div cursor_p"
                      onClick={() => handleCaseStudy(index)}
                    >
                      <ArrowRight className="cursor_p" fill="white" />
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SolarDigitalTwin;
