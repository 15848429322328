const ServiceContent = [
    {
    title:"DevOps & Data Engineering Services",
    description:"SmartTrak excels in DevOps and Data Engineering, focusing on business efficiency. Their DevOps prioritizes collaboration, productivity, and flexibility with Infrastructure Automation, CI/CD, Microservices, and Cloud Services. They harness leading tech to convert data into strategic assets, ensuring client success.",
    image :"https://s3.amazonaws.com/smarttrak.co/landing-images/service1.jpg",
    solTitleOne:"Featured Solutions - DevOps",
    solDescriptnOne:"Our DevOps services integrate development and operations teams to eliminate silos, promote collaboration, and boost efficiency. Our offerings include:",
    feature1:"Infrastructure Automation",
    feature1Descriptn:"Smarttrak offers top-notch DevOps solutions for businesses, automating IT infrastructure for faster system deployments and reducing human error. Their team of experts collaborates with organizations to design and implement tailored solutions, improving operational efficiency, accelerating time-to-market, and productivity. They specialize in microservices architecture and offer cloud services for migration and optimization. Smarttrak is a trusted partner in driving businesses forward, transforming their IT infrastructure and unlocking new possibilities for success.",
    feature1Img:"https://s3.amazonaws.com/smarttrak.co/landing-images/dev1.jpg",
    feature2:"Continuous Integration/Continuous Deployment (CI/CD)Services",
    feature2Descriptn:"Smarttrak's CI/CD services streamline software development by automating code changes, testing, and preparing them for release to production. This approach accelerates deployment times, minimizes errors, and enables organizations to deliver high-quality software faster and more reliably. The CI phase involves continuous integration, compiling, automated tests, and checking for inconsistencies or bugs. CI/CD offers advantages such as minimizing production bugs, faster time-to-market, and fostering collaboration and accountability within development teams.",
    feature2Img:"https://s3.amazonaws.com/smarttrak.co/landing-images/dev2.jpg",
    feature3:"Micro services Architecture",
    feature3Descriptn:"At Smarttrak, we offer expertise in breaking down your application into manageable and loosely coupled services, a fundamental principle of microservices architecture. This approach enhances flexibility, scalability, and enables faster innovation in software development. By decoupling components, each service can be developed, deployed, and maintained independently, allowing for seamless scaling and updates without disrupting the entire application. This modular design fosters a more agile development process, empowering teams to work on individual services concurrently and adapt quickly to changing business requirements. With Smarttrak's microservices solutions, your organization can unlock the potential for continuous innovation and deliver high-performing applications to meet evolving customer needs.",
    feature3Img:"https://s3.amazonaws.com/smarttrak.co/landing-images/dev3.jpg",
    feature4:"Cloud Services",
    feature4Descriptn:"Smarttrak offers expert services in cloud migration and optimization for organizations. They provide step-by-step guidance for migrating to the cloud, assessing infrastructure, applications, and data to create a seamless transition. This approach ensures minimal disruption to business operations and increases agility and scalability. For existing cloud systems, they offer cloud optimization services to enhance performance and cost-effectiveness. Through in-depth analyses, they fine-tune infrastructure, optimize resource allocation, and implement best practices to maximize efficiency and minimize costs. By leveraging Smarttrak's expertise in cloud computing, organizations can fully embrace the full potential of the cloud, enabling greater agility, scalability, and cost-effectiveness.",
    feature4Img:"https://s3.amazonaws.com/smarttrak.co/landing-images/dev4.jpg",
    solTitleTwo:"Featured Solutions - Data Engineering Services",
    solDescriptnTwo:"Seamlessly Integrating Data Operations, Breaking Silos, and Driving Collaboration.",
    dataFea1:"Data Pipeline Construction",
    dataFeaDes1:"SmartTrak focuses on innovative data management solutions by designing robust data pipelines. They automate the end-to-end data lifecycle, reducing human errors and increasing efficiency. Data integrity and security are core, with industry best practices and cutting-edge technologies. Their team handles diverse data types and sources, efficiently managing massive volumes and complex formats. This enables organizations to gain valuable insights, enhance decision-making processes, and stay ahead of the competition. SmartTrak's data pipelines help steer organizations towards success in today's data-intensive business landscape.",
    dataFeaImg1:"https://s3.amazonaws.com/smarttrak.co/landing-images/data1.jpg",
    dataFea2:"Data Lake And Data Warehouse Building",
    dataFeaDes2:"Our company offers comprehensive support for establishing and maintaining centralized repositories, such as data lakes or warehouses, to efficiently store, process, and analyze vast amounts of data. Our team of experts ensures seamless data management and accessibility, scalability, security, and performance. We handle diverse data types, focusing on scalability, security, and performance to support complex analytical queries. By relying on our services, businesses can unlock valuable insights and gain a competitive edge in their industry.",
    dataFeaImg2:"https://s3.amazonaws.com/smarttrak.co/landing-images/data2.jpg",
    dataFea3:"Big Data Solutions",
    dataFeaDes3:"Our company specializes in deploying advanced big data technologies for efficient data processing, analysis, and insights extraction from diverse data sets. Our team of professionals is well-versed in tools and frameworks, tailoring solutions to meet specific needs and challenges. We enable seamless integration and processing of massive data volumes, utilizing distributed computing and parallel processing for speedy and scalable analysis. Our goal is to help organizations harness the power of big data, optimize operations, and identify growth opportunities in today's data-driven world.",
    dataFeaImg3:"https://s3.amazonaws.com/smarttrak.co/landing-images/data3.jpg",
    dataFea4:"Real-Time Data Processing",
    dataFeaDes4:"Our company specializes in real-time data processing and analysis systems, enabling faster decision-making and immediate insights. We integrate cutting-edge technologies and frameworks to create agile, responsive solutions for businesses. Our solutions enable seamless integration with various data sources, enabling data capture and processing from multiple channels. By harnessing real-time insights, businesses can identify emerging trends, spot anomalies, and capitalize on opportunities, giving them a competitive edge.",
    dataFeaImg4:"https://s3.amazonaws.com/smarttrak.co/landing-images/data4.jpg",
},
{
    title:"Generative AI solutions",
    description:"At Smarttrak, we leverage Generative AI to revolutionize your business. Our expert team creates innovative AI models for generating content, designs, and trends, enhancing your creative processes.",
    image :"https://s3.amazonaws.com/smarttrak.co/landing-images/service2.jpg",
    solTitleOne:"Featured Solutions",
    solDescriptnOne:"",
    feature1:"Data Synthesis",
    feature1Descriptn:"SmartTrak offers custom AI solutions tailored to businesses' unique needs and challenges. Our team of experts collaborates with clients to understand their objectives and requirements, utilizing AI expertise to design and implement tailored solutions. These solutions enhance customer experiences, optimize operations, automate tasks, and unlock valuable insights, ensuring efficiency, accuracy, and scalability. Trust SmartTrak to deliver the right tools to stay ahead of the competition.",
    feature1Img:"https://s3.amazonaws.com/smarttrak.co/landing-images/dataFea1.jpg",
    feature2:"",
    feature2Descriptn:"",
    feature2Img:"",
    feature3:"",
    feature3Descriptn:"",
    feature3Img:"",
    feature4:"",
    feature4Descriptn:"",
    feature4Img:"",
    solTitleTwo:"",
    solDescriptnTwo:"",
    dataFea1:"",
    dataFeaDes1:"",
     dataFeaImg1:"",
    dataFea2:"",
    dataFeaDes2:"",
    dataFeaImg2:"",
    dataFea3:"",
    dataFeaDes3:"",
    dataFeaImg3:"",
    dataFea4:"",
    dataFeaDes4:"",
    dataFeaImg4:"",
},
{
    title:"Custom AI models - DS",
    description:"At Smarttrak, we customize AI models using data science and AI expertise tailored to your business needs. Our team unlocks insights, predicts trends, and enhances decision-making through cutting-edge techniques and algorithms.",
    image :"https://s3.amazonaws.com/smarttrak.co/landing-images/service3.jpg",
    solTitleOne:"Featured Solutions",
    solDescriptnOne:"Our DevOps services integrate development and operations teams to eliminate silos, promote collaboration, and boost efficiency. Our offerings include:",
    feature1:"Machine Learning Solutions",
    feature1Descriptn:"SmartTrak's machine learning models are adaptive learners that extract patterns and make informed decisions from data. They empower businesses to automate processes, boost efficiency, and enhance accuracy across various domains. Their team of experts uses advanced techniques like deep learning, natural language processing, and predictive modeling to build models that align with specific requirements. Integrating these capabilities into operations gives a competitive advantage and improves business performance.",
    feature1Img:"https://s3.amazonaws.com/smarttrak.co/landing-images/ca1.jpg",
    feature2:"Deep Learning Solutions",
    feature2Descriptn:"SmartTrak uses advanced neural networks to tackle complex tasks with precision and efficiency. Their deep learning models excel in image recognition, natural language processing, and speech recognition. These models enable advanced systems to identify objects, scenes, and patterns in visual data, extract insights from text data, and convert spoken language into written text or voice commands. SmartTrak delivers innovative solutions that enhance business processes, transform customer experiences, and unlock growth opportunities. Trust their expertise to revolutionize demanding tasks and drive industry innovation.",
    feature2Img:"https://s3.amazonaws.com/smarttrak.co/landing-images/ca2.jpg",
    feature3:"Predictive Analysis",
    feature3Descriptn:"SmartTrak specializes in designing predictive analytics models that use historical and real-time data to forecast future outcomes with precision. Their solutions are tailored to meet specific needs, enabling businesses to anticipate customer behavior, market trends, and optimize operational performance. Utilizing advanced data analysis techniques like machine learning algorithms and time series forecasting, they help businesses stay ahead of the competition and navigate the dynamic business landscape with confidence. By making data-driven decisions based on reliable predictions, organizations can streamline operations, maximize efficiency, and seize opportunities before they arise.",
    feature3Img:"https://s3.amazonaws.com/smarttrak.co/landing-images/ca3.jpg",
    feature4:"Custom AI Models",
    feature4Descriptn:"SmartTrak is dedicated to providing tailored AI solutions for businesses, focusing on understanding their unique needs, challenges, and goals. Through in-depth consultations and analysis, they design bespoke models that integrate seamlessly with existing workflows, maximizing efficiency and driving innovation. Their personalized AI models empower users to make data-driven decisions, automate tasks, and gain a competitive edge in their industry. Trust SmartTrak to unlock the full potential of AI for your organization's success.",
    feature4Img:"https://s3.amazonaws.com/smarttrak.co/landing-images/ca4.jpg",
    solTitleTwo:"",
    solDescriptnTwo:"",
    dataFea1:"",
    dataFeaDes1:"",
    dataFeaImg1:"",
    dataFea2:"",
    dataFeaDes2:"",
    dataFeaImg2:"",
    dataFea3:"",
    dataFeaDes3:"",
    dataFeaImg3:"",
    dataFea4:"",
    dataFeaDes4:"",
    dataFeaImg4:"",
}
]
export default ServiceContent;