import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getCamelCaseLabel } from "../../utils/stringProcessor";
import "../CreateAI/CreateAI.scss";


export default function CustomTable(props) {
  const { headers, values, parsedData, searchVal } = props;
  const [tableData, setTableData] = useState({
    rows: [],
    cols: [],
  });
  const [isChecked, setIsChecked] = useState("");

  useEffect(() => {
    let colsArray = headers.map((item, index) => ({
      id: item,
      label: getCamelCaseLabel(item),
      align: "center",
    }));
    setTableData(() => ({
      rows: parsedData,
      cols: colsArray,
    }));
  }, [headers, values]);

  const handleCheck = (event) => {
    setIsChecked(event.target.value);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer style={{ maxHeight: "432vh", height: "34vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableData.cols.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={
                    searchVal === index
                      ? "highlight"
                      : "custom-table-header-cell"
                  }
                >
                  {column.label}
                  <div className="checkbox">
                    {/* <input type='checkbox' checked={searchVal === index} /> */}
                    <input type="checkbox" onChange={handleCheck} />
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="custom-table-body">
            {tableData.cols.map((column, colIndex) => (
              <TableCell
                key={column.id}
                align={column.align}
                className={
                  searchVal === colIndex
                    ? "highlight custom-body-cell"
                    : "custom-body-cell"
                }
              >
                {tableData.rows.map((row) => {
                  const value = row[column.id];
                  return (
                    <div key={`${row.code}-${column.id}`}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </div>
                  );
                })}
              </TableCell>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
