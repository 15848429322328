import React, { useEffect } from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import ArrowRight from "../Common/ArrowRight";
import { useHistory } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function SolarDeepLearn() {
  const clientControls = useAnimation();
  const challengeControls = useAnimation();
  const solControls = useAnimation();
  const resControls = useAnimation();
  const conControls = useAnimation();

  const [clientRef, inViewClient] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [challengeRef, inViewChallenge] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [conRef, inViewCon] = useInView({ triggerOnce: false, threshold: 0.1 });

  useEffect(() => {
    clientControls.start({
      opacity: inViewClient ? 1 : 0,
      y: inViewClient ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [clientControls, inViewClient]);

  useEffect(() => {
    challengeControls.start({
      opacity: inViewChallenge ? 1 : 0,
      y: inViewChallenge ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [challengeControls, inViewChallenge]);
  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  useEffect(() => {
    conControls.start({
      opacity: inViewCon ? 1 : 0,
      y: inViewCon ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [conControls, inViewCon]);

  const solutionM = [
    {
      title: "Data Aggregation",
      desc: "Integrated data from weather stations, historical solar data, and real-time plant performance to train the models.",
    },
    {
      title: "Model Optimization",
      desc: "Leveraged physics-informed deep learning to account for variables such as solar irradiance, temperature, and seasonal changes.",
    },
    {
      title: "Grid Synchronization",
      desc: "Enabled seamless integration with the grid management system, allowing the client to synchronize energy output with grid demand in real time.",
    },
  ];
  const results = [
    {
      title: "Forecast Accuracy",
      extraT: "Improvement",
      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/solarResultOne.png",
      desc: "Achieved a 20% increase in forecast accuracy, enabling the client to optimize energy production and reduce wastage.",
    },
    {
      title: "Cost",
      extraT: "Savings",

      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/solarResultTwo.png",

      desc: "Improved forecasting accuracy reduced the need for expensive grid interventions, saving the client an estimated 15% in operational costs.",
    },
    {
      title: "Enhanced",
      extraT: "Grid Stability",

      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/solarResultThree.png",

      desc: "By aligning energy output more closely with demand, the client experienced greater grid stability and reliability, resulting in more consistent energy delivery.",
    },
  ];
  const caseStudies = [
    {
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseOne2.png",

      title: "Real-Time Solar Plant Management with 3D Digital Twin Technology",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseOne3.png",

      title:
        "Real-Time Solar Plant Monitoring with Drone Imagery and Edge Processing",
    },
  ];
  const history = useHistory();

  const handleCaseStudy = (index) => {
    if (index === 0) {
      history.push(`./solar-3d`);
    } else if (index === 1) {
      history.push(`./solar-drone`);
    }
  };

  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main"
        id="our_sol"
      >
        <div className="expert_div flex_crct gap_1vw justify_space">
          <div className="expert_div sol_sec ">
            <p className="Raleway_61px font_500 c_secBlack text_c">
              Enhancing Solar Forecast Accuracy with Advanced Deep Learning
            </p>
          </div>

          <div className="expert_div width_casestudy">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarDeep.png"
              alt="solar deep learning"
            />
          </div>
        </div>

        <motion.div
          className="expert_div flex_reverse gap_3vw justify_space"
          id="our_sol"
          ref={clientRef}
          initial={{ opacity: 0, y: 50 }}
          animate={clientControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureOne.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw align_item">
            <p className="Raleway_48px_600 c_thirdBlack text_c">
              Client: Leading Solar Energy Provider
            </p>
            <p className="Raleway_40px opacity_8">Project Focus</p>
            <p className="Raleway_31px_dark c_black opacity_8">
              Energy Forecasting and Grid Optimization
            </p>
            <p className="Raleway_40px opacity_8">Technologies Used </p>
            <p className="Raleway_31px_dark c_black opacity_8">
              Deep Learning (FourcastNet, Graphcast, TimesNet), Time Series
              Analysis
            </p>
          </div>
        </motion.div>
        <motion.div
          className="expert_div flex_crct gap_3vw justify_space"
          id="our_sol"
          ref={challengeRef}
          initial={{ opacity: 0, y: 50 }}
          animate={challengeControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Challenge</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              The client, a major solar energy provider, faced challenges with
              accurately forecasting solar power generation due to varying
              weather conditions and system inefficiencies. Traditional
              forecasting models were unable to consistently align energy output
              with grid demands, leading to energy wastage and inefficiencies in
              grid integration.
            </p>
          </div>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureTwo.png"
              alt="solar deep learning"
            />
          </div>
        </motion.div>
        <div className="expert_div flex_col gap_1vw" id="our_sol">
          <p className="Raleway_48px_600 c_dark" id="our_sol">
            Solution Methodology
          </p>
          <p className="Raleway_31px_dark opacity_8 text_cen text_j">
            Smarttrak AI deployed its advanced deep learning models -
            FourcastNet, Graphcast, and TimesNet - tailored specifically for
            time series forecasting. Our models incorporated extensive
            meteorological data, real-time solar data, and historical trends to
            create a comprehensive energy forecasting solution.{" "}
          </p>
        </div>
        <motion.div
          className="expert_div flex_col text_cen"
          id="our_sol"
          ref={solRef}
          initial={{ opacity: 0, y: 50 }}
          animate={solControls}
          transition={{ duration: 0.5 }}
        >
          <Grid container className="home_grid_container gap_3vw">
            {solutionM.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.7}
                xs={12}
                sm={12}
                md={3.6}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p"
                  id="valData"
                  style={{ flex: 1 }}
                >
                  <div
                    className="expert_div flex_col gap_1vw padd_1vw"
                    id="text_just"
                  >
                    <p className="Raleway_45px align_self">0{index + 1}</p>
                    <p className="Raleway_31px_600 c_lightdrak ">{ele.title}</p>
                    <p className="Raleway_26px_500 opacity_8">{ele.desc}</p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col text_cen"
          id="our_sol"
          ref={resRef}
          initial={{ opacity: 0, y: 50 }}
          animate={resControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark" id="our_sol">
            Results
          </p>
          <Grid container className="home_grid_container" id="our_sol">
            {results.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.4}
                xs={12}
                sm={12}
                md={3.4}
                className="home_grid_product"
                id="border_none"
              >
                <Paper className="home_paper_product">
                  <div className="solar_divs result_down">
                    <img
                      src={ele.imgUrl}
                      alt="ourproduct"
                      className="height_w"
                    />
                  </div>

                  <div className="expert_div result_up">
                    <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                      <div>
                        <p className="Raleway_31px_600 c_black">{ele.title}</p>
                        <p className="Raleway_31px_600 c_black">{ele.extraT}</p>
                      </div>
                      <p
                        className="Raleway_26px_500 opacity_8 height_10vw"
                        id="text_just"
                      >
                        {ele.desc}
                      </p>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw"
          id="our_sol"
          ref={conRef}
          initial={{ opacity: 0, y: 20 }}
          animate={conControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark">Conclusion</p>
          <p className="Raleway_31px_dark opacity_8 text_cen text_j">
            Smarttrak AI's tailored deep learning models delivered a
            transformative solution for the client, significantly improving
            reduced wastage, and notable solar power forecasting accuracy by
            20%. This led to optimized energy production, cost savings of 15% in
            operational expenses. By seamlessly aligning energy output with grid
            demand, the client also experienced enhanced grid stability and
            reliability,paving the way for more efficient and sustainable energy
            management.
          </p>
        </motion.div>
        <div className="expert_div flex_col gap_1vw" id="our_sol">
          <p className="Raleway_48px_600 c_dark text_c">
            Browse more Solar Industry case studies from Smarttrak AI
          </p>
          <Grid
            container
            className="home_grid_container gap_1vw casestudy-grid"
            id="our_sol"
          >
            {caseStudies.map((item, index) => (
              <Grid item key={index} lg={4} xs={11.9} sm={5} md={4}>
                <Paper className="home_paper_casestudy expert_div flex_col">
                  <div className="slide expert_div" key={index}>
                    <img
                      src={item.image}
                      alt={`Slide ${index + 1}`}
                      className="div_casestudy"
                      style={{
                        display: "block",
                      }}
                    />

                    <div className="overlay">
                      <p className="Raleway_31px_600">{item.title}</p>
                    </div>

                    <div
                      className="case_arrows expert_div cursor_p"
                      onClick={() => handleCaseStudy(index)}
                    >
                      <ArrowRight className="cursor_p" fill="white" />
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SolarDeepLearn;
