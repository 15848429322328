import React from "react";
import Plot from "react-plotly.js";

const ConfusionMatrix = (props) => {
  const { data, labels } = props;
  const matrixData = JSON.parse(data);

  return (
    <div>
      <Plot
        style={{ width: "450px" }}
        data={[
          {
            z: matrixData,
            x: labels,
            y: labels,
            type: "heatmap",
          },
        ]}
      />
    </div>
  );
};

export default ConfusionMatrix;
