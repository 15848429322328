import React, { useEffect, useState } from 'react';
import "../Screens/Reports/Report.css";
import { Multiselect } from 'multiselect-react-dropdown';

const MultiSelectWithCheckboxes = ({
  options, labeltrue,
  onChange, isDisable
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const formattedOptions = labeltrue
    ? options.map(({ label, original }) => ({
      label: label,
      key: original,
    }))
    : options.map((field) => ({
      label: field,
      key: field.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
    }));

  const handleSelect = (selectedList, selectedItem) => {
    onChange(selectedList)
    setSelectedOptions(selectedList);
  };

  const handleRemove = (selectedList, removedItem) => {
    onChange(selectedList)
    setSelectAllChecked(false);
    setSelectedOptions(selectedList);
  };
  const handleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked);
    const updatedList = !selectAllChecked ? formattedOptions : [];
    onChange(updatedList);
    setSelectedOptions(updatedList);
  }
  return (
    <div>
      <label className='select-all-container'>
        <input
          disabled={isDisable}
          type="checkbox"
          checked={selectAllChecked}
          onChange={handleSelectAll}
        />
        <p className='Poppins_14px_rem' style={{ fontWeight: "400", color: "gray" }}>Select All</p>
      </label>
      <Multiselect
        displayValue="label"
        onKeyPressFn={() => { }}
        onRemove={handleRemove}
        onSearch={() => { }}
        onSelect={handleSelect}
        options={formattedOptions}
        showCheckbox
        selectedValues={selectedOptions}
        disable={isDisable}
      />
    </div>

  );
};

export default MultiSelectWithCheckboxes;
