import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = 'https://smarttrakportal.in/api';

const apiHelper = new ApiHelper();
export class YourModalService{
    getAllYourModals(){
        const uri = `${BASE_URL}/training/user_jobs`;
        return apiHelper.get(uri);
    }
   
    deployModel(data){
        const uri =`${BASE_URL}/training/deploy`;
        return apiHelper.post(uri, data);
    }
}