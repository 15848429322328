import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import "./ForgotPass.scss";
import TextField from '@mui/material/TextField';
import { UserService } from '../IOT/Services/UserService';
import SimpleBackdrop from '../LifeScience/components/common/SimpleBackdrop';

const userService = new UserService();
function Verify() {
    const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email : "",
    id: ""
  })
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setUserData(() => ({
      
        email: location.state.email,
        id: location.state.id
    }))

  }, [location])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const reqData = {
            id: userData.id.toString(),
            email: userData.email.toString(),
            otp: data.get("otp").toString(),
        };
        await userService.verifyEmail(reqData).then((res) => {
          if(res.status===200 || res.status===201){
            setLoading(false);
            alert("Email has been verified successfully");
            history.push("/signin");
          }
        }).catch((err) => {
          setLoading(false);
          console.log("Error", err);
          alert("Error occured while verifying OTP", err.response.message);
        })
      };
  return (
    <form className="formContainer" onSubmit={handleSubmit}>
       {loading && <SimpleBackdrop open={loading} />}
        <div className="signUpsmartlogo">
        <img src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg" width={160} alt="images" />
      </div>
        <div className="main-container">
        <div className="left-container">
          <img src="https://s3.amazonaws.com/smarttrak.co/images/VerifyEmail.png" alt="img" className='verify-img' width={400} />
        </div>
        <div className="right-container">
          <div className="forgotPasswordContainer">
            <span className="forgotPasswordText"> Verify Email</span>
          </div>
          <p className="paragraph">
          We've sent a verification code to your email. 
          Please enter it below to complete the verify email process.
          </p>

            <TextField value={userData.email}
              margin="normal"
              required
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              disabled={true}
              style={{width: "88%"}}
            />
            <TextField
              margin="normal"
              required
              name="otp"
              label="Enter OTP"
              id="otp"
              style={{width: "88%"}}
              variant="filled"
            />
            
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#1C629E",
              width: "88%",
              marginTop: "40px",
            }}
          >
            Submit
          </Button>
          <p className="backToLogin">
            <a
              href="/signup"
              style={{
                textDecoration: "none",
                fontFamily: "Poppins",
                fontSize: "17px",
                color: "#000000",
              }}
            >
              Back to Signup
            </a>
          </p>
        </div>
        </div>
        </form>

  )
}

export default Verify