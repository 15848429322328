import React, { createContext, useContext, useEffect, useState } from 'react';

// Create a context for both mobile and tablet state
const MobileContext = createContext();

// Create a provider component to handle both mobile and tablet states
export const MobileProvider = ({ children }) => {

  const [isOnlyMobile, setIsOnlyMobile] = useState(window.innerWidth <= 400);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [isMobileAndTab, setIsMobileAndTab] = useState(window.innerWidth <= 1199);
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 1300);

  useEffect(() => {
    const handleResize = () => {
      setIsOnlyMobile(window.innerWidth <= 400);
      setIsMobile(window.innerWidth <= 992); // Update isMobile based on 992px width
      setIsMobileAndTab(window.innerWidth <= 1199); // Update isMobileAndTab based on 1199px width
      setIsDesktopView(window.innerWidth > 1300)
    };

    handleResize(); // Call the function initially to set the correct state
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on unmount
    };
  }, []);

  return (
    <MobileContext.Provider value={{ isOnlyMobile, isMobile, isMobileAndTab, isDesktopView }}>
      {children}
    </MobileContext.Provider>
  );
};

// Custom hook to use mobile and tablet states
export const useMobile = () => useContext(MobileContext);
