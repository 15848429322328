import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SimpleBackdrop from "../LifeScience/components/common/SimpleBackdrop";
import { useSnackbar } from "notistack";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Signup.scss";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { UserService } from "../IOT/Services/UserService";
const userService = new UserService();

export default function AcceptInvite() {
  const [email, setEmail] = useState("");
  const [invitationId, setInvitationId] = useState("");
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get("email");
    const inviteId = searchParams.get("inviteid");
    setEmail(emailParam);
    setInvitationId(inviteId)
  }, [location.search]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowCPassword] = useState(false);
  const [cpassword, setCpassword] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cpasswordError, setCpasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const validateFields = () => {
    let valid = true;

    if (!firstName) {
      setFirstNameError("Required Field*");
      valid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName) {
      setLastNameError("Required Field*");
      valid = false;
    } else {
      setLastNameError("");
    }

    if (!email) {
      setEmailError("Please enter your email address");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Please enter your password");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (password !== cpassword) {
      setCpasswordError("Password is not matched");
    } else {
      setCpasswordError("");
    }

    return valid;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglecPasswordVisibility = () => {
    setShowCPassword(!showcPassword);
  };
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCpasswordChange = (event) => {
    setCpassword(event.target.value);
  };
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateFields()) {
      let reqData = {
        invite_id: invitationId,
        password: password,
        email: email,
        first_name: firstName,
        last_name: lastName,
      }
      await userService
        .postAcceptInvite(reqData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);

            enqueueSnackbar("Invitation accepted successfully", {
              variant: "success",
            });
            history.push("/signin");
          } else if (res.status === 422) {
            enqueueSnackbar("Please fill the valid fields", {
              variant: "warning",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar(err.response.data.detail, { variant: "error" });
        });
    } else {
      setLoading(false);
    }
  };

  const [sliderSettings] = useState({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  });

  return (
    <div className="container">
      {loading && <SimpleBackdrop open={loading} />}
      <div style={{display:"flex", flexDirection:"column", width:"50%", alignItems:"center", justifyContent:"center"}}>
      <div>
        <img src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg" width={160} alt="img" className="smartlogo-img"/>
      </div>
      <div className="login-form">
        <div
          className={`formcontainer ${
            firstNameError ||
            lastNameError ||
            emailError ||
            passwordError ||
            cpasswordError
              ? "error"
              : ""
          }`}
        >
          <div className="up-title">Welcome to Smarttrak</div>
          <form onSubmit={handleSubmit} className="signup-form-container">
            <div className="first-last">
              <div className="first">
                <input
                  type="text"
                  name="firstName"
                  value={firstName}
                  id="firstName"
                  placeholder="First Name"
                  onChange={handleFirstNameChange}
                  className={`firstname-input ${firstNameError ? "error" : ""}`}
                />
                {firstNameError && (
                  <div className="error-message">{firstNameError}</div>
                )}
              </div>
              <br />

              <div className="last">
                <input
                  type="text"
                  name="lastName"
                  value={lastName}
                  id="lastName"
                  placeholder="Last Name"
                  onChange={handleLastNameChange}
                  className={`lastname-input ${lastNameError ? "error" : ""}`}
                />
                {lastNameError && (
                  <div className="error-message">{lastNameError}</div>
                )}
              </div>
              <br />
            </div>

            <input
              type="text"
              name="email"
              id="email"
              value={email}
              placeholder="Email"
            //   onChange={handleEmailChange}
              className={`input ${emailError ? "error" : ""}`}
              disabled
            />
            {emailError && <div className="error-message">{emailError}</div>}
            <br />
            <div style={{ position: 'relative' }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder="Password"
              name="password"
              id="password"
              onChange={handlePasswordChange}
              className={`input ${passwordError ? "error" : ""}`}
            />
              <span
          onClick={togglePasswordVisibility}
          style={{
            position: 'absolute',
            top: '55%',
            right: '15px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </span>
        </div>
        </div>
            {passwordError && (
              <div className="error-message">{passwordError}</div>
            )}
            <br />

          <div style={{ position: 'relative' }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <input
              type={showcPassword ? 'text' : 'password'}
              value={cpassword}
              placeholder="Confirm Password"
              name="cpassword"
              onChange={handleCpasswordChange}
              className={`input ${cpasswordError ? "error" : ""}`}
            />
            <span
          onClick={togglecPasswordVisibility}
          style={{
            position: 'absolute',
            top: '55%',
            right: '15px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
        >
          {showcPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </span>
            </div>
            </div>
            {passwordError && (
              <div className="error-message">{passwordError}</div>
            )}
            <br /> 

            <button type="submit" className="signup-btn">
              <div className="btn-text">Accept Invite</div>
            </button>

            <br />
          
          </form>
        </div>
      </div>
      </div>
      <div className="half-screen">
        <Slider {...sliderSettings}>
          <div className="half-screen-slide-container">
            <img src={"https://s3.amazonaws.com/smarttrak.co/images/firstImage.png"} alt="img" className="img1" />
            <div className="sec-title">Welcome to No Code AI Platform</div>
            <div className="sub-title">
              With our pre-built templates and drag-and-drop interface, you can
              easily upload your data, preprocess it
            </div>
          </div>

          <div className="half-screen-slide-container">
            <img src={"https://s3.amazonaws.com/smarttrak.co/images/secImage.png"} alt="img" className="img1" />
            <div className="sec-title">Welcome to No Code AI Platform</div>
            <div className="sub-title">
              With our pre-built templates and drag-and-drop interface, you can
              easily upload your data, preprocess it
            </div>
          </div>

          <div className="half-screen-slide-container">
            <img src={"https://s3.amazonaws.com/smarttrak.co/images/thirdImg.png"} alt="img" className="img1" />
            <div className="sec-title">Welcome to No Code AI Platform</div>
            <div className="sub-title">
              With our pre-built templates and drag-and-drop interface, you can
              easily upload your data, preprocess it
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
