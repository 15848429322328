import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import StopIcon from '@mui/icons-material/Stop';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import Tooltip from "@mui/material/Tooltip";
import { renderToStaticMarkup } from 'react-dom/server';

const extractTextFromHtml = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
};

const TextToSpeech = ({ markdownText, id, isCurrentPlaying, onPlayStop }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const utteranceRef = useRef(null);

    useEffect(() => {
        const htmlContent = renderToStaticMarkup(<ReactMarkdown>{markdownText}</ReactMarkdown>);
        const textContent = extractTextFromHtml(htmlContent);

        const imageAltTextRegex = /!\[([^\]]*)\]\((.*?)\)/g;
        let modifiedText = textContent;
        let match;

        while ((match = imageAltTextRegex.exec(textContent)) !== null) {
            const altText = match[1] || "image";
            modifiedText = modifiedText.replace(match[0], `Here is the image of ${altText}.`);
        }

        if (modifiedText) {
            const newUtterance = new SpeechSynthesisUtterance(modifiedText);
            newUtterance.onend = () => {
                setIsSpeaking(false);
                onPlayStop(null);
            };
            utteranceRef.current = newUtterance;
        }
    }, [markdownText, onPlayStop]);

    const handlePlayStop = () => {
        if (isSpeaking) {
            window.speechSynthesis.cancel(); // Stop current speech
            setIsSpeaking(false);
            onPlayStop(null); // Notify parent that no audio is playing
        } else if (utteranceRef.current) {
            window.speechSynthesis.cancel(); // Stop any currently playing utterance
            onPlayStop(id); // Notify parent that this audio is now playing
            setTimeout(() => {
                window.speechSynthesis.speak(utteranceRef.current);
                setIsSpeaking(true);
            }, 0);
        }
    };

    useEffect(() => {
        if (!isCurrentPlaying) {
            setIsSpeaking(false);
            window.speechSynthesis.cancel(); // Ensure speech stops when not current
        }
    }, [isCurrentPlaying]);

    useEffect(() => {
        return () => {
            window.speechSynthesis.cancel(); // Cleanup on unmount
        };
    }, []);

    return (
        <div>
            <Tooltip title={isSpeaking ? "Stop Reading" : "Start Reading"}>
                <button onClick={handlePlayStop} className='send-arrow speaker-bg'>
                    {isSpeaking ? <StopIcon style={{ color: "gray" }} /> : <VolumeUpOutlinedIcon style={{ color: "gray" }} />}
                </button>
            </Tooltip>
        </div>
    );
};

export default TextToSpeech;
