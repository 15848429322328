import React from "react";
import { Container, Table } from "@mui/material";
import Box from "@mui/material/Box";
import CustomText from "../../components/CustomText/CustomText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#D5E2EE",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  textAlign: "center",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function PredictResult(props) {
  const { predictionData } = props;

  const data = {
    ID: predictionData.ID,
    prediction: predictionData.prediction,
    prediction_value: predictionData.prediction_value,
  };
  return (
    <Container component="main" maxWidth="lg" style={{ paddingTop: "50px" }}>
      {predictionData ? (
        <div>
          <Box sx={{ minWidth: 100, textAlign: "center" }}>
            <Stack spacing={2}>
              <Item>
                <CustomText
                  type="SemiBold_22px"
                  text="Data Predicted succesfully !!!"
                  styles={{ color: "#1c629e" }}
                />
              </Item>
            </Stack>
          </Box>
          <hr />
          <CustomText
            type="SemiBold_22px"
            text="Predicted values are : "
            styles={{ color: "#1c629e", marginTop: "30px" }}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Prediction Data</StyledTableCell>
                  <StyledTableCell>Disease Prediction</StyledTableCell>
                  <StyledTableCell>Predicted Value&nbsp;</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data.ID).map((key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell>{data.ID[key]}</StyledTableCell>
                    <StyledTableCell>{data.prediction[key]}</StyledTableCell>
                    <StyledTableCell>
                      {data.prediction_value[key]}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </div>
      ) : (
        <Container
          maxWidth="lg"
          style={{
            backgroundColor: "#DAEEFF",
            minHeight: "90%",
            overflow: "hidden",
            textAlign: "center",
          }}
        >
          <div className="nomodels">
            <h1>There is no Data available ,please try again</h1>

            <img src={"https://s3.amazonaws.com/smarttrak.co/images/NoDeployedModels.png"} alt="img" width={300} />
          </div>
        </Container>
      )}
    </Container>
  );
}

export default PredictResult;
