import React from 'react';

const DynamicArrow = ({ pathData, fillColor, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d={pathData} fill={fillColor} />
    </svg>
  );
};

export default DynamicArrow;
