import React, { useState, useEffect } from "react";
import "./YourModals.scss";
import CustomText from "../../components/CustomText/CustomText";
import CustomButton from "../../components/CustomButton/CustomButton";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { YourModalService } from "../../services/YourModalService";
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../../components/common/SimpleBackdrop";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import YourModalDrawer from "./YourModalDrawer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Container from "@mui/material/Container";

const moment = require("moment");
const yourModalService = new YourModalService();

const YourModals = (props) => {
  const [modalData, setModalData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [filterModelData, setFilterModelData] = useState([]);
  const [activeSteps, setActiveSteps] = useState(-1);
  const [selectedModelData, setSelectedModalData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);

  const open = Boolean(isFilter);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchAllYourModals();
  }, []);

  const handleDeploy = () => {
    if (filterModelData.length > 0) {
      setFilterModelData(
        modalData.filter((item) => item.job_status === "DEPLOYED")
      );
    } else {
      enqueueSnackbar("No deployed models ", {
        variant: "warning",
      });

    }

    setOpenFilter(false);


  };
  const handleNonDeploy = () => {
    setFilterModelData(
      modalData.filter((item) => item.job_status !== "DEPLOYED")
    );
    setOpenFilter(false);
  };
  const fetchAllYourModals = async () => {
    setLoading(true);
    await yourModalService
      .getAllYourModals()
      .then((res) => {
        if (
          res.status === 200 &&
          res?.data?.data &&
          res?.data?.data?.length > 0
        ) {
          setModalData(res?.data?.data);
        } else if (res?.data?.data?.length <= 0) {
          enqueueSnackbar("No data available,", {
            variant: "warning",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error in fetching yourModals", err);
        enqueueSnackbar(
          err.response?.data?.detail
            ? err.response?.data?.detail
            : "Error while fetching yourModals",
          { variant: "error" }
        );
        setLoading(false);
      });
  };
  const handleStep = (item) => {
    const n = item.training_status?.sec_status;
    switch (n) {
      case "Starting":
        setActiveSteps(0);
        break;
      case "Downloading":
        setActiveSteps(1);
        break;
      case "Training":
        setActiveSteps(2);
        break;
      case "Uploading":
        setActiveSteps(3);
        break;
      case "Completed":
        setActiveSteps(4);
        // setIsEvaluateActive(true);
        enqueueSnackbar("Model trained succesfully!", {
          variant: "success",
        });
        break;
      case "Failed":
        setActiveSteps(4);
        enqueueSnackbar("Model training Failed!", { variant: "error" });
        break;
      default:
        console.log("training.......");
    }
  };

  const handleExpandClick = (item) => {
    setSelectedModalData(item);
    if (
      item?.job_status === "TRAINING_ONGOING" ||
      item?.job_status === "TRAINING_FAILED"
    ) {
      handleStep(item);
    }
    setShowDrawer(true);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleOpenMenu = (event) => {
    setIsFilter(event.currentTarget);
    setOpenFilter(true);
  };

  const handleCloseMenu = () => {
    setIsFilter(false);
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
    setSelectedModalData({});
  };

  const tempListData = isFilter ? filterModelData : modalData;
  return (
    <div className="containerdata">
      {modalData.length > 0 ? (
        <>
          <div className="contentone">
            <div className="searchbar">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className="search-box">
                  <input className="semibold-smalltext" id="search-input"
                    onChange={handleSearch}
                    type="text"
                    placeholder="Search Case Studies"
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" className="search-icon" viewBox="0 0 55 55" fill="none">
                    <rect width="55" height="55" rx="27.5" fill="#212121" />
                    <path d="M38.2307 37.3048L32.9222 31.9987C34.3449 30.3628 35.2092 28.2264 35.2092 25.8908C35.2092 20.7515 31.0291 16.5784 25.8968 16.5784C20.7574 16.5784 16.582 20.7584 16.582 25.8908C16.582 31.0232 20.7621 35.2032 25.8944 35.2032C28.2278 35.2032 30.3641 34.3412 32.0023 32.9162L37.3108 38.227C37.4359 38.3521 37.6051 38.4217 37.7719 38.4217C37.9364 38.4217 38.1056 38.3591 38.233 38.227C38.4809 37.9745 38.4809 37.5574 38.2307 37.3048ZM25.8898 33.901C21.4781 33.901 17.8842 30.3072 17.8842 25.8908C17.8842 21.4744 21.4781 17.8852 25.8898 17.8852C30.3062 17.8852 33.8954 21.479 33.8954 25.8908C33.8954 30.3025 30.3062 33.901 25.8898 33.901Z" fill="white" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="filterbar">
              <IconButton
                style={{
                  display: openFilter ? "none" : "inline",
                  textAlign: "center",
                }}
                onClick={handleOpenMenu}
              >
                <FilterListOutlinedIcon />
              </IconButton>

              {openFilter ? (
                <RadioGroup
                  open={open}
                  onClose={handleCloseMenu}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Deployed"
                    control={<Radio />}
                    label="Deployed"
                    onClick={handleDeploy}
                    sx={{ mb: -1 }}
                  />
                  <FormControlLabel
                    value="Non-Deployed"
                    control={<Radio />}
                    label="Non-Deployed"
                    onClick={handleNonDeploy}
                  />
                </RadioGroup>
              ) : null}
            </div>
          </div>
          {loading && <SimpleBackdrop open={loading} />}
          {tempListData
            .filter((item) =>
              item?.model_name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((item, index) => (
              <Card
                className="modals"
                sx={{ maxWidth: "100%", bgcolor: "#F8F8F8" }}
                key={index}
              >
                <div className="contentwo">
                  <CustomText
                    type="Medium_15px"
                    text="Modal Name"
                    overflow={false}
                    styles={{ color: "black", fontSize: "12px" }}
                  />
                  <Typography
                    styles={{ color: "black", fontSize: "10px" }}
                    color="text.secondary"
                  >
                    {`User Created ${moment(item.timestamp).fromNow()}`}
                  </Typography>
                </div>
                <CustomText
                  type="Regular_15px"
                  text={item?.model_name}
                  overflow={false}
                  styles={{ color: "gray", fontSize: "15px" }}
                />
                <Divider
                  sx={{ mt: 1 }}
                  style={{ border: "1px solid #BEBEBE" }}
                />
                <CardContent className="subcontent">
                  <div>
                    <h5 className="h55">Status</h5>
                    <CustomText
                      type="Regular_13px"
                      text={item?.job_status}
                      overflow={false}
                      styles={
                        item.job_status === "TRAINING_FAILED" ||
                          item.job_status === "DEPLOY_FAILED"
                          ? {
                            backgroundColor: "#FFDADA",
                          }
                          : {
                            backgroundColor: "#DFFFDA",
                          }
                      }
                    />
                  </div>
                  <div>
                    <h5 className="h55">Model Type</h5>
                    <CustomText
                      type="Regular_15px"
                      text={item?.model_type}
                      overflow={false}
                      sx={{ color: "gray", fontSize: "10px" }}
                    />
                  </div>
                  <div>
                    <h5 className="h55">Data Type</h5>
                    <CustomText
                      type="Regular_15px"
                      text={item?.data_id}
                      overflow={false}
                      sx={{ color: "gray", fontSize: "10px", bgcolor: "red" }}
                    />
                  </div>
                  <CardActions disableSpacing className="cardcls">
                    <CustomButton
                      type="Simple"
                      text="Details"
                      styles={{
                        width: "7rem",
                        height: "3rem",
                      }}
                      onClick={() => handleExpandClick(item)}
                    />
                  </CardActions>
                </CardContent>
              </Card>
            ))}
        </>
      ) : (
        <Container
          maxWidth="lg"
          style={{
            backgroundColor: "#DAEEFF",
            minHeight: "90%",
            overflow: "hidden",
            textAlign: "center",
          }}
        >
          <div className="nomodels">
            <h1>
              There is no Trained models available !! Please Train the model.
            </h1>

            <img src={"https://s3.amazonaws.com/smarttrak.co/images/NoDeployedModels.png"} alt="img" width={300} />
          </div>
        </Container>
      )}

      {showDrawer && (
        <YourModalDrawer
          data={selectedModelData}
          activeStep={activeSteps}
          onClose={() => handleDrawerClose()}
        />
      )}
    </div>
  );
};

export default YourModals;
