import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import EnergyData from "../../../Common/EnergyData";
import Divider from "@mui/material/Divider";
import BarPlotForIOT from "../../../Graphs/BarPlotForIOT";
import "../Trackers/Trackers.scss";
import { useHistory } from "react-router-dom";
import Popover from "@mui/material/Popover";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import TableNoCheckBox from "../../../Common/TableNoCheckBox";

function SMB() {
  const [gridHeight, setGridHeight] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const history = useHistory();
  const apiKey = "6f420b8aac79087eeec4a37ae578f240";
  const gridRef = useRef(null);
  useEffect(() => {
    if (gridRef.current) {
      const height = gridRef.current.offsetHeight;
      setGridHeight(height);
    }
  }, [gridRef]);
 

  const columns = [
    { field: "a", title: "SMB" },
    { field: "b", title: "status" },
    { field: "c", title: "SMB ID" },
    { field: "d", title: "zone" },
    { field: "e", title: "Energy Generated" },
  ];

  const trackerBody = [
    {
      a: "INV2SMB12",
      b: `--`,
      c: `--`,
      d: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
      d: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
      d: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
      d: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
      d: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
      d: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
      d: `--`,
    },
  ];
  const PowerPlant = [
    {
      title: "Cumulative",
      subtitle:"Energy Loss",
      energy: "25999",
      subtitle1: "Active (Fundamental)",
      value1:"568* MWh",
       

      subtitle2: "Apparent",
      value2:"525* MVah",

      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/energySMB.svg",
      colorCode: "#00529A",
    },
    {
      title: "Daily",
      subtitle:"Energy Loss",

      energy: "25999",
      subtitle1: "Active (Fundamental)",
      value1:"568* MWh",
       

      subtitle2: "Apparent",
      value2:"525* MVah",
      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/energySMB.svg",
      colorCode: "#FDA737",
    },
    {
      title: "Weekly",
      subtitle:"Energy Loss",
      energy: "25999",
      subtitle1: "Active (Fundamental)",
      value1:"568* MWh",
       

      subtitle2: "Apparent",
      value2:"525* MVah",
      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/energySMB.svg",
      colorCode: "#04724D",
    },
  ];
  const [anchorEl, setAnchorEl] = useState(
    Array.from({ length: PowerPlant.length }, () => null)
  );
  
  const handleSmbCLick = () => {
    history.push("/iot-home/dev-monitoring/smb/smb-details");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };
  const handlePopoverOpen = (index, event) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };
  const handlePopoverClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };
 


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  return (
    <div className="home-container">
      {loading && <SimpleBackdrop open={loading} />}

      <Grid container className="grid-container">
        <Grid
          item
          lg={5}
          xs={12}
          sm={12}
          md={12}
          className="total_power_container"
          sx={{
            height: gridHeight,
          }}
        >
          <Paper className="smb-paper">
            <p className="Poppins_24px_rem" style={{ color: "#2F67F8" }}>
              SMB
            </p>
            <div className="center_div">
              <div className="smb-one-div">
                <div className="flex-start-column-two">
                  <p className="raleway_14px_blue">Total Energy Generation</p>
                  <p
                    className="raleway_32px_rem center_div_nogap"
                    style={{ fontFamily: "Poppins" }}
                  >
                    136
                    <span className="Poppins_24px_black">MWh</span>
                  </p>
                </div>
              </div>
              <div className="smb-one-div">
                <div>
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/vcbStatus.svg"
                    alt="invStatus"
                    style={{ width: "3.125rem", height: "3.125rem" }}
                  />
                </div>
                <div className="flex-start-column-two">
                  <p className="raleway_14px_blue">Total Working Strings</p>
                  <p
                    className="raleway_32px_rem"
                    style={{ fontFamily: "Poppins" }}
                  >
                    590/
                    <span className="Poppins_24px_black">710</span>
                  </p>
                </div>
              </div>
             
            </div>
          </Paper>
        </Grid>
        <Grid
          item
          lg={6.9}
          xs={12}
          sm={12}
          md={12}
          className="center_center_column"
          ref={gridRef}
        >
          <Grid container className="grid-container">
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="center_center_flex_gap"
            >
              <Paper className="gen-power_paper">
                <p
                  className="Poppins_24px_rem"
                  style={{ color: "#212121", textAlign: "center" }}
                >
                  Generated Energy
                </p>
              </Paper>
            </Grid>

            <Grid
              item
              lg={3.5}
              xs={12}
              sm={3.5}
              md={3.5}
              className="energy-grid"
            >
              <Paper className="energy-paper">
                <EnergyData
                  ACEnergy={"--"}
                  unit="kWh"
                  title="Today’s"
                  colorCode="#00529A"
                />
              </Paper>
            </Grid>
            <Divider className="divider-style" orientation="vertical" />
            <Grid
              item
              lg={3.5}
              xs={12}
              sm={3.5}
              md={3.5}
              className="energy-grid"
            >
              <Paper className="energy-paper">
                <EnergyData
                  ACEnergy={"--"}
                  unit="kWh"
                  title="Yesterday’s"
                  colorCode="#FDA737"
                />
              </Paper>
            </Grid>
            <Divider className="divider-style" orientation="vertical" />
            <Grid
              item
              lg={3.5}
              xs={12}
              sm={3.5}
              md={3.5}
              className="energy-grid"
            >
              <Paper className="energy-paper">
                <EnergyData
                  ACEnergy={"--"}
                  unit="kWh"
                  title="Monthly"
                  colorCode="#04724D"
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {PowerPlant.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.95}
                xs={12}
                sm={12}
                md={3.9}
                className="power-plant-grid-exc"
              >
                <Paper className="power-plant-paper-exc">
                  <div className="center_div">
                    <div className="icon-exc-div">
                      <img
                        src={ele.PlantIcon}
                        alt="plant"
                        style={{ width: "2rem" }}
                      />
                    </div>
                    <div className="flex-start-column">
                      <p className="Poppins_18px_rem">{ele.title}</p>
                      <p className="Poppins_18px_rem">{ele.subtitle}</p>
                    </div>
                  </div>
                  <div 
                  // onMouseEnter={(event) => handlePopoverOpen(index, event)}
                      //  onMouseLeave={() => handlePopoverClose(index)}
                      onClick={(event) => handlePopoverOpen(index, event)}
                      >
                    <img
                      
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/ep_info-filled.svg"
                      alt="plant"
                      style={{ width: "1rem", cursor: "pointer" }}
                    />
                  </div>
                </Paper>
                <Paper className="powerplant-sec-paper">
                  <div
                    className="powerplant-div-exc"
                    style={{
                      borderLeft: `0.2rem solid ${ele.colorCode}`,
                    }}
                  >
                    <p className="raleway_14px_rem">{ele.subtitle1}</p>
                    <p className="Poppins_18px_rem">
                      {ele.value1 ? ele.value1 : "--"}
                    </p>
                  </div>
                  <div
                    className="powerplant-div-exc"
                    style={{
                      borderLeft: `0.2rem solid ${ele.colorCode}`,
                    }}
                  >
                    <p className="raleway_14px_rem">{ele.subtitle2}</p>
                    <p className="Poppins_18px_rem">
                      {ele.value2 ? ele.value2 : "--"}
                    </p>
                  </div>
                  
                </Paper>
                <Popover
                  key={index}
                  open={Boolean(anchorEl[index])}
                  anchorEl={anchorEl[index]}
                  onClose={() => handlePopoverClose(index)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{ className: "customPopoverPaper-exc" }}
                >
                  <div className="center_div">
                    <p className="raleway_14px_rem">{ele.subtitle1}</p>:
                    <p className="Poppins_18px_rem">
                      {ele.value1 ? ele.value1 : "--"}
                    </p>
                  </div>
                  <div className="center_div">
                    <p className="raleway_14px_rem">{ele.subtitle2}</p>:
                    <p className="Poppins_18px_rem">
                      {ele.value2 ? ele.value2 : "--"}
                    </p>
                  </div>
                  
                </Popover>
              </Grid>
            ))}
        <Grid item lg={12} xs={12} sm={12} md={12} className="exc-graph-grid">
          <Paper className="exc-graph-paper">
            <BarPlotForIOT
              isValueData={[]}
              StartTimer=""
              StopTimer=""
              openDrawerValue={setDrawerOpen}
              averagePerformaceRatio={[]}
              titleText="Previous 7 days generated data"
              tooltipName="Power"
              indicateUnit="kWh"
              calenderClick="off"

              selectedDateRangeProp={[
                {
                  startDate: new Date(
                        new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                      ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="notification-grid"
        >
          <Paper className="notification-paper-one">
            <div style={{ padding: "1rem" }}>
              <p className="Poppins_24px_black">Important Notifications</p>
            </div>
            <Divider
              sx={{ mt: 1 }}
              style={{ border: "0.0625rem solid #D9D9D9" }}
            />
          </Paper>
          {/* {NotificationData.map((ele, index) => ( */}
          <Paper
            className="notification-paper"
            // key={index}
            sx={
              {
                // borderBottom:
                //   index === NotificationData.length - 1
                //     ? "none"
                //     : "0.125rem solid #D9D9D9",
              }
            }
          >
            <p className="Poppins_18px_rem">No Notification yet</p>
            {/* <div
                    style={{
                      padding: "1rem 1.5rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <WarningRoundedIcon style={{ color: "#FFA287" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <p className="Poppins_18px_rem">
                        {ele.title}
                        <span style={{ fontWeight: "400" }}>
                          &nbsp;{ele.disc}
                        </span>
                      </p>
                    </div>
                  </div> */}
          </Paper>
          {/* ))} */}
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={11.9} className="center_center_column">

        
        <TableNoCheckBox
            data={trackerBody}
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            handlePageChange={handleChangePage}
            handleRowsPerPageChange={handleChangeRowsPerPage}
            handleRowClick={handleSmbCLick}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            serchText="Search SMB"
            titleText="SMB List"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default SMB;
