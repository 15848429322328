import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from "notistack";
import CustomText from "../../components/CustomText/CustomText";
import CustomButton from "../../components/CustomButton/CustomButton";
import { CreateAIService } from "../../services/CreateAIService";
import SimpleBackdrop from "../../components/common/SimpleBackdrop";
import "./CreateAI.scss";
import { getTableData } from "../../utils/stringProcessor";
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
const createAIService = new CreateAIService();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  padding: "8vh 30px 20px 30px",
};

const steps = ["Select the format of the file", "Select the file and upload"];

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      contrastText: 'white',
    },
  },
});

const MyStyledDropzoneContainer = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  border: '2px dashed #ccc',
  borderRadius: theme.spacing(2),
  backgroundColor: '#f3f3f3',
  color: '#333',
  height: '25vh',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

const MyStyledFileInput = styled('input')({
  display: 'none',
});

const MyStyledImage = styled('img')({
  width: 180,
  height: 140,
});

const MyStyledText = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

export default function DataUploadDrawer(props) {
  const {
    open,
    onClose,
    usecaseId,
    modelId,
    setImagePreviewUrl,
    isDataId,
    acceptedType,
  } = props;
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileTypeSelected, setFileTypeSelected] = useState("");
  const [openDialog, setOpenDialogBox] = useState(false);
  const [isClassName, setIsClassName] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const handleUploadData = (files) => {
    setUploadedFiles(files);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUploadData,
    accept: 'image/*, video/*',
    maxFileSize: 1000000000,
    maxFiles: 20,
  });
  const handleSelectFileType = (type) => {
    if (type === "IMAGE") {
      if (isClassName.length >= 3) {
        setFileTypeSelected(type);
        setCurrentStep(1);
      } else {
        enqueueSnackbar("please enter class Name with minimum 3 char!!", {
          variant: "warning",
        });
      }
    } else {
      setFileTypeSelected(type);
      setCurrentStep(1);
    }
  };

  const handleClose = () => {
    setOpenDialogBox(false);
  };

  const openDialogBox = () => {
    setOpenDialogBox(true);
  };

  const handleUploadClick = async () => {
    try {
      if (uploadedFiles.length === 0) {
        enqueueSnackbar("Please upload a  file", { variant: "warning" });
      }
      if (uploadedFiles.length > 0 && fileTypeSelected === "CSV") {
        setLoading(true);
        const files = uploadedFiles;
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i], files[i].name);
        }
        formData.append("usecase_id", usecaseId);
        formData.append("model_id", modelId);

        let res = await createAIService.uploadCSV(formData);
        if (res.status === 200) {
          enqueueSnackbar("File uploaded successfully", { variant: "success" });
          setUploadedFiles("");
          const { headers, rows } = getTableData(res?.data?.file_head);

          props.updateFileDetails(
            headers,
            rows,
            res.data?.data_id,
            res.data?.plots
          );
        } else {
          enqueueSnackbar("please upload a file", { variant: "warning" });
        }
        setLoading(false);
      }

      if (uploadedFiles.length > 0 && fileTypeSelected === "IMAGE") {
        const files = uploadedFiles;
        let formData = new FormData();
        const imageUrl = [];
        const imageData = [];
        const formatImage = [];
        imageData.push(isClassName);
        for (let i = 0; i < files.length; i++) {
          if (/^image\/(png|jpeg|jpg)$/.test(files[i].type)) {
            formatImage.push(files[i]);
          } else {
            enqueueSnackbar(
              "Please select a image file (PNG, JPEG, JPG) with min 5 Files",
              { variant: "warning" }
            );
          }
        }
        if (formatImage.length > 4) {
          for (let i = 0; i < formatImage.length; i++) {
            formData.append("files", formatImage[i], formatImage[i].name);
            imageUrl.push(formatImage[i]);
          }
          setLoading(true);
          formData.append("usecase_id", usecaseId);
          formData.append("model_id", modelId);
          formData.append("class_name", imageData);
          formData.append("data_id", isDataId);

          let res = await createAIService.uploadImage(formData);
          console.log("isDataId formData", isDataId);
          if (res.status === 200) {
            setImagePreviewUrl(imageUrl);
            enqueueSnackbar("Image uploaded successfully", {
              variant: "success",
            });
            setUploadedFiles("");
            props.updateImageDetails(setImagePreviewUrl, res.data?.data_id);
          }
        } else {
          enqueueSnackbar("Please upload minimum 5 Image files", {
            variant: "warning",
          });
        }
      }
    } catch (err) {
      console.log("Error in handleUploadClick", err);
      enqueueSnackbar("Error while uploading file", { variant: "error" });
      setLoading(false);
    }
  };

  const handleClassNameChange = (event) => {
    setIsClassName(event.target.value);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {loading && <SimpleBackdrop open={loading} />}
            <CloseIcon onClick={onClose} className="close-btn" />
            <Stepper activeStep={currentStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {currentStep === 0 && (
              <div>
                <CustomText
                  type="SemiBold_16px"
                  text="Select the Data Source"
                  styles={{ margin: "40px 0px 20px 30px" }}
                />
                {acceptedType.map(([item], index) => {
                  return item === "jpeg" || item === "jpg" || item === "png" ? (
                    <div className="file-format-container">
                      <div className="file-type-card">
                        <img
                          src="https://s3.amazonaws.com/smarttrak.co/images/images.jpeg"
                          width={72}
                          alt="images"
                        />
                        <CustomText
                          type="Regular_14px"
                          text="Images"
                          styles={{ marginTop: 15 }}
                        />
                        <div
                          className="upload-file-button"
                          onClick={openDialogBox}
                        >
                          <CustomText
                            type="Regular_12px"
                            text="Upload File +"
                            styles={{ color: "#FFF" }}
                          />
                        </div>
                        <Dialog onClose={handleClose} open={openDialog}>
                          <DialogTitle>Add Class Name</DialogTitle>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="isClassName"
                            label="Class Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={isClassName}
                            onChange={handleClassNameChange}
                          />
                          <br />
                          <div style={{ display: "flex" }}>
                            <Button
                              onClick={() => handleSelectFileType("IMAGE")}
                            >
                              Save
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                          </div>
                        </Dialog>
                      </div>
                    </div>
                  ) : (
                    <div className="file-format-container">
                      <div className="file-type-card">
                        <img src="https://s3.amazonaws.com/smarttrak.co/images/csv.jpeg" width={60} alt="csv" />
                        <CustomText
                          type="Regular_14px"
                          text="CSV Files"
                          styles={{ marginTop: 15 }}
                        />
                        <div
                          className="upload-file-button"
                          onClick={() => handleSelectFileType("CSV")}
                        >
                          <CustomText
                            type="Regular_12px"
                            text="Upload File +"
                            styles={{ color: "#FFF" }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* 
                  <div className="file-type-card">
                    <img src="https://s3.amazonaws.com/smarttrak.co/images/videos.jpeg" width={69} alt="videos" />
                    <CustomText
                      type="Regular_14px"
                      text="Videos"
                      styles={{ marginTop: 15 }}
                    />
                    <div
                      className="upload-file-button"
                      onClick={() => handleSelectFileType("VIDEOS")}
                    >
                      <CustomText
                        type="Regular_12px"
                        text="Upload File +"
                        styles={{ color: "#FFF" }}
                      />
                    </div>
                  </div> */}
              </div>
            )}
            {currentStep === 1 && (
              <div>
                <CustomText
                  type="SemiBold_16px"
                  text={
                    fileTypeSelected === "CSV"
                      ? "Upload CSV Files"
                      : "Upload Image Files"
                  }
                  styles={{ margin: "30px 20px 20px 0px" }}
                />
                <div className="dropzone-wrapper">
                  <section>
                    <div {...getRootProps()}>


                      <ThemeProvider theme={customTheme}>
                        <MyStyledDropzoneContainer>
                          <MyStyledFileInput {...getInputProps()} />
                          <MyStyledImage src="https://s3.amazonaws.com/smarttrak.co/images/cloud-computing.png" alt="fileupload" />
                          <MyStyledText>Click to browse</MyStyledText>
                        </MyStyledDropzoneContainer>
                      </ThemeProvider>
                    </div>
                  </section>
                </div>
                <div className="upload-data-button-wrapper">
                  <CustomButton
                    type="Simple"
                    text="Upload data"
                    styles={{ width: "12rem" }}
                    onClick={handleUploadClick}
                  />
                </div>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
