import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = "https://smarttrakportal.in/cosmic";
const apiHelper = new ApiHelper();

export class UserService {

  postSignup(data) {
    const uri = `${BASE_URL}/signup`;
    return apiHelper.post(uri, data);
  }
  postSignin(data) {
    const uri = `${BASE_URL}/login/smarttrak`;
    return apiHelper.post(uri, data);
  }
  googleLogin(data) {
    const uri = `${BASE_URL}/login/google`;
    return apiHelper.post(uri, data);
  }

  postAcceptInvite(data) {
    const uri = `${BASE_URL}/invite/accept`;
    return apiHelper.post(uri, data);
  }

  postInviteUser(data) {
  const uri = `${BASE_URL}/invite/new`;
    return apiHelper.post(uri, data);
 }

  postOnBoardPlant(data) {
    const uri = `${BASE_URL}/solar/plant/onboard`;
    return apiHelper.post(uri, data);
  }
  postOnBoardDevice(data) {
    const uri = `${BASE_URL}/solar/plant/onboard/device`;
    return apiHelper.post(uri, data);
  }
  postSolarData(data) {
    const uri = `${BASE_URL}/solar/device/add_data`;
    return apiHelper.post(uri, data);
  }
  getPlantsList(data) {
    const uri = `${BASE_URL}/solar/plant`;
    return apiHelper.get(uri, data);
  }
  postContactUs(data){
    const uri = `${BASE_URL}/contact_us`;
    return apiHelper.post(uri, data);
  }
  generateOtp(data){
    const uri = `${BASE_URL}/email_auth/generate_otp`;
    return apiHelper.post(uri, data);
  }
  changePassword(data){
    const uri = `${BASE_URL}/email_auth/change_password`;
    return apiHelper.post(uri, data);
  }
  verifyEmail(data){
    const uri = `${BASE_URL}/email_auth/verify`;
    return apiHelper.post(uri, data);
  }
  getUserDetails(data) {
    const uri = `${BASE_URL}/user`;
    return apiHelper.get(uri, data);
  }

}