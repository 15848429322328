import React, { useState, useEffect } from "react";
import { Drawer, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomText from "../../components/CustomText/CustomText";
import SimpleBackdrop from "../../components/common/SimpleBackdrop";
import { getCamelCaseLabel } from "../../utils/stringProcessor";
import { CreateAIService } from "../../services/CreateAIService";
import CloseIcon from '@mui/icons-material/Close';
import { Stepper, Step, StepLabel } from "@mui/material";
import CircularProgressWithLabel from "../Common/CircularProgressWthLabel";
import ConfusionMatrix from "../../components/confusion-matrix/ConfusionMatrix";
import BoxPlot from "../../components/Charts/BoxPlot";
import BarchartGraph from "../../components/Charts/BarchartGraph";
import Linechart from "../../components/Charts/Linechart";
const createAIService = new CreateAIService();

const YourModalDrawer = (props) => {
  const { data, activeStep } = props;
  const [loading, setLoading] = useState(false);
  const [parameters, setParameter] = useState([]);
  const hyperparameters = data?.training_config?.HyperParameters;
  const [isDeployActive, setIsDeployActive] = useState(false);
  const [lineGraphData, setLineGraphData] = useState({
    lineLossData: [],
    lineValData: [],
    showLineData: false,
  });
  const [ImageGraph, setImageGraph] = useState({
    geneData: "",
    showGene: false,
  });
  const [barGraphData, setBarGraphData] = useState({
    barDataValue: [],
    barLabels: [],
    showBarData: false,
  });
  const [confusionData, setConfusionData] = useState({
    matrix: [],
    matrixLabels: [],
    showConfusion: false,
  });
  const [evaluateData, setEvaluateData] = useState({
    accuracy: "0",
    loss: "0",
    showEvaluate: false,
  });
  const [boxPlotData, setBoxPlotData] = useState({
    boxPlotDataVal: [],
    showBoxPlot: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const progress = [
    "Starting",
    "Downloading",
    "Training",
    "Uploading",
    "Completed",
  ];
  useEffect(() => {
    const paramArr = Object.entries(hyperparameters).map(
      ([key, val], index) => ({
        id: index,
        key: key,
        label: getCamelCaseLabel(key),
        val: val,
        isNum: typeof val === "number" ? true : false,
      })
    );
    setParameter(paramArr);
  }, [hyperparameters]);

  const handleEvaluateModel = async () => {
    try {
      setLoading(true);
      const res = await createAIService.evaluateModel(data.job_id);
      setLoading(false);
      if (res.status === 200) {
        setEvaluateData({
          accuracy: res.data.accuracy ? res.data.accuracy : 0,
          loss: res.data.loss ? res.data.loss : 0,
          showEvaluate: true,
        });
        setConfusionData({
          matrix: res.data.cm ? res.data.cm.data : [],
          matrixLabels: res.data.cm ? res.data.cm.labels : [],
          showConfusion: res.data.cm ? true : false,
        });
        setBarGraphData({
          barDataValue: res.data.bar_plot ? res.data.bar_plot.data : [],
          barLabels: res.data.bar_plot ? res.data.bar_plot.labels : [],
          showBarData: res.data.bar_plot ? true : false,
        });
        setImageGraph({
          geneData: res.data.genes_graph ? res.data.genes_graph : " ",
          showGene: res.data.genes_graph ? true : false,
        });
        setBoxPlotData({
          boxPlotDataVal: res.data.box_plot ? res.data.box_plot?.data : [],
          showBoxPlot: res.data.box_plot ? true : false,
        });
        setLineGraphData({
          lineLossData: res.data.loss_data ? res.data.loss_data : [],
          lineValData: res.data.val_loss ? res.data.val_loss : [],
          showLineData: res.data.loss_data ? true : false,
        });
      }
    } catch (err) {
      console.log("Error in handleEvaluateModel", err);
      enqueueSnackbar(
        err.response?.data?.detail
          ? err.response?.data?.detail
          : "Error while evalating model",
        { variant: "error" }
      );
      setLoading(false);
    }
    setIsDeployActive(true);
  };

  const handleDeployModel = async () => {
    try {
      setLoading(true);
      const reqData = { job_id: data.job_id };
      const res = await createAIService.deployModel(reqData);
      if (res.status === 200) {
        enqueueSnackbar(
          `Model deployed successfully with job-id - ${data.job_id}`,
          { variant: "success" }
        );
      }
      setLoading(false);
    } catch (err) {
      console.log("Error in handleDeployModel", err);
      enqueueSnackbar("Error while deploying model", { variant: "error" });
      setLoading(false);
    }
  };

  const renderParameters = () => {
    let paramList = parameters.map((item) => {
      return (
        <Grid item md={3} className="parameter-container">
          <CustomText
            type="Regular_16px"
            text={`${item.label} : ${item.val}`}
            styles={{ marginTop: 10 }}
          />
        </Grid>
      );
    });
    return paramList;
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={true}
        onClose={() => props.onClose()}
        sx={{ "& .MuiDrawer-paper": { boxSizing: "border-box", width: "75%" } }}
      >
        <div>
          {data.job_status === "TRAINING_ONGOING" ||
          data.job_status === "TRAINING_FAILED" ? (
            <Stepper
              activeStep={props.activeStep}
              alternativeLabel
              orientation="horizontal"
              styles={{ marginTop: "20px" }}
            >
              {progress.map((label, index) => (
                <Step
                  key={index}
                  sx={
                    data.training_status.sec_status !== "Failed"
                      ? {
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "green",
                          },
                          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                            {
                              color: "grey.400",
                            },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "green",
                          },
                          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                            {
                              color: "green",
                            },
                          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                            {
                              fill: "black",
                            },
                        }
                      : {
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "red", // circle color (COMPLETED)
                          },
                          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                            {
                              color: "red", // Just text label (COMPLETED)
                            },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "red", // circle color (ACTIVE)
                          },
                          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                            {
                              color: "red", // Just text label (ACTIVE)
                            },
                          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                            {
                              fill: "black", // circle's number (ACTIVE)
                            },
                        }
                  }
                >
                  <StepLabel> {label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : null}
        </div>
        <div className="your-models-drawer-container">
          {loading && <SimpleBackdrop open={loading} />}

          <div
            className="row-aligned"
            style={{ justifyContent: "space-between" }}
          >
            <div className="column-aligned">
              <CloseIcon
                onClick={() => props.onClose()}
                className="close-btn2"
              />
              <CustomText
                type="Regular_12px"
                styles={{ color: "#5A5A5A" }}
                text="Status"
              />
              <CustomText
                type="SemiBold_12px"
                styles={{ color: "#5A5A5A", margin: "0px 0px 20px 0px" }}
                text={data?.job_status}
              />
            </div>

            <CustomButton
              type="Simple"
              text="Evaluate"
              styles={{
                width: "10rem",
                backgroundColor:
                  data.job_status === "TRAINING_COMPLETED"
                    ? "#1C629E"
                    : "#D9D9D9",
                color:
                  data.job_status === "TRAINING_COMPLETED"
                    ? "#FFFFFF"
                    : "#000000",
                marginLeft: 341,
                paddingTop: "17px",
                pointerEvents:
                  data.job_status === "TRAINING_COMPLETED" ? "auto" : "none",
              }}
              onClick={handleEvaluateModel}
            />
            <CustomButton
              type="Simple"
              disabled={isDeployActive}
              text="Deploy"
              styles={{
                width: "10rem",
                backgroundColor: isDeployActive ? "#1C629E" : "#D9D9D9",
                color: isDeployActive ? "#FFFFFF" : "#000000",
                marginLeft: 30,
                pointerEvents: isDeployActive ? "auto" : "none",
              }}
              onClick={handleDeployModel}
            />
          </div>

          <div className="column-aligned">
            <CustomText
              type="Regular_12px"
              text="Modal Type"
              styles={{ color: "#5A5A5A" }}
            />
            <CustomText
              type="Regular_!2px"
              text={data?.model_type}
              styles={{ color: "#5A5A5A" }}
            />
          </div>

          <div className="your-modals-hyper-parameter-container">
            <CustomText
              type="Reglar_12px"
              text="Hyper parameters"
              styles={{ color: "#5A5A5A" }}
            />
            <Grid container spacing={2}>
              {renderParameters()}
            </Grid>
          </div>
        </div>
        {evaluateData.showEvaluate && (
          <div className="evaluate-model-accuracy-container">
            <div className="left">
              <CustomText
                type="SemiBold_14px"
                text="Performance"
                styles={{ color: "#1C629E", margin: "0px 20px 0px 0px" }}
              />
              <CircularProgressWithLabel
                evalVal={evaluateData.accuracy * 100}
                lossVal={evaluateData.loss * 100}
              />
            </div>
            <div className="right">
              <CustomText
                type="Regular_13px"
                text="Your AI model performance"
                styles={{ color: "#1C629E" }}
              />
            </div>
          </div>
        )}

        <>
          <div className="graphs-container">
            {confusionData.showConfusion && (
              <div className="confusionGraph" style={{ width: "500px" }}>
                <CustomText
                  type="Regular_15px"
                  text={"Confusion Matrix"}
                  styles={{ textAlign: "center" }}
                />

                <ConfusionMatrix
                  data={confusionData.matrix}
                  labels={confusionData.matrixLabels}
                />
              </div>
            )}

            {barGraphData.showBarData && (
              <div className="confusionGraph" style={{ width: "500px" }}>
                <CustomText
                  type="Regular_15px"
                  text={"Bar Plot Graph"}
                  styles={{ textAlign: "center" }}
                />
                <BarchartGraph
                  data={barGraphData.barDataValue}
                  labels={barGraphData.barLabels}
                />
              </div>
            )}
          </div>
          {ImageGraph.showGene && (
            <div className="confusionGraph" style={{ width: "900px" }}>
              <CustomText
                type="Regular_15px"
                text={"Gene Graph"}
                styles={{ textAlign: "center" }}
              />
              <img
                width={850}
                height={350} alt="imageGraph not found"
                src={`data:image/jpeg;base64,${ImageGraph.geneData}`}
              />
            </div>
          )}
          {lineGraphData.showLineData && (
            <div className="confusionGraph" style={{ width: "750px" }}>
              <CustomText
                type="Regular_15px"
                text={"Line Graph"}
                styles={{ textAlign: "center" }}
              />
              <Linechart
                lossData={lineGraphData.lineLossData}
                valData={lineGraphData.lineValData}
              />
            </div>
          )}
          {boxPlotData.showBoxPlot && (
            <div className="confusionGraph" style={{ width: "750px" }}>
              <CustomText
                type="Regular_15px"
                text={"Box Plot Graph"}
                styles={{ textAlign: "center" }}
              />
              <BoxPlot plotData={boxPlotData.boxPlotDataVal} />
            </div>
          )}
        </>
      </Drawer>
    </div>
  );
};

export default YourModalDrawer;
