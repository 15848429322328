import React from 'react';

const ReportFailureIcon = (props) => {
  const {
    strokeColor = '#111111', 
    width = "24",
    height = "24",
    ...rest                
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={strokeColor}
      xmlns="http://www.w3.org/2000/svg"
      {...rest} 
    >
      <path
        d="M15.73 3H8.27L3 8.27V15.73L8.27 21H15.73L21 15.73V8.27L15.73 3ZM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5H14.9L19 9.1V14.9Z"
      />
      <path
        d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
      />
      <path
        d="M11 7H13V14H11V7Z"
      />
    </svg>
  );
};

export default ReportFailureIcon;
