import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useHistory } from "react-router-dom";
import "./SideBar.scss";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import DatasetIcon from "@mui/icons-material/Dataset";
import  Paper  from "@mui/material/Paper";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
const drawerWidth = 275;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const SideBar = (props) => {
  const [open, setOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("HOME");
  const history = useHistory();

  const handleClick = (tab) => {
    setActiveTab(tab);
    if (tab === "HOME") {
      history.push("/home");
    } else if (tab === "CREATE_AI") {
      history.push("/home");
    } else if (tab === "PREDICT_AI") {
      history.push("/home/predict-ai");
    } else if (tab === "YOUR_MODELS") {
      history.push("/home/your-models");
    } else if (tab === "PDB_MODELS") {
      history.push("/home/pdb");
    } else if (tab === "Protein_Analysis") {
      history.push("/home/protein-analysis");
    } else if (tab === "PDF_Chat") {
      history.push("/home/pdf-chat");
    }
  };

  const mainListItems = (
    <React.Fragment>
      <ListItemButton
        focusVisibleClassName="sidebar-selected"
        selected={activeTab === "HOME"}
        autoFocus={true}
        onClick={() => handleClick("HOME")}
        sx={{
          backgroundColor:
            activeTab === "HOME"
              ? "#1c629e  ! important"
              : "#daeeff ! important",
        }}
      >
        <ListItemIcon>
          {activeTab === "HOME" ? (
            <DashboardIcon sx={{ color: "white" }} />
          ) : (
            <DashboardIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Get Started"
          sx={{
            color:
              activeTab === "HOME" ? "white ! important" : "black ! important",
          }}
        />
      </ListItemButton>

      <ListItemButton
        focusVisibleClassName="sidebar-selected"
        selected={activeTab === "CREATE_AI"}
        onClick={() => handleClick("CREATE_AI")}
        sx={{
          backgroundColor:
            activeTab === "CREATE_AI"
              ? "#1c629e  ! important"
              : "#daeeff ! important",
        }}
      >
        <ListItemIcon>
          {activeTab === "CREATE_AI" ? (
            <CreateNewFolderIcon sx={{ color: "white" }} />
          ) : (
            <CreateNewFolderIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Create AI"
          sx={{
            color:
              activeTab === "CREATE_AI"
                ? "white ! important"
                : "black ! important",
          }}
        />
      </ListItemButton>
      <ListItemButton
        focusVisibleClassName="sidebar-selected"
        selected={activeTab === "PREDICT_AI"}
        onClick={() => handleClick("PREDICT_AI")}
        sx={{
          backgroundColor:
            activeTab === "PREDICT_AI"
              ? "#1c629e  ! important"
              : "#daeeff ! important",
        }}
      >
        <ListItemIcon>
          {activeTab === "PREDICT_AI" ? (
            <BatchPredictionIcon sx={{ color: "white" }} />
          ) : (
            <BatchPredictionIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Predict AI"
          sx={{
            color:
              activeTab === "PREDICT_AI"
                ? "white ! important"
                : "black ! important",
          }}
        />
      </ListItemButton>
      <ListItemButton
        focusVisibleClassName="sidebar-selected"
        selected={activeTab === "YOUR_MODELS"}
        onClick={() => handleClick("YOUR_MODELS")}
        sx={{
          backgroundColor:
            activeTab === "YOUR_MODELS"
              ? "#1c629e  ! important"
              : "#daeeff ! important",
        }}
      >
        <ListItemIcon>
          <img
            src="https://s3.amazonaws.com/smarttrak.co/images/fluent-mdl2_modeling-view.png"
            width={20}
            alt="model"
          />
        </ListItemIcon>
        <ListItemText
          primary="Your Models"
          sx={{
            color:
              activeTab === "YOUR_MODELS"
                ? "white ! important"
                : "black ! important",
          }}
        />
      </ListItemButton>
      <ListItemButton
        focusVisibleClassName="sidebar-selected"
        selected={activeTab === "PDB_MODELS"}
        onClick={() => handleClick("PDB_MODELS")}
        sx={{
          backgroundColor:
            activeTab === "PDB_MODELS"
              ? "#1c629e  ! important"
              : "#daeeff ! important",
        }}
      >
        <ListItemIcon>
          {activeTab === "PDB_MODELS" ? (
            <DatasetIcon sx={{ color: "white" }} />
          ) : (
            <DatasetIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary="PDB Models"
          sx={{
            color:
              activeTab === "PDB_MODELS"
                ? "white ! important"
                : "black ! important",
          }}
        />
      </ListItemButton>
      <ListItemButton
        focusVisibleClassName="sidebar-selected"
        selected={activeTab === "Protein_Analysis"}
        onClick={() => handleClick("Protein_Analysis")}
        sx={{
          backgroundColor:
            activeTab === "Protein_Analysis"
              ? "#1c629e  ! important"
              : "#daeeff ! important",
        }}
      >
        <ListItemIcon>
          {activeTab === "Protein_Analysis" ? (
            <img
              src="https://s3.amazonaws.com/smarttrak.co/images/mingcute_microscope-line.png"
              width={20}
              alt="model"
            />
          ) : (
            <img src="https://s3.amazonaws.com/smarttrak.co/images/proanalysis.png" width={20} alt="model" />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Protein Analysis"
          sx={{
            color:
              activeTab === "Protein_Analysis"
                ? "white ! important"
                : "black ! important",
          }}
        />
      </ListItemButton>

      <ListItemButton
        focusVisibleClassName="sidebar-selected"
        selected={activeTab === "PDF_Chat"}
        onClick={() => handleClick("PDF_Chat")}
        sx={{
          backgroundColor:
            activeTab === "PDF_Chat"
              ? "#1c629e  ! important"
              : "#daeeff ! important",
        }}
      >
        <ListItemIcon>
          {activeTab === "PDF_Chat" ? (
            <CreateNewFolderIcon sx={{ color: "white" }} />
          ) : (
            <CreateNewFolderIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary="PDF_Chat"
          sx={{
            color:
              activeTab === "PDF_Chat"
                ? "white ! important"
                : "black ! important",
          }}
        />
      </ListItemButton>
    </React.Fragment>
  );

  return (
    <Drawer
      variant="permanent"
      open={open}
      className="custom-drawer"
      sx={{
        backgroundColor: "#daeeff ! important",
      }}
    >
       <Paper
    sx={{
      backgroundColor: "#daeeff !important", height:"100vh ! important"
    }}
  >
      <Divider className="sidebar-divider" />
      <List component="nav">{mainListItems}</List>
      </Paper>
    </Drawer>
  );
};

export default SideBar;
