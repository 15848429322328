import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = "https://smarttrakportal.in/cosmic";
const apiHelper = new ApiHelper();

export class CosmicAlarmsService {
      
      getAlarms(plant_id, params) {
        const queryParams = new URLSearchParams(params).toString(); 
        const uri = `${BASE_URL}/solar/alarm?plant_id=${plant_id}&${queryParams}`;
        
        return apiHelper.get(uri);
      }
    getAdminStaff(plant_id) {
        const uri = `${BASE_URL}/admin/staff?plant_id=${plant_id}`;
        return apiHelper.get(uri);
      }
    postAssignAlarms(data) {
      const uri = `${BASE_URL}/solar/alarm/assign`;
      return apiHelper.post(uri, data);
      }
    postAcknowledgeAlarms(data) {
      const uri = `${BASE_URL}/solar/alarm/acknowledge`;
      return apiHelper.post(uri, data);
      }
    postResolveAlarms(data) {
      const uri = `${BASE_URL}/solar/alarm/resolved`;
      return apiHelper.post(uri, data);
      }
      //tracker
      getMonitorNodeId() {
        const uri = `${BASE_URL}/solar/tracker/nodes`;
        return apiHelper.get(uri);
      }
      getNodeCurrentStatus() {
        const uri = `${BASE_URL}/solar/tracker/node`;
        return apiHelper.get(uri);
      }
      getNodeSettings() {
        const uri = `${BASE_URL}/solar/tracker/node/settings`;
        return apiHelper.get(uri);
      }
      updateNodeSettings(res) {
        const uri = `${BASE_URL}/solar/tracker/update`;
        return apiHelper.put(uri,res);
      }
    }