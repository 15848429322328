import React from "react";

const types = {
  overflowStyle: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    height: "100%",
  },
};

const CommonText = (props) => {
  let { type, text, overflow, styles, className } = props;
  const breakpoints = {
    xxl: 2500,
    xl: 1600,
    lg: 1300,
    md: 992,
    sm: 768,
    xs: 576,
  };

  const screenWidth = window.innerWidth;
  var commonfontSize;
  if (screenWidth >= breakpoints.xxl ) {
    commonfontSize = "0.7vw";
  }
  else if (screenWidth < breakpoints.xxl && screenWidth >= breakpoints.xl ) {
    commonfontSize = "1vw";
  }
  else if (screenWidth < breakpoints.xl && screenWidth >= breakpoints.lg) {
    commonfontSize = "15px";
  }
  else if (screenWidth < breakpoints.lg && screenWidth >= breakpoints.md) {
    commonfontSize = "12px";
  }
  else if (screenWidth < breakpoints.md && screenWidth >= breakpoints.sm) {
    commonfontSize = "10px";
  }
  else if (screenWidth < breakpoints.sm && screenWidth >= breakpoints.xs) {
    commonfontSize = "17px";
  }
  else if (screenWidth < breakpoints.xs) {
    commonfontSize = "15px";
  }

  type = type.split("_");
  const textWeight = type[1];
  const textStyle = type[0];

  const predefinedStyles = {
    fontFamily: `Poppins-${textStyle}`,

    fontWeight: textWeight,
    fontSize: commonfontSize,
    color: "#000000",
  };
  return (
    <div className={className}
      title={overflow ? text : ""}
      style={
        overflow
          ? { ...predefinedStyles, ...types["overflowStyle"], ...styles }
          : { ...predefinedStyles, ...styles }
      }
    >
      {text}
    </div>
  );
};

export default CommonText;