import React from "react";
import { Grid, Paper } from "@mui/material";
import { RemoveUnderScore } from "../../IOT/Common/RemoveUnderScore";
import SimpleBackdrop from "../../LifeScience/components/common/SimpleBackdrop";

const MinMaxWeather = ({ weatherData,loader }) => {
  const unitMapping = {
    wind_speed: "km/hr",
    irradiance: "W/m²",
    ambient_temperature: "°C",
    module_temperature: "°C",
  };

  // This function generates the content for each metric
  const renderMetric = (key) => {
    const minValue = weatherData[`${key}_min`]?.value ? weatherData[`${key}_min`]?.value.toFixed(2) : "0";
    const minTimestamp =
      weatherData[`${key}_min`]?.timestamp?.replace(
        /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}).*/,
        "$1 ($2)"
      ) || "--";
    const maxValue = weatherData[`${key}_max`]?.value ? weatherData[`${key}_max`]?.value.toFixed(2) : "0";
    const maxTimestamp =
      weatherData[`${key}_max`]?.timestamp?.replace(
        /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}).*/,
        "$1 ($2)"
      ) || "--";
    const avgValue = weatherData[`${key}_avg`]?.value ? weatherData[`${key}_avg`]?.value.toFixed(2) : "0";
    const avgTimestamp =
      weatherData[`${key}_avg`]?.timestamp?.replace(
        /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}).*/,
        "$1 ($2)"
      ) || "--";

    return (
      <Grid
        item
        lg={2.93}
        xs={12}
        sm={12}
        md={5.9}
        className="center_minMax_grid"
        key={key}
      >
        <Paper className="center_minMax_paper">
          <p className="weatherHead">{RemoveUnderScore(key)}</p>
          {/* Min value */}
          <div className="minMaxStyle">
            <p className="Poppins_16px_green">{minTimestamp}</p>
            <p className="Poppins_18px_rem">
              Min: {minValue} {unitMapping[key]}
            </p>
          </div>
          {/* Max value */}
          <div className="minMaxStyle">
            <p className="Poppins_16px_green">{maxTimestamp}</p>
            <p className="Poppins_18px_rem">
              Max: {maxValue} {unitMapping[key]}
            </p>
          </div>
          {/* Avg value */}
          <div className="minMaxStyle">
            <p className="Poppins_16px_green">{avgTimestamp}</p>
            <p className="Poppins_18px_rem">
              Avg: {avgValue} {unitMapping[key]}
            </p>
          </div>
        </Paper>
        {loader && (
                <SimpleBackdrop open={loader} />
              )}
      </Grid>
    );
  };

  return (
    <Grid container className="grid-container">
      {["wind_speed", "irradiance", "ambient_temperature", "module_temperature"].map(renderMetric)}
    </Grid>
  );
};

export default MinMaxWeather;
