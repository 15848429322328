// MFMContext.js
import React, { createContext, useContext, useState } from 'react';

// Create a context
const MFMContext = createContext();

// Create a provider component
export const MFMProvider = ({ children }) => {
  const [mfmDeviceData, setMfmDeviceData] = useState({});
  const [mfmList, setMfmList] = useState([]);
  const [firstField, setFirstField] = useState("");
  const [firstFieldWMS, setFirstFieldWMS] = useState("");
  const [inverterArray, setInverterArray] = useState([]);

  return (
    <MFMContext.Provider value={{ mfmDeviceData, setMfmDeviceData, mfmList, setMfmList, firstField, setFirstField, firstFieldWMS, setFirstFieldWMS, inverterArray, setInverterArray }}>
      {children}
    </MFMContext.Provider>
  );
};

// Create a custom hook to use the MFMContext
export const useMFMContext = () => {
  return useContext(MFMContext);
};
