import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = 'https://smarttrakportal.in/api';
const apiHelper = new ApiHelper();

export class AuthService {
    login(data) {
        const uri = `${BASE_URL}/login/`;
        return apiHelper.post(uri, data);
    }

    signup(data) {
        const uri = `${BASE_URL}/signup/`;
        return apiHelper.post(uri, data);
    }

    verifyEmail(data) {
        const uri = `${BASE_URL}/verifyemail/`;
        return apiHelper.post(uri, data)
    }

    getUserData(data) {
        const uri = `${BASE_URL}/login/user`;
        return apiHelper.get(uri, data);
    }

    googleSignin(data) {
        const uri = `${BASE_URL}/login/signin`;
        return apiHelper.post(uri, data);
    }
    forgotPassReqOtp(data) {
        const uri = `${BASE_URL}/request/otp`;
        return apiHelper.post(uri, data);
    }

    verifyChangePassword(data) {
        const uri = `${BASE_URL}/request/change_password`;
        return apiHelper.post(uri, data);
    }
}