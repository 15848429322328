import React, { useState } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/system';

import { useSnackbar } from "notistack";
import { Document, Page } from "react-pdf";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import "./pdfchat.scss";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import SimpleBackdrop from "../../components/common/SimpleBackdrop";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import { pdfjs } from "react-pdf";
import { useHistory } from "react-router-dom";
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { ChatPdfService } from "../../services/ChatPdfService";
import CommonText from "../../../IOT/Common/CommonText";
import BoldCommonText from "../../../IOT/Common/BoldCommonText";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",

  import.meta.url
).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const chatPdfService = new ChatPdfService();

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      contrastText: 'black',
    },
  },
});

const MyStyledDropzoneContainer = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  border: '2px dashed #ccc',
  borderRadius: theme.spacing(2),
  backgroundColor: '#f3f3f3',
  color: '#333',
  height: '25vh',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

const MyStyledFileInput = styled('input')({
  display: 'none',
});

const MyStyledImage = styled('img')({
  width: 180,
  height: 140,
});

const MyStyledText = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  padding: "8vh 30px 20px 30px",
};
const styles = {
  chatWindow: {
    display: "flex",
    width: "40%",
    height: "95vh",
    minHeight: "95vh",
    minWidth: "40%",
    flexDirection: "column",
    maxHeight: "100vh",
    overflowY: "auto",
    border: "5px solid #ccc",
    padding: "10px",
    borderRadius: "5px",
  },
  userMessage: {
    alignSelf: "flex-end",
    background: "#DCF8C6",
    borderRadius: "12px 12px 2px 12px",
    marginBottom: "4px",
    wordBreak: "break-word",
    padding: "8px",
    textAlign: "right",
  },
  aiMessage: {
    alignSelf: "flex-start",
    background: "#F0F0F0",
    borderRadius: "12px 12px 12px 2px",
    marginBottom: "4px",
    wordBreak: "break-word",
    padding: "8px",
    textAlign: "left",
  },
};

const ChatPdf = () => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [sourceId, setSourceId] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState("");
  const [fileLength, setFileLength] = useState(0);
  const [userMessages, setUserMessages] = useState([
    { role: "ai", content: "How can I help you today?" },
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleInputChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setSelectedFile(newFiles);

    const newUrls = newFiles.map((file) => URL.createObjectURL(file));
    const lastUploadedUrl = newUrls[newUrls.length - 1];
    setPdfUrl(lastUploadedUrl);

    setCurrentPage(1);
    setFileLength(newFiles.length);

    const newFileNames = newFiles.map((file) => file.name);
    setSelectedFileName(newFileNames);
  };

  const handleAddIconClick = () => {
    setSelectedFileName("");
    setOpenModal(true);
  };
  const handleFileUpload = async () => {
    try {
      if (fileLength === 0) {
        enqueueSnackbar("Please upload a  file", { variant: "warning" });
      }
      if (fileLength > 0) {
        setLoading(true);
        const files = Array.from(selectedFile);
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("file", files[i]);
        }

        let res = await chatPdfService.uploadPdf(formData);
        if (res.status === 200) {
          enqueueSnackbar("File uploaded successfully", { variant: "success" });
          setShowPdf(true);
          setOpenModal(false);
          setLoading(false);
          setSelectedFileName("");
          setSourceId(res.data.data_id);
          setUserMessages([
            { role: "ai", content: "How can I help you today?" },
          ]);
        } else {
          enqueueSnackbar("please upload a file", { variant: "warning" });
        }
        setLoading(false);
      }
    } catch (err) {
      console.log("Error in handleUploadClick", err);
      enqueueSnackbar("Error while uploading file", { variant: "error" });
      setLoading(false);
    }
  };
  const handleNewChat = () => {
    history.push({
      pathname: "/home/pdf-chat/chat-gpt",
    });
  };
  const sendChatMessage = async () => {
    if (!sourceId) {
      console.log("Source ID is empty.");
      return;
    }

    setLoading(true);

    const newMessageStrings = [];

    let lastAiMessage;
    let lastUserMessage;

    for (let i = userMessages.length - 1; i >= 0; i--) {
      const message = userMessages[i];
      if (message.role === "user" && !lastUserMessage) {
        if (message.content !== undefined) {
          lastUserMessage = message.content;
        } else {
          lastUserMessage = " ";
        }
      } else if (message.role === "ai" && !lastAiMessage) {
        lastAiMessage = message.content;
      }

      if (lastAiMessage && lastUserMessage) {
        break;
      }
    }
    if (userMessages.length > 1) {
      newMessageStrings.push(`user: ${lastUserMessage}`);
      newMessageStrings.push(`ai: ${lastAiMessage}`);
      if (message) {
        newMessageStrings.push(`User: ${message}`);
        setMessage("");
      }
    } else {
      newMessageStrings.push(`ai: ${lastAiMessage}`);
      if (message) {
        newMessageStrings.push(`User: ${message}`);
        setMessage("");
      }
    }

    await chatPdfService
      .getPdfResponse(sourceId, newMessageStrings.join("\n"))
      .then((res) => {
        if (res.status === 200 && res?.data) {
          setUserMessages((prevUserMessages) => [
            ...prevUserMessages,
            { role: "user", content: message },
            { role: "ai", content: res.data.output },
          ]);
        } else if (res?.data?.length <= 0) {
          enqueueSnackbar("No data available,", {
            variant: "warning",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error:", err.message);
        console.log("Error in fetching  the resposnse", err);
        enqueueSnackbar(
          err.response?.data?.detail
            ? err.response?.data?.detail
            : "Error while fetching the response",
          { variant: "error" }
        );
        setLoading(false);
      });
  };

  return (
    <div className="pdfchat">
      {loading && <SimpleBackdrop open={loading} />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          style={{
            backgroundColor: "#111C2E",
            borderRadius: "8px",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "17px",
            marginTop: "18px",
            marginRight: "10px",
            width: "20%",
            padding: "4px 29px",
            textTransform: "none",
            marginLeft: "auto",
          }}
          onClick={handleNewChat}
        >
          Chat with AI
        </Button>

        <div className="chat-response">
          {!showPdf ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <BoldCommonText type="SemiBold_700" text="Welcome to ChatPdf" />
              <CommonText
                type="Regular_400"
                text="Welcome to SmartTrak ChatPdf! Our advanced AI-powered platform
              simplifies document scanning, enhancing text and images while
              focusing on error detection and correction. Experience
              effortless scanning, precise results, and increased productivity
              with SmartScan."
                styles={{
                  lineHeight: "23px",
                  fontStyle: "normal",
                  textAlign: "justify",
                  padding: "10px 210px",
                }}
              />

              <div className="fleximg">
                <p
                  className="pclass"
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                  }}
                >
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/images/tabler_file-text-ai.png"
                    style={{ marginRight: "10px" }}
                    width={20}
                    height={20}
                    alt="tabler_file-text-ai"
                  />
                  <CommonText
                    type="SemiBold_400"
                    text="Intelligent Text Enhancement"
                  />
                </p>

                <p
                  className="pclass"
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                  }}
                >
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/images/reloadError.png"
                    width={20}
                    height={20}
                    alt="reloadError"
                    style={{ marginRight: "10px" }}
                  />
                  <CommonText
                    type="SemiBold_400"
                    text="Error Detection and Correction"
                  />
                </p>
                <p
                  className="pclass"
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                  }}
                >
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/images/tabler_ux-circle.png"
                    width={20}
                    height={20}
                    style={{ marginRight: "10px" }}
                    alt="tabler_ux-circle"
                  />
                  <CommonText
                    type="SemiBold_400"
                    text="Seamless User Experience"
                  />
                </p>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 15px",
                width: "85vw",
              }}
            >
              <div style={{ width: "60vw", height: "95vh", overflowY: "auto" }}>
                <Document
                  file={pdfUrl}
                  onLoadSuccess={(pdf) => setNumPages(pdf.numPages)}
                >
                  <Page pageNumber={currentPage} renderMode="canvas" />
                </Document>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <p style={{ fontWeight: "600" }}>
                    Page {currentPage} of {numPages}
                  </p>
                  <button
                    onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                    disabled={currentPage <= 1}
                    style={{
                      marginLeft: "10px",
                      cursor: currentPage <= 1 ? "no-drop" : "pointer",
                      backgroundColor: currentPage <= 1 ? "gray" : "green",
                    }}
                  >
                    <SkipPreviousRoundedIcon
                      sx={{ color: "white", fontSize: "30px" }}
                    />
                  </button>
                  <button
                    onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                    disabled={currentPage >= numPages}
                    style={{
                      marginLeft: "10px",
                      cursor: currentPage >= numPages ? "no-drop" : "pointer",
                      backgroundColor:
                        currentPage >= numPages ? "gray" : "green",
                    }}
                  >
                    <SkipNextRoundedIcon
                      sx={{ color: "white", fontSize: "30px" }}
                    />
                  </button>
                </div>
              </div>

              <div style={styles.chatWindow}>
                {userMessages.map((message, index) => (
                  <div key={index}>
                    {message.role === "user" ? (
                      <div style={styles.userMessage}>
                        <p>{message.content}</p>
                      </div>
                    ) : (
                      <div style={styles.aiMessage}>
                        <p>{message.content}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={style} style={{ textAlign: "center" }}>
            {loading && <SimpleBackdrop open={loading} />}
            <CloseIcon
              onClick={() => setOpenModal(false)}
              className="close-btn"
            />

            {/* <label className={classes.dropzoneContainer}>
              <input
                type="file"
                accept=".pdf"
                onChange={handleInputChange}
                multiple
                className={classes.fileInput}
              />
              <img
                src="https://s3.amazonaws.com/smarttrak.co/images/cloud-computing.png"
                width={180}
                height={140}
                alt="fileupload"
              />
              <CommonText type="Regular_700" text="Click to browse" />
            </label> */}

            <ThemeProvider theme={customTheme}>
              <MyStyledDropzoneContainer>
                <MyStyledFileInput type="file" accept=".pdf" onChange={handleInputChange} multiple />
                <MyStyledImage src="https://s3.amazonaws.com/smarttrak.co/images/cloud-computing.png" alt="fileupload" />
                <MyStyledText>Click to browse</MyStyledText>
              </MyStyledDropzoneContainer>
            </ThemeProvider>


            {selectedFileName && (
              <CommonText
                type="Regular_400"
                text="Selected file: "
                styles={{
                  color: "black",
                  padding: "15px",
                  textAlign: "center",
                  display: "flex",
                }}
              />
            )}
            <div className="filename">
              {selectedFileName && (
                <CommonText
                  type="Regular_400"
                  text={selectedFileName}
                  styles={{
                    color: "black",
                    padding: "15px",
                    textAlign: "start",
                    wordBreak: "break-all",
                  }}
                />
              )}
            </div>

            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#1C629E",
                width: "41%",
                marginTop: "40px",
                marginRight: "10px",
                textTransform: "none",
              }}
              onClick={handleFileUpload}
            >
              Upload Data
            </Button>
          </Box>
        </Fade>
      </Modal>

      <div
        style={{
          display: "flex",
          gap: "20px",
          width: "80%",
          marginTop: "86px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "70%" }}>
          <TextField
            sx={{
              border: "2px solid white",
              borderRadius: "10px",
              backgroundColor: "white",
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                sendChatMessage(e.target.value);
                e.target.value = "";
              }
            }}
            placeholder={
              fileLength !== 0
                ? "How can I help you today?"
                : "Please Upload a file"
            }
            fullWidth
            value={message}
            onChange={handleMessageChange}
            InputProps={{
              startAdornment: (
                <IconButton
                  onClick={handleAddIconClick}
                  sx={{
                    backgroundColor: "#111C2E",
                    borderRadius: "10px",
                    marginRight: "10px",
                    "&:hover": {
                      backgroundColor: "#111C2E",
                    },
                  }}
                  color="primary"
                >
                  <AddIcon sx={{ color: "white" }} />
                </IconButton>
              ),
            }}
          />
        </div>
        <div
          style={{
            backgroundColor: "#f5f0f0",
            borderRadius: "10px",
            padding: "6px 24px",
            position: "static",
          }}
        >
          {message ? (
            <SendRoundedIcon
              onClick={sendChatMessage}
              style={{
                fontSize: "40px",
                color: "green",
                cursor: "pointer",
              }}
            />
          ) : (
            <SendRoundedIcon
              style={{
                fontSize: "40px",
                color: "red",
                cursor: "no-drop",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatPdf;
