import React, { useState, useEffect, useRef } from "react";
import "./Reports.scss";
import ModalCalender from "../../Common/ModalCalender";
import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import "react-dropdown/style.css";
import Select from "react-select";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import "./Report.css";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Button from "@mui/material/Button";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import { CosmicInverterService } from "../../Services/CosmicInverterService";
import "react-circular-progressbar/dist/styles.css";
import { MenuItem, FormControl } from "@mui/material";
import MultiSelectWithCheckboxes from "../../Common/MultiSelectWithCheckboxes";
import useErrorHandler from "../../../utils/errorHandler";
import { useMobile } from "../../Context/MobileContext/MobileContext";
import { Drawer } from "@mui/material";

const invService = new CosmicInverterService();

function Reports() {

  const [deviceTypeValue, setDeviceTypeValue] = useState([]); //device type option
  const [formErrors, setFormErrors] = useState({});

  const [deviceBoxOptions, setDeviceBoxOptions] = useState([]);
  const [parametersValue, setParametersValue] = useState([]);
  const [parametersOptions, setParametersOptions] = useState([]);

  const [isDeviceType, setIsDeviceType] = useState([]); //device type value
  const [resolutionTypeValue, setResolutionTypeValue] = useState([]);
  const [isResolutionType, setIsResolutionType] = useState("csv");

  const [isDropDownInvArray, setIsDropDownInvArray] = useState([]);
  const [isDeviceBox, setIsDeviceBox] = useState([]); //device box option
  const [isStartTime, setIsStartTime] = useState("");
  const [isStopTime, setIsStopTime] = useState("");
  const [gridHeight, setGridHeight] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDisable, setIsDisable] = useState(true);
  const [reportHistory, setReportHistory] = useState([]);

  const [loadingState, setLoadingState] = useState({
    deviceType: false,
    formatType: false,
    deviceBox: false,
    parameters: false,
    reportDownload: false,
    reportHistory: false,
    eachDownloadReport: false,

  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openHistoryDrawer, setOpenHistoryDrawer] = useState(false);
  const { isMobileAndTab } = useMobile();
  const gridRef = useRef(null);
 
  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day1 = String(currentDate.getDate()).padStart(2, "0");
  const defaultEndValue = `${year1}-${month1}-${day1}T${currentDate.getHours()}:${String(
    currentDate.getMinutes()
  )}:${String(currentDate.getSeconds()).padStart(2, "0")}Z`;

  const { enqueueSnackbar } = useSnackbar();
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];
  const handleFetchError = useErrorHandler();
  
  useEffect(() => {
    handleDeviceType();
    handleReportType();
    handleReportHistory();
  }, []);

  useEffect(() => {
    if (gridRef.current) {
      const height = gridRef.current.offsetHeight;
      setGridHeight(height);
    }
  }, [gridHeight, gridRef]);
 
  const validateForm = () => {
    const errors = {};

    if (isDeviceType.length === 0) {
      errors.deviceType = "Please select at least one device type.";
    }

    if (isDeviceBox.length === 0) {
      errors.deviceBox = "Please select at least one device box.";
    }

    if (parametersValue.length === 0) {
      errors.parameters = "Please select at least one parameter.";
    }

    return errors;
  };
 const handleHistoryClick = ()=>{
  setOpenHistoryDrawer(prev=>(!prev));
 }
  const handleHistoryDotsClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemId(id);
  };

  const downloadCSV = (payload) => {
    const { filename, download_url } = payload;
    const link = document.createElement("a");
    link.setAttribute("href", download_url);
    link.setAttribute("download", filename || "report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadEachReport = (payload) => {
    const { filename, download_url } = payload;
    const link = document.createElement("a");
    link.setAttribute("href", download_url);
    link.setAttribute("download", filename || "report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangeDevice = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.key.toLowerCase());
    setIsDeviceType(selectedValues);
    if(selectedOptions.length === 0){
      setIsDisable(true)
    }else{
      setIsDisable(false);
    }
    handleReportDeviceBox(selectedValues.join(","), id);
    handleReportParameters(selectedValues.join(","), id);
  };
  const handleChangeResolution = (resolution) => {
    setIsResolutionType(resolution.value);
  };
  const handleChangeDeviceBox = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.key);
    setIsDeviceBox(selectedValues);
  };

  const handleChangeParameters = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.key);
    setParametersValue(selectedValues);
  };

  const handleTimings = () => {
    setOpenDrawer(true);
  };

 
  const handleDeviceType = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, deviceType: true }));
      const res = await invService.getReportDeviceTypes();

      if (res.status === 200) {
        setDeviceTypeValue(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, deviceType: false }));

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, deviceType: false }));

      handleFetchError(error, "device types");
    }
  };
  const handleEachReportDownload = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, eachDownloadReport: true }));
      const res = await invService.getEachDownloadReport(id);

      if (res.status === 200) {
        downloadEachReport(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, eachDownloadReport: false }));

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, eachDownloadReport: false }));

      handleFetchError(error, "device types");
    }
  };
  const handleReportHistory = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, reportHistory: true }));
      const res = await invService.getReportHistory();

      if (res.status === 200) {
        setReportHistory(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, reportHistory: false }));

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, reportHistory: false }));

      handleFetchError(error, "report history");
    }
  };
  const handleReportsDownload = async (id, isStartTime, isStopTime) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, reportDownload: true }));

      const reqData = isDropDownInvArray.reduce((acc, device) => {
        if (isDeviceBox.includes(device.name)) {
          const existingDeviceIndex = acc.findIndex(
            (item) => item.device_type === device.type
          );
          if (existingDeviceIndex !== -1) {
            acc[existingDeviceIndex].device_id.push(device.id);
          } else {
            acc.push({
              device_type: device.type,
              device_id: [device.id],
              fields: [],
            });
          }
        }
        return acc;
      }, []);

      const parametersArray = parametersValue.map((param) => {
        const [deviceType, field] = param.split(":").map((str) => str.trim());
        return {
          device_type: deviceType.toLowerCase(),
          device_id: [], // Assuming this needs to be an empty array for now
          fields: [field], // Add the field to the fields array
        };
      });

      parametersArray.forEach((param) => {
        const existingDeviceIndex = reqData.findIndex(
          (item) => item.device_type === param.device_type
        );
        if (existingDeviceIndex !== -1) {
          reqData[existingDeviceIndex].fields.push(...param.fields);
        } else {
          reqData.push(param);
        }
      });

      const res = await invService.postReportsDownload(
        id,

        isStartTime,
        isStopTime,
        isResolutionType === "xlsx" ? true : false,
        reqData
      );
      if (res.status === 200) {
        const csvData = res.data;
        downloadCSV(csvData);
      }
      setLoadingState((prevState) => ({ ...prevState, reportDownload: false }));

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, reportDownload: false }));

      handleFetchError(error, " Report Download");
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      enqueueSnackbar("Please correct the errors in the form.", {
        variant: "error",
      });
    } else {
      setFormErrors({});
      handleReportsDownload(id, isStartTime, isStopTime);
    }
  };
  const handleReportType = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, formatType: true }));

      const res = await invService.reportType();
      if (res.status === 200) {
        setResolutionTypeValue(res.data.report_type);
      }
      setLoadingState((prevState) => ({ ...prevState, formatType: false }));

    
    }
    catch (error) {
      setLoadingState((prevState) => ({ ...prevState, formatType: false }));

      handleFetchError(error, "format  types");
    }
  };
  const handleReportDeviceBox = async (device_type, id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, deviceBox: true }));

      if (device_type.length === 0) {
        setDeviceBoxOptions([])
        enqueueSnackbar("Please choose the Device Type", {
          variant: "warning",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      setLoadingState((prevState) => ({ ...prevState, deviceBox: false }));

        return;
      }
      const res = await invService.getReportDeviceBox(device_type, id);
      if (res.status === 200) {
        const names = res.data.map((device) => device.name);
        setDeviceBoxOptions(names);
        setIsDropDownInvArray(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, deviceBox: false }));
      

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, deviceBox: false }));

      handleFetchError(error, "device Box");
    }
  };

  const handleReportParameters = async (device_type, id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, parameters: true }));

      const res = await invService.getParameterForReport(device_type, id);

      if (res.status === 200) {
        const options = res.data.flatMap((item) =>
          Object.entries(item).flatMap(([key, value]) =>
            value.map((field) => {
              const label = `${
                key.toUpperCase()
              } : ${field
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}`;
              const original = `${
                key.charAt(0).toUpperCase() + key.slice(1)
              }: ${field}`;
              return { label, original };
            })
          )
        );
        setParametersOptions(options);
      }
      setLoadingState((prevState) => ({ ...prevState, parameters: false }));

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, parameters: false }));

      handleFetchError(error, " parameters");
    }
  };
  const handleHistoryPopoverClose = (val) => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };
 
  return (
    <div className="home-container">
    
      <Grid className="grid-container" container sx={{height:"100% !important"}}>
        <Grid className="report-table" item lg={9} xs={12} sm={12} md={12}
              sx={{ position: "relative" }}
              >
          <Paper
            className="report-first-paper"
            
          >
            <Grid container className="report-sec-container">
              <Grid
                item
                lg={11.7}
                xs={12}
                sm={12}
                md={12}
                className="report-img-grid"
              >
                <Paper className="report-img-paper">
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/reportsData.svg"
                    alt="reports"
                    style={{ width: "16rem" }}
                  />{" "}
                  <p className="Poppins_24px_rem" style={{ color: "#2F67F8" }}>
                    Download your preferred report now.
                  </p>
                  <p className="Poppins_report-text media_full_width media_tab_width_80">
                    Select your report, customize your insights, and download
                    with ease – empowering informed decision-making at your
                    fingertips.
                  </p>
                </Paper>
              </Grid>
              <Grid
                item
                lg={5.6}
                xs={12}
                sm={12}
                md={5.8}
                className="report-img-grid"
              >
                <Paper className="report-sub-select">
                  <div className="flex-start-column media_width_80">
                    <p
                      className="Poppins_16px_rem"
                      style={{ color: "#000000", fontWeight: "400" }}
                    >
                      Device Type<span style={{ color: "red" }}> *</span>
                    </p>
                    <div className="media_full_width"
                      style={{
                        width: "24rem",
                        minWidth: "10rem",
                        cursor: "pointer",
                      }}
                    >
                      <FormControl
                        fullWidth
                        error={!!formErrors.deviceType}
                        className="form-report"
                      >
                        <MultiSelectWithCheckboxes
                          options={deviceTypeValue}
                          value={isDeviceType}
                          labeltrue={false}
                          onChange={handleChangeDevice}
                          placeholder="Choose the Device type"
                        />
                      </FormControl>
                    
                      {formErrors.deviceType && (
                        <div
                          style={{ marginLeft: "0rem" }}
                          className="error-message"
                        >
                          {formErrors.deviceType}
                        </div>
                      )}
                    </div>
                  </div>
                </Paper>
              
              </Grid>

              <Grid
                item
                lg={5.6}
                xs={12}
                sm={12}
                md={5.8}
                className="report-img-grid"
              >
                <Paper className="report-sub-select">
                  <div className="flex-start-column media_width_80">
                    <p
                      className="Poppins_16px_rem"
                      style={{ color: "#000000", fontWeight: "400" }}
                    >
                      Device Box
                    </p>

                    <div className="media_full_width" style={{ width: "24rem", minWidth: "10rem" }}>
                      <FormControl
                        fullWidth
                        error={!!formErrors.deviceBox}
                        className="form-report"
                      >
                        <MultiSelectWithCheckboxes
                          options={deviceBoxOptions}
                          value={isDeviceBox}
                          labeltrue={false}
                          isDisable={isDisable}
                          onChange={handleChangeDeviceBox}
                          placeholder="Choose the Device Box"
                        />
                      </FormControl>
                      {formErrors.deviceBox && (
                        <div
                          style={{ marginLeft: "0px" }}
                          className="error-message"
                        >
                          {formErrors.deviceBox}
                        </div>
                      )}
                    </div>
                  </div>
                </Paper>
              
              </Grid>
              <Grid
                item
                lg={5.6}
                xs={12}
                sm={12}
                md={5.8}
                className="report-img-grid"
              >
                <Paper className="report-sub-select">
                  <div className="flex-start-column media_width_80">
                    <p
                      className="Poppins_16px_rem"
                      style={{ color: "#000000", fontWeight: "400" }}
                    >
                      Parameters
                    </p>

                    <div className="media_full_width" style={{ width: "24rem", minWidth: "10rem" }}>
                      <FormControl
                       
                        fullWidth
                        error={!!formErrors.parameters}
                       
                        className="form-report"
                      >
                        <MultiSelectWithCheckboxes
                          options={parametersOptions}
                          value={parametersValue}
                          labeltrue={true}
                          isDisable={isDisable}
                          onChange={handleChangeParameters}
                          placeholder="Choose the Parameters"
                        />
                      </FormControl>
                      {formErrors.parameters && (
                        <div
                          style={{ marginLeft: "0rem" }}
                          className="error-message"
                        >
                          {formErrors.parameters}
                        </div>
                      )}
                    </div>
                  </div>
                </Paper>
              
              </Grid>
              <Grid
                item
                lg={5.6}
                xs={12}
                sm={12}
                md={5.8}
                className="report-img-grid"
              >
                <Paper className="report-sub-select">
                  <div className="flex-start-column media_width_80">
                    <p
                      className="Poppins_16px_rem"
                      style={{ color: "#000000", fontWeight: "400" }}
                    >
                      Format Type
                    </p>
                    <div className="media_full_width" style={{ width: "24rem", minWidth: "10rem" }}>
                     
                      <FormControl
                        className="form-report"
                        fullWidth
                        
                      >
                        <Select
                          options={resolutionTypeValue.map((field) => ({
                            value: field,
                            label: field,
                          }))}
                          isMulti={false}
                          onChange={handleChangeResolution}
                          value={{
                            value: isResolutionType,
                            label: isResolutionType,
                          }}
                          placeholder="Choose the file format"
                        />
                      </FormControl>
                    </div>
                  </div>
                </Paper>
               
              </Grid>

              
              <Grid
                item
                lg={5.6}
                xs={12}
                sm={12}
                md={5.8}
                className="report-img-grid"
              >
                <Paper className="report-sub-select">
                  <div className="flex-start-column media_width_80">
                    <p
                      className="Poppins_16px_rem"
                      style={{ color: "#000000", fontWeight: "400" }}
                    >
                      Date
                    </p>
                    <FormControl
                      className="border-class media_full_width"
                      sx={{ width: "24rem" }}
                    >
                      <div
                        style={{ minWidth: "10rem" }}
                        className="date-calender"
                      >
                        {isStartTime && isStopTime ? (
                          <p
                            className="Poppins_16px_rem"
                            style={{ color: "#000000", fontWeight: "400" }}
                          >{`start: ${isStartTime.split("T")[0]} | end: ${
                            isStopTime.split("T")[0]
                          }`}</p>
                        ) : (
                          <p
                            className="Poppins_16px_rem"
                            style={{ color: "#000000", fontWeight: "400" }}
                          >
                            {`${defaultEndValue.split("T")[0]}`}
                          </p>
                        )}
                        <Tooltip title="Calendar" arrow>
                          <CalendarMonthRoundedIcon
                            onClick={handleTimings}
                            sx={{
                              height: "fit-content",
                              cursor: "pointer",
                              color: "#00529A",
                            }}
                          />
                        </Tooltip>
                      </div>
                    </FormControl>
                  </div>
                  {openDrawer && (
                    <ModalCalender
                      openDrawer={openDrawer}
                      setOpenDrawer={setOpenDrawer}
                      handleStopDateChange={setIsStopTime}
                      handleStartDateChange={setIsStartTime}
                      singledaySelect={false}
                      selectedDateRangeVal={[
                        {
                          startDate: isStartTime ? isStartTime : new Date(),
                          endDate: isStopTime ? isStopTime : new Date(),
                          key: "selection",
                        },
                      ]}
                    />
                  )}{" "}
                </Paper>
              </Grid>
              <Grid
                item
                lg={5.6}
                xs={12}
                sm={12}
                md={5.8}
                className="report-img-grid"
              >
                <Paper className="report-sub-select">
                </Paper>
              </Grid>
              <Grid
                item
                lg={11.7}
                xs={12}
                sm={12}
                md={12}
                className="report-img-grid"
                sx={{paddingBottom:"1rem !important"}}
              >
                <Paper className="submit-paper" onClick={handleSubmit}>
                  <div className="report-btn-div">
                    <Button
                      className="report-btn-btn"
                      variant="contained"
                      sx={{ backgroundColor:
                           "#2F67F8 !important",
                      }}
                    >
                      {" "}
                      <img
                        src="https://s3.amazonaws.com/smarttrak.co/v2Images/downloadIcon.svg"
                        alt="downloadIcon"
                      />{" "}
                      Download
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            
          </Paper>
          {loadingState.deviceType && (
                <SimpleBackdrop open={loadingState.deviceType} />
              )}
          {loadingState.formatType && (
                <SimpleBackdrop open={loadingState.formatType} />
              )}
          {loadingState.reportDownload && (
                <SimpleBackdrop open={loadingState.reportDownload} />
              )}
            {loadingState.parameters && (
                <SimpleBackdrop open={loadingState.parameters} />
              )}
                {loadingState.deviceBox && (
                <SimpleBackdrop open={loadingState.deviceBox} />
              )}
                {loadingState.eachDownloadReport && (
                <SimpleBackdrop open={loadingState.eachDownloadReport} />
              )}
        </Grid>

       
        {isMobileAndTab && 
         <><div className="absolute_top_right_0" onClick={handleHistoryClick}>
              <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/menu.svg" />
             
            </div></>
          }
        {isMobileAndTab ? 
          <Drawer
          anchor="right"
          open={openHistoryDrawer}
          onClose={() => setOpenHistoryDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "85%",
              backgroundColor: "#ffff",
            },
          }}
        >
 <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="report-grid-history"
        >
          <Paper className="report-paper-history">
            <div className="report-history-title">
              <p className="Poppins_24px_rem" style={{ color: "#2F67F8" }}>
                Report History
              </p>
            </div>
            <div className="scrollingbar">
              {reportHistory.map((item, index) => {
                return (
                  <><div className="report-history-map " key={index} style={{position:"relative"}}>
                    <div className="report-img-divone">
                      <img
                        className="report-icon-img"
                        src="https://s3.amazonaws.com/smarttrak.co/v2Images/reportGenerate.svg"
                        alt="message" />
                        
                      <div className="flex-start-column"  id="scrollingHistory">
                      <p className="Poppins_16px_rem"

                          style={{ color: "black", fontWeight: "500" }}
                        >
                          {item?.file_name}{" "}
                        </p>
                        <p
                          className="Poppins_14px_rem"
                          style={{ color: "#00000080", fontStyle: "italic" }}
                        >
                          {item?.created_at.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
                            (_, year, month, day, hour, minute) => `${day} ${new Date(year, month - 1).toLocaleString('default', { month: 'short' })} ${year} ${hour}:${minute}`)}
                        </p>
                      </div>
                    </div>
                    <MoreHorizIcon onClick={(event) => handleHistoryDotsClick(event, item?.id)} />
                 
                    <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "left",
                      horizontal: "center",
                    }}
                    PaperProps={{ className: "customPopoverReport",
                  id:"reportdownload" }}
                  >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                        handleHistoryPopoverClose();
                        handleEachReportDownload(selectedItemId); 
                      }}
                        className="popover-menu"
                      >
                       <img width="20" height="20" src="https://img.icons8.com/material-rounded/20/download--v1.png" alt="download--v1"/>
                        <p className="Poppins_14px_regular">
                          Download Report
                        </p>
                      </MenuItem>
                    </Popover>
                  </div>
                  </>
              );
              })}
            </div>
          </Paper>
         
        </Grid>
        </Drawer>
        :
        <Grid
        item
        lg={2.9}
        xs={12}
        sm={12}
        md={12}
        className="report-grid-history"
      >
        <Paper className="report-paper-history">
          <div className="report-history-title">
            <p className="Poppins_24px_rem" style={{ color: "#2F67F8" }}>
              Report History
            </p>
          </div>
          <div className="scrollingbar">
            {reportHistory.map((item, index) => {
              return (
                <><div className="report-history-map " key={index} style={{position:"relative"}}>
                  <div className="report-img-divone">
                    <img
                      className="report-icon-img"
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/reportGenerate.svg"
                      alt="message" />
                      
                    <div className="flex-start-column"  id="scrollingHistory">
                    <p className="Poppins_16px_rem"

                        style={{ color: "black", fontWeight: "500" }}
                      >
                        {item?.file_name}{" "}
                      </p>
                      <p
                        className="Poppins_14px_rem"
                        style={{ color: "#00000080", fontStyle: "italic" }}
                      >
                        {item?.created_at.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}/,
                          (_, year, month, day, hour, minute) => `${day} ${new Date(year, month - 1).toLocaleString('default', { month: 'short' })} ${year} ${hour}:${minute}`)}
                      </p>
                    </div>
                  </div>
                  <MoreHorizIcon onClick={(event) => handleHistoryDotsClick(event, item?.id)} />
               
                  <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "left",
                    horizontal: "center",
                  }}
                  PaperProps={{ className: "customPopoverReport",
                id:"reportdownload" }}
                >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                      handleHistoryPopoverClose();
                      handleEachReportDownload(selectedItemId); 
                    }}
                      className="popover-menu"
                    >
                     <img width="20" height="20" src="https://img.icons8.com/material-rounded/20/download--v1.png" alt="download--v1"/>
                      <p className="Poppins_14px_regular">
                        Download Report
                      </p>
                    </MenuItem>
                  </Popover>
                </div>
                </>
            );
            })}
          </div>
        </Paper>
       
      </Grid>
        }
      </Grid>
    </div>
  );
}

export default Reports;
