import React from 'react';

const MonitoringIcon = (props) => {
  const {
    width = "24",            // Default width
    height = "24",           // Default height
    strokeColor = "black",           // Default fill
    ...rest                  // Additional props
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={strokeColor}
      {...rest} // Pass extra props to the SVG
    >
      <path
        d="M19.0714 9.6301V14.0745H1.92857V2.22269H6.92857V0.741211H1.92857C1.54969 0.741211 1.18633 0.897295 0.918419 1.17513C0.65051 1.45296 0.5 1.82978 0.5 2.22269V14.0745C0.5 14.4675 0.65051 14.8443 0.918419 15.1221C1.18633 15.3999 1.54969 15.556 1.92857 15.556H7.64286V18.519H4.78571V20.0005H16.2143V18.519H13.3571V15.556H19.0714C19.4503 15.556 19.8137 15.3999 20.0816 15.1221C20.3495 14.8443 20.5 14.4675 20.5 14.0745V9.6301H19.0714ZM11.9286 18.519H9.07143V15.556H11.9286V18.519Z"
      />
      <path
        d="M11.9277 11.1112H11.9206C11.7667 11.1095 11.6174 11.0563 11.495 10.9596C11.3726 10.8628 11.2836 10.7276 11.2413 10.5742L9.96059 5.92601H6.92773V4.44453H10.4992C10.6544 4.44444 10.8055 4.49681 10.9295 4.59371C11.0534 4.69061 11.1436 4.82677 11.1863 4.98156L11.9534 7.76675L14.1027 0.522304C14.1491 0.37197 14.24 0.240605 14.3624 0.146862C14.4849 0.053119 14.6327 0.00176169 14.7849 8.21154e-05C14.9354 -0.00226494 15.0826 0.0457567 15.2047 0.137024C15.3268 0.228291 15.4173 0.357943 15.4627 0.506749L16.7277 4.44453H20.4992V5.92601H16.2134C16.0635 5.92604 15.9173 5.87713 15.7956 5.78619C15.674 5.69525 15.583 5.5669 15.5356 5.41934L14.812 3.16749L12.6106 10.5882C12.5657 10.7397 12.4751 10.8722 12.3522 10.9663C12.2293 11.0604 12.0805 11.1112 11.9277 11.1112Z"
      />
    </svg>
  );
};

export default MonitoringIcon;
