import React, { useState, useEffect } from "react";
import { PredictAIService } from "../../services/PredictAIService";
import { useSnackbar } from "notistack";
import CustomText from "../../components/CustomText/CustomText";
import SimpleBackdrop from "../../components/common/SimpleBackdrop";
import "../PredictAI/PredictAI.scss";
import Container from "@mui/material/Container";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { Button } from "@mui/material";
const predictAIService = new PredictAIService();

const PredictAI = (props) => {
  const [modalData, setModalData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchAllDeployedModals();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleModelClick = (item) => {
    const dataObj = {
      job_id: item.job_id,
    };
    history.push({
      pathname: `/home/predict-upload`,
      state: dataObj,
    });
  };

  const fetchAllDeployedModals = async () => {
    setLoading(true);
    await predictAIService
      .getAllPredictModels()
      .then((res) => {
        if (res.status === 200 && res.data.length > 0) {
          setModalData(res.data);
        } else if (res.data.length <= 0) {
          enqueueSnackbar("No data available,", {
            variant: "warning",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error in fetching deployed Modals", err);
        enqueueSnackbar(
          err.response?.data?.detail
            ? err.response?.data?.detail
            : "Error while fetching deployed Modals",
          { variant: "error" }
        );
        setLoading(false);
      });
  };

  return (
    <div className="predict-container">
      {modalData.length > 0 ? (
        <>
          <div className="predict-searchbar">

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="search-box">
                <input className="semibold-smalltext" id="search-input"
                  onChange={handleSearch}
                  type="text"
                  placeholder="Search Case Studies"
                />
                <svg xmlns="http://www.w3.org/2000/svg" className="search-icon" viewBox="0 0 55 55" fill="none">
                  <rect width="55" height="55" rx="27.5" fill="#212121" />
                  <path d="M38.2307 37.3048L32.9222 31.9987C34.3449 30.3628 35.2092 28.2264 35.2092 25.8908C35.2092 20.7515 31.0291 16.5784 25.8968 16.5784C20.7574 16.5784 16.582 20.7584 16.582 25.8908C16.582 31.0232 20.7621 35.2032 25.8944 35.2032C28.2278 35.2032 30.3641 34.3412 32.0023 32.9162L37.3108 38.227C37.4359 38.3521 37.6051 38.4217 37.7719 38.4217C37.9364 38.4217 38.1056 38.3591 38.233 38.227C38.4809 37.9745 38.4809 37.5574 38.2307 37.3048ZM25.8898 33.901C21.4781 33.901 17.8842 30.3072 17.8842 25.8908C17.8842 21.4744 21.4781 17.8852 25.8898 17.8852C30.3062 17.8852 33.8954 21.479 33.8954 25.8908C33.8954 30.3025 30.3062 33.901 25.8898 33.901Z" fill="white" />
                </svg>
              </div>
            </div>
          </div>

          {loading && <SimpleBackdrop open={loading} />}

          <div className="model-container">
            {modalData
              .filter((item) =>
                item?.name.toLowerCase().includes(searchText.toLowerCase())
              )
              .map((item, index) => (
                <Card
                  style={{
                    marginBottom: "20px",
                    border: "2px",
                    borderColor: "gray",
                  }}
                  className="display-container"
                  key={index}
                >
                  <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                    <div className="content-one">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <img
                            className="content-img"
                            src={item.image_url}
                            srcSet={item.image_url}
                            alt={`Image is at ${index}`}
                            loading="lazy"
                          />
                        </div>
                        <div>
                          <CustomText type="Regular_14px" text={item.name} />
                        </div>
                      </div>

                      <div>
                        <CardActions disableSpacing>
                          <Button onClick={() => handleModelClick(item)}>
                            Predict AI
                          </Button>
                        </CardActions>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
          </div>
        </>
      ) : (
        <Container
          maxWidth="lg"
          style={{
            backgroundColor: "#DAEEFF",
            minHeight: "90%",
            overflow: "hidden",
            textAlign: "center",
          }}
        >
          <div className="nomodels">
            <h1>
              There is no Deployed models available !! Please Deploy the model.
            </h1>

            <img src={"https://s3.amazonaws.com/smarttrak.co/images/NoDeployedModels.png"} alt="model" width={300} />
          </div>
        </Container>
      )}
    </div>
  );
};

export default PredictAI;
