import React, { useState, useEffect, useRef, useMemo } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Divider from "@mui/material/Divider";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Tooltip } from "@mui/material";
import ModalCalender from "../Common/ModalCalender";
import "./BarPlotForIOT.scss";

const MarkerBarPlot = (props) => {
  const { isValueData, titleText, averageRatio, selectedDateRangeProp,
    isMobileAndTab, isDesktopView,isFullScreen, toggleFullScreen, isOnlyMobile
   } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const scrollableContainerRef = useRef(null);

  const xaxisLabels = useMemo(() => {
    return isValueData
      ? isValueData.map((item) => {
          const date = new Date(item.timestamp_edge);
          const month = date.toLocaleString("default", { month: "short" });
          const day = date.getDate();
          return `${day} ${month}`;
        })
      : [];
  }, [isValueData]);

  const yaxisData = useMemo(() => {
    return isValueData ? isValueData.map((item) => item.value) : [];
  }, [isValueData]);

  const firstValue = 0;
  const maxValue = Math.max(...yaxisData);

  const calculateMaxValue = (value) => {
    if (value <= 25) return 25;
    if (value <= 50) return 50;
    if (value <= 75) return 75;
    if (value <= 100) return 100;
    if (value <= 150) return 150;
    if (value <= 200) return 200;
    return 0;
  };

  const maxCalculatedValue = calculateMaxValue(maxValue.toFixed(2));

  const chartData = useMemo(
    () => ({
      series: [
        {
          name: "Actual",
          data: yaxisData.map((value, index) => ({
            x: xaxisLabels,
            y: value,
            goals: [
              {
                name: "Expected",
                value: averageRatio,
                strokeHeight: 3,
                strokeColor: "#b11515",
              },
            ],
          })),
        },
      ],
      options: {
        chart: { type: "bar" },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
          },
        },
        xaxis: {
          categories: xaxisLabels,
          labels: {
            style: {
              colors: "#000000",
            },
            rotate: 0,
          },
        },
        yaxis: {
          min: 0,
          max: maxCalculatedValue,
          labels: {
            style: {
              colors: "#000000",
            },
            formatter: (val) => {
              if (val === firstValue) return "0%";
              if (val === maxCalculatedValue) return `${maxCalculatedValue}%`;
              return " ";
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
          style: {
            colors: ["#b11515"],
            fontSize: "18px",
            textAlign: "center",
          },
          formatter: (val) => (val !== NaN && val < averageRatio ? "!" : " "),
          offsetX: 0,
        },
        colors: ["#90E900"],
        legend: {
          show: false,
          showForSingleSeries: true,
          customLegendItems: ["Actual", "Expected"],
          markers: {
            fillColors: ["#90E900", "#b11515"],
          },
        },
        grid: {
          show: true,
          borderColor: "#BDBDBD",
          strokeDashArray: 5,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box">' +
              '<span class="raleway_12px_rem"><span style="color: #2F67F8;">Date:</span> ' +
              w.config.xaxis.categories[dataPointIndex] +
              "</span><br>" +
              '<span class="raleway_12px_rem"><span style="color: #2F67F8;">' +
              "Actual Value" +
              ":</span> " +
              parseFloat(series[seriesIndex][dataPointIndex]).toFixed(2) +
              " %" +
              "</span><br>" +
              (averageRatio !== undefined &&
              averageRatio !== null &&
              !isNaN(averageRatio)
                ? '<span class="raleway_12px_rem"><span style="color: #b11515;">' +
                  "Expected Value" +
                  ":</span> " +
                  averageRatio.toFixed(2) +
                  " %" +
                  "</span><br>"
                : "") +
              "</div>"
            );
          },
        },
        toolbar: {
          show: false,
        },
      },
    }),
    [xaxisLabels, yaxisData, averageRatio, maxCalculatedValue, isFullScreen]
  );


useEffect(()=>{
  if (scrollableContainerRef.current) {
    scrollableContainerRef.current.scrollLeft = scrollableContainerRef.current.scrollWidth;
  }
},[isValueData,isFullScreen,xaxisLabels])
 
  const chartContainerRef = useRef(null);
  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen(); // Exit full-screen when user exits
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);

  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);
    const svg64 = btoa(xml);
    const imgSrc = `data:image/svg+xml;base64,${svg64}`;
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);
  };

  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);

    props.openDrawerValue(openDrawer);
  };

  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);

    props.openDrawerValue(openDrawer);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p
            className="Poppins_18px_rem"
            style={{ color: "#212121" }}
          >
            {titleText}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Download" arrow>
            <DownloadIcon
              onClick={downloadChart}
              className='icon-css'
            />
          </Tooltip>

          <Tooltip title="Calendar" arrow>
            <CalendarMonthRoundedIcon
              onClick={handleTimings}
             className='icon-css'
            />
          </Tooltip>
          <Tooltip title="FullScreen" arrow>
            <button
              onClick={toggleFullScreen}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              {isFullScreen ? (
                <FullscreenExitIcon
                  className='icon-css'
                />
              ) : (
                <FullscreenIcon
                 className='icon-css'
                />
              )}
            </button>
          </Tooltip>
        </div>
      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />
      {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
        />
      )}
      <div
        style={{
          // height: isValueData.length === 0 ? "350px" : "90%",
          // width: "100%",
          // padding: "1vw",
          height: (isOnlyMobile && isFullScreen) ? "100vw" : isValueData.length === 0 ? "350px" : "90%",
          width: (isOnlyMobile && isFullScreen) ? "84vh" : "100%",
          padding: "1vw",
          transform: (isOnlyMobile && isFullScreen) ? "rotate(90deg)" : "rotate(0deg)",
          marginTop: (isOnlyMobile && isFullScreen) ? "6rem" : "0rem",
          marginLeft: (isOnlyMobile && isFullScreen) ? "-7rem" : "0rem"
        }}
        ref={chartContainerRef}
        id="barplotforIotchart"
      >
        {isValueData && isValueData.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "350px",
              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
          <div ref={scrollableContainerRef} className="scrollable_chat-container">
        <div style={{ width: xaxisLabels.length > 20 ? xaxisLabels.length * 50 : '100%' }}>
          <Chart options={chartData.options} series={chartData.series} type="bar" height={(isFullScreen && isMobileAndTab) ? "300" : (isFullScreen && isDesktopView) ? "500" : "350"} />
          </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarkerBarPlot;
