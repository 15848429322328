import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import Chatbot from "../../Common/Chatbot";
import Box from "@mui/material/Box";


const boxStyle = {
  position: "absolute",
  zIndex: "1",
  top: "50%",
  left: "56%",
  transform: "translate(-50%, -50%)",
  width: "82%",
  height: "83vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  padding: "0px",
  backgroundColor: "white",
  color: "white",
  borderRadius: "30px",
};
function AlramSurge() {
  const [alramData,setAlramData]=useState({})
  const [openDrawer, setOpenDrawer] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { row } = location.state;
      setAlramData(row)
      console.log(row);
     
    }
  }, [location.state]);
  const handleChatBot = () => {
    setOpenDrawer(true);
  };
  const historyList = [
    {
        imageIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg",
        title: "Nishchitha"
    },
    {
        imageIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg",
        title: "Namrata"
    },
    {
        imageIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg",
        title: "Nawal"
    },
    {
        imageIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg",
        title: "Deepak"
    },
    {
        imageIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg",
        title: "Sumit"
    },
    {
        imageIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg",
        title: "Achut"
    },
    {
        imageIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg",
        title: "bhagwan"
    },
    {
        imageIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg",
        title: "BhanuTeja"
    },
]
  return (
    <div className="home-container">
      <Grid
        container className="main-grid-surge"
        
      >
        <Grid item lg={11.9} xs={12} sm={12} md={11.9} className="surges-grid">
          <Paper className="second_paper">
            <p className="surge-text ">{alramData?alramData.alarm_type:"--"}</p>
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.9}
          xs={12}
          sm={12}
          md={11.9}
          container
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: "0rem ! important",
            marginLeft: "0rem ! important",
            gap: "1rem",
          }}
        >
          <Grid
            item
            lg={11.9}
            xs={12}
            sm={12}
            md={11.9}
            className="transparent-grid"
          >
            <Paper className="second_paper">
              <p className="Poppins_24px_rem" style={{ color: "#1B2128" }}>
                Alarm Details
              </p>
            </Paper>
          </Grid>
          <Grid
            item
            lg={5.8}
            xs={12}
            sm={12}
            md={11.9}
            className="transparent-grid"
          >
            <Paper className="severity-paper">
              <div className="severity-div">
                <p className="Poppins_14px_rem">Device Type</p>
                <p></p>
              </div>

              <div className="severity-div">
                <p className="Poppins_14px_rem">OEM Severity</p>
                <p></p>
              </div>
              <div className="severity-div">
                <p className="Poppins_14px_rem">Alarm Type</p>
                <p></p>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            lg={5.8}
            xs={12}
            sm={12}
            md={11.9}
            className="transparent-grid"
          >
            <Paper className="severity-paper">
              <div className="severity-div">
                <p className="Poppins_secondText" >Inverter</p>
                <p></p>
              </div>

              <div className="severity-div-high">
                <p className="high-p" >{alramData?alramData.alarm_severity:"--"}</p>
                
                <p></p>
              </div>
              <div className="severity-div">
                <p className="Poppins_secondText">{alramData?alramData.alarm_type:"--"}</p>
                <p></p>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          item
          lg={5.9}
          xs={12}
          sm={12}
          md={11.9}
          container
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "0rem ! important",
            marginLeft: "0rem ! important",
            gap: "1rem",
          }}
        >
          <Grid
            item
            lg={11.9}
            xs={12}
            sm={12}
            md={11.9}
            className="transparent-grid"
          >
            <Paper className="second_paper" sx={{flexDirection:"column"}}>
              <p className="Poppins_24px_rem" style={{ color: "#1B2128" }}>
              Acknowledgment Details
              </p>
              <p className="acknowedgeGrayText">Time to acknowledge : 2024 - 03 - 06, 04:42</p>
            </Paper>
          </Grid>
          <Grid
            item
            lg={5.8}
            xs={12}
            sm={12}
            md={11.9}
            className="transparent-grid"
          >
            <Paper className="aknowedlge-paper">
              <div className="div-acknowedge">
              <p className="acknowedgeGrayHead">Acknowledge at</p>
              <p className="acknowedgeGrayText">{alramData?.assigned_timestamp ? `${alramData.assigned_timestamp.slice(0, 10)} ${alramData.assigned_timestamp.slice(11, 16)}` : "--"}
</p></div>
              <div>
              <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/clock.svg" alt="chatbot" />{" "}

              </div>

            </Paper>
          </Grid>
          <Grid
            item
            lg={5.8}
            xs={12}
            sm={12}
            md={11.9}
            className="transparent-grid"
          >
            <Paper className="aknowedlge-paper">
              <div className="div-acknowedge">
              <p className="acknowedgeGrayHead">Acknowledge By</p>
              <p className="acknowedgeGrayText">{alramData?alramData.assigned_by_name:"--"}</p></div>
              <div>
              <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/iconuser.svg" alt="userprofile" />{" "}

              </div>

            </Paper>
          </Grid>

        </Grid>
        <Grid
            item
            lg={11.9}
            xs={12}
            sm={12}
            md={11.9}
            className="transparent-grid"
          >
            <Paper className="second_paper" sx={{justifyContent:"flex-end"}}>
            <div className="chatbot-paper"
              
            >
              <div
                onClick={handleChatBot} className="center_div"
                
              >
                <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/chatbot-icon.svg" alt="chatbot" />{" "}
                <p className="table-headText" style={{ color: "#2F67F8" }}>
                  INSIGHT IQ
                </p>
              </div>
              
            </div>
            
            </Paper>
          </Grid>
      </Grid>
      {openDrawer && (
        <Box sx={boxStyle}>
          <Chatbot handleClose={setOpenDrawer} historyList={historyList}/>
        </Box>
      )}
    </div>
  );
}

export default AlramSurge;
