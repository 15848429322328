import React, { useEffect, useRef, useState } from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function RemoteProductsPage() {
  const proCards = [
    {
      img: "https://s3.amazonaws.com/smarttrak.co/newProductPage/fourthImgOne.svg",
      title: "Virtual Control Room",
      description:
        "Offers a centralized interface for remote management, providing real-time data on solar farm performance. Access key metrics such as current power generation, total energy output, and detailed forecasts from any location. This comprehensive dashboard ensures flexible, effective monitoring and control of your solar operations.",
    },
    {
      img: "https://s3.amazonaws.com/smarttrak.co/newProductPage/FourthImgTwo.svg",
      title: "LLM Agent Integration",
      description:
        "Features a natural language interface for seamless audio and text interactions, offering real-time data in an intuitive format. Utilizes multiple specialized LLM agents, each with a distinct role, to enhance reliability and provide streamlined data in text, audio, or graphical formats. Enables sophisticated, AI-driven analysis and efficient scenario handling, ensuring users receive accurate insights and recommendations effortlessly.",
    },
    {
      img: "https://s3.amazonaws.com/smarttrak.co/newProductPage/FourthImgThree.svg",
      title: "Dynamic Adjustments & Performance Optimization",
      description:
        "Utilizes real-time data to refine panel angles and inverter settings for peak solar farm performance. By integrating weather insights, the system ensures precise adjustments and maximizes energy production and efficiency. This continuous optimization guarantees that your solar operations remain at their highest potential.",
    },
  ];

  const keyPoints = [
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/fourthKey1.svg",
      title: "Seamless Control",
      description:
        "Real-time insights and management for effortless solar operations.",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/fourthKey2.svg",
      title: "Peak Efficiency",
      description:
        "Dynamic adjustments optimize performance based on live data.",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newProductPage/fourthKey3.svg",
      title: "Continuous Optimization",
      description:
        "Ensures maximum energy production and operational effectiveness.",
    },
  ];
  const [activeSol, setActiveSol] = useState(0);
  const [autoSlide, setAutoSlide] = useState(true);
  const timeoutRef = useRef(null);
  const overControls = useAnimation();
  const whatControls = useAnimation();
  const craftControls = useAnimation();
  const resControls = useAnimation();

  const [overRef, inViewOver] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [whatRef, inViewWhat] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [craftRef, inViewCraft] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });
  useEffect(() => {
    overControls.start({
      opacity: inViewOver ? 1 : 0,
      y: inViewOver ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [overControls, inViewOver]);
  useEffect(() => {
    whatControls.start({
      opacity: inViewWhat ? 1 : 0,
      y: inViewWhat ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [whatControls, inViewWhat]);
  useEffect(() => {
    craftControls.start({
      opacity: inViewCraft ? 1 : 0,
      y: inViewCraft ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [craftControls, inViewCraft]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (autoSlide) {
        handleSolution(activeSol + 1);
      }
    }, 7000);

    return () => clearInterval(intervalId);
  }, [activeSol, autoSlide]);

  const isMobile = window.innerWidth <= 992;
  const isMobileOne = window.innerWidth <= 900;

  const handleHover = (hover) => {
    if (hover) {
      setAutoSlide(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else {
      timeoutRef.current = setTimeout(() => {
        setAutoSlide(true);
      }, 1000);
    }
  };
  const handleSolution = (ind) => {
    setActiveSol(ind >= proCards.length ? 0 : ind);
  };
  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main text_cen"
        id="our_sol"
      >
        <p className="Raleway_64px" id="our_sol">
          Remote Management
        </p>
        <p className="Raleway_31px_dark opacity_8 c_black ">
          Transforms remote surveillance with real-time insights and dynamic
          control, ensuring highly optimized and effortlessly managed solar
          operations.
        </p>
        <div className="product_slide">
          <img
            className="powerOptix_img"
            src="https://s3.amazonaws.com/smarttrak.co/newProductPage/slideFour.jpg"
            alt="banner"
          />
        </div>
        <motion.div
          className="expert_div flex_col gap_1vw"
          ref={overRef}
          initial={{ opacity: 0, y: 50 }}
          animate={overControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600">Operational Overview</p>
          <p
            id="expertise-description"
            className="Raleway_26px_500 opacity_7 text_cen"
          >
            Enables real-time monitoring of solar panels, inverters, and
            trackers with instant fault alerts. Advanced analytics track
            revenue, energy output, and efficiency. AI-driven reporting and
            direct control offer optimal performance and flexibility.
          </p>
        </motion.div>
        <motion.div
          className="infoImg expert_div"
          ref={whatRef}
          initial={{ opacity: 0, y: 50 }}
          animate={whatControls}
          transition={{ duration: 0.5 }}
        >
          <motion.img
            src="https://s3.amazonaws.com/smarttrak.co/newHomePage/InfoGraphFour.png"
            alt="prod"
            className="infoGraph"
            initial={{ opacity: 0, y: 50 }}
            animate={whatControls}
            transition={{ duration: 0.5 }}
          />
        </motion.div>
        <motion.div
          className="cards_container expert_div"
          ref={craftRef}
          initial={{ opacity: 0, y: 50 }}
          animate={craftControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600">Key Attributes</p>
          {proCards.map((card, index) => {
            return (
              activeSol === index && (
                <div key={index} className="pro-details">
                  <div className="expert_div img_power">
                    <img
                      src={card.img}
                      alt="product"
                      className="expertise_image"
                    />
                  </div>
                  <motion.div
                    className="expert_div gap_1vw flex_col card_power"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{ duration: 0.7, ease: "easeInOut" }}
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                  >
                    <p
                      className="Raleway_40px c_dark"
                      style={{
                        height: isMobile ? "auto" : "5vw",
                      }}
                    >
                      {card.title}
                    </p>
                    <div
                      className="expert_div flex_col gap_1vw"
                      id="text_just"
                      style={{
                        height: isMobile ? "auto" : "16vw",
                      }}
                    >
                      <p className="Raleway_25px">{card.description}</p>
                    </div>
                    <div className="dots_container expert_div">
                      {proCards.map((folder, i) => (
                        <span
                          key={i}
                          className={`dot ${activeSol === i ? "active" : ""}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSolution(i);
                          }}
                        ></span>
                      ))}
                    </div>
                  </motion.div>
                </div>
              )
            );
          })}
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw text_cen div_width core_top"
          ref={resRef}
          initial={{ opacity: 0, y: 20 }}
          animate={resControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600">Core Benefits</p>
          <Grid container className="key_point_grid">
            {keyPoints.map((item, index) => (
              <Grid
                item
                key={index}
                lg={3.8}
                xs={12}
                sm={8}
                md={3.5}
                className="expert_div gap_1vw"
                style={{ position: "relative" }}
              >
                <Paper className="key_cards">
                  <img src={item.keyImage} alt="keyImage" />
                  <div
                    className="expert_div gap_1vw flex_col text_cen
                "
                  >
                    <p className="Raleway_23px_700">{item.title}</p>
                    <p
                      className="Raleway_25px font_500 opacity_8"
                      style={{ height: isMobileOne ? "auto" : "6vw" }}
                    >
                      {item.description}
                    </p>
                  </div>
                </Paper>
                {!isMobileOne && (index === 0 || index === 1) && (
                  <div className="svg_ben">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="15.5"
                      viewBox="0 0 210 31"
                      fill="none"
                    >
                      <path
                        d="M2.35547 20.2998C2.35547 20.2998 45.5353 43.6132 91.0837 13.4379C136.632 -16.7373 207.795 20.2998 207.795 20.2998"
                        stroke="#00529A"
                        strokeWidth="2.7392"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="5.48 10.96"
                      />
                    </svg>
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </div>

      <Footer />
    </div>
  );
}

export default RemoteProductsPage;
