export const getCamelCaseLabel = (val) => {
    return val.split("_").map((item) => item[0].toUpperCase() + item.slice(1)).join(" ")
}

export const getTableData = (data)=>{
    const headers = Object.keys(data)
    let rows = []
    for(let i=0; i<5; i++){
        let tempRow = {}
        headers.forEach(item =>{
            tempRow[item] = data[item][i]
        })
        rows.push(tempRow)
    }

    return {headers, rows}
}