import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CaseStudy.scss";
import Divider from '@mui/material/Divider';
import SlideData from "../SlideData";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import Footer from "../MainLandingPage/Footer";
import { useParams } from "react-router-dom";

function Casestudy() {

  const { index } = useParams();
  const caseStudyIndex = parseInt(index);
  const selectedCaseStudy = SlideData[caseStudyIndex];
  const history = useHistory();
  const containerRef = useRef(null);
  const caseStudies = [
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyOne.jpg",
      title:
        "Solar Power Plant - Predictive Energy Generation Using Deep Learning",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyTwo.jpg",
      title:
        "Rapid Genomic Sequencing Model Training through GPU Infrastructure",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyThree.jpg",
      title:
        "Predicting Heavy Equipment Failure Using Real-Time Data and Deep Learning",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyFour.jpg",
      title:
        "Scalable Car License Plate Detection and Recognition Using Custom CNN and AWS EKS",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudyFive.jpg",
      title: "Actionable Insights from Robot Data Leveraging Deep Learning.",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/landing-images/caseStudySix.jpg",
      title:
        "Deploying Computer Vision Models on NVIDIA Jetson for Industrial Machine Vision Applications",
    },
  ];
  const handleCaseStudy = (index) => {
    history.push(`/case-study/${index}`);
    containerRef.current.scrollIntoView({ behavior: 'smooth' });

  };

  const getCircularIndex = (currentIndex, arrayLength) => {
    return (currentIndex + arrayLength) % arrayLength;
  };
  return (
    <div className="landing-container">
      <div ref={containerRef}>
      <div className="navbar">
    <Navbar />
  </div>
        <div style={{ position: "relative" }}>
          <svg className="vectorAnime-two"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 98 363"
            fill="none"
          >
            <path
              className="vectorAnime"
              d="M94.3703 356.751C95.843 356.751 97.0369 357.945 97.0369 359.418C97.0369 360.891 95.843 362.084 94.3703 362.084C92.8975 362.084 91.7036 360.891 91.7036 359.418C91.7036 357.945 92.8975 356.751 94.3703 356.751ZM17.2015 76.6336L17.5643 76.2895L17.2015 76.6336ZM1.23438 0.0434635L1.23438 35.3433L0.234378 35.3433L0.234378 0.0434635L1.23438 0.0434635ZM17.5643 76.2895L78.266 140.288L77.5404 140.976L16.8387 76.9776L17.5643 76.2895ZM94.8703 181.922L94.8703 359.418L93.8703 359.418L93.8703 181.922L94.8703 181.922ZM78.266 140.288C88.9272 151.528 94.8703 166.43 94.8703 181.922L93.8703 181.922C93.8703 166.686 88.0255 152.031 77.5404 140.976L78.266 140.288ZM1.23438 35.3433C1.23438 50.5794 7.07921 65.2349 17.5643 76.2895L16.8387 76.9776C6.17744 65.7373 0.234378 50.8355 0.234378 35.3433L1.23438 35.3433Z"
              fill="transparent"
            />
          </svg>
        </div>
        <div className="selectedCaseStudy-container">

          <div className="case-container">
            <div style={{ position: "relative" }}>
              <svg
                style={{ position: "absolute" }}
                className="draw-line"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 679 98"
                fill="none"
              >
                <path
                  className="vectorAnime"
                  d="M672.807 94.6662C672.807 96.1389 674.001 97.3328 675.474 97.3328C676.947 97.3328 678.141 96.1389 678.141 94.6662C678.141 93.1934 676.947 91.9995 675.474 91.9995C674.001 91.9995 672.807 93.1934 672.807 94.6662ZM249.067 10.6125L248.796 11.0326L249.067 10.6125ZM0.125 1.53027H216.54V0.530273H0.125V1.53027ZM248.796 11.0326L364.227 85.5041L364.769 84.6638L249.338 10.1923L248.796 11.0326ZM397.026 95.1662H675.474V94.1662H397.026V95.1662ZM364.227 85.5041C374.003 91.8115 385.391 95.1662 397.026 95.1662V94.1662C385.583 94.1662 374.384 90.8669 364.769 84.6638L364.227 85.5041ZM216.54 1.53027C227.982 1.53027 239.181 4.82951 248.796 11.0326L249.338 10.1923C239.562 3.88496 228.174 0.530273 216.54 0.530273V1.53027Z"
                  fill="transparent"
                />
              </svg>
            </div>
            <div style={{ textAlign: "center" }}>
              <p className="bold-big-common-text">{selectedCaseStudy.heading}</p>
              <p className="bold-big-common-text">{selectedCaseStudy.extraHeading}</p>
            </div>

            <div style={{ textAlign: "center" }}>
              <p className="semibold-smalltext" style={{ fontWeight: "700" }}>{selectedCaseStudy.subheading}</p>
              <p className="semibold-smalltext" style={{ fontWeight: "700" }}>{selectedCaseStudy.extraSubheading}</p>
            </div>
            <img style={{ width: "95%" }} className="case-img" src={selectedCaseStudy.image} alt="sub-casestudy" />
          </div>

          <div className="three-section">
            <div className="each-section">
              <p className="bold-common-text" style={{ color: "#111" }}>Client</p>
              <Divider style={{ border: "0.5px solid #E6E6E6", margin: "5vh 0vh" }} />
              <p className="semibold-smalltext">{selectedCaseStudy.descriptionOne}</p>
            </div>
            <div className="each-section">
              <p className="bold-common-text" style={{ color: "#111" }}>Challenges</p>
              <Divider style={{ border: "0.5px solid #E6E6E6", margin: "5vh 0vh" }} />
              <p className="semibold-smalltext">{selectedCaseStudy.descriptionTwo}</p>
            </div>
            <div className="each-section">
              <p className="bold-common-text" style={{ color: "#111" }}>Solution Methodology</p>
              <Divider style={{ border: "0.5px solid #E6E6E6", margin: "5vh 0vh" }} />
              <p className="semibold-smalltext">{selectedCaseStudy.descriptionThree}</p>
            </div>
          </div>
          <div className="expertise-box expert_div flex_col" style={{marginTop: "6vh" }}
          >
            <div className="our-expertise-container"  >
              <div className="expertise-heading">
                <p className="bold-common-text">{selectedCaseStudy.queOne} <br />{selectedCaseStudy.extraQueOne}</p>
                <p id="expertise-description" className="regular-common-text" style={{ width: "41%" }}>
                  {selectedCaseStudy.ansOne}
                </p>
              </div>
              <Divider
                style={{ border: "0.5px solid #656565", margin: "5vh 0vh" }}
              />
              <Grid container className="expertise-grid" sx={{ flexGrow: 1, gap: "1vw", marginTop: "2vh" }}
              >

                <Grid item lg={3.9} xs={11.9} sm={5.8} md={5.8}>
                  <Paper className="expertise-cards"
                    sx={{
                      borderRadius: "2.5vh 2.5vh 15vh",
                      background: "#212121 ! important",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      padding: "1.5vw",
                      height: "65vh"
                    }}
                  >
                    <div>
                      <p
                        className="bold-common-text"
                        style={{ color: "#fff" }}
                      >
                        {selectedCaseStudy.cardTitleOne}
                      </p>
                      <p
                        className="bold-common-text"
                        style={{ color: "#fff" }}
                      >
                        {selectedCaseStudy.extraCardTitleOne}
                      </p>
                      <Divider
                        style={{
                          border: "0.5px solid #656565",
                          margin: "3vh 0px",
                        }}
                      />
                      <p
                        style={{
                          color: "#fff",
                          marginTop: "1vh", height: "10vh"
                        }}
                        className="regular-common-text"
                      >
                        {selectedCaseStudy.titleDescriptionOne}
                      </p>

                    </div>
                  </Paper>
                </Grid>
                <Grid item lg={3.9} xs={11.9} sm={5.8} md={5.8}>
                  <Paper className="expertise-cards"
                    sx={{
                      borderRadius: "2.5vh 2.5vh 15vh",
                      background: "#212121 ! important",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      padding: "1.5vw",
                      height: "65vh"
                    }}
                  >
                    <div>
                      <p
                        className="bold-common-text"
                        style={{ color: "#fff" }}
                      >
                        {selectedCaseStudy.cardTitleTwo}
                      </p>
                      <p
                        className="bold-common-text"
                        style={{ color: "#fff" }}
                      >
                        {selectedCaseStudy.extraCardTitleTwo}
                      </p>
                      <Divider
                        style={{
                          border: "0.5px solid #656565",
                          margin: "3vh 0px",
                        }}
                      />
                      <p
                        style={{
                          color: "#fff",
                          marginTop: "1vh", height: "10vh"
                        }}
                        className="regular-common-text"
                      >
                        {selectedCaseStudy.titleDescriptionTwo}
                      </p>

                    </div>
                  </Paper>
                </Grid>
                <Grid item lg={3.9} xs={11.9} sm={5.8} md={5.8}>
                  <Paper className="expertise-cards"
                    sx={{
                      borderRadius: "2.5vh 2.5vh 15vh",
                      background: "#212121 ! important",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      padding: "1.5vw",
                      height: "65vh"
                    }}
                  >
                    <div>
                      <p
                        className="bold-common-text"
                        style={{ color: "#fff" }}
                      >
                        {selectedCaseStudy.cardTitleThree}
                      </p>
                      <p
                        className="bold-common-text"
                        style={{ color: "#fff" }}
                      >
                        {selectedCaseStudy.extraCardTitleThree}
                      </p>
                      <Divider
                        style={{
                          border: "0.5px solid #656565",
                          margin: "3vh 0px",
                        }}
                      />
                      <p
                        style={{
                          color: "#fff",
                          marginTop: "1vh", height: "10vh"
                        }}
                        className="regular-common-text"
                      >
                        {selectedCaseStudy.titleDescriptionThree}
                      </p>

                    </div>
                  </Paper>
                </Grid>

              </Grid>
            </div>
          </div>
          <div style={{ marginTop: "8vh" }}>
            <div className="service-heading">
              <p className="bold-common-text" style={{ color: "#111" }}>{selectedCaseStudy.queTwo}<br />{selectedCaseStudy.extraQueTwo}</p>
              <p id="expertise-description" className="regular-common-text" style={{ width: "21%", color: "#111" }}>{selectedCaseStudy.ansTwo}</p>
            </div>
            <Divider style={{ border: "0.5px solid #E6E6E6", margin: "1vh 2.3vw" }} />
          </div>
          <div style={{ margin: "1vh 2.3vw" }}>
            <p className="semibold-smalltext">{selectedCaseStudy.paraOne}
              <br /><br />{selectedCaseStudy.paraTwo}
            </p>
          </div>
          <div className="casestudy-container">
            <div className="case-studies">
              <div className="service-heading" style={{ padding: "0px" }}>
                <p className="bold-common-text" >{selectedCaseStudy.queThree}</p>
                <p id="expertise-description" className="regular-common-text" style={{ width: "21%" }}>{selectedCaseStudy.ansThree}</p>
              </div>
              <Divider style={{ border: "0.5px solid #656565", margin: "20px 0px" }} />

              <Grid container className="casecards-main-container" sx={{ flexGrow: 1, gap: "4vw", marginTop: "2vh" }}
              >

                <Grid className="cards-container" item lg={11.9} xs={11.9} sm={5.7} md={11.9}>
                  <Paper className="casecard-body"
                    sx={{
                      borderRadius: "2vw 2vw 14vw 2vw",
                      background: "#212121",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "2vw",
                      padding: "2vw",
                      boxShadow: "none ! important"
                    }}
                  >

                    <img className="casecard-one" src={selectedCaseStudy.cardImgOne} alt="case" style={{
                      borderRadius: "26px",
                      width: "31vw"
                    }} />
                    <div style={{ display: "flex", flexDirection: "column", gap: "1.5vh" }}>
                      <p id="auto-width" className="bold-common-text" style={{ width: "36vw" }}>{selectedCaseStudy.cardHeadingOne}</p>

                      <Divider style={{ border: "0.5px solid #878787" }} />
                      <p id="percent-width" className="regular-common-text" style={{ width: "34vw" }}>{selectedCaseStudy.cardDescriptnOne}</p>
                    </div>


                  </Paper>
                </Grid>
                <Grid className="cards-container" item lg={11.9} xs={11.9} sm={5.7} md={11.9}>
                  <Paper id="card-body" className="casecard-body"
                    sx={{
                      borderRadius: "2vw 2vw 2vw 14vw",
                      background: "#2F67F8",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "2vw",
                      padding: "2vw",
                      boxShadow: "none ! important"
                    }}
                  >


                    <div style={{ display: "flex", flexDirection: "column", gap: "1.5vh" }}>
                      <p id="auto-width" className="bold-common-text" style={{ width: "36vw" }}>{selectedCaseStudy.cardHeadingTwo}</p>

                      <Divider style={{ border: "0.5px solid #878787" }} />
                      <p id="percent-width-align" className="regular-common-text" style={{ width: "34vw", textAlign: "right" }}>{selectedCaseStudy.cardDescriptnTwo}</p>
                    </div>
                    <img className="casecard-two" src={selectedCaseStudy.cardImgThree} alt="case" style={{
                      borderRadius: "26px",
                      width: "31vw"
                    }} />

                  </Paper>
                </Grid>
                <Grid className="cards-container" item lg={11.9} xs={11.9} sm={5.7} md={11.9}>
                  <Paper className="casecard-body"
                    sx={{
                      borderRadius: "2vw 2vw 14vw 2vw",
                      background: "#212121",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "2vw",
                      padding: "2vw",
                      boxShadow: "none ! important"
                    }}
                  >

                    <img className="casecard-three" src={selectedCaseStudy.cardImgTwo} alt="case" style={{
                      borderRadius: "26px",
                      width: "31vw"
                    }} />
                    <div style={{ display: "flex", flexDirection: "column", gap: "1.5vh" }}>
                      <p id="auto-width" className="bold-common-text" style={{ width: "36vw" }}>{selectedCaseStudy.cardHeadingThree}</p>

                      <Divider style={{ border: "0.5px solid #878787" }} />
                      <p id="percent-width" className="regular-common-text" style={{ width: "34vw" }}>{selectedCaseStudy.cardDescriptnThree}</p>
                    </div>


                  </Paper>
                </Grid>

              </Grid>

            </div>
          </div>
          <div className="three-section">
            <div className="each-section">
              <p className="bold-common-text" style={{ color: "#111" }}>Results</p>
              <Divider style={{ border: "0.5px solid #E6E6E6", margin: "5vh 0vh" }} />
              <p className="semibold-smalltext">{selectedCaseStudy.resultText}</p>
              <ul>
                <li className="semibold-smalltext">{selectedCaseStudy.ResultOne}</li>
                {selectedCaseStudy.ResultTwo && (
                  <li className="semibold-smalltext">{selectedCaseStudy.ResultTwo}</li>
                )}
                {selectedCaseStudy.ResultThree && (
                  <li className="semibold-smalltext">{selectedCaseStudy.ResultThree}</li>
                )}
                {selectedCaseStudy.ResultFour && (
                  <li className="semibold-smalltext">{selectedCaseStudy.ResultFour}</li>
                )}
              </ul>
            </div>
            <div className="each-section">
              <p className="bold-common-text" style={{ color: "#111" }}>Conclusion</p>
              <Divider style={{ border: "0.5px solid #E6E6E6", margin: "5vh 0vh" }} />
              <p className="semibold-smalltext">{selectedCaseStudy.conclusion}</p>
            </div>
          </div>
          <div className="three-section">
            <p className="bold-common-text" style={{ color: "#111" }}>More case studies from Smarttrak AI</p>
            <Divider style={{ border: "0.5px solid #E6E6E6", marginTop: "5vh" }} />
          </div>

          <div className="casestudy-grid-two">
            <Grid container sx={{ flexGrow: 1, gap: "1vw", pl: 0 }}>

              {[0, 1, 2].map((offset) => {
                const circularIndex = getCircularIndex(caseStudyIndex + 1 + offset, caseStudies.length);
                const item = caseStudies[circularIndex]

                return (
                  <Grid
                    item
                    key={circularIndex}
                    lg={3.9}
                    xs={11.9}
                    sm={11.9}
                    md={5.9}
                    sx={{
                      marginTop: "3vh",
                    }}
                  >
                    <Paper
                      className="expertise-cards"
                      sx={{
                        // borderRadius: offset === 1 ? "2.5vh 2.5vh 20vh 20vh !important" : "2.5vh 2.5vh 20vh !important",
                        borderRadius: "1rem 1rem 5rem 5rem !important",
                        background: "#212121",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        padding: "4.5vh 1.5vw",
                        ":hover": {
                          backgroundColor: "#2F67F8",
                        },
                      }}
                    >
                      <div>
                        <p className="regular-common-text">Solar Industry</p>
                        <p
                          style={{ height: "15vh", color: "white" }}
                          className=" semibold-commontext"
                        >
                          {item.title}
                        </p>

                        <img className="case_study_imgs"
                          style={{
                            width: "100%",
                            // borderRadius: offset === 1 ? "2.5vh 2.5vh 20vh 20vh" : "2.5vh 2.5vh 20vh",
                            borderRadius: "1rem 1rem 5rem 5rem",
                            marginTop: "4vh",
                          }}
                          src={item.image}
                          alt="expertise"
                        />

                        <div
                          onClick={() => handleCaseStudy(circularIndex)}
                          className="circle-arrow-casestudy"
                          style={{
                            marginTop: "-14vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "translate(0%, 70%)",
                          }}
                        >
                          <div
                            style={{
                              width: "6vw",
                              height: "6vw",
                              borderRadius: "50%",
                              background: "#90E900",
                              zIndex: "1",
                              border: "1px solid #111",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="rotate-on-scroll"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 28 28"
                              fill="none"
                              className="hover-svg"
                            >
                              <path
                                d="M0.56434 25.3139C-0.0214466 25.8996 -0.0214466 26.8494 0.56434 27.4352C1.15013 28.021 2.09987 28.021 2.68566 27.4352L0.56434 25.3139ZM27.8737 1.62577C27.8737 0.797348 27.2022 0.125775 26.3737 0.125774L12.8737 0.125775C12.0453 0.125775 11.3737 0.797347 11.3737 1.62577C11.3737 2.4542 12.0453 3.12578 12.8737 3.12578L24.8737 3.12577L24.8737 15.1258C24.8737 15.9542 25.5453 16.6258 26.3737 16.6258C27.2022 16.6258 27.8737 15.9542 27.8737 15.1258L27.8737 1.62577ZM2.68566 27.4352L27.4344 2.68643L25.3131 0.565115L0.56434 25.3139L2.68566 27.4352Z"
                                fill="black"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </Grid>

                )
              })}


            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Casestudy;