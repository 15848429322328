import React from 'react';

const ThreeDIcon = (props) => {
    const {
        strokeColor = '#111111',
        strokeWidth = 1.5,
        width = "24",
        height = "24",
        ...rest
    } = props;
  return (
    <svg width="42" height="28" viewBox="0 0 42 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_259_342)">
        <path
          d="M19.7251 11.685L12.887 16.2459L6.38867 12.0379L12.9044 7.33983L19.7251 11.685Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M6.17353 12.4619L12.6762 16.6723L12.478 24.8431L6.05371 20.5518L6.17353 12.4619Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M19.9454 12.1212L13.1029 16.6551L12.9155 24.8235L20.0194 20.2871L19.9454 12.1212Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M36.9636 12.3344C36.9616 12.5265 36.9904 12.7175 37.0485 12.8981L33.0489 15.5645C32.9036 15.3248 32.7087 15.1282 32.4811 14.9916C32.2535 14.855 31.9998 14.7825 31.7418 14.7803C31.4728 14.7767 31.2074 14.8509 30.9713 14.996C30.7351 15.1411 30.5361 15.352 30.3934 15.6086L26.6617 13.1971C26.739 12.9788 26.7782 12.7459 26.7772 12.5109C26.7791 12.0966 26.6511 11.6947 26.4155 11.3762L30.3367 8.54557C30.4777 8.8296 30.6852 9.0647 30.9367 9.22531C31.1882 9.38593 31.474 9.46591 31.763 9.45656C32.0521 9.44721 32.3333 9.34889 32.5761 9.17229C32.819 8.99569 33.0141 8.74756 33.1404 8.4549L37.3644 11.1483C37.1072 11.4758 36.9647 11.8974 36.9636 12.3344Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M26.5632 20.716C26.5641 21.0642 26.4742 21.4049 26.3045 21.6961C26.1349 21.9873 25.8931 22.2161 25.609 22.3542C25.3249 22.4923 25.0111 22.5336 24.7064 22.473C24.4016 22.4124 24.1194 22.2525 23.8946 22.0132C23.6699 21.7739 23.5124 21.4657 23.4418 21.1267C23.3713 20.7878 23.3906 20.433 23.4975 20.1063C23.6044 19.7795 23.7942 19.4953 24.0433 19.2887C24.2924 19.0822 24.5899 18.9623 24.8989 18.9441C24.9272 18.9416 24.9556 18.9416 24.9839 18.9441C25.4018 18.9448 25.8025 19.1315 26.0985 19.4635C26.3944 19.7955 26.5615 20.2458 26.5632 20.716Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M31.4612 18.307L31.3435 23.2673C31.0764 23.2949 30.8201 23.3996 30.5995 23.5714C30.3789 23.7431 30.2015 23.9761 30.0844 24.2476L26.2939 21.7111C26.4542 21.4437 26.5469 21.1324 26.5621 20.8104C26.5773 20.4884 26.5144 20.1677 26.3802 19.8828C26.2459 19.5979 26.0454 19.3594 25.7999 19.1927C25.5545 19.0261 25.2735 18.9377 24.9868 18.9368C24.9585 18.9343 24.9301 18.9343 24.9019 18.9368L24.9737 14.2632C25.0521 14.2793 25.1316 14.2867 25.2112 14.2853C25.4487 14.2868 25.6835 14.2281 25.8981 14.1135C26.1127 13.999 26.3015 13.8315 26.4507 13.6236L30.2238 16.0744C30.1781 16.2377 30.1553 16.408 30.1563 16.5792C30.1596 16.9935 30.292 17.3934 30.5304 17.7091C30.7689 18.0248 31.0983 18.2364 31.4612 18.307Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M38.8195 18.6917C38.8036 18.6893 38.7875 18.6893 38.7716 18.6917C38.5641 18.6914 38.3586 18.7371 38.1669 18.8263C37.9751 18.9155 37.8009 19.0464 37.6542 19.2114C37.5074 19.3765 37.3911 19.5725 37.3119 19.7882C37.2326 20.0039 37.1919 20.2351 37.1922 20.4685C37.1902 20.7982 37.2726 21.1215 37.4297 21.3998L32.9225 24.2819C32.8137 24.028 32.6529 23.8067 32.4533 23.6363C32.2537 23.4659 32.0211 23.3512 31.7744 23.3016L31.8855 18.3413C32.2767 18.2998 32.6401 18.0958 32.9048 17.769C33.1694 17.4423 33.3163 17.0163 33.3168 16.5743C33.3181 16.3826 33.2908 16.1921 33.2362 16.0106L37.2423 13.3564C37.3886 13.5939 37.584 13.7879 37.8117 13.9215C38.0394 14.0551 38.2926 14.1245 38.5494 14.1235C38.6275 14.1225 38.7055 14.114 38.7825 14.0978L38.8195 18.6917Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M33.3042 7.68295C33.3055 7.95044 33.2518 8.21465 33.1473 8.45494C32.9784 8.8496 32.6861 9.1604 32.3264 9.328C31.9667 9.49559 31.5646 9.50823 31.1971 9.36348C30.8296 9.21874 30.5224 8.92677 30.3342 8.54334C30.1459 8.1599 30.0898 7.71188 30.1765 7.28486C30.2633 6.85783 30.4869 6.48173 30.8045 6.22838C31.1221 5.97504 31.5116 5.86222 31.8985 5.91146C32.2854 5.96071 32.6426 6.16857 32.9019 6.49535C33.1612 6.82212 33.3044 7.24491 33.3042 7.68295Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M26.7854 12.5109C26.7865 12.7459 26.7473 12.9789 26.6699 13.1971C26.612 13.3458 26.5389 13.4865 26.4521 13.6162C26.304 13.8272 26.1153 13.9976 25.9003 14.1148C25.6852 14.2319 25.4493 14.2927 25.2104 14.2926C25.1308 14.294 25.0513 14.2866 24.9729 14.2706C24.6727 14.2208 24.3915 14.075 24.1628 13.8506C23.9341 13.6261 23.7675 13.3325 23.6829 13.0046C23.5983 12.6767 23.5992 12.3284 23.6855 12.0011C23.7719 11.6738 23.94 11.3813 24.1699 11.1584C24.3997 10.9354 24.6817 10.7915 24.9822 10.7437C25.2827 10.696 25.5891 10.7463 25.8649 10.8888C26.1406 11.0313 26.3742 11.26 26.5378 11.5475C26.7014 11.8351 26.7881 12.1695 26.7876 12.5109H26.7854Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M33.0752 25.0392C33.0752 25.5111 32.9086 25.9636 32.612 26.2973C32.3154 26.631 31.9131 26.8184 31.4937 26.8184C31.0742 26.8184 30.6719 26.631 30.3753 26.2973C30.0787 25.9636 29.9121 25.5111 29.9121 25.0392C29.9154 24.5975 30.0631 24.1727 30.3271 23.8459C30.5911 23.519 30.9529 23.313 31.3434 23.2673C31.3935 23.2673 31.4479 23.2673 31.4958 23.2673C31.5895 23.2666 31.683 23.2773 31.7747 23.2991C32.1407 23.3712 32.4724 23.5862 32.7115 23.9062C32.9506 24.2263 33.0817 24.6309 33.0818 25.049L33.0752 25.0392Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M40.349 20.4636C40.3488 20.8496 40.2367 21.225 40.0297 21.5329C39.8228 21.8407 39.5322 22.0643 39.2021 22.1696C38.872 22.2749 38.5204 22.2562 38.2005 22.1164C37.8807 21.9766 37.6102 21.7233 37.4299 21.3949C37.2728 21.1166 37.1904 20.7932 37.1924 20.4636C37.1921 20.2302 37.2328 19.999 37.3121 19.7833C37.3913 19.5675 37.5077 19.3715 37.6544 19.2065C37.8011 19.0414 37.9753 18.9106 38.1671 18.8214C38.3588 18.7322 38.5643 18.6865 38.7718 18.6868C38.7877 18.6844 38.8038 18.6844 38.8197 18.6868C39.2302 18.7002 39.6198 18.8935 39.9055 19.2254C40.1912 19.5573 40.3503 20.0016 40.349 20.4636Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M40.1244 12.3345C40.1244 12.7596 39.9887 13.1706 39.742 13.4926C39.4953 13.8147 39.154 14.0264 38.7803 14.0892C38.7034 14.1053 38.6254 14.1126 38.5472 14.1113C38.214 14.1146 37.8885 13.9976 37.6188 13.7775C37.349 13.5574 37.1491 13.2457 37.0484 12.8883C36.9903 12.7077 36.9615 12.5168 36.9635 12.3247C36.9635 12.0909 37.0045 11.8595 37.0841 11.6436C37.1638 11.4277 37.2805 11.2316 37.4276 11.0665C37.5747 10.9015 37.7493 10.7707 37.9415 10.6817C38.1336 10.5927 38.3394 10.5472 38.5472 10.5479C38.7552 10.5479 38.9612 10.5942 39.1533 10.6841C39.3454 10.774 39.5197 10.9058 39.6663 11.0719C39.8129 11.2379 39.9289 11.435 40.0075 11.6517C40.0861 11.8684 40.1258 12.1004 40.1244 12.3345Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M33.3169 16.5645C33.3165 17.0065 33.1696 17.4325 32.905 17.7592C32.6403 18.086 32.2769 18.29 31.8857 18.3314C31.8378 18.3314 31.7877 18.3314 31.7332 18.3314C31.6417 18.332 31.5504 18.3213 31.4609 18.2996C31.095 18.229 30.7632 18.0152 30.5239 17.6958C30.2847 17.3765 30.1536 16.9723 30.1538 16.5546C30.1529 16.3834 30.1757 16.2131 30.2214 16.0498C30.2611 15.8913 30.3213 15.7402 30.4 15.6013C30.5412 15.3475 30.7374 15.1384 30.9703 14.9935C31.2031 14.8485 31.4649 14.7727 31.731 14.773C31.989 14.7752 32.2427 14.8477 32.4703 14.9842C32.6979 15.1208 32.8928 15.3174 33.0381 15.5572C33.2126 15.8528 33.3058 16.1998 33.3061 16.5546L33.3169 16.5645Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M16.4688 5.90121H24.1848"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M24.1848 5.90122V4.01413C24.1847 3.9874 24.1909 3.9611 24.2027 3.9379C24.2145 3.91469 24.2315 3.89541 24.2521 3.88198C24.2726 3.86855 24.2959 3.86147 24.3197 3.86144C24.3435 3.8614 24.3668 3.86842 24.3874 3.88179L25.8404 4.82533L27.2935 5.78113C27.3138 5.79453 27.3307 5.81368 27.3424 5.83668C27.3541 5.85968 27.3603 5.88573 27.3603 5.91224C27.3603 5.93876 27.3541 5.96481 27.3424 5.98781C27.3307 6.01081 27.3138 6.02996 27.2935 6.04336L25.8404 6.98935L24.3874 7.93289C24.3668 7.94626 24.3435 7.95328 24.3197 7.95325C24.2959 7.95322 24.2726 7.94613 24.2521 7.9327C24.2315 7.91928 24.2145 7.89999 24.2027 7.87679C24.1909 7.85358 24.1847 7.82728 24.1848 7.80055V5.90122Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_259_342">
          <rect width="40.5642" height="27.0428" fill="white" transform="translate(0.717773 0.765564)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThreeDIcon;