// import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';
// import CircularSlider from '@fseehawer/react-circular-slider';

// const StyledCircle = styled.div`
//   width: ${(props) => props.radius * 2}px;
//   height: ${(props) => props.radius * 2}px;
//   border-radius: 50%;
//   position: absolute;
//   top: ${(props) => props.centerY - props.radius}px;
//   left: ${(props) => props.centerX - props.radius}px;
//   background-color: ${(props) => props.fill};
//   border: 4px solid #fff;
// `;
// const OrangeStyledCircle = styled.div`
//   width: ${(props) => props.radius * 2}px;
//   height: ${(props) => props.radius * 2}px;
//   border-radius: 50%;
//   position: absolute;
//   box-shadow: 0px 7.03px 35.16px 0px #FDA737;
//   top: ${(props) => props.centerY - props.radius}px;
//   left: ${(props) => props.centerX - props.radius}px;
//   background-color: ${(props) => props.fill};
//   border: 4px solid #fff;
// `;

// const StyledLine = styled.div`
//   position: absolute;
//   top: ${(props) => props.centerY - 1}px; /* Adjusted for line thickness */
//   left: ${(props) => props.centerX}px;
//   width: ${(props) => props.length}px;
//   height: 2px; /* Adjust line thickness */
//   background-color: ${(props) => props.stroke};
//   transform-origin: left center;
//   transform: rotate(${(props) => props.angle}deg);
//   z-index: 1;
//   border-top: 2px dashed ${(props) => props.stroke};
//   background-color: transparent;
// `;

// const StyledImage = styled.img`
//   position: absolute;
//   top: ${(props) => props.centerY - props.height / 2}px;
//   left: ${(props) => props.centerX - props.width / 2}px;
//   background-color: white;
//     width: 2rem;
//     border-radius: 50%;
//     box-shadow: 0px 0px 14.07px 0px #00000040;
//     padding: 0.2rem;
// `;

// const StyledText = styled.p`
//   position: absolute;
//   font-family:Poppins;
//   top: ${(props) => props.centerY}px;
//   left: ${(props) => props.centerX}px;
//   font-size: ${(props) => props.fontSize}px;
//   font-weight: ${(props) => props.fontWeight};
//   color: ${(props) => props.fill};
// `;

// const TrackerDrawer = (props) => {
//   const {sunAngle, trackerAngle, isManual, handleControlClick} = props
//   const width = 350; // Width of the SVG
//   const height = 350; // Height of the SVG (full circle)
//   const radius = width / 2;

//   const [sliderValue, setSliderValue] = useState(trackerAngle);

//   const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
//     const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180.0);
//     return {
//       x: centerX + radius * Math.cos(angleInRadians),
//       y: centerY + radius * Math.sin(angleInRadians),
//     };
//   };
 
//   let adjustedTrackerAngle = trackerAngle;
//   if (adjustedTrackerAngle !== null) {
//     //  Clockwise from West (W as 0°)
//     if (adjustedTrackerAngle >= 0 && adjustedTrackerAngle <= 180) {
//       adjustedTrackerAngle = 270 + adjustedTrackerAngle;
//     }
//     // Counterclockwise from West (W as 0°)
//     else if (adjustedTrackerAngle < 0) {
//       adjustedTrackerAngle = 270 - Math.abs(adjustedTrackerAngle);
//     }
//   }
//   const handleSliderChange = (value) => {
//     if (value >= 5 && value <= 175) {
//     setSliderValue(value);
//     props.handleControlClick(2, value)
//     }
//   };

//   return (
//       <div style={{ position: 'relative', width: width, height: height, display:"flex", alignItems:"center", justifyContent:"center" }}>
//         {/* Directional Labels */}
//         {/* East (90°) */}
//         <StyledText
//           centerX={width - (-10)}
//           centerY={radius}
//           fontSize={20}
//           fontWeight="600"
//           fill="#fff"
//         >
//           E
//         </StyledText>
//         {/* West (270°) */}
//         <StyledText
//           centerX={-40}
//           centerY={radius}
//           fontSize={20}
//           fontWeight="600"
//           fill="#fff"
//         >
//           W
//         </StyledText>
//         {/* North (180°) */}
//         <StyledText
//           centerX={radius - 7}
//           centerY={-20}
//           fontSize={20}
//           fontWeight="600"
//           fill="#fff"
//         >
//           N
//         </StyledText>
//         {/* South (0°) */}
//         <StyledText
//           centerX={radius - 10}
//           centerY={height - (-20)}
//           fontSize={20}
//           fontWeight="600"
//           fill="#fff"
//         >
//           S
//         </StyledText>

//         {/* Degree Labels */}
//         {/* 90° */}
//         <StyledText
//           centerX={width - (-10)}
//           centerY={radius - 30}
//           fontSize={20}
//           fontWeight="600"
//           fill="#fff"
//         >
//           90°
//         </StyledText>
//         {/* 0° */}
//         <StyledText
//           centerX={radius - 7}
//           centerY={-40}
//           fontSize={20}
//           fontWeight="600"
//           fill="#fff"
//         >
//           0°
//         </StyledText>
//         {/* 270° */}
//         <StyledText
//           centerX={-45}
//           centerY={radius - 30}
//           fontSize={20}
//           fontWeight="600"
//           fill="#fff"
//         >
//           270°
//         </StyledText>
//         {/* 180° */}
//         <StyledText
//           centerX={radius - 20}
//           centerY={height - 5}
//           fontSize={20}
//           fontWeight="600"
//           fill="#fff"
//         >
//           180°
//         </StyledText>

//         {/* Orange sun track */}
//         <StyledCircle
//           centerX={radius}
//           centerY={radius}
//           radius={radius - 10}
//           fill="#F4F4F4"
//         />
//         {/* Sun tracking dashed line */}
//         <StyledLine
//           centerX={radius}
//           centerY={radius}
//           length={radius - 20}
//           angle={sunAngle - 90}
//           stroke="#FCD303"
//         />
//         <StyledCircle
//           centerX={radius}
//           centerY={radius}
//           radius={10}
//           fill="#fff"
//         />
//         <OrangeStyledCircle
//           centerX={radius}
//           centerY={radius}
//           radius={100}
//           fill="#F4F4F4"
//         />
//         {/* tracker tracking line */}
//         <StyledLine
//           centerX={radius}
//           centerY={radius}
//           length={radius - 20}
//           angle={trackerAngle + 180}
//           stroke="#00529A"
//         />
//         <StyledImage
//           src="https://s3.amazonaws.com/smarttrak.co/v2Images/control_sun.svg"
//           centerX={polarToCartesian(radius, radius, radius - 20, sunAngle).x}
//           centerY={polarToCartesian(radius, radius, radius - 20, sunAngle).y}
//           width={70}
//           height={30}
//         />
//         <StyledText
//           centerX={polarToCartesian(radius, radius, radius - 20, sunAngle).x - 35}
//           centerY={polarToCartesian(radius, radius, radius - 50, sunAngle).y}
//           fontSize={14}
//           fontWeight="500"
//           fill="black"
//         >
//           {sunAngle}°
//         </StyledText>
//         <StyledImage
//           src="https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_panel.svg"
//           centerX={polarToCartesian(radius, radius, radius - 50, adjustedTrackerAngle).x}
//           centerY={polarToCartesian(radius, radius, radius - 20, adjustedTrackerAngle).y}
//           width={50}
//           height={30}
//         />
//         <StyledText
//           centerX={polarToCartesian(radius, radius, radius - 20, adjustedTrackerAngle).x + 25}
//           centerY={polarToCartesian(radius, radius, radius - 20, adjustedTrackerAngle).y}
//           fontSize={14}
//           fontWeight="500"
//           fill="black"
//         >
//           {trackerAngle}°
//         </StyledText>
//        {isManual && 
//        <div className="circular_slider"> 
//        <CircularSlider
//         min={0}
//         max={360}
//         direction={1}
//         knobPosition="left"
//         appendToValue="°"
//         valueFontSize="4rem"
//         trackColor="#d3d1d1"
//         progressColorFrom={"#00529A"}
//         progressColorTo={"#00529A"}
//         labelColor={"transparent"}
//         knobColor={"#00529A"}
//         onChange={handleSliderChange}
//         dataIndex={trackerAngle} 
//         style={{
//           position: 'absolute',
//           top: radius - 50, // Adjust position as needed
//           left: radius - 50, // Adjust position as needed
//         }}
//       />
//       <p className="Poppins_36px_rem" id="slider-value">{`${sliderValue}°`}</p>
//       </div>}
//       </div>
//   );
// };

// export default TrackerDrawer;




import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CircularSlider from '@fseehawer/react-circular-slider';
import { useMobile } from '../../../Context/MobileContext/MobileContext';

const StyledCircle = styled.div`
  width: ${(props) => props.radius * 2}px;
  height: ${(props) => props.radius * 2}px;
  border-radius: 50%;
  position: absolute;
  top: ${(props) => props.centerY - props.radius}px;
  left: ${(props) => props.centerX - props.radius}px;
  background-color: ${(props) => props.fill};
  border: 4px solid #fff;

   @media (max-width: 992px) {
    width: ${(props) => props.radius}px; // Half size on mobile
    height: ${(props) => props.radius}px; // Half size on mobile
    top: ${(props) => props.centerY - props.radius / 2}px; // Center correctly
    left: ${(props) => props.centerX - props.radius / 2}px; // Center correctly
  }
`;
const OrangeStyledCircle = styled(StyledCircle)`

  box-shadow: 0px 7.03px 35.16px 0px #FDA737;

   @media (max-width: 992px) {
    width: ${(props) => props.radius}px; // Half size on mobile
    height: ${(props) => props.radius}px; // Half size on mobile
  }
`;

const StyledLine = styled.div`
  position: absolute;
  top: ${(props) => props.centerY - 1}px; /* Adjusted for line thickness */
  left: ${(props) => props.centerX}px;
  width: ${(props) => props.length}px;
  height: 2px; /* Adjust line thickness */
  background-color: ${(props) => props.stroke};
  transform-origin: left center;
  transform: rotate(${(props) => props.angle}deg);
  z-index: 1;
  border-top: 2px dashed ${(props) => props.stroke};
  background-color: transparent;

    @media (max-width: 992px) {
    height: 1px; // Thinner line on mobile
  }
`;

const StyledImage = styled.img`
  position: absolute;
  top: ${(props) => props.centerY - props.height / 2}px;
  left: ${(props) => props.centerX - props.width / 2}px;
  background-color: white;
    width: 2rem;
    border-radius: 50%;
    box-shadow: 0px 0px 14.07px 0px #00000040;
    padding: 0.2rem;

      @media (max-width: 992px) {
    width: 1.5rem; // Reduce image size on mobile
    height: 1rem; // Maintain aspect ratio
  }
`;

const StyledText = styled.p`
  position: absolute;
  font-family:Poppins;
  top: ${(props) => props.centerY}px;
  left: ${(props) => props.centerX}px;
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.fill};

    @media (max-width: 992px) {
    font-size: ${(props) => props.fontSize / 1.5}px; // Reduce font size on mobile
  }
`;

const TrackerDrawer = (props) => {
  const {sunAngle, trackerAngle, isManual, handleControlClick} = props
  const { isMobile } = useMobile();
  const width = isMobile ? 327 : 350; // Width of the SVG
  const height = 350; // Height of the SVG (full circle)
  const radius = width / 2;

  const [sliderValue, setSliderValue] = useState(trackerAngle);

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180.0);
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };
 
  let adjustedTrackerAngle = trackerAngle;
  if (adjustedTrackerAngle !== null) {
    //  Clockwise from West (W as 0°)
    if (adjustedTrackerAngle >= 0 && adjustedTrackerAngle <= 180) {
      adjustedTrackerAngle = 270 + adjustedTrackerAngle;
    }
    // Counterclockwise from West (W as 0°)
    else if (adjustedTrackerAngle < 0) {
      adjustedTrackerAngle = 270 - Math.abs(adjustedTrackerAngle);
    }
  }
  const handleSliderChange = (value) => {
    if (value >= 5 && value <= 175) {
    setSliderValue(value);
    props.handleControlClick(2, value)
    }
  };

  return (
      <div style={{ position: 'relative', width: width, height: height, display:"flex", alignItems:"center", justifyContent:"center" }}>
        {/* Directional Labels */}
        {/* East (90°) */}
        <StyledText
          centerX={isMobile ? width - (-10) - 95 :  width - (-10)}
          centerY={radius}
          fontSize={20}
          fontWeight="600"
          fill="#fff"
        >
          E
        </StyledText>
        {/* West (270°) */}
        <StyledText
          centerX={isMobile ? -40 + 95 : -40}
          centerY={radius}
          fontSize={20}
          fontWeight="600"
          fill="#fff"
        >
          W
        </StyledText>
        {/* North (180°) */}
        <StyledText
          centerX={radius - 7}
          centerY={isMobile ? -20+95 : -20}
          fontSize={20}
          fontWeight="600"
          fill="#fff"
        >
          N
        </StyledText>
        {/* South (0°) */}
        <StyledText
          centerX={radius - 10}
          centerY={isMobile ? height - (-20)-95 : height - (-20)}
          fontSize={20}
          fontWeight="600"
          fill="#fff"
        >
          S
        </StyledText>

        {/* Degree Labels */}
        {/* 90° */}
        <StyledText
          centerX={isMobile ? width - (-10)-95 : width - (-10)}
          centerY={radius - 30}
          fontSize={20}
          fontWeight="600"
          fill="#fff"
        >
          90°
        </StyledText>
        {/* 0° */}
        <StyledText
          centerX={radius - 7}
          centerY={isMobile ? -40+95 : -40}
          fontSize={20}
          fontWeight="600"
          fill="#fff"
        >
          0°
        </StyledText>
        {/* 270° */}
        <StyledText
          centerX={isMobile ? -45+95 : -45}
          centerY={radius - 30}
          fontSize={20}
          fontWeight="600"
          fill="#fff"
        >
          270°
        </StyledText>
        {/* 180° */}
        <StyledText
          centerX={radius - 20}
          centerY={isMobile ? height - 5-90 : height - 5}
          fontSize={20}
          fontWeight="600"
          fill="#fff"
        >
          180°
        </StyledText>

        {/* Orange sun track */}
        <StyledCircle
          centerX={radius}
          centerY={radius}
          radius={radius - 10}
          fill="#F4F4F4"
        />
        {/* Sun tracking dashed line */}
        <StyledLine
          centerX={radius}
          centerY={radius}
          length={radius - 20}
          angle={sunAngle - 90}
          stroke="#FCD303"
        />
        <StyledCircle
          centerX={radius}
          centerY={radius}
          radius={10}
          fill="#fff"
        />
        <OrangeStyledCircle
          centerX={radius}
          centerY={radius}
          radius={100}
          fill="#F4F4F4"
        />
        {/* tracker tracking line */}
        <StyledLine
          centerX={radius}
          centerY={radius}
          length={radius - 20}
          angle={trackerAngle + 180}
          stroke="#00529A"
        />
        <StyledImage
          src="https://s3.amazonaws.com/smarttrak.co/v2Images/control_sun.svg"
          centerX={polarToCartesian(radius, radius, radius - 20, sunAngle).x}
          centerY={polarToCartesian(radius, radius, radius - 20, sunAngle).y}
          width={70}
          height={30}
        />
        <StyledText
          centerX={polarToCartesian(radius, radius, radius - 20, sunAngle).x - 35}
          centerY={polarToCartesian(radius, radius, radius - 50, sunAngle).y}
          fontSize={14}
          fontWeight="500"
          fill="black"
        >
          {sunAngle}°
        </StyledText>
        <StyledImage
          src="https://s3.amazonaws.com/smarttrak.co/v2Images/tracker_panel.svg"
          centerX={polarToCartesian(radius, radius, radius - 50, adjustedTrackerAngle).x}
          centerY={polarToCartesian(radius, radius, radius - 20, adjustedTrackerAngle).y}
          width={50}
          height={30}
        />
        <StyledText
          centerX={polarToCartesian(radius, radius, radius - 20, adjustedTrackerAngle).x + 25}
          centerY={polarToCartesian(radius, radius, radius - 20, adjustedTrackerAngle).y}
          fontSize={14}
          fontWeight="500"
          fill="black"
        >
          {trackerAngle}°
        </StyledText>
       {isManual && 
       <div className="circular_slider"> 
       <CircularSlider
        min={0}
        max={360}
        direction={1}
        knobPosition="left"
        appendToValue="°"
        valueFontSize="4rem"
        trackColor="#d3d1d1"
        progressColorFrom={"#00529A"}
        progressColorTo={"#00529A"}
        labelColor={"transparent"}
        knobColor={"#00529A"}
        onChange={handleSliderChange}
        dataIndex={trackerAngle} 
        style={{
          position: 'absolute',
          top: radius - 50, // Adjust position as needed
          left: radius - 50, // Adjust position as needed
        }}
      />
      <p className="Poppins_36px_rem" id="slider-value">{`${sliderValue}°`}</p>
      </div>}
      </div>
  );
};

export default TrackerDrawer;
