import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Divider,
} from "@mui/material";

const TableNoSearchBar = ({
  data,
  columns,
  page,
  rowsPerPage,
  order,
  orderBy,
  handlePageChange,
  handleRowsPerPageChange,
 
}) => {
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
      : (a, b) => ascendingComparator(a[orderBy], b[orderBy]);
  };
  const ascendingComparator = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const descendingComparator = (a, b) => {
    if (b < a) return -1;
    if (b > a) return 1;
    return 0;
  };
  

  return (
    <>
      

      <TableContainer className="table-scrollbar-abt" component={Paper} >
        <div className="abt_table_scrol">
        {data.length === 0 ? (
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableRow>
              <TableCell
                sx={{ borderBottom: "0rem solid gray !important" }}
                colSpan={columns.length}
                align="center"
                className="div-borderText"
              >
                No data available
              </TableCell>
            </TableRow>
            <Divider style={{ border: "0.0625rem solid #D9D9D9" }} />
          </Table>
        ) : (
          <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
           <TableRow>
                {columns.length > 0 &&
                  Object.keys(columns[0]).map((key, index) => (
                    <TableCell
                      key={index}
                      align="left"
                      className="table-padding"
                      sx={{
                        borderRight: index < Object.keys(columns[0]).length - 1 ? "0.0625rem solid #D8D8D8" : "none",
                      }}
                    >
                      <p className="abt-tableText">{key}</p>

                    
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
         
            <TableBody>
              {stableSort(columns, getComparator(order, orderBy))
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map((row, rowIndex) => (
                  <TableRow key={rowIndex} sx={{
                    textTransform: "capitalize",
                    backgroundColor: rowIndex % 2 === 0 ? "#E6EEF5" : "transparent"
                  }}>
                    {Object.values(row).map((value, valueIndex) => (
                      <TableCell key={valueIndex} align="left" 
                      className="table-padding"
                      >
                        <p className="abt-tableText-val">
                          {valueIndex !== orderBy
                            ? typeof value === "number"
                              ? value.toFixed(2)
                              : value
                            : value}</p>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
        </div>
         <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  zIndex: 5,
                  backgroundColor: "white",
                }}
              >
        <TablePagination
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          rowsPerPageOptions={[5, 15, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        </div>
      </TableContainer>
    </>
  );
};

export default TableNoSearchBar;
