import React, { useState, useEffect, useRef } from "react";
import Navbar from "../MainLandingPage/Navbar";
import "./AboutUs.scss";
import Button from "@mui/material/Button";
import Footer from "../MainLandingPage/Footer";
import { useSnackbar } from "notistack";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
import { UserService } from "../../IOT/Services/UserService";
import SimpleBackdrop from "../../LifeScience/components/common/SimpleBackdrop";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const userService = new UserService();
function AboutUs() {
  const location = useLocation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [textArea, setTextArea] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactError, setContactError] = useState("");
  const [textAreaError, setTextAreaError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const contactUsRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (location.hash === "#contact-us" && contactUsRef.current) {
      contactUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const missionControls = useAnimation();
  const vissionControls = useAnimation();
  const coreControls = useAnimation();
  const chooseControls = useAnimation();


  const [missionRef, inViewMission] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [vissionRef, inViewVission] = useInView({ triggerOnce: false, threshold: 0.1 });

  const [chooseRef, inViewChoose] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [coreRef, inViewCore] = useInView({ triggerOnce: false, threshold: 0.1 });


  useEffect(() => {
    missionControls.start({
      opacity: inViewMission ? 1 : 0,
      y: inViewMission ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [missionControls, inViewMission]);

  useEffect(() => {
    vissionControls.start({
      opacity: inViewVission ? 1 : 0,
      y: inViewVission ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [vissionControls, inViewVission]);
  useEffect(() => {
    chooseControls.start({
      opacity: inViewChoose ? 1 : 0,
      y: inViewChoose ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [chooseControls, inViewChoose]);
  useEffect(() => {
    coreControls.start({
      opacity: inViewCore ? 1 : 0,
      y: inViewCore ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [coreControls, inViewCore]);

  const validateFields = () => {
    let valid = true;

    if (!firstName) {
      setFirstNameError("Required Field*");
      valid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName) {
      setLastNameError("Required Field*");
      valid = false;
    } else {
      setLastNameError("");
    }

    if ((!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) && email) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else {
      setEmailError("")
    }

    if (!contact) {
      setContactError("Please enter your contact no.");
      valid = false;
    } else {
      setContactError("");
    }

    if (!textArea) {
      setTextAreaError("Please write message here.");
      valid = false;
    } else {
      setTextAreaError("");
    }
    if (!isChecked) {
      enqueueSnackbar("Please acknowledge the data protection declaration", { variant: "warning" });
      valid = false;
    } 
    return valid;
  };
  const ourMission = [
    {
      title: "Precise Forecasting",
      desc: "Using AI-driven models to provide accurate, actionable insights for optimal energy generation.",
    },
    {
      title: "Proactive Maintenance",
      desc: "Leveraging predictive analytics to detect and address issues before they impact operations.",
    },
    {
      title: "Immersive Digital Twins",
      desc: "Creating virtual replicas of physical assets that provide real-time control and a holistic understanding of solar farms.",
    },
  ];
  const coreVal = [
    {
      title: "Integrity",
      desc: "Our commitment to transparency, ethics, and accountability is at the heart of everything we do. We build trust with our clients by delivering on our promises and always putting their needs first.",
    },
    {
      title: "Sustainability",
      desc: "We believe in a future where energy production is both efficient and environmentally responsible. Our solutions are designed to support renewable energy growth, reducing carbon footprints, and promoting cleaner energy.",
    },
    {
      title: "Collaboration",
      desc: "We value the power of teamwork - both within our company and with our partners. By working together, we create solutions that are greater than the sum of their parts.",
    },
    {
      title: "Innovation",
      desc: "We are constantly pushing the envelope, leveraging the latest advancements in AI and digital twin technology to deliver innovative solutions that redefine industry standards.",
    },
  ];
  const whyChoose = [
    {
      title: "Holistic Digital",
      subTitle: "Twin Solutions",
      imgUrl: "https://s3.amazonaws.com/smarttrak.co/newHomePage/chooseOne.svg",
      desc: "From predictive maintenance to real-time 3D visualization, our Digital Twin technology covers all aspects of solar asset management, providing a comprehensive platform for optimizing  energy operations.",
    },
    {
      title: "Physics-Informed",
      subTitle: "AI Expertise",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/newHomePage/chooseTwo.svg",

      desc: "Our models go beyond conventional AI by incorporating physical principles, enabling us to deliver more accurate forecasts and insightful predictions that align closely with real-world conditions.",
    },
    {
      title: "Global Reach,",
      subTitle: "Local Impact",

      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/chooseThree.svg",

      desc: "With projects across multiple continents, we bring global expertise to every local project. Our technology adapts to diverse environments, ensuring that our solutions deliver value wherever they are deployed.",
    },
    {
      title: "Commitment to",
      subTitle: "Excellence",

      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/chooseFour.svg",

      desc: "Smarttrak AI stands at the forefront of renewable energy technology, dedicated to delivering unparalleled quality and precision in every solution we offer.",
    },
  ];
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTextAreaChange = (event) => {
    setTextArea(event.target.value);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); 
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateFields()) {
      let reqData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: contact,
        message: textArea,
        terms_accepted: isChecked,
      };
      await userService
        .postContactUs(reqData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);
            enqueueSnackbar(res.data.details, {
              variant: "success",
            });
          } else if (res.status === 422) {
            enqueueSnackbar("Please fill the valid fields", {
              variant: "warning",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error", err);
          enqueueSnackbar("Error occured while contact us", {
            variant: "error",
          });
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main"
        id="our_sol"
      >
        <p className="Raleway_64px">About Smarttrak AI</p>
        <div className="foo_one gap_3vw flex_reverse">
          <div
            className="expert_div gap_1vw flex_col about_first "
            id="text_just"
          >
            <p className="Raleway_40px text_c">
              Leading the Energy Revolution with Digital Twin Technology and AI
              Innovation
            </p>
            <p className="Raleway_26px_500 opacity_7">
              Welcome to Smarttrak AI, where cutting-edge technology meets
              sustainable energy solutions. We specialize in transforming the
              way solar and renewable energy assets are managed, using advanced
              Digital Twin technology combined with Physics-Informed Machine
              Learning and Time Series Forecasting.
            </p>
            <p className="Raleway_26px_500 opacity_7">
              Founded by a team of visionary engineers and data scientists,
              Smarttrak AI was created to address the growing need for
              intelligent, data-driven management of solar energy assets. With a
              rich background in AI, IoT, and renewable energy, our team brings
              together years of experience to push the boundaries of what’s
              possible in energy technology.
            </p>
            <p className="Raleway_26px_500 opacity_7">
              From our beginnings in predictive maintenance to our current focus
              on immersive 3D Digital Twins, we have continuously evolved,
              leveraging the latest advancements in AI and machine learning to
              meet the unique needs of the energy sector.
            </p>
          </div>
          <div className="width_45">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/aboutUs.png"
              alt="aboutus"
            />
          </div>
        </div>
        <motion.div
          className="expert_div flex_col gap_1vw text_cen"
          id="our_sol"
          ref={missionRef}
          initial={{ opacity: 0, y: 50 }}
          animate={missionControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600">Our Mission</p>
          <p className="Raleway_26px_500 opacity_7">
            To optimize and elevate energy operations by providing precise,
            real-time insights and predictive capabilities for better
            decision-making and enhanced performance. We are committed to
            delivering solutions that exceed customer expectations, with a
            relentless focus on the following solutions:
          </p>
          <Grid container className="home_grid_container" id="our_sol">
            {ourMission.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.4}
                xs={12}
                sm={12}
                md={3.4}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p gap_1vw"
                  id="padding_rem"
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "rgba(253, 167, 55, 0.20)" : "#FFF",
                  }}
                >
                  <div className="svg_Num expert_div">
                    {index % 2 === 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <path
                          opacity="0.1"
                          d="M59.9502 10.4542C53.3834 3.88744 44.4769 0.198241 35.19 0.198242C25.9032 0.198243 16.9967 3.88744 10.4298 10.4543C3.86302 17.0211 0.173828 25.9276 0.173828 35.2145C0.173828 44.5013 3.86303 53.4078 10.4298 59.9747L35.19 35.2145L59.9502 10.4542Z"
                          fill="#00529A"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <path
                          opacity="0.1"
                          d="M0.053669 10.4542C6.62048 3.88744 15.527 0.198241 24.8139 0.198242C34.1007 0.198243 43.0073 3.88744 49.5741 10.4543C56.1409 17.0211 59.8301 25.9276 59.8301 35.2145C59.8301 44.5013 56.1409 53.4078 49.5741 59.9747L24.8139 35.2145L0.053669 10.4542Z"
                          fill="#00529A"
                        />
                      </svg>
                    )}{" "}
                    <p className="Raleway_45px svg_text">0{index + 1}</p>
                  </div>

                  <p className="Raleway_31px_600 c_darkblack">{ele.title}</p>
                  <p className="Raleway_21px_500" id="text_left">
                    {ele.desc}
                  </p>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw text_cen"
          id="our_sol"
          ref={vissionRef}
          initial={{ opacity: 0, y: 50 }}
          animate={vissionControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600">Our Vision</p>
          <p className="Raleway_26px_500 c_black our_solution_main text_cen">
            Smarttrak AI envisions a future where renewable energy systems are
            not only self-optimizing but also contribute to a greener, more
            sustainable planet.
          </p>

          <p className="Raleway_26px_500 opacity_7 text_cen">
            We aim to load the global transition to sustainable energy by
            pioneering innovations in Digital Twin technology and artificial
            intelligence. We aim to redefine energy asset management, making it
            more efficient, insightful, and environmentally responsible.
          </p>
        </motion.div>
        <motion.div className="expert_div flex_col gap_1vw text_cen" id="our_sol"  ref={coreRef}
          initial={{ opacity: 0, y: 50 }}
          animate={coreControls}
          transition={{ duration: 0.5 }}>
          <p className="Raleway_48px_600">Our Core Values</p>
          <Grid container className="home_grid_container gap_5vw" id="our_sol"  >
            {coreVal.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={4.9}
                xs={12}
                sm={12}
                md={4.7}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p"
                  id="valData"
                  style={{ flex: 1 }}
                >
                  <div
                    className="expert_div flex_col gap_1vw padd_1vw"
                    id="text_just"
                  >
                    <p className="Raleway_31px_600 c_darkblack ">{ele.title}</p>
                    <p className="Raleway_26px_500 opacity_8">{ele.desc}</p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div className="expert_div flex_col gap_1vw text_cen" id="our_sol" ref={chooseRef}
        initial={{ opacity: 0, y: 20 }}
        animate={chooseControls}
        transition={{ duration: 0.5 }}>
          <p className="Raleway_48px_600">Why Choose Smarttrak AI?</p>
          <Grid container className="home_grid_container" id="our_sol" >
            {whyChoose.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={2.8}
                xs={12}
                sm={12}
                md={2.8}
                className="abour_mission_grid"
              >
                <Paper className="home_paper_casestudy flex_col">
                  <div className="about_svg expert_div">
                    <img
                      src={ele.imgUrl}
                      alt="ourproduct"
                      className="div_width"
                    />
                  </div>

                  <div className="gap_1vw flex_col expert_div text_cen">
                    <div className="flex_col expert_div">
                      <p className="Raleway_23px_700">{ele.title}</p>
                      <p className="Raleway_23px_700">{ele.subTitle}</p>
                    </div>
                    <p
                      className="Raleway_18px_500 c_dark opacity_8"
                      id="text_just"
                    >
                      {ele.desc}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <div
          className="expert_div flex_col gap_1vw text_cen"
          id="our_sol"
          ref={contactUsRef}
        >
          <p className="Raleway_48px_600">Connect With Us</p>
          <p className="Raleway_26px_500 font_400 opacity_7">
            Connect with Us: Reach Out for Inquiries, Collaboration, or Just to
            Say Hello!
          </p>

          <div className="expert_div about_contact">
            <form onSubmit={handleSubmit} className="about-form-container">
              {loading && <SimpleBackdrop open={loading} />}

              <div className="first-lastname-container">
                <div className="first-name">
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    id="firstName"
                    placeholder="First Name*"
                    onChange={handleFirstNameChange}
                    className={`form-input ${firstNameError ? "error" : ""}`}
                  />
                  {firstNameError && (
                    <div className="error-message">{firstNameError}</div>
                  )}
                </div>
                <br />

                <div className="last-name">
                  <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    id="lastName"
                    placeholder="Last Name*"
                    onChange={handleLastNameChange}
                    className={`form-input ${lastNameError ? "error" : ""}`}
                  />
                  {lastNameError && (
                    <div className="error-message">{lastNameError}</div>
                  )}
                </div>
                <br />
              </div>
              <input
                type="text"
                name="email"
                id="email"
                value={email}
                placeholder="E-mail*"
                onChange={handleEmailChange}
                className={`form-input ${emailError ? "error" : ""}`}
              />
              {emailError && <div className="error-message">{emailError}</div>}
              <PhoneInput
                country={"us"}
                value={contact}
                placeholder="Contact Number*"
                onChange={(contact) => setContact(contact)}
                className={`form-input ${contactError ? "error" : ""}`}
                style={{ flagWidth: "50px" }}
              />
              {contactError && (
                <div className="error-message">{contactError}</div>
              )}

              <textarea
                id="textc"
                onChange={handleTextAreaChange}
                rows="5"
                cols="50"
                placeholder="Write to us..."
                className={`form-input ${textAreaError ? "error" : ""}`}
              ></textarea>
              {textAreaError && (
                <div className="error-message">{textAreaError}</div>
              )}
              <label
                id="text_left"
                className="Raleway_26px_500 font_400 opacity_7 expert_div gap_1vw"
              >
                <input id="about-checkbox" type="checkbox" name="checkbox"  checked={isChecked}
          onChange={handleCheckboxChange} /> I
                hereby acknowledge the data protection declaration
                <br /> I have submitted.
              </label>

              <Button
                id="common-btn"
                type="submit"
                variant="contained"
                className="submit_btn"
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "7vh" }}>
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
