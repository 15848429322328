import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import ServiceContent from "./ServiceContent"
import "./service.scss";
import Navbar from '../MainLandingPage/Navbar';
import Divider from '@mui/material/Divider';
import Footer from "../MainLandingPage/Footer";

function EachService() {
    const { index } = useParams();
    const ourServicesIndex = parseInt(index);
    const selectedOurService = ServiceContent[ourServicesIndex];
    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);
    const [selectedFeature, setSelectedFeature] = useState(ServiceContent[ourServicesIndex].feature1);
    const [feaDescriptn, setFeaDescriptn] = useState(ServiceContent[ourServicesIndex].feature1Descriptn);
    const [feaImg, setFeaImg] = useState(ServiceContent[ourServicesIndex].feature1Img)

    const [dataIndex, setDataIndex] = useState(0);
    const [selectedDataFea, setSelectedDataFea] = useState(ServiceContent[ourServicesIndex].dataFea1);
    const [dataDescriptn, setDataDescriptn] = useState(ServiceContent[ourServicesIndex].dataFeaDes1);
    const [dataImg, setDataImg] = useState(ServiceContent[ourServicesIndex].dataFeaImg1);

    const handleListClick = (index) => {
        setSelectedFeatureIndex(index);
        const selectedFeature = ServiceContent[ourServicesIndex][`feature${index + 1}`];
        const feaDescriptn = ServiceContent[ourServicesIndex][`feature${index + 1}Descriptn`];
        const feaImg = ServiceContent[ourServicesIndex][`feature${index + 1}Img`];
        setSelectedFeature(selectedFeature);
        setFeaDescriptn(feaDescriptn);
        setFeaImg(feaImg);
    };

    const handleDataListClick = (index) => {
        setDataIndex(index);
        const selectedDataFea = ServiceContent[ourServicesIndex][`dataFea${index + 1}`];
        const dataDescriptn = ServiceContent[ourServicesIndex][`dataFeaDes${index + 1}`];
        const dataImg = ServiceContent[ourServicesIndex][`dataFeaImg${index + 1}`];
        setSelectedDataFea(selectedDataFea);
        setDataDescriptn(dataDescriptn);
        setDataImg(dataImg);
    }

    return (
        <div className='landing-container'>
            <div className="navbar">
    <Navbar />
  </div>
            <div style={{ position: "relative" }}>
                <svg style={{ position: "absolute", top:"0"}}
                    className="our-services-draw-line-two" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 299" fill="none">
                    <path className="vectorAnime" d="M83.7598 293.59C85.2325 293.59 86.4264 294.784 86.4264 296.257C86.4264 297.729 85.2325 298.923 83.7598 298.923C82.287 298.923 81.0931 297.729 81.0931 296.257C81.0931 294.784 82.287 293.59 83.7598 293.59ZM1.23438 0.0434623L1.23438 23.6552L0.234377 23.6552L0.234377 0.0434623L1.23438 0.0434623ZM19.087 66.1493L66.1071 112.232L65.4071 112.947L18.3871 66.8635L19.087 66.1493ZM84.2598 155.441L84.2598 296.257L83.2598 296.257L83.2598 155.441L84.2598 155.441ZM66.1071 112.232C77.7174 123.611 84.2598 139.184 84.2598 155.441L83.2598 155.441C83.2598 139.453 76.8255 124.137 65.4071 112.947L66.1071 112.232ZM1.23438 23.6552C1.23438 39.6432 7.66864 54.9584 19.087 66.1493L18.3871 66.8635C6.77678 55.4845 0.234377 39.9119 0.234377 23.6552L1.23438 23.6552Z" fill="#E6E6E6" />
                </svg>
            </div>
         
            <div className='each-service-box' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "19vh", marginBottom: "10vh" }}>
                <div className='about-main'>
                    <div className='about-submain'>
                        <p style={{textAlign:"center"}} className='bold-big-common-text'>{selectedOurService.title}</p>
                        <p id='auto-width' className="semibold-smalltext" style={{ width: "52vw", fontWeight: "700", textAlign: "center" }}>{selectedOurService.description}</p>
                    </div>
                </div>
            </div>
            <div style={{ position: "relative" }}>
                <svg style={{ position: "absolute" }}
                    className='service-draw-line' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 679 97" fill="none">
                    <path className="vectorAnime" d="M672.682 94.2613C672.682 95.734 673.876 96.9279 675.349 96.9279C676.822 96.9279 678.016 95.734 678.016 94.2613C678.016 92.7885 676.822 91.5946 675.349 91.5946C673.876 91.5946 672.682 92.7885 672.682 94.2613ZM248.942 10.2076L248.671 10.6278L248.942 10.2076ZM0 1.1254H216.415V0.125397H0V1.1254ZM248.671 10.6278L364.102 85.0992L364.644 84.2589L249.213 9.78747L248.671 10.6278ZM396.901 94.7613H675.349V93.7613H396.901V94.7613ZM364.102 85.0992C373.878 91.4066 385.266 94.7613 396.901 94.7613V93.7613C385.458 93.7613 374.259 90.462 364.644 84.2589L364.102 85.0992ZM216.415 1.1254C227.857 1.1254 239.056 4.42463 248.671 10.6278L249.213 9.78747C239.437 3.48008 228.049 0.125397 216.415 0.125397V1.1254Z" fill="#E6E6E6" />
                </svg></div>
            <div className='service-margin' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "15vh" }}>
                <img src={selectedOurService.image} alt='service' width="95%" className='our-service-img' />
            </div>
            <div className="service-heading">
                <p className="bold-common-text" style={{ color: "#111" }}>{selectedOurService.solTitleOne}</p>
                <p id="expertise-description" className="regular-common-text" style={{ width: "33%", color: "#111" }}>{selectedOurService.solDescriptnOne}</p>
            </div>
            <Divider style={{ border: "0.5px solid #E6E6E6", margin: "1vh 2.3vw" }} />
            <div className='service-feature-container'>
                <div className='feature-headings'>
                    <p className='bold-common-text' style={{ color: selectedFeatureIndex === 0 ? "#2F67F8" : "#111", fontWeight: selectedFeatureIndex === 0 ? "700" : "500", cursor: "pointer" }} onClick={() => handleListClick(0)}>{selectedOurService.feature1}</p>
                   {selectedOurService.feature2 ? <Divider style={{ border: "0.5px solid #E6E6E6", margin: "4vh 0vw" }} /> : ""}
                   {selectedOurService.feature2 ? <><p className='bold-common-text' style={{ color: selectedFeatureIndex === 1 ? "#2F67F8" : "#111", fontWeight: selectedFeatureIndex === 1 ? "700" : "500", cursor: "pointer" }} onClick={() => handleListClick(1)}>{selectedOurService.feature2}</p><Divider style={{ border: "0.5px solid #E6E6E6", margin: "4vh 0vw" }} /></>
                   : ""}
                   {selectedOurService.feature3 ? <><p className='bold-common-text' style={{ color: selectedFeatureIndex === 2 ? "#2F67F8" : "#111", fontWeight: selectedFeatureIndex === 2 ? "700" : "500", cursor: "pointer" }} onClick={() => handleListClick(2)}>{selectedOurService.feature3}</p><Divider style={{ border: "0.5px solid #E6E6E6", margin: "4vh 0vw" }} /></>
                    : ""}
                   {selectedOurService.feature4 ?  <p className='bold-common-text' style={{ color: selectedFeatureIndex === 3 ? "#2F67F8" : "#111", fontWeight: selectedFeatureIndex === 3 ? "700" : "500", cursor: "pointer" }} onClick={() => handleListClick(3)}>{selectedOurService.feature4}</p>
                     : ""}

                </div>

                <div className='feature-details'>
                    <p id='fea-title' className='bold-common-text' style={{ color: "#111" }}>{selectedFeature}</p>
                    <p id='fea-descriptn' className='semibold-smalltext' style={{ fontWeight: "400" }}>{feaDescriptn}</p>
                    <img src={feaImg} className='service-fea-img' alt='service' />
                </div>
            </div>
           {selectedOurService.solTitleTwo ? <><div className="service-heading">
                <p className="bold-common-text" style={{ color: "#111" }}>{selectedOurService.solTitleTwo}</p>
                <p id="expertise-description" className="regular-common-text" style={{ width: "21%", color: "#111" }}>{selectedOurService.solDescriptnTwo}</p>
            </div><Divider style={{ border: "0.5px solid #E6E6E6", margin: "1vh 2.3vw" }} /></>
            : ""}
           {selectedOurService.dataFea1 ? <div className='service-feature-container'>
                <div className='feature-headings'>
                    <p className='bold-common-text' style={{ color: dataIndex === 0 ? "#2F67F8" : "#111", fontWeight: dataIndex === 0 ? "700" : "500", cursor: "pointer" }} onClick={() => handleDataListClick(0)}>{selectedOurService.dataFea1}</p>
                    <Divider style={{ border: "0.5px solid #E6E6E6", margin: "4vh 0vw" }} />
                    <p className='bold-common-text' style={{ color: dataIndex === 1 ? "#2F67F8" : "#111", fontWeight: dataIndex === 1 ? "700" : "500", cursor: "pointer" }} onClick={() => handleDataListClick(1)}>{selectedOurService.dataFea2}</p>
                    <Divider style={{ border: "0.5px solid #E6E6E6", margin: "4vh 0vw" }} />

                    <p className='bold-common-text' style={{ color: dataIndex === 2 ? "#2F67F8" : "#111", fontWeight: dataIndex === 2 ? "700" : "500", cursor: "pointer" }} onClick={() => handleDataListClick(2)}>{selectedOurService.dataFea3}</p>
                    <Divider style={{ border: "0.5px solid #E6E6E6", margin: "4vh 0vw" }} />

                    <p className='bold-common-text' style={{ color: dataIndex === 3 ? "#2F67F8" : "#111", fontWeight: dataIndex === 3 ? "700" : "500", cursor: "pointer" }} onClick={() => handleDataListClick(3)}>{selectedOurService.dataFea4}</p>


                </div>
                <div className='feature-details'>
                    <p id='fea-title' className='bold-common-text' style={{ color: "#111" }}>{selectedDataFea}</p>
                    <p id='fea-descriptn' className='semibold-smalltext' style={{ fontWeight: "400" }}>{dataDescriptn}</p>
                    <img src={dataImg} className='service-fea-img' alt='service'/>
                </div>
            </div>
           : "" }
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className='why-container'>
                    <p className='bold-common-text'>Why Choose Smarttrak</p>
                    <Divider style={{ border: "0.5px solid #656565", margin: "5vh 0vw" }} />
                    <div className='why-choose'>
                        <div className='why-img-block'>
                            <img className='why_img' src='https://s3.amazonaws.com/smarttrak.co/landing-images/why_smarttrak.jpg' alt='why' />
                        </div>

                        <p id='auto-width' className='semibold-commontext' style={{ fontWeight: "400", color: "#FFF", width: "43%" }}>Choosing us means partnering with a team that’s committed to delivering excellence at every stage of your project. We blend our deep technical expertise with a client-centered approach to provide solutions that align with your business objectives and drive meaningful results. Experiencenext-generation DevOps and Data Engineering services with Smarttrak. Contact us today to learn how we can assist you in your digital transformation journey.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EachService