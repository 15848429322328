import React from "react";
import "./Mainhomepage.scss";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useState } from "react";
import { useSnackbar } from "notistack";
import BoldCommonText from "../../../IOT/Common/BoldCommonText";
import CommonText from "../../../IOT/Common/CommonText";
function MainHomePage() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isLifeScience, setIsLifeScience] = useState(false);
  const [isIot, setIsIot] = useState(false);

  const handleLifeScience = () => {
    setIsLifeScience(true);
    setIsIot(false);
  };
  const handleIot = () => {
    setIsIot(true);
    setIsLifeScience(false);
  };
  const handleStart = () => {
    if (isLifeScience) {
      history.push({
        pathname: `/home`,
      });
    } else if (isIot) {
      history.push({
        pathname: `/subscribe`,
      });
    } else {
      enqueueSnackbar("Please select your domain!!", {
        variant: "warning",
      });
    }
  };
  return (
    <div className="dashboard-main">
      <div className="smartmainlogo">
        <img src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg" width={160} alt="images" />
      </div>
      <Grid
        container
        sx={{
          width: "100% ! important",
          height: "90vh ! important",

          bgcolor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "0px ! important",
          marginLeft: "0px ! important",
          textAlign: "center",
        }}
      >
        <Grid item lg={1} xs={1} md={1}></Grid>
        <Grid
          item
          lg={10}
          xs={10}
          md={10}
          sx={{
            paddingLeft: "0px ! important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Paper
            sx={{
              width: "100% ! important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "none !important",
            }}
          >
            <BoldCommonText
              styles={{ color: "#1C629E" }}
              type="Bold_700"
              text="Welcome to SmartTrak!"
            />
            <CommonText
              styles={{ margin: "15px 35px 15px 35px", textAlign: "center" }}
              type="Regular_400"
              text="Effortlessly turn your data into valuable insights with our AI-powered
          software. Automate your analysis process and get instant access to
          accurate results that drive better decisions. Experience the ease and
          speed of AI with our user-friendly solution."
            />
            <BoldCommonText text="Choose your Domain" type="SemiBold_600" />
          </Paper>
          <Paper
            sx={{
              width: "100% ! important",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "none !important",
            }}
          >
            <Grid
              container
              sx={{
                width: "100% ! important",
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0px ! important",
                marginLeft: "0px ! important",
                gap: "10px",
              }}
            >
              <Grid
                item
                lg={3.5}
                xs={10}
                md={3.5}
                sx={{
                  paddingLeft: "0px ! important",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {isIot ? (
                  <Paper
                    sx={{
                      textAlign: "center",

                      width: "100% ! important",
                      borderRadius: "8px",
                      border: "1.5px solid #1c629e",
                      padding: "45px 30px",
                      background: "#1C629E",
                      boxShadow: "none !important",
                    }}
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/images/carbon_iot_platform_white.png"
                      className="imagesize"
                      alt="carbon domian not found"
                    />
                    <CommonText
                      styles={{ color: "#FFFFFF" }}
                      type="Regular_400"
                      text="Internet of things"
                    />
                  </Paper>
                ) : (
                  <Paper
                    sx={{
                      textAlign: "center",

                      borderRadius: "8px",
                      border: "1.5px solid #1c629e",
                      padding: "45px 30px",
                      width: "100% ! important",
                      boxShadow: "none !important",
                    }}
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/images/carbon_iot_platform.png"
                      className="imagesize"
                      alt="carbon domian not found"
                      onClick={handleIot}
                    />
                    <CommonText
                      styles={{ color: "#000000" }}
                      type="Regular_400"
                      text="Internet of things"
                    />
                  </Paper>
                )}
              </Grid>
              <Grid
                item
                lg={3.5}
                xs={10}
                md={3.5}
                sx={{
                  paddingLeft: "0px ! important",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {isLifeScience ? (
                  <Paper
                    sx={{
                      borderRadius: "8px",
                      width: "100% ! important",
                      textAlign: "center",
                      border: "1.5px solid #1c629e",
                      padding: "45px 30px",
                      background: "#1C629E",
                      boxShadow: "none !important",
                    }}
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/images/lifescience_white.png"
                      className="imagesize"
                      alt="lifescience domian not found"
                    />
                    <CommonText
                      styles={{ color: "#FFFFFF" }}
                      type="Regular_400"
                      text="Life Science"
                    />
                  </Paper>
                ) : (
                  <Paper
                    sx={{
                      textAlign: "center",

                      borderRadius: "8px",
                      border: "1.5px solid #1c629e",
                      padding: "45px 30px",
                      width: "100% ! important",
                      boxShadow: "none !important",
                    }}
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/images/lifescience.png"
                      className="imagesize"
                      alt="lifescience domian not found"
                      onClick={handleLifeScience}
                    />
                    <CommonText
                      styles={{ color: "#000000" }}
                      type="Regular_400"
                      text="Life Science"
                    />
                  </Paper>
                )}
              </Grid>
            </Grid>
            <CustomButton
              type="Simple"
              text="Lets get Started ..."
              styles={{
                padding: "15px 69px 15px 69px",
                width: "100%",
              }}
              onClick={handleStart}
            />
          </Paper>
        </Grid>
        <Grid item lg={1} xs={1} md={1}></Grid>
      </Grid>
    </div>
  );
}

export default MainHomePage;
