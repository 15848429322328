import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { scaleLinear, axisBottom, axisLeft, line, format } from 'd3';

function LineChart(props) {
  const { lossData, valData } = props;
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && lossData && valData) {
      const svg = select(chartRef.current);
      const margin = { top: 20, right: 220, bottom: 30, left: 40 };
      const width = 800 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      const xScale = scaleLinear()
      .domain([-0.3, lossData.length - 1])
      .range([0, width]);
      const maxData = Math.max(...lossData, ...valData);
      const tickInterval = 0.025;
      const tickCount = Math.ceil(maxData / tickInterval) + 1;

      const yScale = scaleLinear()
        .domain([0, tickInterval * (tickCount - 1)])
        .range([height, 0]);

      const yAxisTickValues = Array.from({ length: tickCount }, (_, index) => index * tickInterval);

      const xAxis = axisBottom(xScale)
        .tickValues(xScale.ticks().filter((d, i) => i % 2 === 0));

      const yAxis = axisLeft(yScale)
        .tickValues(yAxisTickValues)
        .tickFormat(format('.3f'));

      svg.selectAll('*').remove();

      const chart = svg
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      chart
        .append('path')
        .datum(lossData)
        .attr('fill', 'none')
        .attr('stroke', 'blue')
        .attr('stroke-width', 2)
        .attr('d', line().x((d, i) => xScale(i)).y(d => yScale(d)));

      chart
        .append('path')
        .datum(valData)
        .attr('fill', 'none')
        .attr('stroke', 'red')
        .attr('stroke-width', 2)
        .attr('d', line().x((d, i) => xScale(i)).y(d => yScale(d)));

      chart
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(xAxis);

      chart.append('g').call(yAxis);

      const legend = chart.append('g').attr('class', 'legend');

      legend
        .append('rect')
        .attr('x', width - 80)
        .attr('y', -20)
        .attr('width', 80)
        .attr('height', 60)
        .attr('fill', 'none')
        .attr('stroke', 'gray');

      legend
        .append('circle')
        .attr('cx', width - 70)
        .attr('cy', 0)
        .attr('r', 4)
        .attr('fill', 'blue');

      legend
        .append('circle')
        .attr('cx', width - 70)
        .attr('cy', 20)
        .attr('r', 4)
        .attr('fill', 'red');

      legend
        .append('text')
        .attr('x', width - 58)
        .attr('y', 4)
        .text('Dataset 1')
        .style('font-size', '12px');

      legend
        .append('text')
        .attr('x', width - 58)
        .attr('y', 24)
        .text('Dataset 2')
        .style('font-size', '12px');
    }
  }, [lossData, valData]);

  return <svg ref={chartRef} width={600} height={300} />;
}

export default LineChart;