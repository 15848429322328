import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SimpleBackdrop(props) {
  const {open} = props

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', 
        zIndex: 1,
        backgroundColor:"transparent",
           position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',borderRadius:"0.7rem"
         }}
        open={open}
        invisible={true} 
      >
        <CircularProgress sx={{color:"rgb(169 169 191)" }}/>
      </Backdrop>
    </div>
  );
}
