import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from "notistack";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {/* <React.StrictMode> */}
      <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <App />
      </SnackbarProvider>
    {/* </React.StrictMode> */}
  </ThemeProvider>
);

reportWebVitals();
