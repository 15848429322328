import React, { useState } from "react";
import { useDropzone } from 'react-dropzone';
import { styled, createTheme, ThemeProvider } from '@mui/system';
import { Container } from "@mui/material";
import CustomText from "../../components/CustomText/CustomText";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import { enqueueSnackbar } from "notistack";
import SimpleBackdrop from "../../components/common/SimpleBackdrop";
import CloseIcon from '@mui/icons-material/Close';
import { PredictAIService } from "../../services/PredictAIService";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LinearProgressBar from "../Common/LinearProgressBar";
import PredictResult from "./PredictResult";
import PredictError from "./PredictError";

const predictAIService = new PredictAIService();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  padding: "8vh 30px 20px 30px",
};

const steps = ["Select the format of the file", "Select the file and upload"];


const customTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      contrastText: 'white',
    },
  },
});

const MyStyledDropzoneContainer = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  border: '2px dashed #ccc',
  borderRadius: theme.spacing(2),
  backgroundColor: '#f3f3f3',
  color: '#333',
  height: '25vh',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

const MyStyledFileInput = styled('input')({
  display: 'none',
});

const MyStyledImage = styled('img')({
  width: 180,
  height: 140,
});

const MyStyledText = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

function PredictDataUpload() {
  const [currentStep, setCurrentStep] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fileTypeSelected, setFileTypeSelected] = useState("");
  const [openUploadModel, setOpenUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [modeljobId, setModeljobId] = useState({});
  const [progressBar, setProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isStatus, setIsStatus] = useState("");
  const [isPrediction, setIsPrediction] = useState({
    ID: {},
    prediction: {},
    prediction_value: {},
  });

  const location = useLocation();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUploadData,
    accept: 'image/*, video/*',
    maxFileSize: 1000000000,
    maxFiles: 20,
  });

  useEffect(() => {
    if (location.state) {
      const { job_id } = location.state;
      setModeljobId({
        job_id,
      });
    }
  }, [location.state]);
  const handleUploadData = (files) => {
    setUploadedFiles(files);
  };
  const handleUploadDrawer = () => {
    setOpenDrawer(true);
    setCurrentStep(0);
  };
  const handleSelectFileType = (type) => {
    setFileTypeSelected(type);
    setCurrentStep(1);
  };
  const handlePostData = async () => {
    try {
      if (uploadedFiles.length === 0) {
        enqueueSnackbar("Please upload a  file", { variant: "warning" });
      }
      if (uploadedFiles.length > 0 && fileTypeSelected === "CSV") {
        setLoading(true);
        const files = uploadedFiles;
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i], files[i].name);
        }
        formData.append("job_id", modeljobId.job_id);

        let res = await predictAIService.predictModelData(formData);
        if (res.status === 200) {
          enqueueSnackbar("File uploaded successfully", { variant: "success" });
          setIsStatus(res.data.status);
          setUploadedFiles("");
          setIsPrediction({
            ID: res?.data?.predicted_value?.data?.ID,
            prediction: res?.data?.predicted_value?.data?.prediction,
            prediction_value:
              res?.data?.predicted_value?.data?.prediction_value,
          });
          setProgressBar(true);

          setOpenUploadModal(true);
        }

        setLoading(false);
      }
    } catch (err) {
      console.log("Error in handleUploadClick", err);
      enqueueSnackbar("Error while uploading file", { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="lg" style={{ paddingTop: "50px" }}>
      {progress === 100 ? (
        <div>
          {isStatus === "success" ? (
            <PredictResult predictionData={isPrediction} />
          ) : (
            <PredictError />
          )}
        </div>
      ) : (
        <div>
          {!progressBar ? (
            <div>
              <CustomText
                type="SemiBold_25px"
                text="Upload Files "
                styles={{ color: "#1c629e" }}
              />
              <CustomText
                type="Regular_16px"
                text=" Please upload the data files to continue and predict the data "
                styles={{ color: "#1c629e" }}
              />

              <Container maxWidth="lg" className="container-dropbox">
                <CustomText
                  type="Regular_16px"
                  text="Upload file here or Click here"
                  styles={{ paddingTop: "30px" }}
                />
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/images/Vector.png"
                  width={160}
                  alt="images not found"
                  className="imgvector"
                  onClick={handleUploadDrawer}
                />
              </Container>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openDrawer}
                onClose={() => setOpenUploadModal(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={openDrawer}>
                  <Box sx={style}>
                    {loading && <SimpleBackdrop open={loading} />}
                    <CloseIcon
                      onClick={() => setOpenDrawer(false)}
                      className="close-btn"
                    />
                    <Stepper activeStep={currentStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    {currentStep === 0 && (
                      <div>
                        <CustomText
                          type="SemiBold_16px"
                          text="Select the Data Source"
                          styles={{ margin: "40px 0px 20px 30px" }}
                        />
                        <div className="file-format-container">
                          <div className="file-type-card">
                            <img src="https://s3.amazonaws.com/smarttrak.co/images/csv.jpeg" width={60} alt="csv" />
                            <CustomText
                              type="Regular_14px"
                              text="CSV Files"
                              styles={{ marginTop: 15 }}
                            />
                            <div
                              className="upload-file-button"
                              onClick={() => handleSelectFileType("CSV")}
                            >
                              <CustomText
                                type="Regular_12px"
                                text="Upload File +"
                                styles={{ color: "#FFF" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 1 && (
                      <div>
                        <CustomText
                          type="SemiBold_16px"
                          text={
                            fileTypeSelected === "CSV"
                              ? "Upload CSV Files"
                              : "Upload Image Files"
                          }
                          styles={{ margin: "30px 20px 20px 0px" }}
                        />
                        <div className="dropzone-wrapper">
                        <section>
                        <div {...getRootProps()}>
                          <ThemeProvider theme={customTheme}>
                        <MyStyledDropzoneContainer>
                          <MyStyledFileInput {...getInputProps()} />
                          <MyStyledImage src="https://s3.amazonaws.com/smarttrak.co/images/cloud-computing.png" alt="fileupload" />
                          <MyStyledText>Click to browse</MyStyledText>
                        </MyStyledDropzoneContainer>
                      </ThemeProvider>
                        </div>
                      </section>
                        </div>
                        <div className="upload-data-button-wrapper">
                          <CustomButton
                            type="Simple"
                            text="Predict Data"
                            styles={{ width: "12rem" }}
                            onClick={handlePostData}
                          />
                        </div>
                      </div>
                    )}
                  </Box>
                </Fade>
              </Modal>
            </div>
          ) : (
            <LinearProgressBar progress={progress} setProgress={setProgress} />
          )}{" "}
        </div>
      )}
    </Container>
  );
}

export default PredictDataUpload;
