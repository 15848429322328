import React, { useEffect, useRef, useState } from "react";
import * as NGL from "ngl";
import JSZip from "jszip";
import "./ThreedPdb.scss";

const ThreedPdb = () => {
  const [pdbId, setPdbId] = useState("");
  const [loadedStructures, setLoadedStructures] = useState([]);
  const [structureVisibility, setStructureVisibility] = useState({});
  const [representationType, setRepresentationType] = useState("cartoon");
  const [hasStage, setHasStage] = useState(false);
  const viewportRef = useRef(null);
  const stageRef = useRef(null);

  useEffect(() => {
    console.log("hasStage", hasStage);
    if (!hasStage) {
      const stage = new NGL.Stage(viewportRef.current);
      stageRef.current = stage;
      setHasStage(true);
    }

    const handleResize = () => {
      const stage = stageRef.current;
      stage.handleResize();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [hasStage]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const contents = e.target.result;
      handleZipFileUpload(contents);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleZipFileUpload = (zipContents) => {
    const zip = new JSZip();
    zip.loadAsync(zipContents).then((loadedZip) => {
      const pdbPromises = [];
      const newLoadedStructures = [];
      loadedZip.forEach((relativePath, file) => {
        if (file.name.toLowerCase().endsWith(".pdb")) {
          const pdbPromise = file.async("string").then((pdbContent) => {
            const structure = {
              pdbContent,
              id: generateUniqueId(),
              filename: file.name,
              component: null,
            };
            newLoadedStructures.push(structure);
            return structure;
          });
          pdbPromises.push(pdbPromise);
        }
      });

      Promise.all(pdbPromises).then(() => {
        setLoadedStructures(newLoadedStructures);
        setHasStage(false);
      });
    });
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const displayProteinStructure = (pdbContent, filename) => {
    const stage = stageRef.current;
    const structureVisibilityCopy = { ...structureVisibility };
    structureVisibilityCopy[filename] = !structureVisibilityCopy[filename];
    setStructureVisibility(structureVisibilityCopy);

    if (structureVisibilityCopy[filename]) {
      const blob = new Blob([pdbContent], { type: "text/plain" });
      const file = new File([blob], filename);

      stage.loadFile(file, { ext: "pdb" }).then(function (component) {
        const componentsCopy = [...loadedStructures];
        const index = componentsCopy.findIndex(
          (structure) => structure.filename === filename
        );

        if (index !== -1) {
          componentsCopy[index].component = component;
          setLoadedStructures(componentsCopy);
        }

        component.addRepresentation("cartoon", {
          color: "sstruc",
        });

        component.autoView();
      });
    } else {
      const component = loadedStructures.find(
        (structure) => structure.filename === filename
      )?.component;

      if (component) {
        stage.removeComponent(component);
        const componentsCopy = [...loadedStructures];
        const index = componentsCopy.findIndex(
          (structure) => structure.filename === filename
        );

        if (index !== -1) {
          componentsCopy[index].component = null;
          setLoadedStructures(componentsCopy);
        }
      }
    }
  };

  function toggleRepresentation() {
    const stage = stageRef.current;

    const checkedStructures = loadedStructures.filter(
      (structure) => structureVisibility[structure.filename]
    );

    if (checkedStructures.length > 0 && stage) {
      checkedStructures.forEach((checkedStructure) => {
        const component = checkedStructure.component;

        if (component) {
          const reprCount = component.reprList.length;
          const currentReprType = component.reprList[reprCount - 1].repr.type;

          component.removeAllRepresentations();
          const newReprType =
            currentReprType === "cartoon" ? "ball+stick" : "cartoon";
          component.addRepresentation(newReprType, {
            color: newReprType === "cartoon" ? "sstruc" : "atomindex",
            multipleBond: newReprType === "ball+stick" ? "offset" : false,
          });

          const structureVisibilityCopy = { ...structureVisibility };
          structureVisibilityCopy[checkedStructure.filename] = true;
          setStructureVisibility(structureVisibilityCopy);
        }
      });
      stage.viewer.requestRender();
    }
  }

  function resetCamera() {
    const stage = stageRef.current;
    stage.autoView();
  }

  function takeScreenshot() {
    const stage = stageRef.current;
    const screenshot = stage.makeImage().then(function (blob) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `screenshot_${pdbId}.png`;
      link.click();
    });
  }

  return (
    <div className="pdb-container">
      <input type="file" onChange={handleFileUpload} accept=".zip" />
      {loadedStructures.length > 0 ? (
        <div>
          <div
            ref={viewportRef}
            style={{ width: "940px", height: "500px", margin: "20px 0px" }}
          ></div>
          <div>
            {loadedStructures.map((structure) => (
              <label key={structure.id}>
                <input
                  type="checkbox"
                  checked={structureVisibility[structure.filename]}
                  onChange={() =>
                    displayProteinStructure(
                      structure.pdbContent,
                      structure.filename
                    )
                  }
                />
                {structure.filename}
              </label>
            ))}
            <button onClick={toggleRepresentation}>
              Toggle Representation (
              {representationType === "cartoon" ? "Ball and Stick" : "Cartoon"})
            </button>

            <button onClick={resetCamera}>Reset Camera</button>

            <button onClick={takeScreenshot}>Take Screenshot</button>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontFamily: "Poppins",
            marginLeft: "20px",
            color: "grey",
            marginTop: "10px",
          }}
        >
          Please upload a .zip file containing a .pdb file.
        </div>
      )}
    </div>
  );
};

export default ThreedPdb;
