import React, { useState, useEffect, useRef } from "react";
import "./MyProfile.scss";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CustomText from "../../../LifeScience/components/CustomText/CustomText";
import { useHistory } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { SubscriptionPlanService } from "../../Services/SubscriptionPlanService";
import { useSnackbar } from "notistack";

import { useLocation } from 'react-router-dom';

import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import { UserService } from "../../Services/UserService";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import CustomButton from "../../../LifeScience/components/CustomButton/CustomButton";
import useErrorHandler from "../../../utils/errorHandler";

const subscriptionPlanService = new SubscriptionPlanService();
const userService = new UserService();
function MyProfile() {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    border: "0.0625rem solid grey",
    borderRadius: "0.625rem",
    color: theme.palette.text.secondary,
    paddingLeft: "1.25rem",
  }));

  const theme = createTheme({
    palette: {
      primary: {
        main: "#007bff",
      },
      secondary: {
        main: "#6c757d",
      },
    },
    typography: {
      fontSize: 12,
    },
  });
  const [planDetails, setPlanDetails] = useState({});
  const [userData, setUserData] = useState({});
  const [plantListsArray, setPlantListsArray] = useState([]);
  const [gridHeightTwo, setGridHeightTwo] = useState(0);

const [activetab, setActiveTab] = useState(location.state?.id);

  const gridRefTwo = useRef(null);
  const [loadingState, setLoadingState] = useState({
    plantData: false,
    userData:false})
  const handleFetchError = useErrorHandler();
  useEffect(() => {
    if (gridRefTwo.current) {
      const heightTwo = gridRefTwo.current.offsetHeight;
      setGridHeightTwo(heightTwo);
    }
  }, [gridRefTwo]);

  const history = useHistory();
  // useEffect(() => {
  //   fetchUserPlans();
  // }, []);
  useEffect(() => {
    handleUserDetails();
    setActiveTab(location.state?.id)
  }, []);
  const listItems = [
    {
      text: "Personal Details",
      backgroundColor: "#BAC1F4",
      image: "https://s3.amazonaws.com/smarttrak.co/images/line-md_account.png",
    },
    {
      text: "My Subscriptions",
      backgroundColor: "#BAC1F4",
      image:
        "https://s3.amazonaws.com/smarttrak.co/images/solar_money-bag-outline.png",
    },
    {
      text: "Plant Details",
      backgroundColor: "#BAC1F4",
      image: "https://s3.amazonaws.com/smarttrak.co/images/bi_plug.png",
    },
    {
      text: "Help and Support",
      backgroundColor: "#BAC1F4",
      image:
        "https://s3.amazonaws.com/smarttrak.co/images/ion_help-outline.png",
    },
  ];

  useEffect(() => {
    getPlantsData();
  }, []);
  const handleItemClick = (id) => {
    setActiveTab(id);
    if (id===0) {
      setActiveTab(0)
    } else if (id===1) {
      setActiveTab(1)

    } else if (id===2) {
      setActiveTab(2)

  
    } else if (id===3) {
      setActiveTab(3)

    
    }
  };
 
  const getPlantsData = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, plantData: true }));
      const res = await userService.getPlantsList();
      if (res.status === 200) {
        setPlantListsArray(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, plantData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, plantData: false }));
      handleFetchError(error, "Plants Data");
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("plantIds");
    history.push("/signin");
  };
  const handleUserDetails = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, userData: true }));

      const res = await userService.getUserDetails();
      if (res.status === 200) {
        setUserData(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, userData: false }));

    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, userData: false }));
      handleFetchError(error, "Plants Data");
    }
  };
  const backToHome = () => {
    history.push("/iot-home");
  };

  const handleSubscribeNow = () => {
    history.push({
      pathname: "/subscribe",
    });
  };

  // const fetchUserPlans = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await subscriptionPlanService.getUserPlan();
  //     if (res.status === 200) {
  //       setPlanDetails(res.data);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     enqueueSnackbar(
  //       err.response?.data?.detail
  //         ? err.response?.data?.detail
  //         : "Error while fetching user plan details",
  //       { variant: "error",anchorOrigin: {
  //         vertical: 'bottom',
  //         horizontal: 'left',
  //     }, }
  //     );
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="profile-container">
    
      <div className="top-bar-profile">
        <div className="flex-start_profile" onClick={backToHome}>
          <ChevronLeftIcon  />
          <p className="Poppins_14px_regular">Back Home</p>
        </div>
        <div className="account_overview">
          <p className="Poppins_bold">Account Overview</p>

          <Button
            type="submit"
            variant="contained"
            className="logout-btn green-tab Poppins_16px_400"
            onClick={handleLogout}
          >
            Logout
            <img
              src="https://s3.amazonaws.com/smarttrak.co/images/circum_logout.png"
              alt="Logout"
            />
          </Button>
        </div>
      </div>
      <div className="user-main-container">
        <div>
          <Box sx={{ maxWidth: 1200 }}>
            <Item className="user-plan-details">
              <Grid container className="username-container">
                <div className="Poppins_bold">
                  {userData.first_name} {userData.last_name}
                </div>
                <div>
                  <hr className="horizontal-line" />
                </div>
                <ThemeProvider theme={theme}>
                  {listItems.map((item,id) => (
                    <ListItemButton
                      key={item.id}
                      onClick={() => handleItemClick(id)}
                      style={{
                        backgroundColor:
                        activetab === id
                            ? item.backgroundColor
                            : "inherit",
                      }}
                      className="btn-list green-tab"
                    >
                      <img
                        src={item.image}
                        alt="Image"
                        className="icon-images"
                      />
                      <ListItemText
                        primary={
                          <p className="Poppins_14px_regular">{item.text}</p>
                        }
                      />
                    </ListItemButton>
                  ))}
                </ThemeProvider>
              </Grid>
            
            </Item>
          </Box>
        </div>
        {activetab===0 && (
          <Grid className="item-container-profile" sx={{position:"relative"}}>
            <div className="space_btn_gap">
              <p className="Poppins_15px_black">Personal Info</p>

              <p className="Poppins_12px_rem">
                Below are the name, email and overview information for your
                account.
              </p>
            </div>
            <hr className="horizontal-line2" />

            <Paper className="gap-manager">
              <div className="center_div">
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/images/ph_user.png"
                  alt="img"
                  className="icon-images"
                />
                <p className="Poppins_12px_rem user-variable">{`Name \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0`}</p>
              </div>
              <div className="smb_value_container">
                <p className="Poppins_12px_Bold">{`${userData.first_name} \u00A0\u00A0${userData.last_name}`}</p>
              </div>
            </Paper>
            <Paper className="gap-manager">
              <div className="center_div">
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/images/mdi-light_email.png"
                  alt="img"
                  className="icon-images"
                />
                <p className="Poppins_12px_rem user-variable">Email Address</p>
              </div>
              <div className="smb_value_container">
                <p
                  className="Poppins_12px_Bold"
                  style={{ textTransform: "none" }}
                >
                  {userData.email}
                </p>
              </div>
            </Paper>
            <Paper className="gap-manager">
              <div className="center_div">
                <img
                  src="https://s3.amazonaws.com/smarttrak.co/images/solar_login-2-linear.png"
                  alt="img"
                  className="icon-images"
                />
                <p className="Poppins_12px_rem user-variable">{`Last Login \u00A0\u00A0\u00A0\u00A0 \u00A0\u00A0`}</p>
              </div>
              <div className="smb_value_container">
                <p className="Poppins_12px_Bold">
                  {userData.last_login ? userData.last_login : "--"}
                </p>
              </div>
            </Paper>
            {loadingState.userData && (
                <SimpleBackdrop open={loadingState.userData} />
              )}
          </Grid>
        )}
        {activetab===1 && (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div className="subscription-block">
              <Grid
                className="item-container-profile subscription-container"
                ref={gridRefTwo}
              >
                <div className="space_btn_gap">
                  <p className="Poppins_15px_black">My Subscriptions</p>

                  <p className="Poppins_12px_rem">
                    Here are the details of your subscriptions
                  </p>
                </div>
                <hr className="horizontal-line2" />

                <Paper className="gap-manager">
                  <div className="center_div">
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/images/ph_user.png"
                      alt="img"
                      className="icon-images"
                    />
                    <p className="Poppins_12px_rem user-variable">{`Subscription Type  \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 \u00A0\u00A0`}</p>
                  </div>

                  <div className="smb_value_container">
                    <p className="Poppins_12px_Bold">
                      {" "}
                      {Object.keys(planDetails).length > 0 &&
                      planDetails.subscription_type
                        ? planDetails.subscription_type
                        : "--"}
                    </p>
                  </div>
                </Paper>
                <Paper className="gap-manager">
                  <div className="center_div">
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/images/mdi-light_email.png"
                      alt="img"
                      className="icon-images"
                    />
                    <p className="Poppins_12px_rem user-variable">{`Registered Mail Address \u00A0\u00A0`}</p>
                  </div>

                  <div className="smb_value_container">
                    <p
                      className="Poppins_12px_Bold"
                      style={{ textTransform: "none" }}
                    >
                      {" "}
                      {userData.email}
                    </p>
                  </div>
                </Paper>
                <Paper className="gap-manager">
                  <div className="center_div">
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/images/solar_login-2-linear.png"
                      alt="img"
                      className="icon-images"
                    />
                    <p className="Poppins_12px_rem user-variable">
                      Last Subscription Payment
                    </p>
                  </div>

                  <div className="smb_value_container">
                    <p className="Poppins_12px_Bold">
                      {Object.keys(planDetails).length > 0 &&
                      planDetails.last_subscription_payment
                        ? planDetails.last_subscription_payment
                        : "--"}
                    </p>
                  </div>
                </Paper>
              </Grid>
              {Object.keys(planDetails).length > 0 &&
              planDetails.subscription_type ===
                "No Active Subscription available" ? (
                <div
                  className="subscribe-now"
                  style={{
                    height: gridHeightTwo,
                  }}
                >
                  <>
                    <div className="imp_notification_block">
                      <p className="Poppins_18px_rem">
                        You are not a subscribed user please subscribe now
                      </p>
                    </div>
                    <CustomButton
                      className="subscribe-now_btn"
                      type="Simple"
                      text="Subscribe Now"
                      onClick={handleSubscribeNow}
                      styles={{
                        color: "#000000",
                        textAlign: "center",
                        padding: "0.625rem",
                        cursor: "pointer",
                        backgroundColor: "#BAC1F4",
                        borderRadius: "0rem 0rem 0.5625rem 0.5625rem",
                        boxShadow: "0rem 0.25rem 0.625rem 0rem rgba(0, 0, 0, 0.25)",
                      }}
                    />
                  </>
                </div>
              ) : null}
            </div>
            <Grid className="transaction-history item-container-profile">
              <div className="space_btn_gap">
                <p className="Poppins_15px_black">Transaction History</p>

                <p className="Poppins_12px_rem">
                  {planDetails.subscription_type ===
                  "No Active Subscription available"
                    ? "No Active Subscription available"
                    : "Here are the details of your recent transactions"}
                </p>
              </div>
              <hr className="horizontal-line2" />

              {planDetails.subscription_type !==
                "No Active Subscription available" && (
                <>
                  <div style={{ display: "flex", gap: "33px" }}>
                    <div className="help_div padding_pro">
                      <p className="poppins_21px_italic">Current Cycle</p>
                     
                    </div>
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="Poppins_14px_regular">Current Payment</TableCell>
                        <TableCell className="Poppins_14px_regular">Subscription ID</TableCell>
                        <TableCell className="Poppins_14px_regular">Subscription Plan</TableCell>
                        <TableCell className="Poppins_14px_regular">Payment Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {Object.keys(planDetails).length > 0 && (
                          <>
                            <TableCell className="Poppins_14px_regular">
                              {planDetails.last_subscription_payment
                                ? planDetails.last_subscription_payment
                                : "--"}
                            </TableCell>
                            <TableCell className="Poppins_14px_regular">
                              {planDetails.subscription_id
                                ? planDetails.subscription_id
                                : "--"}
                            </TableCell>
                            <TableCell className="Poppins_14px_regular">
                              {planDetails.subscription_plan_name
                                ? planDetails.subscription_plan_name
                                : "--"}
                            </TableCell>
                            <TableCell className="Poppins_14px_regular">
                              {planDetails.last_subscription_payment_amount
                                ? planDetails.last_subscription_payment_amount
                                : "--"}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                  {Object.keys(planDetails).length > 0 &&
                  planDetails.transaction_history.previous_plan ===
                    "No Previous Plans available" ? (
                      <p className="Poppins_18px_rem padding_pro">{planDetails.transaction_history.previous_plan}</p>
                    
                  ) : (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Poppins_14px_regular">Last Payment</TableCell>
                          <TableCell className="Poppins_14px_regular">Subscription ID</TableCell>
                          <TableCell className="Poppins_14px_regular">Subscription Plan</TableCell>
                          <TableCell className="Poppins_14px_regular">Payment Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(planDetails).length > 0 &&
                          Object.keys(planDetails.transaction_history).map(
                            (item, index) => (
                              <TableRow key={index}>
                                <TableCell className="Poppins_14px_regular">{item ? item : "--"}</TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </Grid>
          </div>
        )}
        {activetab===2 && (
          <div className="plant-container" sx={{position:"relative"}}>
            <div className="details-conatiner">
              <div className="space_btn_gap">
                <p className="Poppins_15px_black">Plant Details</p>

                <p className="Poppins_12px_rem">
                  Here are the details of your Power Plant
                </p>
              </div>
              <hr className="horizontal-line2" />
              <div className="center_div_profile">
                <div className="space_btn_gap">
                  <p className="Poppins_12px_rem">Plant Name</p>
                  <p className="Poppins_12px_rem">onboard Date</p>
                  <p className="Poppins_12px_rem">Capacity</p>
                  <p className="Poppins_12px_rem">Description</p>
                  <p className="Poppins_12px_rem">Location</p>
                </div>
                <div className="space_btn_gap">
                  <p className="Poppins_12px_Bold">
                    {" "}
                    {plantListsArray[0]?.name ? plantListsArray[0]?.name : "--"}
                  </p>
                  <p className="Poppins_12px_Bold">
                    {" "}
                    {plantListsArray[0]?.onboard_date
                      ? plantListsArray[0]?.onboard_date
                      : "--"}
                  </p>
                  <p className="Poppins_12px_Bold">
                    {plantListsArray[0]?.capacity
                      ? `${plantListsArray[0].capacity} MW`
                      : "--"}
                  </p>
                  <p className="Poppins_12px_Bold">
                    {" "}
                    {plantListsArray[0]?.description
                      ? plantListsArray[0]?.description
                      : "--"}
                  </p>{" "}
                  <p className="Poppins_12px_Bold">
                    {" "}
                    {plantListsArray[0]?.location
                      ? `Lattitude - ${plantListsArray[0].location.latitude.toFixed(
                          2
                        )} \u00A0\u00A0 \u00A0\u00A0 Longitude - ${plantListsArray[0].location.longitude.toFixed(
                          2
                        )}   \u00A0\u00A0 \u00A0\u00A0 Timezone - ${plantListsArray[0].location.timezone}`
                      : "--"}
                  </p>
                </div>
              </div>
            </div>
            {planDetails.subscription_plan_name && (
              <Grid className="plant-location">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CustomText
                    type="Bold_14px"
                    text="Plant Location"
                    styles={{ color: "#000000" }}
                  />
                  <CustomText
                    type="Bold_11px"
                    text="View on maps"
                    styles={{ color: "#2A3568" }}
                  />
                </div>
                {/* <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={{ width: "100%", height: "94%" }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
                onLoad={(map) => setMap(map)}
              >
                <Marker position={center} />
              </GoogleMap> */}
                {/* <Globe lat={latitude} lon={longitude}/> */}
              </Grid>
            )}
             {loadingState.plantData && (
                <SimpleBackdrop open={loadingState.plantData} />
              )}
          </div>
        )}
        {activetab===3 && (
          <div className="help-container">
            <div className="help-support">
              <div className="space_btn_gap">
                <p className="Poppins_15px_black"> FAQ’s</p>

                <p className="Poppins_14px_regular">
                  Explore our Frequently Asked Questions (FAQ) section for quick
                  answers and expert guidance
                </p>
              </div>
              <hr className="horizontal-line2" />
              <div className="help_div">
                <div className="help_div">
                  <p className="Poppins_12px_Bold">How to update my details?</p>

                  <p className="Poppins_12px_rem">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum condimentum urna sed nulla tristique, in
                    dignissim lectus consequat. Mauris consectetur, libero at
                    aliquet faucibus, ligula mauris lobortis enim, sit amet
                    rutrum tellus tortor a nunc.
                  </p>
                </div>
                <div className="help_div">
                  <p className="Poppins_12px_Bold">
                    How to change my plant name?
                  </p>

                  <p className="Poppins_12px_rem">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum condimentum urna sed nulla tristique, in
                    dignissim lectus consequat. Mauris consectetur, libero at
                    aliquet faucibus, ligula mauris lobortis enim, sit amet
                    rutrum tellus tortor a nunc.
                  </p>
                </div>
                <div className="help_div">
                  <p className="Poppins_12px_Bold">
                    I’m getting error in my dashboard
                  </p>

                  <p className="Poppins_12px_rem">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum condimentum urna sed nulla tristique, in
                    dignissim lectus consequat. Mauris consectetur, libero at
                    aliquet faucibus, ligula mauris lobortis enim, sit amet
                    rutrum tellus tortor a nunc.
                  </p>
                </div>
              </div>
            </div>

            <div className="help-support contact-team" id="help-id"> 
              <div className="space_btn_gap">
                <p className="Poppins_15px_black">Contact Our Team</p>

                <p className="Poppins_12px_rem">
                  When you need professional assistance and dedicated support,
                  our team is here to help.
                </p>
              </div>
              <hr className="horizontal-line2" />

              <div
                className="contact-email"
              >
                <div className="help_div">
                  <p className="Poppins_15px_black">Chat With us</p>

                  <div className="center_div">
                    <img className="icon-images"
                      src="https://s3.amazonaws.com/smarttrak.co/images/mdi_phone-outline.png"
                      alt="phone"
                      
                    />
                    <p className="Poppins_12px_Bold">(+1) 510-945-8169</p>
                  </div>
                </div>
                <div className="help_div">
                  <p className="Poppins_15px_black">Email Us</p>

                  <div className="center_div">
                    <img className="icon-images"
                      src="https://s3.amazonaws.com/smarttrak.co/images/mdi-light_email.png"
                      alt="email"
                     
                    />
                    <p className="Poppins_12px_Bold email-link">
                      <a className="Poppins_12px_Bold" style={{textDecoration:"none",}}
                        href="mailto:info@smarttrak.co"
                       
                      >
                        info@smarttrak.co
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyProfile;
