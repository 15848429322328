import React, { useState, useEffect ,useCallback} from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import "../MainLandingPage/LandingPage.scss";
import "./MainCaseStudy.scss";
import Footer from "../MainLandingPage/Footer";
import ArrowRight from "../Common/ArrowRight";

function MainCaseStudy() {
  const caseStudies = [
    {
      role: "Solar Industry",

      firstarr: [
        {
          title:
            "Enhancing Solar Forecast Accuracy with Advanced Deep Learning",
          image:
            "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseOne1.png",
        },
        {
          title:
            "Real-Time Solar Plant Management with 3D Digital Twin Technology",
          image:
            "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseOne2.png",
        },
        {
          title:
            "Real-Time Solar Plant Monitoring with Drone Imagery and Edge Processing",
          image:
            "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseOne3.png",
        },
      ],
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseTwo.png",
      title:
        "Rapid Genomic Sequencing Model Training through GPU Infrastructure",
      role: "Life Science",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseThree.png",
      title:
        "Predicting Heavy Equipment Failure Using Real-Time Data and Deep Learning",
      role: "Manufacturing",
    },
    // {
    //   image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseFour.png",
    //   title:
    //     "Scalable Car License Plate Detection and Recognition Using Custom CNN and AWS EKS",
    //   role: "Automotive",
    // },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseFive.png",
      title: "Actionable Insights from Robot Data Leveraging Deep Learning.",
      role: "Robotics",
    },
    {
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/caseSix.png",
      title:
        "Deploying Computer Vision Models on NVIDIA Jetson for Industrial Machine Vision Applications",
      role: "Process Industry",
    },
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCaseStudies, setFilteredCaseStudies] = useState(caseStudies);
  const [hoveredIndices, setHoveredIndices] = useState([]);
  const [solOne, setSolOne] = useState(0);

  const [autoSlide, setAutoSlide] = useState(true);

  const history = useHistory();
  const isMobile = window.innerWidth <= 770;

  const handleSearch = useCallback((event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filteredStudies = caseStudies.filter((study) => {
      const isMainTitleMatch = study.title?.toLowerCase().includes(value);
      const isRoleMatch = study.role?.toLowerCase().includes(value);
      const isFirstArrTitleMatch = study.firstarr
        ? study.firstarr.some((item) => item.title.toLowerCase().includes(value))
        : false;

      return isMainTitleMatch || isRoleMatch || isFirstArrTitleMatch;
    });

    setFilteredCaseStudies(filteredStudies);
  }, []);
  const handleCaseStudy = (index) => {
    const paths = [
      ["./solar-forecast", "./solar-3d", "./solar-drone"],
      "./life-science",
      "./manufacturing",
      "./robotics",
      "./process-industry",
    ];
    const path = index === 0 ? paths[0][solOne] : paths[index];
    history.push(path);
  };
  const handleMouseEnter = (index) => {};

  const handleMouseLeave = (index) => {
    setHoveredIndices((prev) => {
      if (!prev.includes(index)) {
        const newHoveredIndices = [...prev, index];
        return newHoveredIndices;
      }
      return prev;
    });
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (autoSlide) {
        handleSolution(solOne + 1);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [solOne, autoSlide]);
  const handleSolution = (ind) => {
    const currentFirstArr = caseStudies[0].firstarr;
    if (currentFirstArr && currentFirstArr.length > 0) {
      setSolOne(ind >= currentFirstArr.length ? 0 : ind);
    }
  };
  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="expert_div gap_1vw flex_col" id="our_sol">
        <p className="Raleway_64px" id="our_sol">
          Case Studies
        </p>
        <p className="Raleway_25px font_500 text_cen">
          A Deep Dive into Smarttrak's Innovative Solutions and Operational
          Triumphs
        </p>

        <div className="search-box">
          <input
            className="semibold-smalltext"
            id="search-input"
            value={searchTerm}
            onChange={handleSearch}
            type="text"
            placeholder="Search Case Studies"
          />
          <div className="expert_div search_case">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M19.1129 17.5178L17.4596 15.9617M10.4329 16.7398C11.4642 16.7398 12.4855 16.5486 13.4383 16.1771C14.3911 15.8057 15.2568 15.2612 15.9861 14.5749C16.7153 13.8885 17.2938 13.0737 17.6885 12.177C18.0831 11.2802 18.2863 10.3191 18.2863 9.3484C18.2863 8.37775 18.0831 7.41661 17.6885 6.51985C17.2938 5.62308 16.7153 4.80827 15.9861 4.12191C15.2568 3.43556 14.3911 2.89112 13.4383 2.51967C12.4855 2.14821 11.4642 1.95703 10.4329 1.95703C8.35009 1.95703 6.35256 2.73576 4.87978 4.12191C3.40699 5.50807 2.57959 7.38809 2.57959 9.3484C2.57959 11.3087 3.40699 13.1887 4.87978 14.5749C6.35256 15.961 8.35009 16.7398 10.4329 16.7398V16.7398Z"
                stroke="white"
                stroke-width="1.25967"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <Grid
        container
        className="home_grid_container gap_1vw casestudy-grid"
        id="our_sol"
      >
        {filteredCaseStudies.length > 0 ? (
          filteredCaseStudies.map((item, index) => (
            <Grid item key={index} lg={4} xs={11.9} sm={5} md={4} 
            style={{ cursor: isMobile ? "pointer" : "auto" }}
            
                onClick={isMobile ? () => handleCaseStudy(index) : undefined}
            >
              <Paper className="home_paper_casestudy expert_div flex_col">
                <div>
                  <p className="Raleway_48px_600">{item.role}</p>
                </div>
                <div className="slide expert_div" key={index}>
                  {item.firstarr ? (
                    <img
                      src={item.firstarr[solOne].image}
                      alt={`Slide ${index + 1}`}
                      className="div_casestudy"
                      style={{
                        display: "block",
                      }}
                    />
                  ) : (
                    <img
                      src={item.image}
                      alt={`Slide ${index + 1}`}
                      className="div_casestudy"
                      style={{
                        display: "block",
                      }}
                    />
                  )}

                  <div className="overlay expert_div flex_col gap_1vw" id="overlay_c">
                    {item.firstarr ? (
                      <p className="Raleway_31px_600">
                        {item.firstarr[solOne].title}
                      </p>
                    ) : (
                      <p className="Raleway_31px_600">{item.title}</p>
                    )}
                    <div className="dots_container expert_div">
                      {index === 0 &&
                        item.firstarr &&
                        item.firstarr.map((folder, i) => (
                          <span
                            key={i}
                            id="dotWhite"
                            className={`dot ${solOne === i ? "active" : ""}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSolution(i);
                            }}
                          ></span>
                        ))}
                    </div>
                  </div>
{!isMobile &&(

                  <div
                    className="case_arrows expert_div cursor_p"
                    // style={{
                    //   padding: hoveredIndices.includes(index)
                    //     ? "0.7vw 7vw"
                    //     : "0.7vw",
                    //   borderRadius: hoveredIndices.includes(index)
                    //     ? "2.75vw"
                    //     : "50%",
                    // }}
                    onClick={() => handleCaseStudy(index)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                  >
                    <ArrowRight className="cursor_p" fill="white" />
                  </div>
)}

                </div>
              </Paper>
            </Grid>
          ))
        ) : (
          <div className="expert_div">
            <p className="Raleway_31px_600" style={{color:"red"}}>No relevant search found !</p>
          </div>
        )}
      </Grid>
      <Footer />
    </div>
  );
}

export default MainCaseStudy;
