import React from 'react';
import Chart from 'react-apexcharts';

const OperationalEfficiency = ({ data }) => {
  const {
    subtitle1,
    value1,
    subtitle2,
    value2,
    subtitle3,
    value3
  } = data;

  const chartData = {
    series: [
      {
        name: 'Performance Ratio',
        data: [parseFloat(value3)]
      },
      {
        name: 'CUF-AC Capacity',
        data: [parseFloat(value1)]
      },
      {
        name: 'CUF-DC Capacity',
        data: [parseFloat(value2)]
      }
    ],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '60%' 
        }
      },
      colors: ['#FDA737', '#00529A', '#AFD926'], 
      xaxis: {
        categories: [],
        max: 100 
      },
      yaxis: {
        show: false 
      },
      fill: {
        opacity: 1
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: (val) => `${val}%`
        }
      }
    }
  };

  return (
    <div className="operational-efficiency-card">
      <div className="flex-start_div">
      <div className="icon-exc-div">
                      <img
                        src={data?.PlantIcon}
                        alt="plant"
                        style={{ width: "2rem" }}
                      />
                    </div>
                    <div className="flex-start-column">
                    <p className="Poppins_18px_rem">{data?.title ? data.title : "--"}</p>
                    </div>
       
      </div>

      <div className="chart-container">
        <Chart options={chartData.options} series={chartData.series} type="bar" height={100}
        className="custom-tooltip-chart" />
      </div>

      <div className="metrics">
        <div className="metric">
          <span className="color-box orange"></span>
          <div>
            <p className='Poppins_12px_rem'>{subtitle3}</p>
            <p className='Poppins_18px_rem'>{value3}</p>
          </div>
        </div>
        <div className="metric">
          <span className="color-box blue"></span>
          <div>
            <p className='Poppins_12px_rem'>{subtitle1}</p>
            <p className='Poppins_18px_rem'>{value1}</p>
          </div>
        </div>
        <div className="metric">
          <span className="color-box green"></span>
          <div>
            <p className='Poppins_12px_rem'>{subtitle2}</p>
            <p className='Poppins_18px_rem'>{value2}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationalEfficiency;
