import React from 'react';
import {Switch, Route, useRouteMatch} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import SideBar from './SideBar';
import GetStarted from '../GetStarted/GetStarted';
import CreateAI from '../CreateAI/CreateAI';
import PredictAI from '../PredictAI/PredictAI';
import YourModals from '../YourModals/YourModals';
import Development from '../Common/Development';
import UpperBoard from './UpperBoard';
import PredictDataUpload from '../PredictAI/PredictDataUpload';
import ThreedPdb from '../PDB/ThreedPdb';
import ProteinAnalysis from '../ProteinAnalysis/ProteinAnalysis';
import AnalysisResult from '../ProteinAnalysis/AnalysisResult';
import ChatPdf from '../PDFChat/ChatPdf';
import ChatGpt from '../PDFChat/ChatGpt';

const mdTheme = createTheme();

function Dashboard() {
  let { path } = useRouteMatch();
  return (  
    <ThemeProvider theme={mdTheme}>
        <UpperBoard/>

      <Box sx={{ display: 'flex', flexGrow: 1, height: '100vh', overflow: 'auto' }}>
        <CssBaseline />
        <SideBar />
        <Switch>
          <Route exact path={path} component={GetStarted} />
          <Route exact path={`${path}/create-ai`} component={CreateAI} />
          <Route exact path={`${path}/predict-ai`} component={PredictAI} />
          <Route exact path={`${path}/your-models`} component={YourModals}/>
          <Route exact path={`${path}/development`} component={Development} />
          <Route exact path={`${path}/predict-upload`} component={PredictDataUpload} />
          <Route exact path={`${path}/pdb`} component={ThreedPdb} />
          <Route exact path={`${path}/protein-analysis`} component={ProteinAnalysis} />
          <Route exact path={`${path}/protein-analysis/analysis-result`} component={AnalysisResult} />
          <Route exact path={`${path}/pdf-chat`} component={ChatPdf} />
          <Route exact path={`${path}/pdf-chat/chat-gpt`} component={ChatGpt} />

         


        </Switch>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;