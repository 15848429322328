import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = "https://smarttrakportal.in/api";
const apiHelper = new ApiHelper();

export class InverterService {
  getInvertersFieldsData(bucket, inverterName) {
    const uri = `${BASE_URL}/iot/inverter/fields?bucket=${bucket}&measurement=${inverterName}`;
    return apiHelper.get(uri);
  }

  postGraphData(data) {
    const uri = `${BASE_URL}/iot/solar_power_plant/inverter-4/multi-fields`;
    return apiHelper.post(uri, data);
  }
  getInverterData() {
    const uri = `${BASE_URL}/iot/solar_power_plant/inverters`;
    return apiHelper.get(uri);
  }
  getInvertersDetails(inverterName) {
    const uri = `${BASE_URL}/iot/${inverterName}/data`;
    return apiHelper.get(uri);
  }
  getInvertersList() {
    const uri = `${BASE_URL}/iot/inverters?bucket=solar_power_plant`;
    return apiHelper.get(uri);
  }
  //new api
  // getExecutiveDashboardData(data) {
  //   const uri = `${BASE_URL}/iot/inverters/performance`;
  //   return apiHelper.post(uri, data);
  // }
  getExecutiveDashboardData(data) {
    const uri = `${BASE_URL}/iot/inverters/performance/test`;
    return apiHelper.post(uri, data);
  }
  getNewInvertersList(plant_id) {
    const uri = `${BASE_URL}/iot/plant/inverters?plant_id=${plant_id}`;
    return apiHelper.get(uri);
  }
  postPerformanceRatio(inverterName, data) {
    const uri = `${BASE_URL}/iot/${inverterName}/performance_ratio`;
    return apiHelper.post(uri, data);
  }
  
  postGenerationGraph(inverterName, data) {
    const uri = `${BASE_URL}/iot/${inverterName}/generation`;
    return apiHelper.post(uri, data);
  }
  postPerformanceOverview(inverterName, data) {
    const uri = `${BASE_URL}/iot/${inverterName}/performance_overview`;
    return apiHelper.post(uri, data);
  }
  postTemperatureOverview(inverterName, data) {
    const uri = `${BASE_URL}/iot/${inverterName}/temperature_overview`;
    return apiHelper.post(uri, data);
  }
  getInvertersParameters(inverterName, plant_id) {
    const uri = `${BASE_URL}/iot/plant/${inverterName}/parameters?plant_id=${plant_id}`;
    return apiHelper.get(uri);
  }
  postActivePOwerAndIrradiance(data) {
    const uri = `${BASE_URL}/iot/plant/inverters/active_power_irradiance`;
    return apiHelper.post(uri, data);
  }
  postCUF_AC_Graph(data) {
    const uri = `${BASE_URL}/iot/inverters/performance/cuf_ac`;
    return apiHelper.post(uri, data);
  }
  postCUF_DC_Graph(data) {
    const uri = `${BASE_URL}/iot/inverters/performance/cuf_dc`;
    return apiHelper.post(uri, data);
  }
  postAlram_Data(data) {
    const uri = `${BASE_URL}/iot/inverters/alarms`;
    return apiHelper.post(uri, data);
  }
  postAlram_Comments(data) {
    const uri = `${BASE_URL}/model/report/alarms`;
    return apiHelper.post(uri, data);
  }
  getInvertersPowerInfo(plant_id) {
    const uri = `${BASE_URL}/iot/plant/inverters/past_generated_power?plant_id=${plant_id}`;
    return apiHelper.get(uri);
  }
  postChatBotData(data) {
    const uri = `${BASE_URL}/model/report/chatbot`;
    return apiHelper.post(uri, data);
  }
  getAlarmsFailure(report_id) {
    const uri = `${BASE_URL}/iot/fault_reports?report_id=${report_id}`;
    return apiHelper.get(uri);
  }
  reportsDownload(data) {
    const uri = `${BASE_URL}/iot/plant/report/pdf`;
    return apiHelper.post(uri, data);
  }
  
}
