import React,{useEffect} from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import Divider from "@mui/material/Divider";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
function Manufacturing() {
  const clientControls = useAnimation();
  const challengeControls = useAnimation();
  const solControls = useAnimation();
  const whatControls = useAnimation();
  const qusControls = useAnimation();
  const craftControls = useAnimation();

  const resControls = useAnimation();

  const [clientRef, inViewClient] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [challengeRef, inViewChallenge] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [whatRef, inViewWhat] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [qusRef, inViewQus] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [craftRef, inViewCraft] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });

  useEffect(() => {
    clientControls.start({
      opacity: inViewClient ? 1 : 0,
      y: inViewClient ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [clientControls, inViewClient]);

  useEffect(() => {
    challengeControls.start({
      opacity: inViewChallenge ? 1 : 0,
      y: inViewChallenge ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [challengeControls, inViewChallenge]);
  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);
  useEffect(() => {
    whatControls.start({
      opacity: inViewWhat ? 1 : 0,
      y: inViewWhat ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [whatControls, inViewWhat]);
  useEffect(() => {
    qusControls.start({
      opacity: inViewQus ? 1 : 0,
      y: inViewQus ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [qusControls, inViewQus]);
  useEffect(() => {
    craftControls.start({
      opacity: inViewCraft ? 1 : 0,
      y: inViewCraft ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [craftControls, inViewCraft]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  const solutionM = [
    {
      title: "Predictive Maintenance",
      desc: "Real-time data analysis using deep learning can help predict equipment failures by analyzing patterns in sensor data, enabling proactive maintenance. This approach prevents unexpected downtime, reduces repair costs, and optimizes operational efficiency.",
    },
    {
      title: "Anomaly Detection",
      desc: "Deep learning models can identify unusual patterns in real-time equipment data, flagging potential issues before they lead to failure. By quickly spotting anomalies, industries can take immediate corrective actions, minimizing disruptions and avoiding production losses.",
    },
    {
      title: "Optimized Operations",
      desc: "Real-time data-driven insights from deep learning models can assist in optimizing equipment usage and scheduling. By analyzing the data, industries can make informed decisions about when to perform maintenance, allocate resources efficiently, and extend the lifespan of their machinery while maintaining productivity.",
    },
  ];
  const results = [
    {
      title: "Enhanced Reliability",
      extra: "and Downtime Reduction",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/ph1.jpg",
      desc: "Utilizing Deep Learning algorithms for predictive maintenance enhances machinery reliability in industries. Real-time data analysis enables early detection of potential failures, facilitating proactive measures to prevent escalation. This approach minimizes unplanned downtime, avoids costly breakdowns, and ensures smoother operations, ultimately increasing overall equipment effectiveness.",
    },
    {
      title: "Cost Savings",
      extra: "and Efficient Resource Allocation",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/ph3.jpg",

      desc: "Deep Learning-powered predictive maintenance reduces costs by minimizing reactive repairs. Accurate predictions optimize maintenance schedules, lowering unnecessary activities and allowing efficient resource allocation. This results in significant time and budget savings for industries.",
    },
    {
      title: "Data-Driven Insights",
      extra: "and Continuous Improvement",

      imgUrl: "https://s3.amazonaws.com/smarttrak.co/landing-images/ph2.jpg",

      desc: "Deep Learning in predictive maintenance provides valuable insights by analyzing equipment behavior patterns. Industries leverage this understanding for strategic decisions, optimizing processes and enhancing overall efficiency. Continuous learning improves model accuracy over time, enabling more precise predictions and refined maintenance strategies.",
    },
  ];

  const resultData = [
    {
      title: "Increased Operational Efficiency:",
      desc: "The ability to predict and prevent machinery failure led to fewer interruptions and increased operational efficiency.",
    },
    {
      title: "Reduced Maintenance Costs:",
      desc: "Proactive maintenance reduced the cost associated with unexpected equipment failure and prolonged the machinery's lifespan.",
    },
    {
      title: "Enhanced Safety:",
      desc: "Early failure detection improved the safety conditions for the client's workers by reducing the risk of accidents caused by equipment malfunction.",
    },
  ];

  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main"
        id="our_sol"
      >
        <div className="expert_div flex_crct gap_1vw justify_space">
          <div className="expert_div sol_sec flex_col gap_1vw align_item">
            <p className="Raleway_61px font_500 c_secBlack text_c">
              Predicting Heavy Equipment Failure Using Real-Time Data and Deep
              Learning
            </p>
            <p className="Raleway_31px_dark c_black opacity_8">
              Use AI to detect anomalies in Industrial machines
            </p>
          </div>

          <div className="expert_div width_40 box_shade">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/manufacturing.png"
              alt="solar deep learning"
            />
          </div>
        </div>

        <motion.div className="expert_div flex_reverse gap_3vw justify_space" id="our_sol"  ref={clientRef}
          initial={{ opacity: 0, y: 50 }}
          animate={clientControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureOne.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Client</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              Our client is a major industry player, reliant on heavy machinery
              for their day-to-day operations.Unpredicted equipment failure led
              to costly downtime and maintenance, disrupting their operation and
              reducing overall productivity.
            </p>
          </div>
        </motion.div>
        <motion.div className="expert_div flex_crct gap_3vw justify_space" id="our_sol"  ref={challengeRef}
          initial={{ opacity: 0, y: 50 }}
          animate={challengeControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Challenge</p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              The unpredictable nature of heavy equipment failure caused
              significant disruption and operational inefficiency. Traditional
              preventive maintenance methods were not sufficient to completely
              prevent unexpected breakdowns. The client needed a predictive
              solution capable of detecting potential failures in advance based
              on real-time data from the equipment.
            </p>
          </div>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureFour.png"
              alt="solar deep learning"
            />
          </div>
        </motion.div>
        <motion.div className="expert_div flex_reverse gap_3vw justify_space" id="our_sol"  ref={solRef}
          initial={{ opacity: 0, y: 50 }}
          animate={solControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/solarFeatureThree.png"
              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">
              Solution Methodology
            </p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              At Smarttrak, we recognized the potential of deep learning to
              accurately predict equipment failure. We developed a custom deep
              learning model capable of analyzing real-time data from the
              client's equipment. This model was trained on a wealth of
              historical data, including machinery operating conditions,
              previous failure records, and maintenance logs.The model was
              designed to continuously monitor the equipment, process incoming
              data, and alert the client when potential issues were detected.
              This proactive approach allowed the client to schedule maintenance
              before a failure could occur, minimizing disruption and potential
              damage.
            </p>
          </div>
        </motion.div>
        <div className="expert_div flex_col gap_1vw" id="our_sol">
          <p className="Raleway_48px_600 c_dark text_c" id="our_sol">
            What is Heavy equipment failure?
          </p>
          <p className="Raleway_31px_dark opacity_8 text_cen text_j">
            Heavy equipment failure in industries refers to the malfunction or
            breakdown of large machinery used for various tasks such as
            construction, manufacturing, or mining. These failures can result
            from mechanical issues, electrical faults, wear and tear, or
            improper maintenance, leading to downtime, project delays, and
            financial losses. Preventive maintenance and regular inspections are
            crucial to mitigate the impact of heavy equipment failures in
            industrial operations.
          </p>
        </div>
        <motion.div className="expert_div flex_col text_cen" id="our_sol"  ref={whatRef}
          initial={{ opacity: 0, y: 50 }}
          animate={whatControls}
          transition={{ duration: 0.5 }}>
          <Grid container className="home_grid_container gap_3vw">
            {solutionM.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.7}
                xs={12}
                sm={12}
                md={3.6}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p"
                  id="valData"
                  style={{ flex: 1 }}
                >
                  <div
                    className="expert_div flex_col gap_1vw padd_1vw"
                    id="text_just"
                  >
                    <p className="Raleway_31px_600 c_lightdrak ">{ele.title}</p>
                    <p className="Raleway_21px_500 c_dark opacity_8">
                      {ele.desc}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div className="expert_div flex_col gap_1vw margin_4vw" id="our_sol"  ref={qusRef}
          initial={{ opacity: 0, y: 50 }}
          animate={qusControls}
          transition={{ duration: 0.5 }}>
          <p className="Raleway_48px_600 c_dark" id="our_sol">
            How does Deep Learning help in Prediction of machine failure ?
          </p>
          
          <div className="div_casestudy life_gene" id="our_sol">
            <img
              className="expertise_image"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/yManufacturing.png"
              alt="our solution"
            />
          </div>
          <div id="our_sol" className="expert_div flex_col gap_1vw">
            <p className="Raleway_26px_500 opacity_8" id="text_just">
              Deep Learning plays a pivotal role in predicting machine failure
              by harnessing real-time sensor data from industrial equipment.
              This process involves gathering data on variables like
              temperature, vibration, and pressure, followed by preprocessing
              and extracting relevant features that capture normal operational
              behavior. Neural network models, such as Recurrent Neural Networks
              (RNNs) or Long Short-Term Memory (LSTM) networks, are then trained
              on historical data to discern patterns associated with equipment
              faults.
            </p>
            <p className="Raleway_26px_500 opacity_8 text_cen" id="text_just">
              Once trained, these models analyze incoming data in real time and
              trigger alerts when deviations from established norms are
              detected, enabling timely intervention. The models can adapt
              through continuous learning and validation, ensuring accurate
              predictions. Ultimately, this approach empowers industries to
              proactively address potential failures, minimizing downtime,
              reducing costs, and optimizing operational efficiency.
            </p>
          </div>
        </motion.div>
        <motion.div className="expert_div flex_col text_cen" id="our_sol"  ref={craftRef}
          initial={{ opacity: 0, y: 50 }}
          animate={craftControls}
          transition={{ duration: 0.5 }}>
          <div className="expert_div width_60 flex_col gap_1vw">
            <p className="Raleway_48px_600 c_dark">Crafting Solutions</p>
            <p className="Raleway_31px_dark opacity_8 text_cen">
              Navigating Challenges with Strategic Ingenuity in Problems That
              Have Been Smartly Resolved.
            </p>
          </div>
          <Grid container className="home_grid_container" id="our_sol">
            {results.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.4}
                xs={12}
                sm={12}
                md={3.4}
                className="home_grid_product"
                id="border_none"
              >
                <Paper className="home_paper_product">
                  <div className="solar_divs result_down">
                    <img
                      src={ele.imgUrl}
                      alt="ourproduct"
                      className="height_w border_5"
                    />
                  </div>

                  <div className="expert_div result_up width_97">
                    <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                      <div>
                        <p className="Raleway_23px_dark">{ele.title}</p>
                        <p className="Raleway_23px_dark">{ele.extra}</p>

                      </div>
                      <p
                        className="Raleway_21px_500 c_dark opacity_8 height_18vw"
                        id="text_just"
                      >
                        {ele.desc}
                      </p>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw align_item margin_4vw"
          id="our_sol"
          ref={resRef}
          initial={{ opacity: 0, y: 20 }}
          animate={resControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px_600 c_dark">Results</p>
          <p className="Raleway_26px c_black">
            Our custom deep learning solution had a significant positive impact
            on the client's operations:
          </p>
          <div className="expert_div gap_1vw flex_col">
            {resultData.map((ele, index) => (
              <div>
                <div className="expert_div gap_1vw align_item">
                  <p className="Raleway_26px c_lightgray">0{index + 1}</p>
                  <p className="Raleway_25px font_500 opacity_8" id="text_just">
                    <span className="c_blue">{ele.title} </span>
                    {ele.desc}
                  </p>
                </div>
                <Divider
                  className="yellow_line"
                  style={{
                    border: "0.1px solid #FDA737",
                  }}
                />
              </div>
            ))}
          </div>
        </motion.div>
        <div
          className="expert_div flex_col gap_1vw align_item margin_4vw margin_bottom"
          id="our_sol"
        >
          <p className="Raleway_48px_600 c_dark">Conclusion</p>
          <p className="Raleway_26px_500 opacity_8" id="text_just">
            This project highlights Smarttrak's commitment to leveraging
            cutting-edge AI and deep learning technologies to solve our clients'
            unique challenges. By understanding our clients' needs and applying
            our technical expertise, we provide innovative solutions that drive
            efficiency, reduce costs, and enhance safety.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Manufacturing;
