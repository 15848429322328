import React, { useState, useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import "./BarPlotForIOT.scss";
import "./graphCSS.css";
import { Tooltip } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Divider from "@mui/material/Divider";
import { Backdrop, Modal, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./../Graphs/PlantDetails.scss";
import CloseIcon from '@mui/icons-material/Close';
const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  padding: "8vh 30px 20px 30px",
  backgroundColor: "white",
  color: "white",
};

const NewBarLineChart = (props) => {
  const {
    legendName,
    isValueData,
    titleText,
    calenderClick,
    stringDurationFun, selectedDateRangeProp, isFilter, separateYaxis,
    isMobileAndTab,
    isDesktopView,isFullScreen, toggleFullScreen,
  } = props;

  const [tempStartDateTime, setTempStartDateTime] = useState(new Date());
  const [tempStopDateTime, setTempStopDateTime] = useState(new Date());
  const [isDurationType, setIsDurationType] = useState("Hourly");
  const [series, setSeries] = useState([]);
  const [xaxisData, setXaxisData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const [isStartTimeEnergy, setIsStartTimeEnergy] = useState("");
  const [isStopTimeEnergy, setIsStopTimeEnergy] = useState("");
  const [dayCount, setDayCount] = useState(0);
  const [durationType, setDurationType] = useState([
    "2 MIN",
    "15 MIN",
    "30 MIN",
    "Hourly",
  ]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const scrollableContainerRef = useRef(null);

  const getYAxisConfig = (name, opposite, min, max) => ({
    title: {
      text: name,
      style: { color: '#000000' },
    },
    opposite,
    labels: { style: { color: '#000000' } },
    min: min,
    max: max,
  });
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if ((isStartTimeEnergy && isStopTimeEnergy) || (tempStartDateTime && tempStopDateTime)) {
      let availableFilters = [];
  
      if (dayCount > 0 && dayCount <= 5) {
        availableFilters = ["Daily"];
      } else if (dayCount > 5 && dayCount <= 61) {
        availableFilters = ["Daily", "Weekly"];
      } else if (dayCount > 61 && dayCount < 365) {
        availableFilters = ["Weekly","Monthly"];
      } else if (dayCount >= 365) {
        availableFilters = ["Yearly"];
      } else if (dayCount === 0) {
        availableFilters = ["2 MIN", "15 MIN", "30 MIN", "Hourly"];
      }
  
      setDurationType(availableFilters);
  
      if (availableFilters.length > 0 && !availableFilters.includes(isDurationType)) {
        setIsDurationType(availableFilters[0]); 
      }
    }
  }, [dayCount, isDurationType, isStopTimeEnergy, isStartTimeEnergy, tempStartDateTime, tempStopDateTime]);
  
  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen(); // Exit full-screen when user exits
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);
   useEffect(() => {
    if (isValueData && isValueData.series) {
      if ((isStartTimeEnergy && isStopTimeEnergy) || (tempStartDateTime && tempStopDateTime)) {
        const newYaxis = {
          title: {
            text: isValueData?.yaxis?.title?.text !== undefined ? isValueData.yaxis.title.text : "",
            style: {
              color: "black"
            }
          },
          min: isValueData.min,
          max: isValueData.max
        }
        setOptions((prevOptions) => ({
          ...prevOptions,
          chart: {
            type: isValueData?.chart?.type,
            toolbar: {
              autoSelected: 'pan',
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          yaxis: separateYaxis ? isValueData.series.map((series, index) => getYAxisConfig(series.name, series.separateYAxis, series.min, series.max)) : newYaxis,
        }));
        setSeries(isValueData.series);
        setXaxisData(isValueData.categories);
      } else {
        const newYaxis = {
          title: {
            text: isValueData?.yaxis?.title?.text !== undefined ? isValueData.yaxis.title.text : "",
            style: {
              color: "black"
            }
          },
          min: isValueData.min,
          max: isValueData.max
        }
        setOptions((prevOptions) => ({
          ...prevOptions,
          chart: {
            type: isValueData?.chart?.type,
            toolbar: {
              autoSelected: 'pan',
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          yaxis: separateYaxis ? isValueData.series.map((series, index) => getYAxisConfig(series.name, series.separateYAxis, series.min, series.max)) : newYaxis,
        }));
        setSeries(isValueData.series);
        setXaxisData(isValueData.categories);
      }
    }
  }, [isValueData]);
 
  const [options, setOptions] = useState({
    chart: {
      type: isValueData?.chart?.type,
      toolbar: {
        autoSelected: 'pan',
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: xaxisData,
      //   type: "datetime",

      labels: {
        datetimeUTC: false,

        style: {
          colors: "#000000",
        },
      },
    },

    fill: {
      type: isValueData?.chart?.type === "area" ? "gradient" : "solid",
      gradient: {
        shade: "light",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },

    grid: {
      show: isValueData?.chart?.type === "area" ? false : true,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: isValueData?.chart?.type === "area" ? false : true,
        },
      },
    },
    legend: {
      // position: "top",
      showForSingleSeries: true,
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd HH:mm",
      },
      y: {
        formatter: function (value) {
          if (value === null || typeof value === "undefined") {
            return ""; // Return an empty string if value is undefined
          }
          return value.toFixed(2) + isValueData.series.units;
        },
      },
    },
    toolbar: {
      show: false,
    },
  });

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      chart: {
        type: isValueData?.chart?.type,
        toolbar: {
          autoSelected: 'pan',
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      stroke: {
        width: isValueData?.chart?.type === "area" ? 2 : 0,
      },
      grid: {
        ...prevOptions.grid,

        show: isValueData?.chart?.type === "area" ? false : true,
        borderColor: "#BDBDBD",
        strokeDashArray: 5,
        position: "back",
        xaxis: {
          lines: {
            show: isValueData?.chart?.type === "area" ? false : true,
          },
        },
      },
      xaxis: {
        ...prevOptions.xaxis,
        categories: xaxisData,
        // type: "datetime",

        labels: {
          datetimeUTC: false,

          style: {
            colors: "#000000",
          },
        },
      },
      fill: {
        type: isValueData?.chart?.type === "area" ? "gradient" : "solid",

        gradient: {
          shade: "light",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      tooltip: {
        x: {
          format: "yyyy-MM-dd HH:mm", // Apply the desired format
        },
        y: {
          formatter: function (value) {
            if (value === null || typeof value === "undefined") {
              return ""; 
            }
            return value.toFixed(2);
          },
        },
      },
      legend: {
        ...prevOptions.legend,
        labels: {
          colors: "#000000",
        },
      },
    }));
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft = scrollableContainerRef.current.scrollWidth;
    }
  }, [
    isValueData,
    isFullScreen,
    xaxisData,
    legendName,
    titleText,
  ]);
  const chartContainerRef = useRef(null);
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;

    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);

    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(xml);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);
  };

  const handleStopDateTimeChange = (date) => {
    setTempStopDateTime(date);
    const currentDate = new Date();
    const selectedDate = new Date(date);
    if (selectedDate > currentDate) {
      date = currentDate.toISOString();
    }
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    let hours, minutes;
    if (selectedDate.toDateString() === currentDate.toDateString()) {
      hours = currentDate.getHours().toString().padStart(2, "0");
      minutes = currentDate.getMinutes().toString().padStart(2, "0");
    } else {
      hours = "23";
      minutes = "59";
    }
    const formattedTimestampStop = `${year}-${month}-${day}T${hours}:${minutes}:00%2B05:30`;
    props.StopTimer(formattedTimestampStop);
    setIsStopTimeEnergy(formattedTimestampStop);
    props.openDrawerValue(openDrawer);
  };

  const handleStartDateTimeChange = (date) => {
    setTempStartDateTime(date);
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    const formattedTimestampStart = `${year}-${month}-${day}T00:00:00%2B05:30`;
    props.StartTimer(formattedTimestampStart);

      setIsStartTimeEnergy(formattedTimestampStart);
      props.openDrawerValue(openDrawer);
    
  };
 
  const handleClear = (event) => {
    event.preventDefault();
    setDayCount(0)
    const currentDate = new Date();
    setTempStopDateTime(currentDate)
    setTempStartDateTime(currentDate)
    setSelectedDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  const funDate = (date) => {
    if (date instanceof Date) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    const parts = date.split(/[T:+-]/);
    const date1 = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
    return date1;
  };

  const initialDateRange = selectedDateRangeProp.map((range) => {
    return {
      ...range,
      startDate: funDate(range.startDate),
      endDate: funDate(range.endDate),
    };
  });

  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);
  const handleApply = () => {
    let selectedDuration;
  
    if (dayCount === 0) {
      selectedDuration = isDurationType || "Hourly"; 
    } else if (dayCount > 0 && dayCount <=5) {
      selectedDuration =  isDurationType || "Daily";
    } 
    else if (dayCount > 5 && dayCount <=61) {
      selectedDuration =  isDurationType || "Weekly";
    } else if (dayCount > 61 && dayCount < 365) {
      selectedDuration =isDurationType || "Monthly";
    
    } else if (dayCount >= 365) {
      selectedDuration = "Yearly";
    }
  
    if (selectedDuration) {
      props.stringDurationFun(getDurationCode(selectedDuration));
    }
  
    handleStartDateTimeChange(tempStartDateTime);
    handleStopDateTimeChange(tempStopDateTime);
    setSelectedDateRange((prevSelectedDateRange) => [
      {
        ...prevSelectedDateRange[0],
        startDate: funDate(tempStartDateTime),
        endDate: funDate(tempStopDateTime),
      },
      ...prevSelectedDateRange.slice(1),
    ]);
    setOpenDrawer(false);
  };
  const getDurationCode = (durationType) => {
    setIsDurationType(durationType);

    let availableFilters;
    if (dayCount > 0 && dayCount <= 5) {
      availableFilters = ["Daily"];
    } else if (dayCount > 5 && dayCount <= 61) {
      availableFilters = ["Daily", "Weekly"];
    } else if (dayCount > 61 && dayCount < 365) {
      availableFilters = ["Weekly", "Monthly"];
    } else if (dayCount >= 365) {
      availableFilters = ["Yearly"];
    } else if (dayCount === 0) {
      availableFilters = ["2 MIN", "15 MIN", "30 MIN", "Hourly"];
    } else {
      availableFilters = []; 
    }
  
    setDurationType(availableFilters);
  
    switch (durationType) {
      case "2 MIN":
        return "2min";
      case "15 MIN":
        return "15min";
      case "30 MIN":
        return "30min";
      case "Hourly":
        return "h";
      case "Daily":
        return "D";
      case "Weekly":
        return "W";
      case "Monthly":
        return "ME";
      case "Yearly":
        return "YE";
      default:
        return "";
    }
  };
  const disableFutureDates = (date) => {
    const today = new Date();
    return date > today;
  };

  const calculateDateRangeLength = (startDate, endDate) => {
    const timeDifference = endDate.getTime() - startDate.getTime();
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
    return dayDifference;
  };
  const handleClose = () => {
    setOpenDrawer(false)
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p
            className="Poppins_18px_rem"
            style={{ color: "#212121" }}
          >
            {isValueData?.title?.text !== undefined ? isValueData.title.text : ""}
          </p>{" "}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Download" arrow>
              <DownloadIcon
                onClick={downloadChart} className='icon-css'
              />
            </Tooltip>

            <Tooltip title="Calendar" arrow>
              <CalendarMonthRoundedIcon
                onClick={handleTimings}
                className='icon-css'
              />
            </Tooltip>
            <Tooltip title="FullScreen" arrow>
              <button
                onClick={toggleFullScreen}
                style={{
                  width: "1.6vw !important", height: "fit-content !important",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  color: "#00529A",
                }}
              >
                {isFullScreen ? (
                  <FullscreenExitIcon className='icon-css'
                  />
                ) : (
                  <FullscreenIcon className='icon-css'
                  />
                )}
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />
      {openDrawer && (
      
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDrawer}>
          <Box sx={boxStyle}>
          <div onClick={handleClose} className="handleclose-subdiv" style={{ position: "absolute" }}>
          <CloseIcon sx={{color:"#2F67F8"}} />
            </div>

            <div className="calender-container">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="range_title"
                  style={{
                    width: "fit-content",
                    position: "relative",
                    top: "2rem",
                  }}
                >
                  <p
                    className="raleway_20px_rem"
                    style={{ color: "black", fontWeight: "700" }}
                  >
                    Range Filters
                  </p>
                </div>
               
                  <DateRangePicker
                    months={isMobile ? 1 : 2}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    direction="horizontal"
                    ranges={selectedDateRange}
                    disabledDay={disableFutureDates}
                    onChange={(ranges) => {
                      const startDate = ranges.selection.startDate;
                      const endDate = ranges.selection.endDate;
                      const lengthOfRange = calculateDateRangeLength(startDate, endDate); 
                      setDayCount(lengthOfRange)
                      if (startDate === endDate) {
                        setTempStartDateTime(startDate); 
                        setTempStopDateTime(endDate); 
                      } else {
                        setTempStartDateTime(startDate); 
                        setTempStopDateTime(endDate);
                      }
                      setSelectedDateRange([ranges.selection]);
                    }}
                  />
              </div>
              {isFilter && <div className="intervals_container">
                <div className="interval_filter_combined">
                <p className="raleway_20px_rem" style={{ color: "black", fontWeight: "700", paddingLeft:"1rem" }}>Interval Filters</p>
                <div className="filter_container">
                {durationType.map((field, index)=>{
                 return (<div key={index} className="filter_button" style={{
                  backgroundColor: isDurationType === field ? 'rgb(222 231 246)' : 'transparent',
                }} onClick={() => getDurationCode(field)}>
                  <p className="rdrStaticRangeLabel">{field
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())}</p>
                </div> )})}
                </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf:"end",
                    gap: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    className="btn_style"
                    style={{
                      backgroundColor: "#2F67F8",
                    }}
                    onClick={handleApply}
                  >
                    {" "}
                    <p className="raleway_20px_rem">Apply</p>
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    className="btn_style"
                    style={{
                      backgroundColor: "#FF472E",
                    }}
                    onClick={handleClear}
                  >
                    <p className="raleway_20px_rem">Clear</p>
                  </Button>
                </div>
              </div>}
              {
                isMobile &&   <div
                style={{
                  display: "flex",
                  alignSelf:"end",
                  gap: "10px",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  className="btn_style"
                  style={{
                    backgroundColor: "#2F67F8",
                  }}
                  onClick={handleApply}
                >
                  {" "}
                  <p className="raleway_20px_rem">Apply</p>
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  className="btn_style"
                  style={{
                    backgroundColor: "#FF472E",
                  }}
                  onClick={handleClear}
                >
                  <p className="raleway_20px_rem">Clear</p>
                </Button>
              </div>
              }
            </div>
          </Box>
        </Fade>
      </Modal>)}
      <div
        style={{
          height: isValueData.length === 0 ? "350px" : "90%",
          width: "100%",
          padding: "1vw",
        }}
        ref={chartContainerRef}
         id="prediction-chart"
      >
        {isValueData && isValueData.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
          <div ref={scrollableContainerRef} className="scrollable_chat-container">
       <div style={{ width: isMobile ? (isValueData?.categories.length > 0 ? isValueData?.categories.length * 60 : '100%') : (isValueData?.categories.length > 20 ? isValueData?.categories.length * 60 : '100%') }}>

        
              <Chart options={options} series={series} type={isValueData?.chart?.type} height={(isFullScreen && isMobileAndTab) ? "300" : (isFullScreen && isDesktopView) ? "500" : "350"} />
              </div>
            </div>
        )}
      </div>
    </div>
  );
};

export default NewBarLineChart;
