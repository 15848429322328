import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "./vcb.scss";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box } from "@mui/material";
import BarPlotForIOT from "../../../Graphs/BarPlotForIOT";
function VCBDetails() {
  const smblist = ["INV2VCB12", "VCB1", "VCB2", "VCB3", "VCB4", "VCB5", "VCB6"];

  const [deviceId, setDeviceId] = useState(0);

  const [gridHeight, setGridHeight] = useState(0);
  const [gridHeightTwo, setGridHeightTwo] = useState(0);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const gridRef = useRef(null);
  const gridRefTwo = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      const height = gridRef.current.offsetHeight;
      setGridHeight(height);
    }
  }, [gridRef]);
  useEffect(() => {
    if (gridRefTwo.current) {
      const height = gridRefTwo.current.offsetHeight;
      setGridHeightTwo(height);
    }
  }, [gridRefTwo]);
  const handleSMBDeatils = (index) => {
    setDeviceId(index);
  };
  const powerMetrics = [
    {
      name: "Reactive Power",
      value: "--",
    },
    ,
    {
      name: "Apparent Power",
      value: "--",
    },
    {
      name: "Frequency",
      value: "--",
    },
  ];
  const currentGrid = [
    {
      phase: "R-Phase Current",
      value: "--",
      iconSRC: "https://s3.amazonaws.com/smarttrak.co/v2Images/abtIcon.svg",
    },
    {
      phase: "Y-Phase Current",
      value: "--",
      iconSRC: "https://s3.amazonaws.com/smarttrak.co/v2Images/abtIcon.svg",
    },
    {
      phase: "B-Phase Current",
      value: "--",
      iconSRC: "https://s3.amazonaws.com/smarttrak.co/v2Images/abtIcon.svg",
    },
    {
      phase: "Neutral Current" + "\u00A0" + "\u00A0",
      value: "--",
      iconSRC: "https://s3.amazonaws.com/smarttrak.co/v2Images/abtIcon.svg",
    },
  ];
  const phaseCurrentVolt = [
    {
      phase: "R-Phase Voltage",
      value: "--",
    },
    {
      phase: "Y-Phase Voltage",
      value: "--",
    },
    {
      phase: "B-Phase Voltage",
      value: "--",
    },
    {
      phase: "R-Phase Current",
      value: "--",
    },

    {
      phase: "Y-Phase Current",
      value: "--",
    },

    {
      phase: "B-Phase Current",
      value: "--",
    },
  ];
  const otherDetails = [
    {
      phase: "Total Up Time",
      value: "35H",
    },
    {
      phase: "Total Down Time",
      value: "2H",
    },
    {
      phase: "Energy Loss",
      value: "2kW",
    },
  ];
  return (
    <div className="home-container">
      <Grid container className="grid-container-smb">
        {smblist.length > 0 ? (
          <Grid item lg={12} xs={12} sm={12} md={12} className="grid_padding">
            <Grid container className="cummulative_paper">
              <Grid
                item
                lg={12}
                xs={12}
                md={12}
                sm={12}
                className="flex_start_container"
              >
                <Paper className="paper_borderless">
                  <p className="raleway_18px_abt">VCB Selections</p>
                </Paper>
              </Grid>
              {smblist.length > 0 ? (
                smblist
                  .sort((a, b) => {
                    return a.localeCompare(b);
                  })
                  .map((ele, index) => (
                    <Grid
                      item
                      key={index}
                      lg={1.5}
                      xs={12}
                      sm={2.5}
                      md={1.8}
                      onClick={() => handleSMBDeatils(index)}
                      className="flex-start_gap"
                    >
                      <Paper
                        className="inv-names_paper"
                        sx={{
                          backgroundColor:
                            deviceId === index ? "#2F67F8 !important" : "#ffff",
                          border:
                            deviceId !== index
                              ? "0.0625rem solid #D8D8D8"
                              : "none",
                        }}
                      >
                        <div
                          className="inv-names_div"
                          style={{ justifyContent: "flex-start !important" }}
                        >
                          <p
                            className="Poppins_18px_rem"
                            style={{
                              fontWeight: "400",
                              color: deviceId === index ? "#ffff" : "#212121",
                            }}
                          >
                            {ele}
                          </p>
                        </div>
                      </Paper>
                    </Grid>
                  ))
              ) : (
                <div className="no-data">
                  <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>
                    No data is available!
                  </p>
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Grid
          item
          lg={2.8}
          xs={12}
          sm={5.8}
          md={5.8}
          className="paper_padding_nogap"
          ref={gridRef}
        >
          <Paper className="paper_borderless_center space_btn_gap">
            <div className="vcb_details space-btn-center">
              <div>
                <p className="raleway_18px_abt">Status</p>
              </div>
              <div
                className="green-status"
                style={{ backgroundColor: "green" }}
              >
                <div
                  className="status-on"
                  style={{
                    backgroundColor:
                      true === true
                        ? "rgb(113 213 69)"
                        : "red"
                        ? "#de6c64"
                        : "orange"
                        ? "#e49516"
                        : "rgb(113 213 69)",
                    border:
                      true === true
                        ? "0.0625rem solid #21ad3d"
                        : "red"
                        ? "0.0625rem solid #c3392f"
                        : "orange"
                        ? "0.0625rem solid #f65e0c"
                        : "0.0625rem solid #21ad3d",
                  }}
                ></div>
                <p className="raleway_8px_rem">
                  {"green" === "red" ? "OFF" : "ON"}
                </p>
              </div>
            </div>
            <div className="vcb_gap">
              <div
                style={{ width: "-webkit-fill-available" }}
                className="vcb_details"
              >
                <p className="raleway_18px_abt">ON</p>
              </div>
              <div
                style={{ width: "-webkit-fill-available" }}
                className="vcb_details"
              >
                <p className="raleway_18px_abt">OFF</p>
              </div>
            </div>
            <div className="vcb_details space-btn-center">
              <div>
                <p className="Poppins_16px_rem">Location</p>
              </div>
              <div className="center_div">
                <p className="raleway_18px_abt">
                  <OpenInNewIcon
                    sx={{
                      color: "#2F67F8",
                      backgroundColor: "transparent !important",
                    }}
                  />
                </p>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid
          item
          lg={2.8}
          xs={12}
          sm={5.8}
          md={5.8}
          className="paper_padding_nogap"
          sx={{
            height: {
              xs: "auto",
              sm: gridHeight,
              md: gridHeight,
              lg: gridHeight,
              xl: gridHeight,
            },
          }}
        >
          <Paper className="paper_borderless_center">
            <p className="raleway_18px_abt">Active Power</p>
          </Paper>
          <Paper className="paper_borderless_center">
            <Box
              component="img"
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/gaugeSVG.svg"
              alt="guage"
              sx={{
                width: {
                  xs: "13vw",
                  sm: "19vw",
                  md: "16vw",
                  lg: "12vw",
                  xl: "11vw",
                },
              }}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={2.8}
          xs={12}
          sm={5.8}
          md={5.8}
          className="paper_padding_nogap"
          sx={{
            height: {
              xs: "auto",
              sm: gridHeight,
              md: gridHeight,
              lg: gridHeight,
              xl: gridHeight,
            },
          }}
        >
          <Paper className="paper_borderless_center">
            <p className="raleway_18px_abt">Average Voltage</p>
          </Paper>
          <Paper className="paper_borderless_center">
            <Box
              component="img"
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/averageVoltage.svg"
              alt="guage"
              sx={{
                width: {
                  xs: "13vw",
                  sm: "19vw",
                  md: "16vw",
                  lg: "12vw",
                  xl: "8vw",
                },
              }}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={2.8}
          xs={12}
          sm={5.8}
          md={5.8}
          className="paper_padding_nogap"
          sx={{
            height: {
              xs: "auto",
              sm: gridHeight,
              md: gridHeight,
              lg: gridHeight,
              xl: gridHeight,
            },
          }}
        >
          <Paper className="paper_borderless_center">
            <p className="raleway_18px_abt">AC Current</p>
          </Paper>
          <Paper className="paper_borderless_center">
            <Box
              component="img"
              src="https://s3.amazonaws.com/smarttrak.co/v2Images/acCurrent.svg"
              alt="guage"
              sx={{
                width: {
                  xs: "13vw",
                  sm: "19vw",
                  md: "16vw",
                  lg: "12vw",
                  xl: "8vw",
                },
              }}
            />
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.9}
          xs={12}
          md={12}
          sm={12}
          className="paper_padding_one "
          sx={{
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: gridHeightTwo,
              xl: gridHeightTwo,
            },
          }}
        >
          <Grid className="grid-container-smb" container>
            <Grid
              item
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className="paper_borderless"
            >
              <Paper
                className="paper_borderless center_div_nogap"
                sx={{
                  gap: "2rem",
                }}
              >
                <div className="icon_div icon_div_border">
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/v2Images/powerMetrics.svg"
                    alt="powerMetrics"
                    style={{ width: "3vw", height: "-webkit-fill-available " }}
                  />
                </div>
                <div>
                  <p className="Poppins_24px_black">
                    Power and Frequency Metrics
                  </p>
                </div>
              </Paper>
            </Grid>
            {powerMetrics.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.7}
                xs={12}
                sm={3.7}
                md={3.7}
                className="metrix-grid"
              >
                <Paper className="metrix-paper">
                  <div className="metrix_div">
                    <p className="raleway_12px_rem">{ele.name}</p>

                    <p
                      className="Poppins_20px_rem"
                      style={{ color: "#2f67f8" }}
                    >
                      {ele.value}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          className="grid_padding"
          item
          lg={5.9}
          xs={12}
          md={12}
          sm={12}
          sx={{
            alignItems: "flex-start !important",
            borderRadius: "0.625rem !important",
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: gridHeightTwo,
              xl: gridHeightTwo,
            },
          }}
        >
          <Grid container className="grid-container-smb">
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="paper_borderless_center"
            >
              <Paper className="paper_borderless_center">
                <p className="Poppins_24px_black">LINE VCB - Meter</p>
              </Paper>
            </Grid>

            {currentGrid.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={5.8}
                xs={12}
                sm={5.7}
                md={5.7}
                className="border_none_transparent"
              >
                <Paper className="paper_padding_one center_div_nogap">
                  <div className="icon_div icon_div_border">
                    <img src={ele.iconSRC} alt="abtRYB" />
                  </div>
                  <div className="flex-start-column">
                    <p className="Poppins_24px_black">{ele.value}</p>
                    <p className="raleway_18px_abt">{ele.phase}</p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          className="grid_padding"
          ref={gridRefTwo}
          item
          lg={5.9}
          xs={12}
          md={12}
          sm={12}
          sx={{
            borderRadius: "0.625rem !important",
          }}
        >
          <Grid container className="grid-container-smb">
            <Grid
              item
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className="flex_start_container"
            >
              <Paper className="paper_borderless">
                <p className="Poppins_24px_black">
                  Voltage and Current with THD
                </p>
              </Paper>
            </Grid>
            {phaseCurrentVolt.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.7}
                xs={12}
                sm={3.6}
                md={3.8}
                className="metrix-grid"
              >
                <Paper
                  className="metrix-paper"
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <div className="metrix_div">
                    <p className="raleway_12px_rem">{ele.phase}</p>

                    <p
                      className="Poppins_16px_white"
                      style={{ color: "#2F67F8" }}
                    >
                      {ele.value}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          className="grid_padding"
          item
          lg={5.9}
          xs={12}
          md={12}
          sm={12}
          sx={{
            alignItems: "flex-start !important",
            borderRadius: "0.625rem !important",
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: gridHeightTwo,
              xl: gridHeightTwo,
            },
          }}
        >
          <Grid container className="grid-container-smb">
            <Grid
              item
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className="flex_start_container"
            >
              <Paper className="paper_borderless">
                <p className="Poppins_24px_black">Other Details</p>
              </Paper>
            </Grid>
            {otherDetails.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.6}
                xs={12}
                sm={3.8}
                md={3.7}
                className="metrix-grid"
              >
                <Paper
                  className="metrix-paper"
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <div className="metrix_div">
                    <p className="raleway_12px_rem">{ele.phase}</p>

                    <p
                      className="Poppins_16px_white"
                      style={{ color: "#2F67F8" }}
                    >
                      {ele.value}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12} className="exc-graph-grid">
          <Paper className="exc-graph-paper">
            <BarPlotForIOT
              isValueData={[]}
              StartTimer=""
              StopTimer=""
              openDrawerValue={setDrawerOpen}
              averagePerformaceRatio={[]}
              titleText="Power Graph"
              tooltipName="Power"
              indicateUnit="kWh"
              calenderClick="off"

              selectedDateRangeProp={[
                {
                  startDate: new Date(
                        new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                      ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default VCBDetails;
