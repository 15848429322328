import React, { useState } from "react";

const LossesDonutChart = ({ lossPadding, isValueData }) => {
 
  const [hoverIndexes, setHoverIndexes] = useState({});
  const handleMouseEnter = (index) => setHoverIndexes({ [index]: true });
  const handleMouseLeave = () => setHoverIndexes({});
const legendList = isValueData?.inverters.length > 0 ? isValueData?.inverters : []
  const generateColor = (index) => {
    const hue = (index * 37) % 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  const radius = 50;
  const circumference = 2 * Math.PI * radius;

  const validInverters = legendList.filter(inverter => inverter.loss.value !== null);
  const totalLoss = validInverters.reduce((acc, inverter) => acc + inverter.loss.value, 0);

  return (
    <div className="circular_main" id="media_flex_col" style={{ gap: "1rem", width: "100%", justifyContent: "center" }}>
      <div className="plant-info" style={{ gap: "0rem", padding: lossPadding || "0.5rem 3rem", borderRadius: "0.5rem", boxShadow: "0px 0px 8px 0px #00529A4D" }}>
        <p className="Poppins_16px_400" style={{ color: "black", textAlign: "center", fontWeight: "600" }}>Total Downtime</p>
        <p className="Poppins_16px_400" style={{ fontWeight: "600", color: "red" }}>{`${isValueData?.total_downtime ? isValueData?.total_downtime.value.toFixed(2) : "--"} ${isValueData?.total_downtime ? isValueData?.total_downtime.unit : ""}`}</p>
      </div>

      <div className="justify_space_betwn">
        <div className="circular_main circular_submain medium_width_150">
          <svg width="200" height="200" viewBox="0 0 120 120">
            <circle cx="60" cy="60" r={radius} stroke="#d6d6d6" strokeWidth="20" fill="none" />
            {validInverters.map((inverter, index) => {
              const value = inverter.loss.value;
              const segmentFraction = value / totalLoss;
              const segmentLength = segmentFraction * circumference;
              const strokeDasharray = `${segmentLength} ${circumference}`;
              const strokeDashoffset = validInverters.slice(0, index).reduce((offset, prevInverter) => {
                return offset + (prevInverter.loss.value / totalLoss) * circumference;
              }, 0);

              return (
                <circle
                  key={index}
                  cx="60"
                  cy="60"
                  r={radius}
                  stroke={generateColor(index)}
                  strokeWidth="20"
                  fill="none"
                  strokeDasharray={strokeDasharray}
                  strokeDashoffset={-strokeDashoffset}
                  transform="rotate(-90 60 60)"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                />
              );
            })}
            <text x="50%" y="35%" textAnchor="middle" dy=".3em" fontSize="20px" fill="#000">
              <tspan x="50%" dy="1.2em" className="Poppins_12px_rem">Overall</tspan>
              <tspan x="50%" dy="1.2em" className="Poppins_16px_rem">{`${isValueData?.overall_loss ? isValueData?.overall_loss?.value : "--"} ${isValueData?.overall_loss ? isValueData?.overall_loss?.unit : ""}`}</tspan>
            </text>
          </svg>
        </div>

        <div className="legend_power" id={legendList.length > 4 ? "legend_power_inv" : undefined} >
          {legendList.map((inverter, index) => (
            <div key={index} className="center_div_nogap">
              <div className="circle_power" style={{ backgroundColor: generateColor(index) }}></div>
              <div className="smb_value_container">
                <p className="Poppins_16px_400" style={{ fontWeight: "500" }}>{inverter.name}</p>
                <p className="Poppins_16px_rem" style={{ fontWeight: "600", color: "black"}}>
                  {inverter.loss.value !== null ? `${inverter.loss.value} ${inverter.loss.unit}` : "0 %"}
                </p>
              </div>
            </div>
          ))}
        </div>

        {hoverIndexes[Object.keys(hoverIndexes)[0]] && (
          <div className="powerTooltip">
             <p className="Poppins_16px_400">
                  Loss :{" "}
                  <span style={{ fontWeight: "600" }}>{`${legendList[Object.keys(hoverIndexes)[0]].loss.value || "--"} ${legendList[Object.keys(hoverIndexes)[0]].loss.unit || "%"}`}</span>
                </p>
                <p className="Poppins_16px_400">
                  Downtime :{" "}
                  <span style={{ fontWeight: "600", color: "red" }}>{`${legendList[Object.keys(hoverIndexes)[0]].downtime.value || "--"} ${legendList[Object.keys(hoverIndexes)[0]].downtime.unit || "%"}`}</span>
                </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LossesDonutChart;
