import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Divider from "@mui/material/Divider";

import ModalCalender from "../Common/ModalCalender";

const TimeSeriesBarPlots = (props) => {
  const { dataArray, calenderClick, selectedDateRangeProp ,titleText,
    isMobileAndTab,
    isDesktopView,
    isFullScreen, toggleFullScreen
  } =
    props;
  const [openDrawer, setOpenDrawer] = useState(false);

  const chartContainerRef = useRef(null);

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen(); 
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;

    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);

    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(xml);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };


  const seriesDataMap = new Map();
  dataArray.forEach((entry) => {
    const key = `${entry.inverter} - ${entry.field}`;
    if (seriesDataMap.has(key)) {
      seriesDataMap.get(key).push({
        x: new Date(entry.time).getTime(),
        y: entry.value,
      });
    } else {
      seriesDataMap.set(key, [
        {
          x: new Date(entry.time).getTime(),
          y: entry.value,
        },
      ]);
    }
  });

  const series = Array.from(seriesDataMap.entries()).map(([label, data]) => ({
    name: label,
    data: data,
  }));

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      zoom: {
        enabled: true,
      },
      // toolbar: {
      //   tools: {
      //     download: `<img src="https://s3.amazonaws.com/smarttrak.co/images/download.png" alt="download" title="Download"/>`,
      //   },
      // },
    },
    xaxis: {
      labels: {
        datetimeUTC: false,

        style: {
          colors: "#000000",
        },
      },
      title: {
        text: "Date & Time",
      },
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Value",
      },

      labels: {
        formatter: function (value) {
          if (value >= 1000) {
            return (value / 1000).toFixed(0) + "k";
          } else {
            return value;
          }
        },
      },
    },
    legend: {
      showForSingleSeries: true,
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd HH:mm",
      },
      y: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      title: {
        ...prevOptions.title,
        style: {
          color: "#000000",
        },
      },
      xaxis: {
        ...prevOptions.xaxis,
        type: "datetime",

        labels: {
          datetimeUTC: false,

          style: {
            colors: "#000000",
          },
        },
      },

      tooltip: {
        x: {
          format: "yyyy-MM-dd HH:mm",
        },
        y: {
          formatter: function (val) {
            return val.toFixed(2);
          },
        },
      },
      yaxis: {
        ...prevOptions.yaxis,
        style: {
          colors: "#000000",
        },

        labels: {
          formatter: function (value) {
            if (value >= 1000) {
              return (value / 1000).toFixed(0) + "k";
            } else {
              return value;
            }
          },
          style: {
            colors: "#000000",
          },
        },
      },
      legend: {
        ...prevOptions.legend,
        labels: {
          colors: "#000000",
        },
      },
    }));
  }, [isFullScreen, dataArray]);

  const handleTimings = () => {
    setOpenDrawer(true);
  };
  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);
    props.openDrawerValue(openDrawer);
  };

  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);
    props.openDrawerValue(openDrawer);
  };
  return (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1vw",
        alignItems: "center",
      }}
    >
      <div>
        <p
          className="Poppins_18px_rem"
          style={{ color: "#212121" }}
        >
          {titleText}
        </p>{" "}
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <Tooltip title="Download" arrow>
          <DownloadIcon
            onClick={downloadChart}
            sx={{
              width: "1.6vw !important",
              height: "fit-content",
              cursor: "pointer",
              color: "#00529A",
            }}
          />
        </Tooltip>

        <Tooltip title="Calendar" arrow>
          <CalendarMonthRoundedIcon
            onClick={handleTimings}
            sx={{
              width: "1.6vw !important",
              height: "fit-content",
              color: "#00529A",
              pointerEvents: calenderClick === "off" ? "none" : "auto",

              cursor: calenderClick === "off" ? "not-allowed" : "pointer",
            }}
          />
        </Tooltip>
        <Tooltip title="FullScreen" arrow>
          <button
            onClick={toggleFullScreen}
            style={{ border: "none", background: "none", cursor: "pointer" }}
          >
            {isFullScreen ? (
              <FullscreenExitIcon
                sx={{
                  color: "#00529A",
                  width: "1.6vw !important",
                  height: "fit-content",
                }}
              />
            ) : (
              <FullscreenIcon
                sx={{
                  color: "#00529A",
                  width: "1.6vw !important",
                  height: "fit-content",
                }}
              />
            )}
          </button>
        </Tooltip>
      </div>
    </div>
    <Divider style={{ border: "1px solid #D9D9D9" }} />

      {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
        />
      )}

<div
        style={{
          height: dataArray.length === 0 ? "350px" : "90%",
          width: "100%",
          padding: "1vw",
          display: dataArray.length === 0 && "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={chartContainerRef}
        id="barplotforIotchart"
      >
        {dataArray && dataArray.length === 0 ? (
          <div>
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
          <Chart options={options} series={series} type="bar" 
          height={(isFullScreen && isMobileAndTab) ? "300" : (isFullScreen && isDesktopView) ? "500" : "350"} />
        )}
      </div>
    </div>
  );
};

export default React.memo(TimeSeriesBarPlots);
