import React, { useState } from 'react';
import Button from "@mui/material/Button";
import { UserService } from '../../Services/UserService';
import { useSnackbar } from "notistack";
import SimpleBackdrop from '../../../LifeScience/components/common/SimpleBackdrop';
import BoldCommonText from '../../Common/BoldCommonText';

const userService = new UserService();
function OnBoardPlant() {
  const [plantData, setPlantData] = useState({
    name: '',
    type: '',
    capacity: '',
    description: '',
    onboarddate: '',
    imgurl: '',
    address: '',
    lat: '',
    long: '',
    city: '',
    state: '',
    country: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    type: '',
    capacity: '',
    description: '',
    onboarddate: '',
    imgurl: '',
    address: '',
    lat: '',
    long: '',
    city: '',
    state: '',
    country: '',
  });

  const [deviceData, setDeviceData] = useState({
    serial_number: "",
    model: "",
    manufacturer: "",
    warrenty_end_date: "",
    onboard_date: "",
    name: "",
    description: "",
    image_url: "",
    type: "",
    lat: "",
    long: "",
    status: true,
    device_status_code: "",
    plant_id: ""
  });
  const [deviceErrors, setDeviceErrors] = useState({
    serial_number: "",
    model: "",
    manufacturer: "",
    warrenty_end_date: "",
    onboard_date: "",
    name: "",
    description: "",
    image_url: "",
    type: "",
    lat: "",
    long: "",
    status: true,
    device_status_code: "",
    plant_id: ""
  });
  const [deviceId, setDeviceId] = useState("");

  const [solarData, setSolarData] = useState({
    device_id: deviceId,
    device_status_code: "",
    timestamp: "",
  });
  const [solarErrors, setSolarErrors] = useState({
    device_id: "",
    device_status_code: "",
    timestamp: "",
  });

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isPlantOnBoard, setIsPlantOnBoard] = useState(false);
  const [isData, setIsData] = useState(false);
  const [plantOnBoardData, setPlantOnBoardData] = useState({});


  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error when the user starts typing again
    }));
  };
  const handleDeviceChange = (e) => {
    const { name, value } = e.target;
    setDeviceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setDeviceErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error when the user starts typing again
    }));
  };
  const handleSolarChange = (e) => {
    const { name, value } = e.target;
    setSolarData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setSolarErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error when the user starts typing again
    }));
  };

  const validateFields = () => {
    let valid = true;
    const newErrors = {};
    Object.entries(plantData).forEach(([fieldName, value]) => {
      if (value.trim() === '') {
        newErrors[fieldName] = 'Field cannot be empty';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      // return;
      valid = false;
    }
    return valid;
  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    if (validateFields()) {
      let reqData = {
        name: plantData.name,
        type: plantData.type,
        capacity: plantData.capacity,
        description: plantData.description,
        onboarddate: plantData.onboarddate,
        imgurl: plantData.imgurl,
        address: plantData.address,
        lat: plantData.lat,
        long: plantData.long,
        city: plantData.city,
        state: plantData.state,
        country: plantData.country,
      };
      await userService
        .postOnBoardPlant(reqData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);
            setIsPlantOnBoard(true);
            setPlantOnBoardData(res.data)

          } else if (res.status === 422) {
            enqueueSnackbar("user does not exist or inactive user", {
              variant: "warning",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error", err);
          enqueueSnackbar("user does not exist or inactive user", { variant: "error" ,anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },});
        });
    } else {
      setLoading(false);
    }
  };
  const validateDeviceFields = () => {
    let valid = true;
    const newDeviceErrors = {};

    Object.entries(deviceData).forEach(([fieldName, value]) => {
      const trimmedValue = String(value).trim(); // Convert to string and then trim
      if (trimmedValue === '') {
        newDeviceErrors[fieldName] = 'Field cannot be empty';
      }
    });

    if (Object.keys(newDeviceErrors).length > 0) {
      setDeviceErrors(newDeviceErrors);
      valid = false;
    }
    return valid;
  };
  const validateSolarFields = () => {
    let valid = true;
    const newDeviceErrors = {};

    Object.entries(solarData).forEach(([fieldName, value]) => {
      const trimmedValue = String(value).trim(); // Convert to string and then trim
      if (trimmedValue === '') {
        newDeviceErrors[fieldName] = 'Field cannot be empty';
      }
    });

    if (Object.keys(newDeviceErrors).length > 0) {
      setSolarErrors(newDeviceErrors);
      valid = false;
    }
    return valid;
  };

  const handleDeviceDetails = async (event) => {
    event.preventDefault();

    setLoading(true);
    if (validateDeviceFields()) {
      let reqData = {
        serial_number: deviceData.serial_number,
        model: deviceData.model,
        manufacturer: deviceData.manufacturer,
        warrenty_end_date: deviceData.warrenty_end_date,
        onboard_date: plantOnBoardData.onboard_date,
        name: plantOnBoardData.name,
        description: deviceData.description,
        image_url: deviceData.image_url,
        type: deviceData.type,
        lat: deviceData.lat,
        long: deviceData.long,
        status: true,
        device_status_code: deviceData.device_status_code,
        plant_id: plantOnBoardData.plant_id
      };
      await userService
        .postOnBoardDevice(reqData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);
            setIsData(true)
            setDeviceId(res.data.device_id);

          } else if (res.status === 422) {
            enqueueSnackbar("user does not exist or inactive user", {
              variant: "warning",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error", err);
          enqueueSnackbar("user does not exist or inactive user", { variant: "error" ,anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },});
        });
    } else {
      setLoading(false);
    }
  };
  const handleSolarDetails = async (event) => {
    event.preventDefault();

    setLoading(true);
    if (validateSolarFields()) {
      let reqData = {
        device_id: deviceId,
        device_status_code: solarData.device_status_code,
        timestamp: solarData.timestamp,
      };
      await userService
        .postSolarData(reqData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);
            enqueueSnackbar(res.data.detail, {
              variant: "success",
            });

          } else if (res.status === 422) {
            enqueueSnackbar("user does not exist or inactive user", {
              variant: "warning",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error", err);
          enqueueSnackbar("user does not exist or inactive user", { variant: "error" ,anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },});
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className='exceutive-dashboard'>
      {loading && <SimpleBackdrop open={loading} />}
      {!isPlantOnBoard ? <><BoldCommonText styles={{ margin: "20px" }} type="Bold_700" text="Fill OnBoard Plant Details" /><div style={{ width: '40%', margin: "20px", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "20px" }}>
        {Object.keys(plantData).map((fieldName) => (
          <div key={fieldName}>
            <input
              type="text"
              name={fieldName}
              id={fieldName}
              value={plantData[fieldName]}
              placeholder={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
              onChange={handleChange}
              className={`input ${errors[fieldName] ? 'error' : ''}`}
              style={{
                border: '1px solid #ccc',
                width: '100%',
                marginLeft: '0px',
                borderRadius: '2px',
              }} />
            {errors[fieldName] && <div className="error-message">{errors[fieldName]}</div>}
          </div>
        ))}

        <Button
          type="submit"
          variant="contained"
          style={{
            backgroundColor: '#1C629E',
            width: '100%',
            marginTop: '40px',
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div></> : !isData ? <><BoldCommonText styles={{ margin: "20px" }} type="Bold_700" text="Fill OnBoard Device Details" /><div style={{ width: '40%', margin: "20px", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "20px" }}>
        {Object.keys(deviceData).map((fieldName) => (
          <div key={fieldName}>
            <input
              type="text"
              name={fieldName}
              id={fieldName}
              value={deviceData[fieldName]}
              placeholder={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
              onChange={handleDeviceChange}
              className={`input ${deviceErrors[fieldName] ? 'error' : ''}`}
              style={{
                border: '1px solid #ccc',
                width: '100%',
                marginLeft: '0px',
                borderRadius: '2px',
              }} />
            {deviceErrors[fieldName] && <div className="error-message">{deviceErrors[fieldName]}</div>}
          </div>
        ))}

        <Button
          type="submit"
          variant="contained"
          style={{
            backgroundColor: '#1C629E',
            width: '100%',
            marginTop: '40px',
          }}
          onClick={handleDeviceDetails}
        >
          Submit
        </Button>
      </div></> : ""}
      {isData && <div>
        <BoldCommonText styles={{ margin: "20px" }} type="Bold_700" text="Fill Below Details" />
        <div style={{ width: '22%', margin: "20px", display:"flex", flexDirection:"column", gap:"20px" }}>
          {Object.keys(solarData).map((fieldName) => (
            <div key={fieldName}>
              <input
                type="text"
                name={fieldName}
                id={fieldName}
                value={solarData[fieldName]}
                placeholder={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                onChange={handleSolarChange}
                className={`input ${solarErrors[fieldName] ? 'error' : ''}`}
                style={{
                  border: '1px solid #ccc',
                  width: '100%',
                  marginLeft: '0px',
                  borderRadius: '2px',
                }} />
              {solarErrors[fieldName] && <div className="error-message">{solarErrors[fieldName]}</div>}
            </div>
          ))}

          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: '#1C629E',
              width: '100%',
              marginTop: '40px',
            }}
            onClick={handleSolarDetails}
          >
            Submit
          </Button>
        </div>
      </div>}
    </div>
  );
}

export default OnBoardPlant;
