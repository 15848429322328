import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import "./ForgotPass.scss";
import TextField from "@mui/material/TextField";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { UserService } from "../IOT/Services/UserService";
import SimpleBackdrop from "../LifeScience/components/common/SimpleBackdrop";
const userService = new UserService();
function VerifyPassword() {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    id: "",
  });
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cpasswordError, setCpasswordError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowCPassword] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setUserData(() => ({
      email: location.state.email,
      id: location.state.id,
    }));
  }, [location]);

  const validateFields = () => {
    let valid = true;
    if (!password) {
      setPasswordError("Please enter your new password");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (password !== cpassword) {
      setCpasswordError("Password is not matched");
    } else {
      setCpasswordError("");
    }

    if (!otp) {
      setOtpError("Please enter otp");
    } else {
      setOtpError("");
    }

    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateFields()) {
      const data = new FormData(event.currentTarget);
      const reqData = {
        id: userData.id.toString(),
        email: userData.email,
        otp: data.get("otp"),
        password: data.get("password"),
      };
      await userService
        .changePassword(reqData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);
            alert("Password changed successfully");
            history.push("/signin");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error", err);
          alert("Error occured while verifying OTP", err.response.message);
        });
    } else {
      setLoading(false);
    }
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCpasswordChange = (event) => {
    setCpassword(event.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglecPasswordVisibility = () => {
    setShowCPassword(!showcPassword);
  };
  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      {loading && <SimpleBackdrop open={loading} />}
      <div className="signUpsmartlogo">
        <img
          src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg"
          width={160}
          style={{ marginLeft: "-150px" }}
          alt="images"
        />
      </div>
      <div className="main-container">
        <div className="left-container">
          <img src="https://s3.amazonaws.com/smarttrak.co/images/VerifyEmail.png" alt="img" width={400} />
        </div>
        <div className="right-container">
          <div className="forgotPasswordContainer">
            <span className="forgotPasswordText"> Change Password</span>
          </div>
          <p className="paragraph">
            We've sent a verification code to your email. Please enter it below
            to complete the change password process.
          </p>

          <TextField
            value={userData.email}
            margin="normal"
            required
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            disabled={true}
            style={{ width: "88%" }}
          />

          <input
            type="number"
            value={otp}
            placeholder="Enter OTP"
            name="otp"
            id="otp"
            onChange={handleOtpChange}
            className={`input ${otpError ? "error" : ""}`}
            style={{
              width: "88%",
              border: "1px solid grey",
              marginLeft: "0px",
            }}
          />
          {otpError && <div className="error-message">{otpError}</div>}
          <br />
          <div style={{ position: 'relative' }}>
          <div style={{ position: 'relative', width: '100%' }}>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            placeholder="New Password"
            name="password"
            id="password"
            onChange={handlePasswordChange}
            className={`input ${passwordError ? "error" : ""}`}
            style={{
              width: "88%",
              border: "1px solid grey",
              marginLeft: "0px",
            }}
          />
           <span
          onClick={togglePasswordVisibility}
          style={{
            position: 'absolute',
            top: '55%',
            right: '90px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </span>
        </div>
        </div>
          {passwordError && (
            <div className="error-message">{passwordError}</div>
          )}
          <br />

          <div style={{ position: 'relative' }}>
          <div style={{ position: 'relative', width: '100%' }}>
          <input
            type={showcPassword ? 'text' : 'password'}
            value={cpassword}
            placeholder="Confirm Password"
            name="cpassword"
            onChange={handleCpasswordChange}
            className={`input ${cpasswordError ? "error" : ""}`}
            style={{
              width: "88%",
              border: "1px solid grey",
              marginLeft: "0px",
            }}
          />
            <span
          onClick={togglecPasswordVisibility}
          style={{
            position: 'absolute',
            top: '55%',
            right: '90px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
        >
          {showcPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </span>
        </div>
        </div>
          {cpasswordError && (
            <div className="error-message">{cpasswordError}</div>
          )}
          <br />

          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#1C629E",
              width: "88%",
            }}
          >
            Submit
          </Button>
          <p className="backToLogin">
            <a
              href="/forgot-password"
              style={{
                textDecoration: "none",
                fontFamily: "Poppins",
                fontSize: "17px",
                color: "#000000",
              }}
            >
              Back
            </a>
          </p>
        </div>
      </div>
    </form>
  );
}

export default VerifyPassword;
