
import { enqueueSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const useErrorHandler = () => {
  const history = useHistory();

  const handleFetchError = (error, dataType) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          enqueueSnackbar(error.response?.data?.detail || "An error occurred", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
        case 404:
          enqueueSnackbar(error.response?.data?.detail || "An error occurred", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
        case 403:
          history.push("/signin");
          break;
        case 500:
          enqueueSnackbar(`Internal Server Error: ${dataType}`, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
        default:
          console.log(`Error while fetching the ${dataType}`, error);
          enqueueSnackbar(`Error while fetching the ${dataType}`, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
      }
    } else {
      console.log(`Error while fetching the ${dataType}`, error);
      enqueueSnackbar(`Error while fetching the ${dataType}`, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
    }
  };

  return handleFetchError;
};

export default useErrorHandler;
