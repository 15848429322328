import React, { useMemo, useEffect, useState } from "react";
import DynamicArrow from "../SVG/DynamicArrow";

function EnergyData(props) {
  const [isMobile, setIsMobile] = useState(false);
  const {
    ACEnergy,
    DCEnergy,
    ghiValue,
    title,
    colorCode,
    unit,
    ACValue,
    ACPercent,
    ghiPercent,
    ghiVal,
  } = props;

  const upwardPath =
    "M5.20759 0.529365C5.6079 0.00935769 6.3921 0.00935763 6.79241 0.529365L11.6888 6.89001C12.195 7.54757 11.7263 8.5 10.8964 8.5H1.10358C0.273737 8.5 -0.195026 7.54757 0.311171 6.89001L5.20759 0.529365Z";
  const downwardPath =
    "M6.79241 8.47063C6.3921 8.99064 5.6079 8.99064 5.20759 8.47063L0.311171 2.10999C-0.195026 1.45243 0.273737 0.5 1.10358 0.5H10.8964C11.7263 0.5 12.195 1.45243 11.6888 2.10999L6.79241 8.47063Z";
  const greenFill = "#30B900";
  const redFill = "#FF0000";
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  const arrowComponentAC = useMemo(
    () =>
      ACPercent < 0 ? (
        <DynamicArrow pathData={downwardPath} fillColor={redFill} width={12} height={12} />
      ) : (
        <DynamicArrow pathData={upwardPath} fillColor={greenFill} width={12} height={12} />
      ),
    [ACPercent]
  );

  const arrowComponentGHI = useMemo(
    () =>
      ghiPercent < 0 ? (
        <DynamicArrow pathData={downwardPath} fillColor={redFill} width={12} height={12} />
      ) : (
        <DynamicArrow pathData={upwardPath} fillColor={greenFill} width={12} height={12} />
      ),
    [ghiPercent]
  );

  const ACInfo = useMemo(
    () => (
      <div>
        {ACValue && ACPercent ? (
          <div
            style={{
              height: isMobile ? "auto" : "100%",
              display: "flex",
              flexDirection: isMobile ? "row" : "column",
              justifyContent: isMobile ? "flex-start" : "space-between",
              alignItems: "center",
              gap:isMobile ? "0.4rem" : "0.7rem"

            }}
          >
           
               {arrowComponentAC}
              <p
                className="Poppins_12px_rem font_8px"
                style={{
                  fontWeight: "600", textAlign:isMobile ? "inherit" : "center",
                  color: ACPercent < 0 ? "red" : "#30B900",
                }}
              >
                {`${ACValue} ${unit}`}
              </p>

              <p
                className="Poppins_12px_rem font_8px"
                style={{
                  fontWeight: "600",
                  color: ACPercent < 0 ? "red" : "#30B900",
                }}
              >
                {`(${ACPercent} %)`}
              </p>
          </div>
        ) : (
          <p
            className="Poppins_12px_rem font_8px"
            style={{ fontWeight: "600", color: "#30B900" }}
          >
            {`-- kWh (-- %)`}
          </p>
        )}
      </div>
    ),
    [ACValue, ACPercent, arrowComponentAC, unit]
  );

  const GHIInfo = useMemo(
    () => (
      <div>
        {ghiVal && ghiPercent ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              gap:"0.7rem"
            }}
          >
           
                {arrowComponentGHI}
              <p
                className="Poppins_12px_rem font_8px"
                style={{
                  fontWeight: "600",
                  textAlign:"center",
                  color: ghiPercent < 0 ? "red" : "#30B900",
                }}
              >
                {`${ghiVal} kWh/m²`}
              </p>
              <p
                className="Poppins_12px_rem font_8px"
                style={{
                  fontWeight: "600",
                  color: ghiPercent < 0 ? "red" : "#30B900",
                }}
              >
                {`(${ghiPercent} %)`}
              </p>
          
          </div>
        ) : (
          <p
            className="Poppins_12px_rem font_8px"
            style={{ fontWeight: "600", color: "#30B900" }}
          >
            {`-- kWh/m² (-- %)`}
          </p>
        )}
      </div>
    ),
    [ghiVal, ghiPercent, arrowComponentGHI]
  );
  const mobileGHIInfo = useMemo(
    () => (
      <div>
        {ghiVal && ghiPercent ? (
          <div
            style={{
              height: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap:isMobile ? "0.4rem" : "0.7rem"
            }}
          >
           
                {arrowComponentGHI}
              <p
                className="Poppins_12px_rem font_8px"
                style={{
                  fontWeight: "600",
                  textAlign: "inherit",
                  color: ghiPercent < 0 ? "red" : "#30B900",
                }}
              >
                {`${ghiVal} kWh/m²`}
              </p>
              <p
                className="Poppins_12px_rem font_8px"
                style={{
                  fontWeight: "600",
                  color: ghiPercent < 0 ? "red" : "#30B900",
                }}
              >
                {`(${ghiPercent} %)`}
              </p>
          
          </div>
        ) : (
          <p
            className="Poppins_12px_rem font_8px"
            style={{ fontWeight: "600", color: "#30B900" }}
          >
            {`-- kWh/m² (-- %)`}
          </p>
        )}
      </div>
    ),
    [ghiVal, ghiPercent, arrowComponentGHI]
  );



  return (
    <div className="energy_data_container">
      <p
        className="raleway_16px_rem font_14px"
        style={{ fontWeight: "700", color: colorCode }}
      >
        {title}
      </p>
      <div
        style={{
          padding: "10px",
          borderRadius: "0.7rem",
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "space-between",
          height: isMobile ? "auto" : "7rem",
          flexDirection: isMobile ? "column" : "row",
          gap: "5px",
          backgroundColor: "#F1F1F7",
          borderLeft: `0.2vw solid ${colorCode}`,
        }}
      >
       {isMobile ? 
       <> <p className="raleway_14px_rem">AC Energy</p>
        <p className="Poppins_18px_rem font_14px">{ACEnergy} {unit}</p>
        </> 
        : <div style={{ display: "flex", flexDirection: "column", gap: "1rem" ,justifyContent: isMobile ? "space-between" : "center"}}>
          <p className="raleway_14px_rem">AC Energy</p>
          <p className="Poppins_18px_rem font_14px">
            {ACEnergy} {unit}
          </p>
        </div>}
        {ACInfo}
      </div>
      {DCEnergy && (
        <div
          style={{
            backgroundColor: "#F1F1F7",
            padding: "10px",
            borderRadius: "0.7rem",
            display: "flex",
            height: isMobile ? "auto" : "7rem",

            justifyContent: isMobile ? "flex-start" : "space-between",
            flexDirection: isMobile ? "column" : "row",
            gap: "5px",
            borderLeft: `0.2vw solid ${colorCode}`,
          }}
        >
         {isMobile ? <><p className="raleway_14px_rem">GHI</p>
          <p className="Poppins_18px_rem font_14px">{ghiValue} kWh/m²</p>
          {mobileGHIInfo}
          </> : 
          <>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem",justifyContent:isMobile ? "space-between" : "center" }}
          >
            <p className="raleway_14px_rem">GHI</p>
            <p className="Poppins_18px_rem font_14px">{ghiValue} kWh/m²</p>
          

          </div>
          {GHIInfo}
          </>
          }

          
        </div>
      )}
    </div>
  );
}

export default EnergyData;