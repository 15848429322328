import React, { useState, useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {  Logout } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./SideBar.scss";
import { useSnackbar } from "notistack";
import { AuthService } from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
const authService = new AuthService();

function UpperBoard() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

 
  useEffect(() => {
    fetchUserData();
  }, []);

  const handleOpenMenu = () => {
    setExpanded(true);
  };
  const handleCloseMenu = () => {
    setExpanded(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("plantIds");
    history.push("/signin");
  };

  const fetchUserData = async () => {
    try {
      const res = await authService.getUserData();
      if (res.status === 200) {
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setEmail(res.data.email);
        localStorage.setItem("firstName", res.data.first_name);
        localStorage.setItem("lastName", res.data.last_name);
        localStorage.setItem("email", res.data.email);
      }
    } catch (err) {
      console.log("Error in fetchUserData", err);
      enqueueSnackbar(
        err.response?.data?.detail
          ? err.response?.data?.detail
          : "Error while fetching user data",
        { variant: "error" }
      );
    }
  };

  return (
    <div>
      <AppBar
        position="static"
        style={{
          minWidth: "100%",
          maxHeight: "65px",
          backgroundColor: "#DAEEFF",
        }}
      >
        <Toolbar className="maintoolBar">
          <div className="imgClass">
            <img src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg" width={160} alt="images" />
          </div>
          <div className="icon-box">
            <AccountCircleIcon style={{ fontSize: "35px" }} />
            <p style={{ marginTop: "3px" }}>
              {firstName} {lastName}
            </p>
            <ExpandMoreIcon
              style={{ fontSize: "30px", marginTop: "3px" }}
              onClick={!expanded ? handleOpenMenu : handleCloseMenu}
            />
          </div>
        </Toolbar>
      </AppBar>
      {expanded && (
        <div className="userDetails">
          <div className="logout">
            {" "}
            <MailOutlineOutlinedIcon /> <p className="email">{email}</p>
          </div>
          <div className="logout">
            {" "}
            <Logout />
            <p onClick={handleLogout}>LOGOUT</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpperBoard;
