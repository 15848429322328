import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import SimpleBackdrop from '../../../../LifeScience/components/common/SimpleBackdrop';
import "./Inverter.scss";
import { useSnackbar } from "notistack";
import Dropdown from "react-dropdown";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CosmicInverterService } from '../../../Services/CosmicInverterService';
import TimeSeriesChart from '../../../Graphs/TimeSeriesChart';
import TimeSeriesBarPlots from '../../../Graphs/TimeSeriesBarPlots';
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slider from "react-slick";
import { useMobile } from '../../../Context/MobileContext/MobileContext';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const invService = new CosmicInverterService();
function CompareInverters() {

  const location = useLocation();

  const { invList } = location.state || {};
  const { isMobileAndTab, isDesktopView } = useMobile();
  const [inverterFields, setInverterFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [CheckedField, setCheckedField] = useState([]);
  const [inverterField, setInverterField] = useState(invList.map(item => item.device_details.id));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
  });
  const [isStartTime, setIsStartTime] = useState("");
  const [isStopTime, setIsStopTime] = useState("");

  const [isGraph, setIsGraph] = useState("Line Chart");
  const [dataArray, setDataArray] = useState([]);
  const [openNoData, setOpenNoData] = useState(false);
  const [openViewData, setOpenViewData] = useState(false);
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [graphIndicateUnit, setGraphIndicateUnit] = useState("");

  useEffect(() => {
    graphData();
  }, [isGraph, graphIndicateUnit]);
  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };
  useEffect(() => {
    if (location.state) {
      const { activeInvField, invList } = location.state;
      setDeviceFieldValue(activeInvField);
      setInverterFields(invList);


      if (isStartTime.length === 0 && isStopTime.length === 0) {
        setInverterField(invList.map(item => item.device_details.id));
        setCheckedField(activeInvField[0]);
        handleMultiFieldCompare(
          invList.map(item => item.device_details.id),
          activeInvField[0],
          isStartTime,
          isStopTime
        );
      }
      else {

        const fetchData = () => {
          handleMultiFieldCompare(inverterField, CheckedField, isStartTime,
            isStopTime);
        };
        fetchData();
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTime, location.state]);
  const GraphValue = ["Line Chart", "Bar Graph"];
  const handleCheckboxChange = (event) => {
    handleMultiFieldCompare(inverterField, event, isStartTime,
      isStopTime);
    setCheckedField(event);
  };
  const graphDataUnit = (graphUnit) => {
    switch (graphUnit) {
      case "output_power_kW":
        return setGraphIndicateUnit("kW");
      case "output_current_A":
        return setGraphIndicateUnit("A");

      case "energy_counter_MWh":
        return setGraphIndicateUnit("MWh");
      case "net_frequency_Hz":
        return setGraphIndicateUnit("Hz");
      case "net_AC_voltage_V" || "DC_voltage_DCV":
        return setGraphIndicateUnit("V");

      case "average_cosphii_percent":
        return setGraphIndicateUnit("%");
      default:
        return setGraphIndicateUnit("")
    }
  };
  const graphData = () => {
    switch (isGraph) {
      case "Line Chart":
        return (
          <TimeSeriesChart
            dataArray={dataArray}
            titleText="Inverter Performance Comparison"
            calenderClick="on"
            StartTimer={setIsStartTime}
            StopTimer={setIsStopTime}
            openDrawerValue={setDrawerOpen}
            IndicateUnit={graphIndicateUnit}
            selectedDateRangeProp={[
              {
                startDate:isStartTime?isStartTime:new Date(),
                endDate: isStopTime? isStopTime:new Date(),
                key: "selection",
              },
            ]}
            isMobileAndTab={isMobileAndTab}
            isFullScreen={fullScreenStates.graph1}
            toggleFullScreen={() => toggleFullScreen('graph1')}
            isDesktopView={isDesktopView}
          />
        );
      case "Bar Graph":
        return (
          <TimeSeriesBarPlots
            dataArray={dataArray}
            titleText="Inverter Performance Comparison"
            calenderClick="on"
            StartTimer={setIsStartTime}
            StopTimer={setIsStopTime}
            openDrawerValue={setDrawerOpen}
            IndicateUnit={graphIndicateUnit}
            selectedDateRangeProp={[
              {
                startDate:isStartTime?isStartTime:new Date(),
                endDate: isStopTime? isStopTime:new Date(),
                key: "selection",
              },
            ]}
            isMobileAndTab={isMobileAndTab}
            isFullScreen={fullScreenStates.graph2}
            toggleFullScreen={() => toggleFullScreen('graph2')}
            isDesktopView={isDesktopView}
          />
        );

      default:
        return (
          <TimeSeriesChart
            dataArray={dataArray}
            calenderClick="on"
            titleText="Inverter Performance Comparison"
            StartTimer={setIsStartTime}
            StopTimer={setIsStopTime}
            openDrawerValue={setDrawerOpen}
            Graphtype="area"
            selectedDateRangeProp={[
              {
                startDate:isStartTime?isStartTime:new Date(),
                endDate: isStopTime? isStopTime:new Date(),
                key: "selection",
              },
            ]}
          />
        );
    }
  };
  const handleChangeGraph = (graph) => {
    setIsGraph(graph.value);
  };
  const handleClose = () => {
    setOpenNoData(false);
    setOpenViewData(false);
  };
  const handleMultiFieldCompare = async (ids, fields, start_time, end_time) => {
    try {
      setLoading(true);

      const res = await invService.getMultiFieldCompare(
        ids,
        fields,
        start_time,
        end_time
      );
      if (res.status === 200 && Object.keys(res.data).length > 0) {
        setLoading(false);
        const { timestamp_edge, name, ...otherFields } = res.data;
        const isDataArray = [];
        const otherFieldValues = Object.values(otherFields);
        const firstFieldValue = otherFieldValues[0];
        const otherKeys = Object.keys(otherFields).filter(
          (key) => key !== "timestamp_edge" && key !== "name"
        );
        graphDataUnit(otherKeys[0]);

        Object.keys(timestamp_edge).forEach((key) => {
          const time = timestamp_edge[key];
          const inverter = name[key];
          const value = firstFieldValue[key];

          if (value !== 0) {
            const dataObject = {
              inverter: inverter,
              value: value,
              time: time,
              field: otherKeys[0],
            };
            isDataArray.push(dataObject);
          }
        });
        setDataArray(isDataArray);
      }

    } catch (err) {
      console.log("Error while comparing multi fields data", err);
      setDataArray([]);
      enqueueSnackbar(
        err.response?.data
          ? err.response?.data.detail
          : "Error while fetching fields data",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }
      );
      setLoading(false);
    }
  };
  const handleInverterChange = (event, id) => {
    const { checked } = event.target;
    if (checked) {
      const updatedField = [...inverterField, id];
      setInverterField(updatedField);
  
      handleMultiFieldCompare(
        updatedField.join(","),
        Array.isArray(CheckedField) ? CheckedField.join(",") : CheckedField, 
        isStartTime,
        isStopTime
      );
    } else {
      if (inverterField.length > 1) {
        const updatedInverterField = inverterField.filter(
          (field) => field !== id
        );
        setInverterField(updatedInverterField);
  
        handleMultiFieldCompare(
          updatedInverterField.join(","),
          Array.isArray(CheckedField) ? CheckedField.join(",") : CheckedField, 
          isStartTime,
          isStopTime
        );
      } else {
        enqueueSnackbar("At least one inverter should be checked",
          {
            variant: "warning",
            
          }
        );
        // If only one checkbox is left, do not allow uncheck
        console.warn("At least one value should be checked.");
      }
    }
  };

  return (
    <div className='home-container'>
      {loading && <SimpleBackdrop open={loading} />}
      <Grid
        container
        className='center_center_flex_gap'
      >
        <Grid
          item
          lg={2}
          xs={12}
          sm={12}
          md={2}
          className='center_center_grid-container'
        >
          <Paper
            className='flex-column-paper'
          >
            <p className='Poppins_24px_rem' style={{ color: "#111C43" }}>Comparison of Inverters</p>
            <div className='download-btn'>
              <img src='https://s3.amazonaws.com/smarttrak.co/v2Images/download_icon.svg' alt='download' className='download_icon' />
              <p className='Poppins_12px_rem'>Download</p>
            </div>
          </Paper>
        </Grid>

        <Grid
          item
          lg={7.91}
          xs={12}
          sm={10}
          md={7.91}
          className='flex-scroll-container'
        >
          {invList.filter(
            (item) =>
              item.device_details !== null && item.device_details.name !== null
          ).map((inverter, index) => {
            return (<div
              key={index}
              className='inline_block'
            >
              <Paper
                className='column-center-paper'
              >
                <img src='https://s3.amazonaws.com/smarttrak.co/v2Images/inverter.svg' alt='inverter' className='inverter_icon' />
                <p className='Poppins_20px_rem' style={{ color: "black" }} >{inverter.device_details.name.charAt(0).toUpperCase() + inverter.device_details.name.slice(1).toLowerCase()}</p>
              </Paper>

            </div>)
          })}
        </Grid>
        <Grid
          item
          lg={1.9}
          xs={12}
          sm={1.9}
          md={1.9}
          className='grid-item'
        >
          <Paper
            className='paper-container'
          >
            <img src='https://s3.amazonaws.com/smarttrak.co/v2Images/add_icon.svg' alt='add' className='add_icon' />
            <p className='Poppins_14px_rem' style={{ color: "#646464", textAlign:"center" }}>Add Inverter</p>
          </Paper>
        </Grid>
        <Grid
          item
          lg={11.95}
          xs={12}
          sm={12}
          md={11.95}
          className='fault_grid'
        >
          <Paper
            className='fault-paper'
          >
            <div className='playground_heading'>
              <p className='raleway_18px_rem' style={{ fontWeight: "700" }}>Playground</p></div>
            {openViewData && (
              <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={openViewData}
              >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  <p className='Poppins_12px_rem'>Checking Details</p>
                </DialogTitle>

                <DialogContent dividers>
                  <p className='Poppins_12px_rem'>We're currently encountering data retrieval issues, possibly due to various factors. Our
                    team is systematically investigating each potential cause to address the situation effectively.</p>

                  <ul className='fault_ul_style'
                  >
                    <li>
                      <p className='Poppins_12px_rem'>Make sure that you have applied the filters for the field</p>
                    </li>
                    <li>
                      <p className='Poppins_12px_rem'>Please check with the filter by changing the fields</p>
                    </li>
                    <li>
                      <p className='Poppins_12px_rem'>Please select the other time range or go with the custom time range setting</p>
                    </li>
                  </ul>
                </DialogContent>
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={handleClose}
                    style={{
                      fontFamily: "Poppins-SemiBold",
                      fontSize: "0.9375rem",
                      color: "#315CE3",
                    }}
                  >
                    Okay
                  </Button>
                </DialogActions>
              </BootstrapDialog>
            )}

            <div
              className="compareInvgraph-container"
            >
              <Slider>{graphData()}</Slider>
            </div>
            <div className="compare-inv-subcontainer"
            >
              <div className='compare_invGraphs'>
                <div className='center_center_full-width'
                >
                  <div style={{ width: "-webkit-fill-available" }}>
                    <p className='Poppins_16px_rem' style={{ marginBottom: "0.625rem" }}>Graph</p>

                    <Dropdown
                      style={{
                        width: "17vw",
                        minWidth: 160,
                        border: "1px solid rgba(0, 0, 0, 0.50)",
                        borderRadius: "0.9375rem",
                        cursor: "pointer ! important",
                      }}
                      className="recommendations-text"
                      options={GraphValue}
                      onChange={handleChangeGraph}
                      value={isGraph}
                      placeholder="Select an option"
                    />
                  </div>
                </div>

                <div>
                  <p className='Poppins_16px_rem'>Compare Inverters</p>
                  <FormGroup
                    className='form-group'
                  >
                    {" "}
                    {inverterFields
                      .filter(
                        (item) =>
                          item.device_details !== null
                      )
                      .map((item, index) => (
                        <FormControlLabel
                          className='formControlLabel'
                          key={index}
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              sx={{
                                width: 24,
                                height: 24,
                                color: "#172b4d !important",
                              }}
                              checked={
                                Array.isArray(inverterField) &&
                                inverterField.includes(item.device_details.id)
                              }
                              onChange={(e) =>
                                handleInverterChange(e, item.device_details.id)
                              }
                            />
                          }
                          label={<p className='Poppins_12px_rem' style={{ marginLeft: "0.75rem" }}>{item.device_details.name}</p>

                          }
                          name={item.device_details.name}
                        />
                      ))}
                  </FormGroup>
                </div>

                <div className="compare_button"
                  onClick={() => {
                    handleMultiFieldCompare(
                      inverterField,
                      Array.isArray(CheckedField)
                        ? CheckedField.join(",")
                        : CheckedField,
                      isStartTime,
                      isStopTime
                    );
                  }}>
                  <p className="raleway_12px_rem" style={{ color: "white" }}>Submit</p>
                </div>
              </div>

              <div
                style={{
                  marginLeft: "0.625rem",
                }}
              >
                <p className='Poppins_16px_rem' style={{ marginBottom: "0.625rem" }}>
                  Filters
                </p>

                <FormGroup
                  sx={{
                    textAlign: "center",
                    paddingLeft: "0.9375rem",
                    overflow: "auto",
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "0.625rem",
                  }}
                >

                  {deviceFieldValue.map((ele, index) => {
                    return (
                      <FormControlLabel
                        sx={{
                          width: "250px",
                          height: "42px",
                          fontSize: "2px",
                          border: "1px solid black",
                          borderRadius: "39px",
                          display: "flex",
                          flexDirection: "row",
                          padding: "0px 10px",
                          overflowY: "auto",
                          overflowX: "auto",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                        key={index}
                        control={
                          <Radio
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            sx={{
                              width: 32,
                              height: 32,
                              color: "#172b4d !important",
                            }}
                            checked={CheckedField === ele}
                            onChange={() => handleCheckboxChange(ele)}
                          />
                        }
                        label={<p className='Poppins_12px_rem' style={{ marginLeft: "10px" }}>{ele}</p>

                        }
                        name={ele}
                      />
                    );
                  })}
                </FormGroup>
              </div>
            </div>
          </Paper>
        </Grid>

      </Grid>


    </div>
  )
}

export default CompareInverters