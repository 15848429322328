import React from "react";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import CommonText from "./CommonText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BoldCommonText from "./BoldCommonText";

function ModalPopup(props) {
  const { openDrawer, setOpenDrawer } = props;
  const history = useHistory();

  const handleContactUs = () => {
    history.push("/about-us#contact-us");
  };

  const handleCancel = (event) => {
    event.preventDefault();

    setOpenDrawer(false);
  };

  return (
    <Dialog
      open={openDrawer}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <BoldCommonText type="SemiBold_500" text="Get in touch with us " />
      </DialogTitle>
      <DialogContent>
        <CommonText
          type="Regular_400"
          text="You are not associated with a solar plant. Feel free to get in touch with us to explore the potential of predictive maintenance, forecasting, and gaining insights on your device using AI."
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          style={{
            backgroundColor: "#1C629E",
            display: "flex",
            alignSelf: "end",
            textTransform:"capitalize"
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          style={{
            backgroundColor: "#1C629E",
            display: "flex",
            alignSelf: "end",
            textTransform:"capitalize"
          }}
          onClick={handleContactUs}
        >
          Contact Us
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalPopup;
