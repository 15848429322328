import React from 'react'
import { Container } from "@mui/material";
import Box from '@mui/material/Box';
import CustomText from "../../components/CustomText/CustomText";
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CustomButton from "../../components/CustomButton/CustomButton";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useHistory } from "react-router-dom";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#D5E2EE' : '#D5E2EE',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary, 
    display:'flex',
    paddingLeft:"20px",
  }));
function PredictError() {

  const history = useHistory();

  const handleUploadAgain=()=>{
   
    history.push({
      pathname: `/home/predict-ai`,
      
    });
  }
  return (
    <Container component="main" maxWidth="lg" style={{ paddingTop: "50px" }}>
         
<Box sx={{ minWidth: 100,textAlign:"center"}}>
<Stack spacing={2}>
<Item style={{backgroundColor:'#D5E2EE'}}>
<img
          src="https://s3.amazonaws.com/smarttrak.co/images/errorPrediction.png"
          width={27}
          alt="images"
        />  
<CustomText
        type="SemiBold_24px"
        text="Data Prediction Failed !!!"
        styles={{ color: "#ff0000",paddingLeft:"20px" }}
      />
      </Item></Stack>
      </Box>
      <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" style={{fontSize:"20px"}}> 
        Your Data Prediction Failed Due to the below reasons
        </ListSubheader>
      }
    >
      <ListItemButton>
        <ListItemIcon>
          <FiberManualRecordIcon   style={{ color:'#1C629E'}}/>
        </ListItemIcon>
        <ListItemText primary="Internal Server Error" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <FiberManualRecordIcon style={{color:"#1C629E"}} />
        </ListItemIcon>
        <ListItemText primary="Upload only .csv,.tsv etc files only" />
      </ListItemButton>
      
      
    </List>
    <CustomButton
                            type="Simple"
                            text="Upload Again"
                            styles={{ width: "12rem" }}
                            onClick={handleUploadAgain}
                          />
    </Container>
  )
}

export default PredictError