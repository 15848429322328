import React from "react";
import Plot from "react-plotly.js";
function HistogramChart() {
  const data = [
    { x: [1, 2, 3, 4, 4, 4, 5, 5, 6, 6, 6, 6, 7, 7, 8, 9], type: "histogram" },
  ];
  return (
    <div>
      <Plot data={data} layout={{ width: 500, height: 350, bargap: 0.1,yaxis: { title: "Probability" },
    xaxis: { title: "Protien ID" }, }} />
    </div>
  );
}

export default HistogramChart;
